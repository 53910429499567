<template>
  <div class="box">
    <div class="content">
      <div>
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            label-width="auto"
            class="demo-ruleForm"
            status-icon
        >
          <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;width: 85%">
            <el-form-item :label="`${$t('sending.customer.search.no')}:`" prop="taskCode">
              <el-input v-model="ruleForm.taskCode" :placeholder="$t('common.please.input')"/>
            </el-form-item>
            <el-form-item style="width: 200px" :label="`${$t('sending.customer.search.massMailType')}:`"
                          prop="massMailType">
              <el-select v-model="ruleForm.massMailType" :placeholder="$t('common.please.select')">
                <el-option :label="$t('sending.customer.option.massMailType.system')" :value="1"/>
                <el-option :label="$t('sending.customer.option.massMailType.smart')" :value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item :label="`${$t('sending.customer.search.createName')}:`" prop="createBy"
                          style="width: 240px">
              <el-select v-model="ruleForm.createBy" :placeholder="$t('common.please.select')"
                         :default-first-option="true">
                <el-option
                    v-for="item in tableDataSelect"
                    :label="item.userName"
                    :value="item.userId"
                    :key="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="`${$t('sending.customer.search.targetTime')}:`" prop="targetTime"
                          style="width: 340px">
              <el-date-picker
                  style="width: 100%;"
                  v-model="ruleForm.targetTime"
                  type="daterange"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  date-format="YYYY/MM/DD"
                  range-separator="~"
                  :start-placeholder="$t('sending.customer.search.startTime')"
                  :end-placeholder="$t('sending.customer.search.endTime')"
              />
            </el-form-item>
            <el-form-item :label="`${$t('sending.customer.search.taskStatus')}:`" prop="taskStatus"
                          style="width: 240px">
              <el-select v-model="ruleForm.taskStatus" :placeholder="$t('common.please.select')">
                <el-option :label="$t('sending.search.status1')" style="color: #9c9ea2" :value="1"/>
                <el-option :label="$t('sending.search.status2')" style="color: #5a9cf8" :value="2"/>
                <el-option :label="$t('sending.search.status3')" style="color: #dca550" :value="3"/>
                <el-option :label="$t('sending.search.status4')" style="color: #7ec050" :value="4"/>
                <el-option :label="$t('sending.search.status5')" style="color: #e47470" :value="5"/>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <div style="display: flex">
                <el-button @click="resetForms(ruleFormRef)" style="margin-right: 10px">{{ $t('common.reset') }}
                </el-button>
                <el-button type="primary" @click="onSubmit(ruleFormRef)">
                  {{ $t('common.query') }}
                </el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div style="display: flex;flex-direction: column">
        <!--        top-->
        <div class="table_top">
          <div class="h2_titles">{{ $t('sending.table.title') }}</div>
          <!--          <div style="background: #dca550;color: white;border-radius: 4px;line-height: 40px;padding: 0px 10px">为了保证用户体验和使用效率，系统数据库和配置正在全面升级中，群发模块预计24小时内完成全部升级，感谢支持</div>-->
          <div>
            <el-button
                type="danger"
                :icon="Delete"
                @click="handleClickDelete"
                style="margin-right: 12px"
            >{{ $t('common.delete') }}
            </el-button
            >
            <el-button type="primary" @click="createNewTask"><img src="../../assets/sendgroup/send.png" width="18"
                                                                  style="margin-right: 8px"/>{{
                $t('sending.create.grouptask')
              }}
            </el-button>
          </div>
        </div>
        <!--      table-->
        <div>
          <el-table
              ref="multipleTableRef"
              :data="tableData"
              style="width: 100%"
              :header-cell-style="headerClassName"
              @selection-change="handleSelectionChange"
              v-loading="loading"
              :cell-style="rowClassName"
          >
            <el-table-column type="selection" width="55"/>
            <el-table-column :label="$t('sending.table.col.taskId')" prop="taskCode"
                             :width="flexWidth('taskCode', tableData, $t('sending.table.col.taskId'))">
              <template #default="{row}">
                <router-link
                    :to="'/email/customer/senddetail/' + row.id+`/${row.massMailType}/${row.taskCode}/${row.userId}`"
                    style="color: #409eff"
                >
                  <el-button link type="primary">{{ row.taskCode }}</el-button>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.title')" prop="title"
                             :width="flexWidth('title', tableData, $t('sending.table.col.title'))"
                             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('send.all.remark')" prop="remark"
                             :width="auto" style="min-width: 100px">
              <!--            </el-table-column>-->
              <template #default="scope">
                <div class="flex">
                  <!--                         @click.stop="handleRef(refMap[`${scope.row.id}`], scope.row, 1)"-->
                  <el-icon
                      @click.stop="handleClickRemark(scope.row)"
                      :color="scope.row.remark ? '#40a9ff' : '#dddddd'" style="padding-top: -2px">
                    <el-tooltip
                        v-if="emailListCheckoutTarget.remark"
                        class="box-item"
                        :content="emailListCheckoutTarget.remark"
                        placement="right"
                        :show-after="600"
                    >
                      <Memo/>
                    </el-tooltip>
                    <Memo v-else/>
                  </el-icon>
                </div>
              </template>
            </el-table-column>
            <!--            宽度自适应？-->
            <el-table-column :label="$t('sending.table.col.massMailType')" prop="massMailType"
                             :width="flexWidth('massMailType', tableData, $t('sending.table.col.massMailType'))">
              <template #default="{row}">
                <template v-if="row.massMailType===1">{{ $t('sending.table.col.massMailType.way1') }}</template>
                <template v-if="row.massMailType===2">{{ $t('sending.table.col.massMailType.way2') }}</template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.createTime')" prop="createTime"
                             :width="flexWidth('createTime', tableData, $t('sending.table.col.createTime'))">
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.startTime')" prop="startTime"
                             :width="flexWidth('startTime', tableData, $t('sending.table.col.startTime'))">
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.createName')" prop="createName"
                             :width="flexWidth('createName', tableData, $t('sending.table.col.createName'))">
            </el-table-column>
            <el-table-column :label="$t('sending.customer.search.taskStatus')" prop="taskStatus"
                             :width="flexWidth('taskStatus', tableData, $t('sending.customer.search.taskStatus'))">
              <template #default="{row}">
                <el-button link type="info" v-if="row.taskStatus===1" class="btn_reset">
                  {{ $t('sending.search.status1') }}
                </el-button>
                <el-button link type="primary" v-if="row.taskStatus===2" class="btn_reset">
                  {{ $t('sending.search.status2') }}
                </el-button>
                <el-button link type="warning" v-if="row.taskStatus===3" class="btn_reset">
                  {{ $t('sending.search.status3') }}
                </el-button>
                <el-button link type="success" v-if="row.taskStatus===4" class="btn_reset">
                  {{ $t('sending.search.status4') }}
                </el-button>
                <el-button link type="danger" v-if="row.taskStatus===5" class="btn_reset">
                  {{ $t('sending.search.status5') }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.massMailCount.num')" prop="massMailCount"
                             :width="flexWidth('massMailCount', tableData, $t('sending.table.col.massMailCount.num'))">
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.successCount')" prop="successCount"
                             :width="flexWidth('successCount', tableData, $t('sending.table.col.successCount'))">
              <template #default="{row}">
                <el-button type="success" link class="btn_reset">{{ row.successCount }}</el-button>
              </template>
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.openCount')" prop="openCount"
                             :width="flexWidth('openCount', tableData, $t('sending.table.col.openCount'))">
              <template #default="{row}">
                <el-button link type="info" class="btn_reset">{{ row.openCount }}</el-button>
              </template>
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.replyCount')" prop="replyCount"
                             :width="flexWidth('replyCount', tableData, $t('sending.table.col.replyCount'))">
            </el-table-column>
            <el-table-column :label="$t('sending.table.col.failCount')" prop="failCount"
                             :width="flexWidth('failCount', tableData, $t('sending.table.col.failCount'))">
              <template #default="{row}">
                <el-button type="danger" link class="btn_reset">{{ row.failCount }}</el-button>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.operate')" prop="taskStatus" :width="computedTargetWidth"
                             fixed="right">
              <template #default="{row}">
                <div ref="setting">
                  <el-button type="primary" link @click="toTargetDetail(row)">{{ $t('common.check') }}</el-button>
                  <el-button type="primary" link @click="toResendGroupMail(row)">{{ $t('common.resend') }}</el-button>
                  <el-button type="warning" v-if="row.taskStatus===3" link @click="handleChangeStatus(row,'1')">
                    {{ $t('common.continue') }}
                  </el-button>
                  <el-button type="danger" v-if="row.taskStatus===2" link
                             @click="handleChangeStatus(row,'2')">{{ $t('common.pause') }}
                  </el-button>
                  <el-button type="danger" v-if="row.taskStatus!==4 && row.taskStatus!==5" link
                             @click="handleChangeStatus(row,'3')">{{ $t('common.cancel') }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </el-table>
          <div style="display: flex;justify-content:flex-end;margin-bottom: 10px">
            <div class="pages">
              <el-pagination
                  v-model:current-page="pageNo"
                  v-model:page-size="pageSize"
                  background
                  v-if="totals"
                  :total="totals"
                  :pager-count="4"
                  layout="total, prev, pager, next, jumper"
                  @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <CreateTaskDia v-model="visibleDialog" v-if="visibleDialog" @close="close" :isHeadLine="true"
                 :activeItem="activeItem"></CreateTaskDia>
  <DeleteGroupTask v-if="dialogVisiblePopover" v-model="dialogVisiblePopover" @close="closeDialog"
                   :multipleSelection="multipleSelection" :getGroupMailList="getGroupMailList"></DeleteGroupTask>
  <!--   添加备注   -->
  <Remark v-if="visitableRemark" v-model="visitableRemark" @close="closeRemark" :isHeadLine="true"
          :currentItem="currentItem" @getList="getGroupMailList" :task="true"></Remark>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref} from "vue";
import {useTransition} from '@vueuse/core'
import CreateTaskDia from "@/views/SendGroup/compontents/CreateTaskDia.vue";
import {useStore} from "vuex";
import {throttle, debouncePlus} from "@/utils/mylodash"
import {useRouter} from "vue-router";
import {emptyUrl} from "@/utils/statusParams";
import {i18n} from "@/lang";
import {flexWidth} from '@/utils/tableWidth'
import {Delete} from "@element-plus/icons-vue";
import DeleteGroupTask from "@/components/DiaPops/DeleteGroupTask.vue";
import {ElMessage, ElTable} from "element-plus";
import {auto} from "@popperjs/core";
import Remark from "@/components/Remark/index.vue";

const TIME_DELAY = 300;
const ruleForm = ref({taskCode: "", massMailType: "", createBy: "", targetTime: [], taskStatus: ""})
const store = useStore()
const router = useRouter();

const tableData = ref([])
const multipleTableRef = ref(null)

// 任务发起人
const tableDataSelect = computed(() => [...store.state.groups.AllTableData, {
  userName: i18n.global.t('common.all'),
  userId: 'all',
}]);


const visibleDialog = ref(false);
const close = () => {
  visibleDialog.value = false
}
/**
 * 新建团队任务
 */
const createNewTask = () => {
  visibleDialog.value = true;
}

/**
 * 失败数量
 * @type {Ref<UnwrapRef<number>>}
 */
const sendError = ref(0)
const sendErrorValue = useTransition(sendError, {
  duration: 1000,
})
sendError.value = 172

/**
 * 成功数量
 */
const sendSuccess = ref(0)
const sendSuccessValue = useTransition(sendSuccess, {
  duration: 1000,
})
sendSuccess.value = 300


/**
 * 打开数量
 */
const openEmail = ref(0)
const openEmailValue = useTransition(openEmail, {
  duration: 1000,
})
openEmail.value = 300


/**
 * 回复数量
 */

const reEmail = ref(0)
const reEmailValue = useTransition(reEmail, {
  duration: 1000,
});
reEmail.value = 100


//表单查询相关
const ruleFormRef = ref(null);
const startTime = ref(null);
const endTime = ref(null)

const resetForms = debouncePlus(async (formEl) => {
  ruleFormRef.value.resetFields();
  ruleForm.value.targetTime = []
  ruleForm.value.createBy = tableDataSelect.value[0].userId;
  startTime.value = null;
  endTime.value = null;
  pageNo.value = 1;
  await getGroupMailList()
}, TIME_DELAY)

const onSubmit = debouncePlus(async () => {
  const {targetTime, ...data} = ruleForm.value
  startTime.value = targetTime[0] ? `${targetTime[0]} 00:00:00` : null;
  endTime.value = targetTime[1] ? `${targetTime[1]} 23:59:59` : null;
  const form = {
    ...data,
    startTime: startTime.value,
    endTime: endTime.value
  }
  pageNo.value = 1;
  await getGroupMailList(form)
}, TIME_DELAY)

// 暂停继续发送
const beginFlag = ref(false)
const handleChangeStatus = async (row, flag) => {
  const {id, taskStatus} = row
  const payload = {
    massTaskId: id,
    teamId: teamId.value
  }
  if (flag === "1") {
    //   继续
    payload.taskStatus = 2
  } else if (flag === "2") {
    //   暂停
    payload.taskStatus = 3
  } else if (flag === "3") {
    //   取消
    payload.taskStatus = 5
  }
  console.log(payload, 'payload')
  await store.dispatch("groupSend/updateMailMassStatusApis", payload)
  await getGroupMailList()
}


// 重发群发邮件
// active
const activeItem = ref(null)
const toResendGroupMail = async (row) => {
  console.log('重发', row)
  const {id} = row
  activeItem.value = id;
  // visibleDialog.value = true;
  // 群发任务详情
  router.push({
    path: `/email/send_all`,
    query: {type: row.massMailType, massTaskId: row.id, typeFlag: "reSent", mailType: "3"}
  })
}


// 查看email详情
const toTargetDetail = (row) => {
  console.log(row, 'row')
  const {id, massMailType, taskCode, userId} = row
  router.push(`/email/customer/senddetail/${id}/${massMailType}/${taskCode}/${userId}`)
}


// 获取文件列表
const pageNo = ref(1);
const pageSize = ref(10);
const totals = ref(0);
const loading = ref(false)

const teamId = computed(() => store.state.email.teamID);
const userInfo = computed(() => store.state.user.userInfo);
const getGroupMailList = async (form) => {
  let params = {
    pageNo: pageNo.value,
    pageSize: pageSize.value,
    teamId: teamId.value,
    createBy: tableDataSelect.value[0].userId,
  }
  if (ruleForm) {
    params = {...params, ...ruleForm.value}
  }

  if (params.createBy === 'all') {
    params.createBy = '';
  }


  loading.value = true
  const res = await store.dispatch('groupSend/queryPageListApis', params)
  loading.value = false;
  const {records, total, current, size} = res;
  tableData.value = records;
  totals.value = total;
  pageNo.value = current;
}

const handleCurrentChange = async (page) => {
  pageNo.value = page
  await getGroupMailList()
}

// 判断是否是超级管理员如果是的话 就展示
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])

onMounted(async () => {
  // Shift监听/取消监听
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);

  await store.dispatch("groups/getAllUserList");
  ruleForm.value.createBy = tableDataSelect.value[0].userId;
  await getGroupMailList()
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('keyup', handleKeyUp);
});

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 5 || columnIndex === 6 || columnIndex === 14) {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}

const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 5 || columnIndex === 6 || columnIndex === 14) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}
// 语言
const lang = computed(() => store.state.system.langLocal)
const computedTargetWidth = computed(() => {
  console.log('tableData.value', tableData.value)
//   是否存在 进行中
  const target1 = tableData.value.some(item => item.taskStatus === 1) ? 3 : 2
  const target2 = tableData.value.some(item => item.taskStatus === 2 || item.taskStatus === 3) ? 4 : 2
  const target3 = tableData.value.some(item => item.taskStatus === 4 || item.taskStatus === 5) ? 2 : 2

  const res = [target1, target2, target3]
  return lang.value === 'en' ? Math.max(...res) * 65 : Math.max(...res) * 48;

})

const dialogVisiblePopover = ref(false);
const multipleSelection = ref([])

const handleClickDelete = throttle(() => {
  if (multipleSelection.value.length === 0) {
    ElMessage.warning(i18n.global.t('index.sending.multiple.select'));
    return;
  }
  dialogVisiblePopover.value = true
}, 2000)


const handleSelectionChange = (val) => {
  console.log(val, 'val')
  endIndex = val.length > 1 ? tableData.value.findIndex(item => item.id === val[val.length - 1].id) : -1;
  const startIndex = val.length > 1 ? tableData.value.findIndex(item => item.id === val[val.length - 2].id) : -1;
  console.log(endIndex, startIndex);
  if (isShiftPressed.value && val.length > 1 && endIndex !== startIndex) {
    const start = startIndex < endIndex ? startIndex : endIndex;
    const end = endIndex < startIndex ? startIndex : endIndex;
    for (let i = start; i <= end; i++) {
      const row = tableData.value[i];
      if (start === i || i === end) {
        continue;
      }
      console.log(row, '更新');
      if (row) {
        multipleTableRef.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
      }
    }
    nextTick(() => {
      multipleSelection.value = multipleTableRef.value.getSelectionRows();
      console.log(multipleTableRef.value.getSelectionRows(), '当前选中行');
    });
  } else {
    multipleSelection.value = val;
  }
}
const closeDialog = () => {
  dialogVisiblePopover.value = false;
}

let endIndex = -1;
// table 选择增加 shift
const isShiftPressed = ref(false); // 标记 Shift 键是否被按下
const handleKeyDown = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    // console.log("进来了-----------------", event.key, event.keyCode);
    isShiftPressed.value = true;
  }
};

const handleKeyUp = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    isShiftPressed.value = false;
  }
};


// 弹窗进行编辑备注
const visitableRemark = ref(false)
const currentItem = ref({remark: ""})
const emailListCheckoutTarget = ref({remark: ''})
const handleClickRemark = (row) => {
  const {id} = row
  console.log(id, row, 'id')
  currentItem.value = row
  visitableRemark.value = true
}
const closeRemark = () => {
  visitableRemark.value = false
}
</script>


<style lang="less" scoped>
@import "../../less/page.less";

.box {
  width: 100%;
  height: 100%;
  background: white;

  .top_box {
    height: 120px;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .top_left {
      width: 50%;
      min-width: 580px;
      height: 100%;
      flex: 0 0 auto; /* 不压缩，不增长 */

      .content_box {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .content_bottom {
          display: flex;
          justify-content: flex-start;

          & > div {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }

          & > div:nth-of-type(1) {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }

    .top_right {
      width: 50%;
      flex: 1 1 auto; /* 不压缩，不增长 */
      display: flex;
      justify-content: space-around;
      align-items: center;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    padding: 20px;

    & > div {
      display: flex;
      padding: 0px 10px;
    }

    & > div:nth-of-type(1) {
      width: 100%;
      min-height: 80px;
      max-height: max-content;
      padding: 24px;
      background: white;
    }

    & > div:nth-of-type(2) {
      width: 100%;
      margin-top: 16px;
      padding: 24px;
      height: max-content !important;
      background: white;
    }
  }
}

.font {
  font-size: 16px;
  font-weight: 500;
}

.font_small {
  font-size: 12px;
  min-width: 48px;
}

.font_big {
  font-size: 22px;
  font-weight: 500;
}

.font_small_color {
  color: #b9b9b9;
}

span {
  line-height: 16px;
}

:deep(.el-button+.el-button) {
  margin-left: 0px;
}

.demo-ruleForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

:deep(.el-form-item) {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.table_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 4px;
}

.table_font {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

:deep(.el-table__header) {
  width: 100% !important;
}

:deep(.el-table__body) {
  width: 100% !important;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
}

:deep(.el-form-item__label) {
  font-weight: 500;
}

:deep(.el-range-input) {
  width: 40% !important;
}

:deep(.el-date-editor .el-range-separator) {
  flex: none !important;
}

:deep(.el-range-separator) {
  color: #a9abb1;
}

.btn_reset {
  cursor: text !important;
  pointer-events: none
}

:deep(.el-form-item__label-wrap) {
  margin-left: 10px !important;
}

.h2_titles {
  font-size: 21px;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
</style>
