<template>
  <div class="message-audio" 
           :class="[
            message.flow === 'out' && 'reserve',
          ]"
          @click.stop="play">
    <img :src="audioIconType" alt="" />
    <span class="time"   :style="{ width: `${data.second * 10 + 20}px` }">{{ data.second || 1 }} " </span>
    <audio
      ref="audioRef"
       :src="data.url"
    />
  </div>
</template>

<script setup>
import { onMounted,  onUnmounted, watchEffect, ref, computed } from "vue";
import audioIcon from "../../../assets/img/chat/audio.png";
import audioInIcon from "../../../assets/img/chat/audio_in.png";

const audioIconType = computed(() => message.value.flow  === 'out' ? audioIcon :  audioInIcon);

const props = defineProps(['messageItem', 'payload']);

const data = ref();
const audioRef = ref();
const isAudioPlaying = ref();
const message = ref();

onMounted(() => {
  console.log(props.payload, 'payload');
   if (audioRef.value) {
    audioRef.value.addEventListener('ended', onAudioEnded);
    audioRef.value.addEventListener('pause', onAudioPaused);
  }
})

onUnmounted(() => {
  if (audioRef.value) {
    audioRef.value.removeEventListener('ended', onAudioEnded);
    audioRef.value.removeEventListener('pause', onAudioPaused);
  }
});

watchEffect(() => {
  message.value = props.messageItem;
  data.value = props.payload;
});


function play() {
  if (message.value.hasRiskContent || !audioRef.value) {
    return;
  }
  if (!audioRef.value.paused) {
    audioRef.value.pause();
    audioRef.value.currentTime = 0;
    isAudioPlaying.value = false;
    return;
  }
  const audios = document.getElementsByTagName('audio');
  Array.from(audios).forEach((audio) => {
    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }
  });
  audioRef.value.play();
  isAudioPlaying.value = true;
  // if (message.value.flow === 'in') {
  //   emits('setAudioPlayed', message.value.ID);
  // }
}

function onAudioEnded() {
  isAudioPlaying.value = false;
}

function onAudioPaused() {
  isAudioPlaying.value = false;
}

</script>

<style scoped>
.message-audio{
  flex-direction: row;
  display: flex;
  flex: 0 0 auto;
  cursor: pointer;
  overflow: hidden;
  font-size: 14px;

  &.reserve{
    flex-flow: row-reverse;

    img{
      margin-left: 8px;
      margin-right: 0;
    }
  }

  img{
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .time {
    flex: 1 1 auto;
    max-width: 300px;
    text-align: start;
  }

  audio {
    width: 0;
    height: 0;
  }
}
</style>
