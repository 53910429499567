<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.binding.phone')"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <template #default>
      <el-form
          ref="ruleFormRefEmail"
          :model="ruleFormEmail"
          :rules="rulesEmail"
          :label-position="'top'"
      >
        <el-form-item :label="$t('common.emailbox')" prop="email">
          <el-input
              v-model="ruleFormEmail.email"
              :placeholder="$t('sys.tip.input.emailbox')"
              :disabled="userInfo.email"
          ></el-input>
        </el-form-item>
        <el-form-item
            :label="$t('common.yzm')"
            prop="emailCode"
            :aria-placeholder="$t('90104')"
            id="sp_input"
        >
          <el-input
              v-model="ruleFormEmail.emailCode"
              :placeholder="$t('90104')"
              :clearable="false"
              @keydown.enter.native="bindEmailApis"
          >
            <template #suffix>
              <el-button type="primary" @click="send" :disabled="!show" link
                         style="padding-right: 15px;font-size: 12px">
                <span v-if="show">{{ $t('common.label.getsmscode') }}</span>
                <span v-show="!show">{{ count }}s</span>
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div class="bottom_btn">
        <el-button @click="close">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="bindEmailApis">{{ $t("common.confirm") }}</el-button>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted, nextTick, reactive} from 'vue'
import {useStore} from "vuex";
import {addEmailGroupFile, updateEmailGroupFile} from "@/api/sidebar";
import CountrySelect from "@/components/CountrySelect/index.vue";
import {i18n} from "@/lang";
import {emailReg, phoneReg, TIME_COUNT, TIME_COUNTPhone} from "@/utils/statusParams";
import {bindEmail, bindPhone, sendBindPhoneSms, sendEmailSms} from "@/api/usercenter";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const fileDirName = ref("")
const loading = ref(false)
const ruleFormRefPhone = ref(null)
const teamId = computed(() => store.state.email.teamID);
const refInput = ref(null)
const {value, isHeadLine, isEdit, currentItem} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  isEdit: {type: Boolean, default: false},
  currentItem: {type: Object, default: {}}
})
const rulesEmail = reactive({
  email: [{required: true, message: i18n.global.t('sys.tip.input.emailbox'), trigger: "blur"}],
  emailCode: [{required: true, message: i18n.global.t('sys.tip.input.verify.code'), trigger: "blur"}],
});

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}
const bindEmailValue = ref("")
const ruleFormEmail = ref({email: "", emailCode: ""})
// 绑定的手机
const ruleFormRef = ref(null)
const rules = ref({
  phone: [{required: true, message: i18n.global.t('set.phone.phone.placeholder'), trigger: "blur"}],
  code: [{required: true, message: i18n.global.t('sys.tip.input.verify.code'), trigger: "blur"}],
});
const userInfo = computed(() => store.state.user.userInfo)
// 选中的区号 默认为中国
const selectTargetPrefix = ref('CN')
const getSelectValue = (val) => {
  selectTargetPrefix.value = val.zoneCode
}
const ruleFormRefEmail = ref()

// 绑定邮箱，重新获取用户数据
const bindEmailApis = async () => {
  const res = await bindEmail({
    email: ruleFormEmail.value.email,
    code: ruleFormEmail.value.emailCode,
    type: bindEmailValue.value !== '' ? 2 : 1
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
//   重新获取用户相关数据
  await store.dispatch('user/getUserInfoForGUID')
  await getBindEmails()
  ruleFormRefEmail.value.resetFields();
  close()
  emit("refresh");
}

// 重新获取用户信息
const getBindEmails = () => {
  const email = userInfo.value.email
  if (email) {
    bindEmailValue.value = email
    ruleFormEmail.value.email = bindEmailValue.value
  }
}

onMounted(async () => {
  await getBindEmails()
})

// 发送邮箱验证码
const sendEmailSmsApis = async () => {
  const res = await sendEmailSms({
    email: ruleFormEmail.value.email
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  ElMessage.success(res.message)
}

/**
 * 绑定邮箱
 * @type {null}
 */
const timer = ref(null);
const count = ref(0);
const show = ref(true)
const send = () => {
  if (ruleFormEmail.value.email !== '' && emailReg.test(ruleFormEmail.value.email)) {
    if (!timer.value) {
      count.value = TIME_COUNT;
      show.value = false;
      timer.value = setInterval(() => {
        if (count.value > 0 && count.value <= TIME_COUNT) {
          count.value--;
        } else {
          show.value = true;
          clearInterval(timer.value); // 清除定时器
          timer.value = null;
        }
      }, 1000)

      //   发送请求
      sendEmailSmsApis()
    }
  } else {
    ElMessage.error(i18n.global.t('sys.message.error.emailbox'));
  }
}

const cancelEmail = () => {
  dialogVisibleEmail.value = false;
  ruleFormRefEmail.value.resetFields();
};

</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

:deep(.el-form-item__label) {
  font-weight: 500 !important;
}

.selects {
  width: 250px;
  margin: 10px 0 20px 18px;

  :deep(.el-select) {
    width: 100% !important;
  }
}

.bottom_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

#sp_input {
  :deep(.el-form-item__content) {
    .el-input--suffix {
      .el-input__wrapper {
        padding: 1px 0px 0px 11px !important;
      }
    }
  }
}
</style>
