import _store from "store2";
import {
    getTeamUserList,
    addTeamUser,
    delTeamUser,
    getTeamDepartment,
    addTeamDepartment,
    updateTeamDepartment,
    delTeamDepartment,
    updateTeamUser,
    findTeamDepartmentById, getCompanyUserList, getTeamUserListAll, getAllTeamMember,
} from "@/api/groups.js";
import {ElMessage} from "element-plus";
import dayjs from "dayjs";

const email = {
    namespaced: true,
    state: {
        tableData: [],
        departList: [],
        // 部门分页
        pageDepart: {
            current: 1,
            size: 10,
            total: 0,
        },
        isLoadingDepart: false,
        // 成员分页
        pageUser: {
            current: 1,
            size: 10,
            total: 0,
        },
        isLoadingUser: false,

        // 通过id获取到的部门
        targetDepartById: "",

        //   内部成员列表分页
        innerUserPage: {
            currentUserList: 1,
            sizeUserList: 500,
            totalsUserList: 0
        },
        // 新数据
        innerUserList: [],
        //     所有数据
        allInnerUserList: [],
        //     选中的数据
        transferData: [],
        // 所有成员列表
        AllTableData: [],
        //     所有成员无权限
        AllTableDataNo: [],
    },
    mutations: {
        setAllTableDataNo(state, payload) {
            state.AllTableDataNo = payload
        },
        setAllTableData(state, payload) {
            state.AllTableData = payload;
        },
        // 设置table
        setTableData(state, payload) {
            state.tableData = payload;
        },
        // 设置 部门列表
        setDepartList(state, payload) {
            state.departList = payload;
        },
        //设置页数相关
        setDepartPage(state, payload) {
            state.pageDepart = payload;
        },
        setUserPage(state, payload) {
            const {current, size, total} = payload
            state.pageUser.current = current;
            state.pageUser.size = size;
            state.pageUser.total = total;
        },
        setTargetDepartById(state, payload) {

            state.targetDepartById = payload;
        },
        // 内部企业用户分页
        setInnerUserPage(state, payload) {
            const {currentUserList, sizeUserList, totalsUserList} = payload
            if (currentUserList !== undefined) {
                state.innerUserPage.currentUserList = currentUserList;
            }
            if (sizeUserList !== undefined) {
                state.innerUserPage.sizeUserList = sizeUserList;
            }
            if (totalsUserList !== undefined) {
                state.innerUserPage.totalsUserList = totalsUserList;
            }
        },
        //     内部企业用户 数据
        setInnerUserList(state, payload) {
            state.innerUserList = payload;
        },
        setAllInnerUserList(state, payload) {
            state.allInnerUserList = payload;
        },
        //     更改选中的值
        setTransferData(state, payload) {
            state.transferData = payload;
        }
    },
    actions: {
        // 获取内部成员列表
        async getCompanyUserList({state, commit}, payload) {
            const companyId = JSON.parse(window.localStorage.getItem('userInfo')).companyId
            const teamId = window.localStorage.getItem('teamId')

            const targetObj = {
                companyId,
                teamId,
                pageNo: state.innerUserPage.currentUserList,
                pageSize: state.innerUserPage.sizeUserList,
            }
            let res = await getCompanyUserList(targetObj)
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return;
            }
            const {records, total, current, size} = res.result;
            commit('setInnerUserList', records)
            commit('setInnerUserPage', {currentUserList: current, sizeUserList: size, totalsUserList: total})
        },
        // 获取成员列表--分页
        async getUserList({state, commit}, payload) {
            let teamId = window.localStorage.getItem("teamId");
            // let pages = JSON.parse(
            //     JSON.stringify({
            //         pageNo: state.pageUser.current,
            //         pageSize: state.pageUser.size,
            //     })
            // );
            state.isLoadingUser = true;
            // 这里在删除团队的时候需要判断是否 有团队的存在
            if (teamId) {
                await getTeamUserList({
                    teamId,
                    pageNo: state.pageUser.current,
                    pageSize: state.pageUser.size
                }).then((res) => {
                    // console.log(res, "所有的成员");
                    state.isLoadingUser = false;
                    commit("setUserPage", {
                        current: res.result.current,
                        size: res.result.size,
                        total: res.result.total
                    });
                    commit("setTableData", res.result.records);
                });
            }
        },
        // 获取所有成员列表
        async getAllUserList({state, commit}, payload) {
            let teamId = window.localStorage.getItem("teamId");
            let res = await getTeamUserListAll({teamId, ...payload})
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }
            let targetList = JSON.parse(JSON.stringify(res.result));
            //  默认排在第一个
            const id = JSON.parse(localStorage.getItem('userInfo')).id

            targetList.map(item => {
                let index = targetList.findIndex(_ => _.userId === id)
                if (index !== -1) {
                    targetList.unshift(targetList.splice(index, 1)[0]);
                }
            })
            console.log(targetList, id, targetList.findIndex(_ => _.userId === id), 'sortDefaultFirst')
            commit('setAllTableData', targetList)
        },
        // 获取所有成员 --没有权限
        async getAllUserListNo({state, commit}, payload) {
            let teamId = window.localStorage.getItem("teamId");
            let res = await getAllTeamMember({teamId, ...payload})
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }
            commit('setAllTableDataNo', res.result)
        },
        // 添加成员
        async addUsers(context, payload) {
            let res = await addTeamUser(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }
            ElMessage.success(res.result)
            await context.dispatch("getUserList");
        },
        // 修改成员
        async updateUsers(context, payload) {
            let res = await updateTeamUser(payload);

            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            ElMessage.success(res.result);
            await context.dispatch("getUserList");
        },
        // 删除团队成员
        async deleteUsers(context, payload) {
            let res = await delTeamUser(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            ElMessage.success(res.result);
            await context.dispatch("getUserList");
        },
        // 获取团队部门
        async getDepartList({state, commit}, payload) {
            let pages = JSON.parse(
                JSON.stringify({
                    pageNo: state.pageDepart.current,
                    pageSize: state.pageDepart.size,
                })
            );
            state.isLoadingDepart = true;
            let res = await getTeamDepartment({...payload, ...pages});
            state.isLoadingDepart = false;
            const {records, current, size, total} = res.result;
            // console.log(records, "部门列表");
            commit("setDepartPage", {current, size, total});
            commit("setDepartList", records);
        },
        // 添加团队部门
        async addDepart(context, payload) {
            // console.log(payload, "添加部门ss");
            let res = await addTeamDepartment(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            ElMessage.success(res.result);
        },
        // 通过id 获取部门
        // async getDepartById({ state, commit }, payload) {
        //   let res = await findTeamDepartmentById(payload);
        //   const { name } = res.result;
        //   commit("setTargetDepartById", name);
        // },
        // 修改部门
        async updateDepart(context, payload) {
            let res = await updateTeamDepartment(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            ElMessage.success(res.result);
        },
        // 删除部门
        async deleteDepart(context, payload) {
            let res = await delTeamDepartment(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            ElMessage.success(res.result);
        },
    },
};

export default email;
