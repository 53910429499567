<template>
  <div class="contents">
    <div class="left">
      <el-avatar :size="size" :src="data.userUrl"/>
    </div>
    <div class="right">
      <div class="name">{{ data.userName }}</div>
      <div class="times">{{ data.createTIme }}</div>
      <div class="content">{{ data.content }}</div>
      <div class="bottoms" v-if="arrImgs[0]!==''">
        <el-image
            v-for="item in arrImgs"
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="arrImgs"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :initial-index="4"
            fit="cover"
        />
      </div>
    </div>
  </div>
  <hr style="margin: 20px 0px "/>
</template>

<script setup>
import {ref, defineProps, computed} from "vue"

const {data} = defineProps({
  data: {
    type: Object,
    default: () => {
    }
  }
})

const arrImgs = computed(() => {
  let result = []
  console.log(data.url.indexOf(','))
  if (data.url.indexOf(',') != -1) {
    result = data.url.split(',')
  } else {
    result.push(data.url)
  }
  return result
});

</script>

<style lang="less" scoped>
.contents {
  display: flex;
  justify-content: flex-start;

  .left {
    margin-right: 10px;
  }

  .right {
    .name {
      font-weight: bold;
      color: rgb(51, 51, 51);
      font-size: 16px;
    }

    .times {
      color: rgb(170, 170, 170);
      font-size: 12px;
      margin-top: 5px;
    }

    .content {
      color: rgb(170, 170, 170);
      font-size: 14px;
      margin-top: 10px;
    }
  }
}

hr {
  background-color: #efefef;
  height: 1px;
  border: none;
}

.demo-image__error .image-slot {
  font-size: 30px;
}

.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}

.demo-image__error .el-image {
  width: 100%;
  height: 200px;

}

.bottoms {
  margin-top: 10px;

  div:nth-child(1) {
    margin: 0px 4px 0px 0px;
  }

  div {
    margin: 0px 4px;
    border-radius: 4px;
  }
}
</style>