import _store from "store2";
import {checkUserIsAdmin, getPositionByLogin, logout} from "../../api/index";
import {V2UserInfoForToken} from "@/api/v2UserInfoForToken";
import {ElMessage} from "element-plus";
import {useWebSocket} from "../../Hooks";
import router from '@/router'
import store from "@/store";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
// import { removeNeedTokenRequest } from '@/utils/request'

const userInfo = {
    account: "",
    accountType: 1, //1=个人账号 2=企业主账号 3=企业子账号
    enterpriseID: 0,
    guid: "",
    iM_Sign: "",
    photoUrl: "",
    token: "",
    userID: 0,
    userName: "",
    userType: 10, //10 普通 20管路员 99主账号
};

const UserInfoForGUID = {
    guid: "",
    zoneCode: "",
    userCode: "",
    token: "",
    userID: 0,
    accountType: 1, //1=个人账号 2=企业主账号 3=企业子账号
    enterpriseID: 0,
    account: "",
    userName: "",
    phoneCode: "",
    phone: "",
    identificationStatus: 0,
    photoUrl: "",
    sex: 0,
    country: "",
    address: "",
    language: "",
    emailAddress: "",
    iM_Sign: "",
    teamID: 0,
    isVip: 0,
    vipExpireDate: null,
};

const token = _store.get("token");
const loginStatus = !!token || false;
const UserEmailList = _store.get("UserEmailList") || [];
const user = {
    namespaced: true,
    state: {
        loginStatus,
        userInfo: _store.get("userInfo") || userInfo,
        UserInfoForGUID: _store.get("UserInfoForGUID") || UserInfoForGUID,
        token,
        UserEmailList,
        userIsAdmin: sessionStorageUtil.getItem('userIsAdmin') || 1, //是否是管理员
        mailWorkPosition: {} //工位信息

    },
    getters: {
        isEnterpriseChildUser(state) {
            return state.userInfo.accountType === 3;
        },
        isEnterpriseMainUser(state) {
            return state.userInfo.accountType === 2;
        },
        isNormalUser(state) {
            return state.userInfo.accountType === 1;
        },
        //     超级管理员 superAdmin
        isSuperAdmin(state) {
            return state.userIsAdmin === 3
        },
        //     管理员 admin
        isAdmin(state) {
            return state.userIsAdmin === 2
        },
        //     普通用户
        isPublic(state) {
            return state.userIsAdmin === 1
        }
    },
    mutations: {
        SetUserEmailList(state, list) {
            state.UserEmailList = list;
            _store("UserEmailList", list);
        },
    },
    actions: {
        UserLogin({state, commit}, userInfo) {
            Object.assign(state.userInfo, userInfo);
            state.token = userInfo.token;
            _store("userInfo", userInfo);
            _store("token", userInfo.token);
            // _store('imSign', userInfo.iM_Sign)
            _store("guid", userInfo.guid);
            _store("nBarUnreadMailNum", 0);
            commit("system/changeLanguage", userInfo.language, {root: true});
            state.loginStatus = true;
        },
        async UserQuit({state, rootState, dispatch}) {
            // removeNeedTokenRequest()
            await logout({userId: state.userInfo?.id})
            const {stopWS} = useWebSocket(state.userInfo.id);
            stopWS();
            window.socketTarget = null;
            state.token = "";
            state.UserEmailList = [];
            state.loginStatus = false;
            _store.remove("userInfo");
            _store.remove("UserInfoForGUID");
            Object.assign(state.userInfo, userInfo);
            Object.assign(state.UserInfoForGUID, UserInfoForGUID);
            _store.remove("token");
            _store.remove("imSign");
            _store.remove("guid");
            _store.remove("UserEmailList");
            _store("nBarUnreadMailNum", 0);
            localStorage.removeItem("currentTime");
            localStorage.removeItem("lastTime");
            // 清除其他模块
            let {email, system, cloud} = rootState;
            Object.assign(system.tabList[1], {
                cacheName: "",
                query: {},
                params: {},
            }); //重新登录后去除邮件缓存
            email.teamID = "";
            email.teamList = []; //重置团队列表
            email.teamListLoaded = false; //是否获取teamList重置
            email.EmailUnReadNum = 0; //重置邮件未读数
            email.RequestToAuditSum = 0;
            email.auditMailNum = 0;
            email.nBarUnreadMailNum = 0;
            email.mailFollowNum = 0;
            email.customerFollowNum = 0;
            system.friendList = []; //重置好友列表
            cloud.downloadStatus = "done";
            dispatch("email/resetTeam", {}, {root: true});
            system.isChatBoxVisibe = false; //关闭聊天
            // 清除所有数据
            localStorage.clear();
            sessionStorage.clear();
            sessionStorageUtil.clear();
            store.commit('email/setCurTeamID', '')
            // window.location.reload();
            //     console.log(res, 'res')
            router.push('/login')

        },
        async getUserInfoForGUID({state, dispatch}) {
            const result = await V2UserInfoForToken();
            if (result.code !== 200) {
                dispatch("user/UserQuit")
                return
            }
            // 覆盖 userInfo 数据
            localStorage.setItem('userInfo', JSON.stringify(result.result))
            state.userInfo = result.result
            const accountType = result.result.accountType;
            _store("UserInfoForGUID", accountType);
        },
        // 判断当前用户是否是管理员
        async checkUserInfoIsAdmin({state}, payload) {
            let isUpdate = false
            if (payload) {
                isUpdate = payload.isUpdate
            }
            if (!sessionStorageUtil.getItem('userIsAdmin') || isUpdate) {
                try {
                    if (payload.teamId) {
                        let res = await checkUserIsAdmin(payload);
                        if (res.code !== 200) {
                            return;
                        }
                        state.userIsAdmin = res.result;
                        sessionStorageUtil.setItem("userIsAdmin", res.result)
                    }
                } catch (error) {
                    // console.log('[checkUserInfoIsAdmin]: error', error);
                }
            }
        },
        //  获取工位
        async getPositionByLoginList({state, commit}) {
            const res = await getPositionByLogin();
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return;
            }
            state.mailWorkPosition = res.result
        }
    },
};

export default user;
