<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.binding.phone')"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <template #default>
      <el-form
          ref="ruleFormRefPhone"
          :model="ruleFormPhone"
          :rules="rulesPhone"
          class="demo-ruleForm"
          :label-position="'top'"
          style="padding:0px 20px"
      >
        <el-form-item :label="$t('common.phone.number')" prop="phone">
          <CountrySelect @changeSelect="getSelectValue"></CountrySelect>
          <el-input
              style="width: calc(100% - 95px)"
              v-model="ruleFormPhone.phone"
              :placeholder="$t('sys.tip.input.phone')"
              :disabled="userInfo.phone!==''"
          ></el-input>
        </el-form-item>
        <el-form-item
            :label="$t('common.yzm')"
            prop="emailCode"
            :aria-placeholder="$t('sys.tip.input.sms.code')"
            id="sp_input"
        >
          <el-input
              v-model="ruleFormPhone.code"
              :placeholder="$t('90104')"
              :clearable="false"
              @keydown.enter.native="bindPhoneApis"
          >
            <template #suffix>
              <el-button type="primary" @click="sendPhone" :disabled="!showPhone" link
                         style="padding-right:15px;font-size: 12px">
                <span v-if="showPhone">{{ $t('common.label.getsmscode') }}</span>
                <span v-show="!showPhone">{{ countPhone }}s</span>
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div class="bottom_btn">
        <el-button @click="close">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="bindPhoneApis"
        >{{ $t("common.confirm") }}
        </el-button
        >
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted, nextTick, reactive} from 'vue'
import {useStore} from "vuex";
import {addEmailGroupFile, updateEmailGroupFile} from "@/api/sidebar";
import CountrySelect from "@/components/CountrySelect/index.vue";
import {i18n} from "@/lang";
import {phoneReg, TIME_COUNTPhone} from "@/utils/statusParams";
import {bindPhone, sendBindPhoneSms} from "@/api/usercenter";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const fileDirName = ref("")
const loading = ref(false)
const ruleFormRefPhone = ref(null)
const userInfo = computed(() => store.state.user.userInfo)
const teamId = computed(() => store.state.email.teamID);
const refInput = ref(null)
const {value, isHeadLine, isEdit, currentItem} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  isEdit: {type: Boolean, default: false},
  currentItem: {type: Object, default: {}}
})
const rulesPhone = reactive({
  phone: [{required: true, message: i18n.global.t('sys.tip.input.phone'), trigger: "blur"}],
  code: [{required: true, message: i18n.global.t('sys.tip.input.verify.code'), trigger: "blur"}]
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}

const ruleFormPhone = ref({});
// 绑定的手机
const ruleFormRef = ref(null)
const rules = ref({
  phone: [{required: true, message: i18n.global.t('set.phone.phone.placeholder'), trigger: "blur"}],
  code: [{required: true, message: i18n.global.t('sys.tip.input.verify.code'), trigger: "blur"}],
});
// 选中的区号 默认为中国
const selectTargetPrefix = ref('CN')
const getSelectValue = (val) => {
  selectTargetPrefix.value = val.zoneCode
}

// 绑定的手机
const bindPhoneValue = ref('')
// 绑定手机
const bindPhoneApis = async () => {
  const res = await bindPhone({
    phone: ruleFormPhone.value.phone,
    code: ruleFormPhone.value.code,
    type: bindPhoneValue.value !== '' ? 2 : 1,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  await store.dispatch('user/getUserInfoForGUID')
  await getBindPhones()
  ruleFormRefPhone.value.resetFields()
  close()
  emit("refresh");
}


// 重新获取用户信息
const getBindPhones = () => {
  const phone = userInfo.value.phone
  if (phone) {
    bindPhoneValue.value = phone
    ruleFormPhone.value.phone = bindPhoneValue.value
  }
}


onMounted(async () => {
  await getBindPhones()
})


// 绑定手机号
const timerPhone = ref(null);
const countPhone = ref(0);
const showPhone = ref(true)
const sendPhone = () => {
  if (ruleFormPhone.value.phone !== '' && phoneReg.test(ruleFormPhone.value.phone)) {
    if (!timerPhone.value) {
      countPhone.value = TIME_COUNTPhone;
      showPhone.value = false;
      timerPhone.value = setInterval(() => {
        if (countPhone.value > 0 && countPhone.value <= TIME_COUNTPhone) {
          countPhone.value--;
        } else {
          showPhone.value = true;
          clearInterval(timerPhone.value); // 清除定时器
          timerPhone.value = null;
        }
      }, 1000)
      //   发送请求
      sendBindPhoneSmsApis()
    }
  } else {
    ElMessage.error($t('sys.message.error.phone'));
  }
}

// 发送手机验证码
const sendBindPhoneSmsApis = async () => {
  const res = await sendBindPhoneSms({
    phone: ruleFormPhone.value.phone,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)

}

</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

:deep(.el-form-item__label) {
  font-weight: 500 !important;
}

.selects {
  width: 250px;
  margin: 10px 0 20px 18px;

  :deep(.el-select) {
    width: 100% !important;
  }
}

.bottom_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

#sp_input {
  :deep(.el-form-item__content) {
    .el-input--suffix {
      .el-input__wrapper {
        padding: 1px 0px 0px 11px !important;
      }
    }
  }
}
</style>
