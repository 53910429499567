<template>
  <div id="Card" ref="card">
    <el-card
      :bordered="false"
      :style="{ height: isNaN(height - 0) ? height : height + 'px' }"
    >
      <el-row type="flex" justify="space-between" align="middle">
        <el-col class="title">{{ title }}</el-col>
        <el-col class="header"><slot name="extra"></slot></el-col>
      </el-row>
      <div class="flex1 card-content">
        <slot name="content"></slot>
      </div>
    </el-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { ElCard, ElRow, ElCol } from "element-plus";

export default defineComponent({
  name: "Card",
  components: {
    ElCard,
    ElRow,
    ElCol,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "400",
    },
  },
});
</script>

<style lang="less" scoped>
:deep(.el-card__body) {
  padding: 0px !important;
  flex-direction: column;
}
#Card {
  margin-top: 12px !important;

}
</style>
