<template>
  <div>
    <div class="teams" style="margin-top: 12px">
      <h2 class="title">{{ $t("common.myteam") }}</h2>
      <el-button type="primary" link @click="createTeamLink" v-if="isSuperAdmin||isAdmin">
        {{ $t("common.team.create") }}
      </el-button>
    </div>
    <!--创建团队对话框-->
    <Dialog
        v-model="dialogVisible"
        :title="$t('common_team_create')"
        :dialogVisible="dialogVisible "
        :width="'max-content'"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (dialogVisible = val)"
    >
      <template #content>
        <div class="my_forms">
          <div>{{ $t("common.team.name") }}：</div>
          <div style="margin-top: 8px">
            <el-input v-model="inputTeamName" @keydown.enter.native="createConfirm"/>
          </div>
        </div>
      </template>
      <template #footer>
        <div id="sendRequire">
          <el-button :loading="teamLoading" type="primary" @click="createConfirm">{{ $t("common.confirm") }}</el-button>
        </div>
      </template>
    </Dialog>
    <!--团队列表-->
    <el-card
        v-loading="loading"
        v-if="loading"
        class="fx-aj-c"
        style="margin-top: 10px; text-align: center; height: 130px;position: relative;z-index: 9"
    >
    </el-card>
    <draggable
        v-if="!loading"
        v-model="teamListPage"
        group="people"
        animation="300"
        @start="drag = true"
        @end="drag = false"
        item-key="teamId"
        :key="mainKey"
    >
      <template #item="{ element ,index}" style="margin-top: 12px">
        <CardTeam :bordered="false" :height="'max-content'">
          <template #content>
            <div id="move_cards" class="top_title">
              <span slot="title">
                <span @click.stop="toLink(element)" class="pointer">
                  {{ element.teamName }}
                  <span>{{
                      $t("common.team.numberTeam1", {
                        num: element.teamCount == undefined ? 0 : element.teamCount,
                      })
                    }}</span>
                </span>
                <a @click="changeTeamNameHandler(element)" v-if="element.flag">
                  <el-icon style="margin-left: 10px; color: #409eff">
                    <Edit/>
                  </el-icon>
                </a>
              </span>
              <a slot="extra" href="#" v-if="element.flag">
                <el-icon
                    @click="inviteTeamMenber(element)"
                    style="color: #409eff"
                >
                  <CirclePlus/>
                </el-icon>
              </a>
            </div>
            <div class="boxContent fx">
              <!-- Other content slots go here -->
              <!-- 未读邮件 -->
              <router-link
                  class="Item pointer"
                  :to="{ name: 'Inbox', query: { teamID: element.teamId } }"
                  @click.native="handleClick('inbox',element.teamId,index)"
              >
                <span class="red bold">{{ element.unReadCount }}</span>
                <br/>
                <span class="fs14c000">{{ $t("common.email.noread") }}</span>
              </router-link>
              <!-- 跟进提醒 -->
              <router-link
                  class="Item pointer"
                  :to="{ name: 'FollowUp', query: { teamID: element.teamId } }"
                  @click.native="handleClick('follow_up',element.teamId,index)"
              >
                <span class="bold">{{ element.followCount }}</span>
                <br/>
                <span class="fs14c000">{{
                    $t("common.followup.emailremind")
                  }}</span>
              </router-link>
              <!-- 邮件待审核 -->
              <router-link
                  v-if="element.flag"
                  class="Item pointer"
                  :to="{ name: 'Examine', query: { teamID: element.teamId } }"
                  @click.native="handleClick('examine',element.teamId,index)"
              >
                <span class="bold">{{ element.auditCount }}</span>
                <br/>
                <span class="fs14c000">{{ $t("common.email.toexamine") }}</span>
              </router-link>
              <!-- 发送失败 -->
              <router-link
                  class="Item pointer"
                  :to="{ name: 'Draft', query: { teamID: element.teamId } }"
                  @click.native="handleClick('draft',element.teamId,index)"
              >
                <span class="bold">{{ element.failCount }}</span>
                <br/>
                <span class="fs14c000">{{ $t("common.send.failed") }}</span>
              </router-link>
              <!-- 领用待审核 -->
              <router-link
                  v-if="element.flag"
                  class="Item pointer"
                  :to="{
                  name: 'Setting',
                  query: { teamID: element.teamId ,index: 6},
                }"
                  @click.native="handleClick('examine',element.teamId,index)"
              >
                <span class="bold">{{ element.receiveCount }}</span>
                <br/>
                <span class="fs14c000">{{ $t("common.claim.toexamine") }}</span>
              </router-link>
            </div>
          </template>
        </CardTeam>
      </template>
    </draggable>
    <div class="pages">
      <!--      <el-config-provider :locale="'en'">-->
      <el-pagination
          v-model:current-page="currentTeam"
          v-model:page-size="sizeTeam"
          v-if="totalTeam"
          layout="total, prev, pager, next"
          :total="totalTeam"
          @current-change="handleCurrentChange"
      />
      <!--      </el-config-provider>-->
    </div>
    <PositionWarningPop v-if="dialogVisibleTips" v-model="dialogVisibleTips" @close="close"></PositionWarningPop>
    <!--    修改团队-->
    <Dialog
        v-model="dialogVisibleChangeTeam"
        :title="$t('common.team.modifyName')"
        :width="'max-content'"
        :dialogVisible="dialogVisibleChangeTeam"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (dialogVisibleChangeTeam = val)"
    >
      <template #content>
        <div class="my_forms">
          <div>{{ $t('common.team.name') }}：</div>
          <div>
            <el-input v-model="inputTeamName" @keydown.enter.native="changeTeamName"/>
          </div>
        </div>
      </template>
      <template #footer>
        <div id="sendRequire">
          <el-button type="primary" @click="changeTeamName">{{ $t('common.confirm') }}</el-button>
        </div>
      </template>
    </Dialog>

    <SearchTargetUserAndSend v-if="dialogVisibleInviteMember" v-model="dialogVisibleInviteMember"
                             @close="closeDia"
                             :teamId="activeTeamsInvite.teamId"></SearchTargetUserAndSend>

  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "vue";
import draggable from "vuedraggable";
import CardTeam from "@/components/CardTeam.vue";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import Dialog from "@/components/Dialog.vue";
import {V2CreateTeam, V2TeamEdit} from "@/api";
import {Search} from "@element-plus/icons-vue";
import {i18n} from "@/lang";
import PositionWarningPop from "@/components/DiaPops/PositionWarningPop.vue";
import CreateTeamPop from "@/components/DiaPops/CreateTeamPop.vue";
import SearchTargetUserAndSend from "@/components/DiaPops/SearchTargetUserAndSend.vue";

export default defineComponent({
  computed: {
    Search() {
      return Search
    }
  },
  components: {SearchTargetUserAndSend, CreateTeamPop, PositionWarningPop, Dialog, draggable, CardTeam},
  setup() {
    // 判断是否是超级管理员如果是的话 就展示
    // 权限控制
    const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
    const isAdmin = computed(() => store.getters["user/isAdmin"])

    const store = useStore();
    const teamListPage = computed(() => store.state.home.teamListPage)
    const loadingDone = ref(true);
    const mainKey = ref(0)

    const mailWorkPosition = computed(() => store.state.user.mailWorkPosition)

    // 工位预警
    const dialogVisibleTips = ref(false)
    const dialogVisibleChangeTeam = ref(false)
    const dialogVisibleInviteMember = ref(false);
    const userInfo = computed(() => store.state.user.userInfo);

    // team id
    const teamId = computed(() => store.state.email.teamID);

    const teamList = ref([])
    const drag = ref(false)
    const inputSendID = ref('')

    // 获取团队成员--分页
    const currentTeam = computed(() => store.state.home.currentTeam);
    const sizeTeam = computed(() => store.state.home.sizeTeam);
    const totalTeam = computed(() => store.state.home.totalTeam);
    const loading = computed(() => store.state.home.loading)
    const handleCurrentChange = async (page) => {
      store.commit('home/setPages', page)
      await getTeamListPages();
    };

    const getTeamListPages = async () => {
      await store.dispatch("home/getTeamListPages");
      drag.value = false;
      // 数据无法进行交换
      // teamList.value = teamListPage.value;
    }

    const close = () => {
      dialogVisibleTips.value = false
    }

    const closeDia = () => {
      dialogVisibleInviteMember.value = false;
    }

    const handleClick = async (flag, teamId, index) => {
      await store.commit('email/setCurTeamID', teamId);
      // localStorage.setItem("activeMenu", `/email/${flag}`);
      // localStorage.setItem("teamId", teamId)
      localStorage.setItem("tabIndex", index)
    }


    onMounted(async () => {
      await getTeamListPages();
      await store.dispatch("user/getPositionByLoginList");
      // await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value})
    });

    // 创建团队对话框
    const dialogVisible = ref(false);
    //  创建团队
    const inputTeamName = ref("");
    const teamLoading = ref(false)
    const createTeamLink = () => {
      dialogVisible.value = true
    }
    const createConfirm = async () => {
      if (mailWorkPosition.value.freePosition !== 0) {
        if (inputTeamName.value !== '') {
          teamLoading.value = true;
          let res = await V2CreateTeam({
            name: inputTeamName.value,
          });
          if (res.code !== 200) {
            ElMessage.error(res.message)
            return;
          }
          ElMessage.success(res.message)
          await getTeamListPages()
          await store.dispatch('email/getTeamList', {isUpdate: true})
          teamLoading.value = false;
          dialogVisible.value = false
          inputTeamName.value = ''
        } else {
          ElMessage.error(i18n.global.t('20002'))
        }
      } else {
        dialogVisibleTips.value = true;
      }
    }
    // 改变团队名称
    const activeTeams = ref(null);
    const changeTeamNameHandler = async (val) => {
      dialogVisibleChangeTeam.value = true
      activeTeams.value = val;
      inputTeamName.value = val.teamName
    }

    const changeTeamName = async () => {
      const res = await V2TeamEdit({
        id: activeTeams.value.teamId,
        name: inputTeamName.value
      })
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return
      }
      ElMessage.success(res.message)
      dialogVisibleChangeTeam.value = false;
      inputTeamName.value = ''
      await getTeamListPages()
      await store.dispatch('email/getTeamList')
    }
    // 邀请成员
    const activeTeamsInvite = ref({teamId: ''});
    const inviteTeamMenber = (val) => {
      dialogVisibleInviteMember.value = true
      activeTeamsInvite.value = val;
      inputSendID.value = ''
      // console.log(activeTeamsInvite.value.teamId, 'ff')
    }
    return {
      handleClick,
      handleCurrentChange,
      activeTeamsInvite,
      loadingDone,
      closeDia,
      currentTeam,
      sizeTeam,
      totalTeam,
      loading,
      teamLoading,
      createConfirm,
      createTeamLink,
      inputTeamName,
      dialogVisible,
      mailWorkPosition,
      dialogVisibleTips,
      changeTeamNameHandler,
      dialogVisibleChangeTeam,
      changeTeamName,
      dialogVisibleInviteMember,
      inputSendID, inviteTeamMenber,
      userInfo,
      drag,
      teamList,
      mainKey,
      teamListPage,
      close,
      isSuperAdmin,
      isAdmin,
    };
  },
});
</script>

<style lang="less" scoped>
@import "ElCalender/less/MyTeam.less";
@import "../../less/page.less";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.my_forms {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    line-height: 30px;
  }
}

#sendRequire {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


</style>
