<template>
  <div class="top_title">{{ $t('common.noticeandnews')}}</div>
  <div style="padding: 0px 12px 0px 18px;" v-if="dataList.length>1">
    <el-scrollbar>
      <div class="item_box">
        <div v-for="item in dataList" class="item">
          <div class="left">
            <el-avatar
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            />
          </div>
          <div class="right">
            <div class="tops">{{ item.notifyType }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
  <div v-else>
    <div class="item_box">
      <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {emptyUrl} from "@/utils/statusParams";

const dataList = ref([])
</script>

<style lang="less" scoped>
.top_title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding: 20px;
}

.top_title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding: 20px;
}

.item_box {
  //background: white;
  padding: 0 20px;

  .item {
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: flex-start;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      margin: 14px 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .tops {
        font-size: 16px;
        font-weight: bold;
      }

      .content {
        overflow-wrap: break-word;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
      }
    }
  }
}

:deep(.el-scrollbar) {
  height: calc(100vh - 140px);
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: 6px;
}

</style>