import request from "@/utils/request";

// 获取 sidebar 文件列表
export function getEmailGroupFileList(params, config) {
    return request.get("/mailEmailGroupFile/getEmailGroupFileList", params, config)
}

//添加 sidebar 文件
export function addEmailGroupFile(params, config) {
    return request.get('/mailEmailGroupFile/addEmailGroupFile', params, config)
}

//删除 sidebar 文件
export function deleteGroupFile(params, config) {
    const {id} = params
    return request.delete(`/mailEmailGroupFile/delete?id=${id}`, params, config)
}

//编辑
export function updateEmailGroupFile(params, config) {
    return request.get(`/mailEmailGroupFile/updateEmailGroupFile`, params, config)
}