<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('set.phone.title') }}</div>
      <div class="content">
        <el-form
            ref="ruleFormRef"
            :model="ruleFormPhone"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
            :label-position="'top'"
        >
          <el-form-item :label="$t('set.phone.phone')" prop="phone">
            <CountrySelect @changeSelect="getSelectValue"></CountrySelect>
            <el-input
                v-model="ruleFormPhone.phone"
                :placeholder="$t('set.phone.phone.placeholder')"
                style="width: 68%"
            ></el-input>
          </el-form-item>
          <el-form-item
              :label="$t('common.yzm')"
              prop="code"
              :aria-placeholder="$t('sys.tip.input.verify.code')"
          >
            <el-input
                v-model="ruleFormPhone.code"
                :placeholder="$t('set.phone.code.placeholder')"
                id="sp_input"
                :clearable="false"
            >
              <template #suffix>
                <el-button style="border-radius: 0px 4px 4px 0px !important;" type="primary" @click="sendPhone"
                           :disabled="!showPhone">
                  <span v-if="showPhone">{{ $t('common.label.getsmscode') }}</span>
                  <span v-show="!showPhone">{{ countPhone }}s</span>
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round @click="bindPhoneApis">{{ $t('common.save') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="text_md">
        [ {{ $t('set.phone.text.md.des') }}]
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import {ElMessage,} from "element-plus";
import {sendBindPhoneSms, bindPhone} from "@/api/usercenter";
import {TIME_COUNTPhone, phoneReg} from '@/utils/statusParams'
import CountrySelect from "@/components/CountrySelect/index.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {i18n} from "@/lang";

const store = useStore()
const router = useRouter()
const ruleFormPhone = ref({});
// 绑定的手机
const ruleFormRef = ref(null)
// 绑定手机号
const timerPhone = ref(null);
const countPhone = ref(0);
const showPhone = ref(true)
const rules = ref({
  phone: [{required: true, message: i18n.global.t('set.phone.phone.placeholder'), trigger: "blur"}],
  code: [{required: true, message: i18n.global.t('sys.tip.input.verify.code'), trigger: "blur"}],
});
// 选中的区号 默认为中国
const selectTargetPrefix = ref('CN')
const getSelectValue = (val) => {
  selectTargetPrefix.value = val.zoneCode
}

const sendPhone = () => {
  if (ruleFormPhone.value.phone !== '' && phoneReg.test(ruleFormPhone.value.phone)) {
    if (!timerPhone.value) {
      countPhone.value = TIME_COUNTPhone;
      showPhone.value = false;
      timerPhone.value = setInterval(() => {
        if (countPhone.value > 0 && countPhone.value <= TIME_COUNTPhone) {
          countPhone.value--;
        } else {
          showPhone.value = true;
          clearInterval(timerPhone.value); // 清除定时器
          timerPhone.value = null;
        }
      }, 1000)
      //   发送请求
      sendBindPhoneSmsApis()
    }
  } else {
    ElMessage.error(i18n.global.t('sys.message.error.phone'));
  }
}
// 发送手机验证码
const sendBindPhoneSmsApis = async () => {
  const res = await sendBindPhoneSms({
    phone: ruleFormPhone.value.phone,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)

}

// 绑定手机
const bindPhoneApis = async () => {
  const res = await bindPhone({
    phone: ruleFormPhone.value.phone,
    code: ruleFormPhone.value.code,
    type: 1,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  await store.dispatch('user/getUserInfoForGUID')
  ruleFormRef.value.resetFields();
  router.push('/personal/basic')
}
const userInfo = computed(() => store.state.user.userInfo)
const isChange = ref('')
onMounted(async () => {
  isChange.value = router.currentRoute.value.query.isChange
  if (isChange.value === '1') {
    ruleFormPhone.value.phone = userInfo.value.phone
  }
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 200px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: center;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}

#sp_input {
  border-right: none !important;
}

:deep(.el-input__wrapper) {
  padding: 1px 0px 1px 11px !important;
}

.text_md {
  text-align: center;
  margin-top: 40px;
  color: #999;
  font-size: 14px;
}
</style>
