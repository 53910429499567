<template>
  <div ref="inboxRef">
    <el-table :data="tableData" style="width: 100%"
              :height="tableHeight"
              :cell-style="{ textAlign: 'center' }"
              v-loading="loading"
              :header-cell-style="{ 'text-align': 'center',background: '#f9f9f9', color: 'black',padding:'16px 0px'}">
      <el-table-column :label="$t('common.customer.typeList')">
        <template #default="{ row, column, $index }">
          <div>
            {{ row.customerType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common_customer_level')">
        <template #default="{ row, column, $index }">
          <div>
            {{ row.customerLevel }}
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('common.followup.indays')">
        <template #default="{ row, column, $index }">
          <div v-if="!row.editing">
            {{ row.alertDay }}
          </div>
          <div v-else>
            <el-input v-model.number="row.alertDay" type="number" :min="0" oninput="if(value<0)value=0"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.cz')">
        <template #default="{ row, column, $index }">
          <div v-if="!row.editing">
            <div class="settings">
              <el-button
                  type="primary"
                  link
                  @click="handleEdit(row, column, $index)"
              >{{ $t('common.setting') }}
              </el-button
              >
            </div>
          </div>
          <div v-else>
            <div class="settings">
              <el-button
                  type="primary"
                  link
                  @click="handleBlur(row, column, $index)"
              >{{ $t('common.save') }}
              </el-button
              >
              <el-button
                  type="danger"
                  link
                  @click="handleCancel(row, column, $index)"
              >{{ $t('common.sss16') }}
              </el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
      </template>
    </el-table>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from "vue";
import {useStore} from "vuex";
import {ElTable} from "element-plus";
import {emptyUrl} from "@/utils/statusParams";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const store = useStore();
const tableData = computed(() => store.state.cusList.tableData);
const loading = ref(false)
const originalAlertDay = ref('')
const handleEdit = async (row, column, index) => {
  originalAlertDay.value = row.alertDay;
  store.commit("cusList/changeEditing", {index, flag: true});
};
//保存
const handleBlur = async (row, column, index) => {
  await store.dispatch("cusList/editCustomerData", {
    ...row,
    alertDay: row.alertDay,
  });
  store.commit("cusList/changeEditing", {index, flag: false});
  await store.dispatch("cusList/getMailCustomerLevel", {isUpdate: true});
};

const tableHeight = ref("40vh");

const inboxRef = ref(null);


// 添加
const addNewTableItem = () => {
  tableData.value.push({
    name: "",
    days: 0,
    address: "",
    editing: true,
  });
};

onMounted(async () => {
  loading.value = true
  await store.dispatch("cusList/getMailCustomerLevel");
  loading.value = false
});
// 取消
const handleCancel = async (row, column, index) => {
  console.log(row, 'row')
  tableData.value[index].editing = false;
  row.alertDay = originalAlertDay.value
};
const handleDelete = (row, column, index) => {
  tableData.value.splice(index, 1);
};
</script>
<style scoped lang="less">
.settings {
  display: flex;
  justify-content: center;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px !important;
}
</style>
