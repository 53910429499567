//全局页面跳转是否启用loading
export const routerLoading = true;

//全局api接口调用是否启用loading
export const apiLoading = true;

//loading参数配置
export const loadingConfig = {
    lock: true,
    text: '',
    background: 'rgba(255,255,255,0.8)',
    body: true,
}

//loading 注销账号
export const loadingConfigLogOut = {
    lock: true,
    text: '账号正在注销中...',
    background: 'rgba(255,255,255,0.8)',
    body: true,
}