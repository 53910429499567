<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.remark')"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div style="display: flex;justify-content: center;align-items: center">
      <ElInput
          ref="refInput"
          v-model="remark"
          style="height: 32px; width: 100%;"
          maxlength="30"
          show-word-limit
          clearable
          type="text"
          @keydown.enter.native="setRemark"
      />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="close" style="margin-right: 10px">
          {{
            $t("common.cancel")
          }}
        </ElButton>
        <ElButton type="primary" @click="setRemark" :loading="loading">
          {{
            $t("common.confirm")
          }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted, nextTick} from 'vue'
import {useStore} from "vuex";
import {addEmailGroupFile, updateEmailGroupFile} from "@/api/sidebar";
import {i18n} from "@/lang";
import {requestUpdateMailRemark, requestUpdateTaskRemark} from "@/api/mail";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const remark = ref("")
const loading = ref(false)
const teamId = computed(() => store.state.email.teamID);
const refInput = ref(null)
// task 是否是任务列表
const {value, isHeadLine, isEdit, currentItem, mailId, task} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  isEdit: {type: Boolean, default: false},
  currentItem: {type: Object, default: {remark: ""}},
  mailId: {type: Boolean, default: false},
  task: {type: Boolean, default: false},
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

watch(() => currentItem, (newValue) => {
  if (newValue?.remark) {
    remark.value = newValue.remark;
  }
}, {immediate: true});
const close = () => {
  emit("close");
}

onMounted(() => {
  nextTick(() => {
    refInput.value.focus()
  })
})

const isLoading = ref(false)

async function setRemark() {
  console.log({id: currentItem?.id, remark: remark.value}, 'ff')
  if (remark.value.length > 30) {
    ElMessage.warning(i18n.global.t('common.email.setBlockSize'))
    remark.value = "";
    return;
  } else {
    if (task) {
      const result = await requestUpdateTaskRemark({
        id: currentItem.id,
        remark: remark.value
      })
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
    } else {
      const result = await requestUpdateMailRemark({
        id: mailId ? currentItem?.mailId : currentItem?.id,
        remark: remark.value
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
    }
    close()
    emit('getList')
  }
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

</style>
