import _store from 'store2'
import {
    addFile, addFileShare,
    clearFile, copyFile, copyMailFile,
    deleteFile,
    editFile, fileCancelPublicShare, fileCancelShare, filePublicShare, getFileByLinkAddress,
    getFileList,
    getFilePath, getPublicShareUserList, getShareNameByLinkCode,
    hideFile, mailFileShare, mailFileShareList,
    moveFile, myPublicShareList, publicShareOtherList,
    queryByUserId, queryShareFileByParentId
} from "@/api/cloud";
import {ElMessage} from "element-plus";
import {formatEchartsData} from "@/utils/echarts";

const cloud = {
    namespaced: true,
    state: {
        // 是否切换路由
        isChangeRouter: false,
        disabled: true,
        downloadStatus: 'done', //loading 下载中 done 已完成
        //     文件列表
        fileList: [],
        total: 0,
        pages: 1,
        isCurrent: false,
        //  选中文件夹中的文件list
        dirFileList: [],
        //  是否第一次 scroll 纠正偏移
        isFirstPageLoaded: false,


        //移动文件夹
        moveFileList: [],

        //文件分享
        fileLink: {
            shareDay: 0,
            shareCode: "",
            linkAddress: "",
        },


        //     文件分享列表---链接提取码
        shareCodeFileList: [],
        //     是否匹配
        isCodeShareLink: false,


        //     共享用户列表
        sharedUserList: [],

        //     分享用户详情
        shareTargetUser: {},

        //     存储空间大小
        spaceSize: {
            effectiveLocation: 0,
            containsLocation: 0,
            freeLocation: 0,
            fileCloudLocation: 0,
            emailFileLocation: 0,
        },
        //     initEchart
        echartsData: []

    },
    mutations: {
        changeDownLoadStatus(state, status) {
            state.downloadStatus = status
        },
        setFileList(state, status, payload) {
            state.fileList = [...state.fileList, ...payload]
        },
        changeCurrent(state, payload) {
            state.isCurrent = payload;
        },
        changeIsFirstPageLoaded(state, payload) {
            console.log("isFirstPageLoaded", payload,)
            state.isFirstPageLoaded = payload;
        },
        changeDisabled(state, payload) {
            state.disabled = payload;
        },
        changeTotal(state, payload) {
            state.total = payload;
        },
        isChangeRouterPayload(state, payload) {
            state.isChangeRouter = payload
        }
    },
    actions: {
        // 根据路径获取文件列表
        async getFilePathApis({state}, payload) {
            const {show, type, pageNo, pageSize, id} = payload

            const res = await getFilePath({id, type, pageNo, pageSize})
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }

            console.log('target by id list', res.result)
            if (show === 1) {
                state.dirFileList = res.result.records
            } else {
                if (!state.isCurrent) {
                    state.fileList = []
                    state.fileList = res.result.records;

                    state.moveFileList = [];
                    state.moveFileList = res.result.records;
                } else {
                    state.fileList = [...state.fileList, ...res.result.records];

                    state.moveFileList = [...state.moveFileList, ...res.result.records];
                }
                state.total = res.result.total
                state.pages = res.result.pages;
            }
        },

        async getFilePathApisMove({state}, payload) {
            const {show, type, pageNo, pageSize, id} = payload

            const res = await getFilePath({id, type, pageNo, pageSize})
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }

            console.log('target by id list', res.result)
            if (show === 1) {
                state.dirFileList = res.result.records
            } else {
                if (!state.isCurrent) {
                    state.moveFileList = [];
                    state.moveFileList = res.result.records;
                } else {
                    state.moveFileList = [...state.moveFileList, ...res.result.records];
                }
                state.total = res.result.total
                state.pages = res.result.pages;
            }
        },

        //  查询当前用户空间情况
        async queryByUserIdApis({state}, payload) {
            const res = await queryByUserId(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log("queryByUserIdApis", res)
            state.spaceSize = res.result
            state.echartsData = formatEchartsData(state.spaceSize)
        },

        // 	获取文件列表
        async getFileListApi({state,}, payload) {
            const res = await getFileList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log(state.isCurrent, 'state.isCurrent')
            if (!state.isCurrent) {
                state.fileList = []
                state.fileList = res.result.records;

                state.moveFileList = [];
                state.moveFileList = res.result.records;
            } else {
                state.fileList = [...state.fileList, ...res.result.records]
                state.moveFileList = [...state.moveFileList, ...res.result.records];
            }
            // console.log('fileList=====>', res, state.fileList)
            state.total = res.result.total;
            state.pages = res.result.pages;

        },

        // 获取文件列表---move
        async getFileListApiMove({state,}, payload) {
            const res = await getFileList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log(state.isCurrent, 'state.isCurrent')
            if (!state.isCurrent) {
                state.moveFileList = [];
                state.moveFileList = res.result.records;
            } else {
                state.moveFileList = [...state.moveFileList, ...res.result.records,];
            }
            // console.log('fileList=====>', res, state.fileList)
            state.total = res.result.total;
            state.pages = res.result.pages;

        },

        //  添加文件
        async addFile({state,}, payload) {
            const res = await addFile(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log("addFile===>", res);
            // ElMessage.success(res.message)
        },

        //  文件收藏
        async hideFileApis({state}, payload) {
            const res = await hideFile(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log("hideFile===>", res)
            // ElMessage.success(res.message);
        },


        //    删除文件 type	1:恢复文件,2:删除文件,3:彻底删除文件
        async deleteFileApis({state}, payload) {
            const res = await deleteFile(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
        },

        //     修改指定文件的信息
        async editFileApis({state}, payload) {
            const res = await editFile(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
        },

        // 清空回收站
        async clearFileApis({state,}, payload) {
            const res = await clearFile()
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
        },

        //     移动到指定位置
        async moveFileApis({state}, payload) {
            const res = await moveFile(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
        },
        //  共享
        async filePublicShareApis({state}, payload) {
            const res = await filePublicShare(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log('filePublicShareApis', res)
        },

        // 共享给我--根据文件id 获取用户列表
        async getPublicShareUserListApis({state}, payload) {
            const res = await getPublicShareUserList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log("getPublicShareUserList", res.result)
            state.sharedUserList = res.result.records;
        },
        //     共享给我的
        async publicShareOtherListApis({state}, payload) {
            const res = await publicShareOtherList(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            if (!state.isCurrent) {
                state.fileList = []
                state.fileList = res.result.records;
            } else {
                state.fileList = [...state.fileList, ...res.result.records]
            }
            state.total = res.result.total;
            state.pages = res.result.pages;
        },
        //     我共享的
        async myPublicShareListApis({state}, payload) {
            const res = await myPublicShareList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            if (!state.isCurrent) {
                state.fileList = []
                state.fileList = res.result.records;
                console.log(res.result.records, 'res.result.records')
            } else {
                state.fileList = [...state.fileList, ...res.result.records]
            }
            state.total = res.result.total;
            state.pages = res.result.pages;
        },
        // 取消共享
        async fileCancelPublicShareApis({state}, payload) {
            const res = await fileCancelPublicShare(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
        },
        //     分享
        async mailFileShareApis({state}, payload) {
            const res = await addFileShare(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log('mailFileShare', res.result)
            const {linkAddress, shareCode, shareDay} = res.result
            state.fileLink.linkAddress = linkAddress;
            state.fileLink.shareCode = shareCode;
            state.fileLink.shareDay = shareDay;
        },
        //    分享列表
        async mailFileShareListApis({state,}, payload) {
            const res = await mailFileShareList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            if (!state.isCurrent) {
                state.fileList = []
                state.fileList = res.result.records;
            } else {
                state.fileList = [...state.fileList, ...res.result.records]
            }
            state.total = res.result.total;
            state.pages = res.result.pages;
        },
        //    取消分享
        async fileCancelShareApis({state}, payload) {
            const res = await fileCancelShare(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
        },

        //     文件分享---分享链接提取文件列表
        async getFileByLinkAddressApis({state}, payload) {
            const res = await getFileByLinkAddress(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log("getFileByLinkAddressApis", res);
            state.isCodeShareLink = true;
            if (!state.isCurrent) {
                state.fileList = []
                state.fileList = res.result.records;
            } else {
                state.fileList = [...state.fileList, ...res.result.records]
            }
            state.total = res.result.total;
            state.pages = res.result.pages;
        },

        // 获取分享人相关信息
        async getShareNameByLinkCodeApis({state}, payload) {
            const res = await getShareNameByLinkCode(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            console.log('shareTargetUser', res.result);
            state.shareTargetUser = res.result;
        },
        // 文件分享--根据父id 查询子孙
        async queryShareFileByParentIdApi({state}, payload) {
            const {show, type, pageNo, pageSize, parentId} = payload

            const res = await queryShareFileByParentId({pageNo, pageSize, parentId})
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            if (show === 1) {
                state.dirFileList = res.result.records
            } else {
                if (!state.isCurrent) {
                    state.fileList = []
                    state.fileList = res.result.records;

                    state.moveFileList = [];
                    state.moveFileList = res.result.records;
                } else {
                    state.fileList = [...state.fileList, ...res.result.records];

                    state.moveFileList = [...state.moveFileList, ...res.result.records];
                }
                state.total = res.result.total
                state.pages = res.result.pages;
            }
        },
        //     文件转存
        async copyFileApis({state}, payload) {
            const res = await copyFile(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
            console.log('copyFileApis', res)
        },
        //    邮件文件转存
        async copyEmailFileApis({state}, payload) {
            const res = await copyMailFile(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            ElMessage.success(res.message)
            console.log('copyFileApis', res)
        }

    }
}

export default cloud
