<template>
  <Dialog
      v-model="dialogVisible"
      :title="$t('common.archives.return')"
      :dialogVisible="dialogVisible"
      :isHeadLine="false"
      style="min-width: 800px"
      @update:dialogVisible="(val) => (dialogVisible = val)"
      :width="900"
      :top="!forms.merge?'0vh':'8vh'"
  >
    <template #content>
      <el-scrollbar :height="!forms.merge?'78vh':'450'" style="margin-top: 24px">
        <div class="my_forms">
          <div class="box_titles">
            <div class="inner_card sp_box">
              <el-form-item :label="$t('common.emailbox')+':'" label-width="80px" prop="email">
                <el-input v-model="forms.email"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="box_titles">
            <div class="inner_card sp_box">
              <el-form-item class="el-row" :label="$t('common.customer.name')+':'" label-width="80px"
                            prop="customerName">
                <el-select filterable v-model="forms.customerName" id="sp_selects" class="m-2" placeholder="Select"
                           @change="handleSelectChange" @focus="getAllCustomers">
                  <el-option
                      v-for="item in optionsCustomers"
                      :key="item.id"
                      :label="item.customerName"
                      :value="item.id"
                  />
                </el-select>
                <el-button class="sp_buttons" link type="primary"
                           @click.native.stop="createdNewCustomer">
                  {{ $t('common.email.archiv') }}
                </el-button>
              </el-form-item>
              <el-form-item class="el-row" :label="$t('common.email.archiveWay')+':'" label-width="80px"
                            prop="customerName">
                <el-select v-model="forms.merge" class="m-2" placeholder="Select" @change="handleChangesMerge">
                  <el-option :label="$t('common.email.existingContacts')" v-if="contactList.length!==0"
                             :value="true"></el-option>
                  <el-option :label="$t('common.email.newContacts')" :value="false"></el-option>
                </el-select>

              </el-form-item>
            </div>
          </div>
          <!--            这里需要选择性展示-->
          <div v-if="forms.merge" class="box_titles">
            <div class="inner_card sp_box">
              <el-form-item :label="$t('common.email.ContactName')+':'" prop="contactName">
                <el-select v-model="forms.contactName" class="m-2" placeholder="Select">
                  <el-option
                      v-for="item in contactList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div v-else class="box_titles_created">
            <div class="inner_card">
              <el-form
                  ref="ruleFormRef"
                  :model="ruleFormUsers"
                  :rules="rules"
                  label-width="80px"
                  class="demo-ruleForm"
                  :size="formSize"
              >
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item :label="$t('common.name')" prop="name">
                      <el-input v-model="ruleFormUsers.name"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('common.sex')" prop="sex">
                      <el-radio-group
                          v-model="ruleFormUsers.sex"
                          class="ml-4"
                      >
                        <el-radio :label="1" :value="1">{{ $t('common.man') }}</el-radio>
                        <el-radio :label="2" :value="2">{{ $t('common.woman') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item :label="$t('common.company.position')" prop="post">
                      <el-input v-model="ruleFormUsers.post"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('common.phone.number')" prop="phone">
                      <el-input v-model="ruleFormUsers.phone"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item :label="$t('common.contact.phone')" prop="telPhone">
                      <el-input v-model="ruleFormUsers.telPhone"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('common.dateofbirth')" prop="birthday">
                      <el-date-picker
                          v-model="ruleFormUsers.birthday"
                          type="date"
                          :placeholder="$t('sys.tip.select.date')"
                          :size="size"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="24">
                    <el-form-item :label="$t('common.remark')" prop="remark">
                      <el-input
                          v-model="ruleFormUsers.remark"
                          type="textarea"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="24">
                    <MyTitles :title="$t('common.emailbox')">
                      <template #content>
                        <div class="bottoms">
                          <el-form-item
                              class="sp_form_item"
                              v-for="(
                                      item, index
                                    ) in ruleFormUsers.mailContactEmailList"
                              style="margin-top: 20px"
                              :key="index"
                              :label="index === 0 ? $t('common.emailbox') : ''"
                              :prop="
                                      'mailContactEmailList[' +
                                      index +
                                      '].email'
                                    "
                              :rules="[
                                      {
                                        required: true,
                                        message: $t('common.email.email.notempty'),
                                        trigger: 'blur',
                                      },
                                      {
                                        pattern:emailReg,
                                        message: $t('sys.message.error.emailbox'),
                                        trigger: 'blur',
                                      },
                                    ]"
                          >
                            <div class="email-input">
                              <el-input
                                  v-model="item.email"
                                  :placeholder="$t('20202')"
                              />
                              <el-icon
                                  v-if="
                                          ruleFormUsers.mailContactEmailList
                                            .length !== 1
                                        "
                                  style="
                                          font-size: 20px;
                                          color: #d93737;
                                          margin-left: 20px;
                                          cursor: pointer;
                                        "
                                  @click="removeEmail(index)"
                              >
                                <RemoveFilled/>
                              </el-icon>
                              <div v-else style="width: 44px"></div>
                            </div>
                          </el-form-item>
                          <el-form-item>
                            <el-button
                                @click="addEmail"
                                id="spButton"
                                :icon="Plus"
                            >
                              {{ $t('common.add') }}
                            </el-button>
                          </el-form-item>
                        </div>
                      </template>
                    </MyTitles>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="24">
                    <MyTitles :title="$t('common.memorialday')">
                      <template #content>
                        <div class="remembers">
                          <RememberDate></RememberDate>
                          <div>
                            <div
                                v-for="(
                                        event, index
                                      ) in ruleFormUsers.mailContactSouvenirList"
                                :key="index"
                                class="event-input"
                            >
                              <template v-if="!event.editing">
                                <div class="out_box">
                                  <div
                                      class="event-text"
                                      @click="editEvent(index)"
                                  >
                                    {{ event.remark }}
                                  </div>
                                  <div class="event-text"></div>
                                </div>
                              </template>
                              <el-input
                                  v-if="event.editing"
                                  v-model="event.remark"
                                  :placeholder="$t('common.contact.info.anniversary.name')"
                                  @focus="showButtons(index)"
                                  @blur="hideButtons(index)"
                                  ref="inputRef"
                              ></el-input>
                              <div style="margin: 0 10px"></div>
                              <el-date-picker
                                  v-model="event.souvenirDay"
                                  type="date"
                                  class="event-text"
                                  :placeholder="$t('common.please.select')"
                              />
                              <div class="setting">
                                <el-button
                                    v-if="!event.showButtons"
                                    @click="updateEvent(index)"
                                    icon="el-icon-check"
                                    type="primary"
                                    link
                                >{{ $t('common.edit') }}
                                </el-button>
                                <el-button
                                    v-if="event.showButtons"
                                    @click="removeEvent(index)"
                                    icon="el-icon-delete"
                                    link
                                    type="danger"
                                >{{ $t('common.delete') }}
                                </el-button>
                                <el-button
                                    v-if="!event.showButtons"
                                    @click="removeEvent(index)"
                                    icon="el-icon-delete"
                                    link
                                    type="danger"
                                >{{ $t('common.delete') }}
                                </el-button>
                              </div>
                            </div>

                            <el-button @click="addEvent" type="primary"
                            >{{ $t('common.memorialday.add') }}
                            </el-button>
                          </div>
                        </div>
                      </template>
                    </MyTitles>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </template>
    <template #footer>
      <div class="footer_box">
        <el-button @click.native.stop="dialogVisible = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click.native.stop="saveAllDiaDatas">{{ $t('common.save') }}</el-button>
      </div>
    </template>
  </Dialog>
  <Dialog
      v-model="dialogVisibleCreatedMessage"
      :title="$t('common.khjd')"
      :dialogVisible="dialogVisibleCreatedMessage"
      :isHeadLine="false"
      :width="1010"
      @update:dialogVisible="(val) => (dialogVisibleCreatedMessage = val)"
      :top="forms.merge?'0vh':'8vh'"
  >
    <template #content>
      <el-scrollbar :height="forms.merge?'600':'75vh'">
        <Forms :isShowButton="false" ref="childRef">
          <template #createMessage>
            <div class="inner_outbox">
              <el-form-item :label="$t('common.region.name')" prop="zoneAddress">
                <el-select v-model="ruleForm.zoneAddress" :placeholder="$t('common.please.select')">
                  <el-option
                      v-for="item in areaList"
                      :label="item.country"
                      :value="item.country"
                      :key="item.country"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('common_customer_name')" prop="customerName">
                <el-input v-model="ruleForm.customerName" :placeholder="$t('common.please.input')"/>
              </el-form-item>
            </div>
          </template>
        </Forms>
      </el-scrollbar>
    </template>
    <template #footer>
      <div class="footer_box">
        <el-button @click.native.stop="dialogVisibleCreatedMessage = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="handleSaveData">{{ $t('common.save') }}</el-button>
      </div>
    </template>
  </Dialog>
</template>
<script setup>
import MyTitles from "@/views/Customer/Detail/MyTitles.vue";
import Dialog from "@/components/Dialog.vue";
import Forms from "@/views/Customer/CreateCheck/Forms.vue";
import {ref, defineProps, computed} from "vue";
import {addMailCustomerContact, getMailCustomerContactByCustomerId, requestAllCustomer} from "@/api/customer";
import {ElMessage} from "element-plus";
import dayjs from "dayjs";
import {emailReg} from "@/utils/statusParams";

const {forms, shows} = defineProps({
  forms: {
    type: Object,
    default: () => {
    }
  },
  shows: {
    type: Object,
    default: () => {
    }
  }
})

const  {dialogVisible, dialogVisibleCreatedMessage} = shows;
// 对话框
// const dialogVisible = ref(false);
// const dialogVisibleCreatedMessage = ref(false)
const teamId = computed(() => store.state.email.teamID);
const optionsCustomers = ref([])
// 手动筛选联系人列表
const contactList = ref([])

async function handleEdit(row) {
  dialogVisible.value = true
  forms.email = row.from
  await getAllCustomers();
  await defaultSelectCustomer()
  console.log(row, 'ff')
}


async function getAllCustomers() {
  let res = await requestAllCustomer(
      {
        teamId: teamId.value,
        type: 1,
      }
  )
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  const records = res.result.records;
  optionsCustomers.value = records;
}


// 默认选中
async function defaultSelectCustomer() {
  // 默认选中第一个
  forms.customerName = optionsCustomers.value[0].id
  let res = await getMailCustomerContactByCustomerId({customerId: forms.customerName})
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  const targetNewObj = res.result
  // 寻找第一个的联系人
  if (targetNewObj.length != 0) {
    contactList.value = targetNewObj
    forms.contactName = targetNewObj[0].id;
    forms.merge = true
  } else {
    contactList.value = []
    forms.merge = false
    forms.contactName = ''
  }
}


async function handleSaveData() {
  const res = await childRef.value.saveData()
  const form = computed(() => store.state.customer.form);
  const isHaveEmail = form.value.emails.length > 0 && form.value.emails[0].email != '' ? true : false
  // 校验成功
  if (res && isHaveEmail) {
    dialogVisible.value = false;
    dialogVisibleCreatedMessage.value = false
  }
  if (res && !isHaveEmail) {
    dialogVisibleCreatedMessage.value = false
  }
}


async function handleSelectChange(value) {
  let res = await getMailCustomerContactByCustomerId({customerId: value})
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  const targetNewObj = res.result
  if (targetNewObj.length !== 0) {
    contactList.value = targetNewObj
    forms.contactName = targetNewObj[0].id
    forms.merge = true;
  } else {
    contactList.value = [];
    forms.contactName = ""
    forms.merge = false;
  }
}


// 添加联系人表单
const ruleFormRef = ref(null);

// 保存添加的联系人
async function saveAllDiaDatas() {
  console.log(forms, ruleFormRef, forms.merge, '数据整合');
  const {email, customerName, contactName} = forms
  // 是否需要开启表单验证
  if (!forms.merge) {
    ruleFormRef.value.validate(async (valid, fields) => {
      if (valid) {
        const copObj = JSON.parse(JSON.stringify(ruleFormUsers));
        const {mailContactSouvenirList, mailContactEmailList} = copObj;
        let resMails = mailContactSouvenirList.map((item) => {
          return {
            ...item,
            souvenirDay:
                item.souvenirDay && dayjs(item.souvenirDay).isValid()
                    ? dayjs(item.souvenirDay).format("YYYY-MM-DD")
                    : null,
          };
        });
        //默认邮箱
        const selectDefault = mailContactEmailList.some(item => item.isDefault === 1) ? mailContactEmailList : mailContactEmailList.map((item, index) => {
          if (index === 0) {
            return {isDefault: 1, email: item.email}
          } else {
            return {...item, isDefault: 1}
          }
        })
        delete copObj.birthday;
        delete copObj.mailContactSouvenirList;
        delete copObj.mailContactEmailList
        const targetObj = {
          ...copObj,
          birthday:
              (ruleFormUsers.birthday && dayjs(ruleFormUsers.birthday).isValid())
                  ? dayjs(ruleFormUsers.birthday).format("YYYY-MM-DD")
                  : null,
          mailContactSouvenirList: resMails,
          mailContactEmailList: selectDefault,
          customerId: forms.customerName,
          teamId: teamId.value,
        };

        console.log(targetObj, forms, 'ffff')
        // 添加联系人邮箱
        let res = await addMailCustomerContact(targetObj);
        if (res.code !== 200) {
          ElMessage.error(res.message);
          return;
        }
        ElMessage.success(res.message);
        console.log('pinkser', res.result)
        const {id} = res.result;

        // 最终的数据
        const endTargetObj = {
          contactId: id,
          customerId: customerName,
          email,
          teamId: teamId.value,
        }
        console.log(endTargetObj, 'end')
        dialogVisible.value = false;
        await store.dispatch("customer/addAttachment", endTargetObj)

        getMailList()
      } else {
        console.log("error submit!", fields);
      }
    });
  } else {
    // 最终的数据
    const endTargetObj = {
      contactId: contactName,
      customerId: customerName,
      email,
      teamId: teamId.value,
    }
    console.log(endTargetObj, 'end')
    await store.dispatch("customer/addAttachment", endTargetObj)
    dialogVisible.value = false;
    getMailList()
  }
}


// 更改归档方式
function handleChangesMerge(value) {
  console.log(value)
  forms.merge = value;
}

// 建档
function createdNewCustomer() {
  dialogVisibleCreatedMessage.value = true;
}
</script>