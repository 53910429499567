import _store from "store2";

import {ElMessage} from "element-plus";

const pay = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {},
};

export default pay;
