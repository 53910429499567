<template>
  <div class="out_box">
    <div class="inner_search" id="sp_search">
      <el-form
          id="forms"
          ref="ruleFormRef"
          :model="searchForm"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
      >
        <div class="left_box">
          <el-form-item label-width="71" :label="$t('common.bybranch')+':'" v-if="adminShow"
                        prop="managerId">
            <!-- 分管人 -->
            <el-select
                v-model="searchForm.managerId"
                class="m-2"
                :default-first-option="true"
                @change="selectMore($event)"
            >
              <el-option
                  v-for="item in tableDataSelect"
                  :label="item.userName"
                  :value="item.userId"
                  :key="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item id="customerCode" :label="$t('common_customer_number')+':'" prop="customerCode"
                        v-if="searchShow">
            <el-input
                style="width: 200px"
                v-model="searchForm.customerCode"
                :placeholder="$t('30004')"
            />
          </el-form-item>
          <!-- 客户名称 -->
          <el-form-item :label="$t('common_customer_name')+':'" prop="customerName">
            <el-input
                style="width: 200px"
                v-model="searchForm.customerName"
                :placeholder="$t('30003')"
            />
          </el-form-item>
          <!-- 所属地区 -->
          <el-form-item :label="$t('common.customer.Region')+':'" prop="zoneAddress">
            <el-select
                filterable
                v-model="searchForm.zoneAddress"
                class="m-2"
                :placeholder="$t('common.please.select')"
            >
              <el-option
                  v-for="item in areaList"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
              />
            </el-select>
          </el-form-item>
          <!-- 客户等级 -->
          <el-form-item :label="$t('common.customer.type')+':'" prop="customerTypeId">
            <el-select
                filterable
                v-model="searchForm.customerTypeId"
                class="m-2"
                :placeholder="$t('common.please.select')"
            >
              <el-option
                  v-for="item in tableData"
                  :key="item.id"
                  :label="item.customerType"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.customer.email')+':'" prop="email" v-if="searchShow">
            <el-input
                style="width: 200px"
                v-model="searchForm.email"
                :placeholder="$t('common.customer.email.placeholder')"
            />
          </el-form-item>
          <el-form-item :label="$t('common.customer.source')+':'" prop="customerOrigin">
            <el-select
                filterable
                v-model="searchForm.customerOrigin"
                class="m-2"
                :placeholder="$t('common.please.select')"
            >
              <el-option
                  v-for="item in customerSelectData.customerOriginList"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.interest.product')+':'" prop="product">
            <el-select
                filterable
                v-model="searchForm.product"
                class="m-2"
                :placeholder="$t('common.please.select')"
            >
              <el-option
                  v-for="item in customerSelectData.productList"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>
          <!--          多项选择-->
          <!--          <el-form-item :label="$t('common.customer.levelList')+':'">-->
          <!--            &lt;!&ndash;      分类选择勾选&ndash;&gt;-->
          <!--            <el-select-->
          <!--                v-model="searchForm.valueTarget"-->
          <!--                multiple-->
          <!--                clearable-->
          <!--                collapse-tags-->
          <!--                placeholder="Select"-->
          <!--                popper-class="custom-header"-->
          <!--                :max-collapse-tags="1"-->
          <!--                style="width: 240px;"-->
          <!--            >-->
          <!--              <template #header>-->
          <!--                <el-checkbox-->
          <!--                    v-model="checkAll"-->
          <!--                    :indeterminate="indeterminate"-->
          <!--                    @change="handleCheckAll"-->
          <!--                >-->
          <!--                  All-->
          <!--                </el-checkbox>-->
          <!--              </template>-->
          <!--              <el-option-->
          <!--                  v-for="item in tableData"-->
          <!--                  :key="item.id"-->
          <!--                  :label="item.customerType"-->
          <!--                  :value="item.id"-->
          <!--              />-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->

          <!--          <el-form-item :label="`${$t('sending.customer.search.targetTime')}:`" prop="targetTime"-->
          <!--                        style="width: 340px;">-->
          <!--            <el-date-picker-->
          <!--                v-model="searchForm.targetTime"-->
          <!--                type="daterange"-->
          <!--                format="YYYY-MM-DD"-->
          <!--                value-format="YYYY-MM-DD"-->
          <!--                date-format="YYYY/MM/DD"-->
          <!--                range-separator="~"-->
          <!--                :start-placeholder="$t('sending.customer.search.startTime')"-->
          <!--                :end-placeholder="$t('sending.customer.search.endTime')"-->
          <!--            />-->
          <!--          </el-form-item>-->
        </div>
        <div class="right_box">
          <el-form-item>
            <el-button @click="resetForms(ruleFormRef)">{{ $t('common.reset') }}</el-button>
            <el-button type="primary" @click="onSubmit(ruleFormRef)"
            >{{ $t('common.query') }}
            </el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  computed,
  onMounted,
  reactive,
  defineEmits, watch,
} from "vue";
import {useStore} from "vuex";
import {throttle} from "@/utils/mylodash"
import {i18n} from "@/lang";
import {useRoute} from "vue-router";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const store = useStore();
const emits = defineEmits();
const route = useRoute()
// 区域列表
const areaList = computed(() => store.state.customer.areaList);
// 客户等级
const tableData = computed(() => store.state.cusList.tableData);
const customerSelectData = computed(() => store.state.cusList.customerSelectData);
const teamId = computed(() => store.state.email.teamID);

// search 表单
const searchForm = ref({
  customerName: "",
  managerId: "",
  zoneAddress: "",
  customerTypeId: "",
  valueTarget: '',
  targetTime: [],
  email: '',
  customerCode: '',
  customerOrigin: "",
  product: '',
});

// 权限控制
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isAdmin = computed(() => store.getters["user/isAdmin"])
// 分管列表
const tableDataSelect = computed(() => {
  return isSuperAdmin.value || isAdmin.value ? [...store.state.groups.AllTableData, {
    userName: i18n.global.t('common.all'),
    userId: 'all',
  }] : store.state.groups.AllTableData;
});
// 传递参数
const {adminShow, searchShow} = defineProps({
  adminShow: {
    type: Boolean,
    default: false,
  },
  searchShow: {
    type: Boolean,
    default: false
  },
  isDias: {
    type: Boolean,
    default: true,
  }
});
async function selectMore(event) {
  if (event === "all") {
    event = ""
  }
  if(route.path==='/email/customer/public'){
    await store.dispatch("cusList/getCustomerSelectDataFunction", {teamId: teamId.value, type: 2,managerId:event})
  }else{
    await store.dispatch("cusList/getCustomerSelectDataFunction", {teamId: teamId.value, type: 1,managerId:event})
  }
}


const userInfo = computed(() => store.state.user.userInfo)
onMounted(async () => {
  await store.dispatch("groups/getAllUserList");
  console.log(sessionStorageUtil.getItem("countryList"), 'sessionStorageUtil.getItem("countryList")')
  // 检查 sessionStorage 中是否存在 countryList
  await store.dispatch('customer/getAreaNames');
  if (sessionStorageUtil.getItem("customerLevel") === null) {
    await store.dispatch("cusList/getMailCustomerLevel")
  }
  if (tableDataSelect.value.length >= 1) {
    searchForm.value.managerId = adminShow ? tableDataSelect.value.find(_ => _.userId === userInfo.value.id).userId : "";
  }
  if (searchForm.value.managerId === "all") {
    searchForm.value.managerId = ""
  }
  if(route.path==='/email/customer/public'){
    await store.dispatch("cusList/getCustomerSelectDataFunction", {teamId: teamId.value, type: 2,managerId:searchForm.value.managerId})
  }else{
    await store.dispatch("cusList/getCustomerSelectDataFunction", {teamId: teamId.value, type: 1,managerId:searchForm.value.managerId})
  }


  emits("searchKey", searchForm.value);
});

// 表单dom
const ruleFormRef = ref(null);
// 表单重制
const resetForms = throttle((formEl) => {
  ruleFormRef.value.resetFields();
  if (tableDataSelect.value.length >= 1) {
    searchForm.value.managerId = adminShow ? tableDataSelect.value.find(_ => _.userId === userInfo.value.id).userId : "";
  }
  if (searchForm.value.managerId === "all") {
    searchForm.value.managerId = ""
  }
  emits("searchKey", searchForm.value);
}, 3000);
// 搜索提交
const onSubmit = throttle((formEl) => {
  emits("searchKey", searchForm.value);
}, 3000);


const checkAll = ref(false)
const indeterminate = ref(false)
watch(() => searchForm.value.valueTarget, (val) => {
  if (val.length === 0) {
    checkAll.value = false
    indeterminate.value = false
  } else if (val.length === tableData.value.length) {
    checkAll.value = true
    indeterminate.value = false
  } else {
    indeterminate.value = true
  }
})

const handleCheckAll = (val) => {
  indeterminate.value = false
  if (val) {
    searchForm.value.valueTarget = tableData.value.map((_) => _.id)
  } else {
    searchForm.value.valueTarget = []
  }
}
</script>

<style lang="less" scoped>
:deep(.el-form-item) {
  float: left;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, .85) !important;
  margin-bottom: 0px !important;
  margin-top: 10px;
}

.left_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

}

.right_box {
  min-width: 200px;

  & > :deep(.el-form-item) {
    margin-right: 0px !important;
    display: flex;
    justify-content: right;
  }
}

#forms {
  display: flex;
  justify-content: space-between;
}


:deep(.el-form-item__label) {
  color: rgba(0, 0, 0, .85) !important;
}

:deep(.el-form-item:last-child) {
  float: right;
}

:deep(.el-form-item__content) {
  margin: 0px !important;
}


:deep(.el-tabs__header) {
  margin-bottom: 0px !important;
}

:deep(.el-select) {
  width: 200px !important;
}

:deep(.el-form-item__label) {
  font-weight: 700;
}
</style>
