<template>
  <div class="container">
    <div class="side_menu" v-if="!isTarget">
      <ElMenu
          :default-active="targetRouter"
          class="el-menu-vertical-demo"
          :router="true"
          :default-openeds="[`/cloud/myf`, `/cloud`]"
      >
        <ElMenuItem :index="`/cloud/myf`" router>
          <el-icon v-if="activeRoute === '/cloud/myf'">
            <FolderOpened/>
          </el-icon>
          <el-icon v-else>
            <Folder/>
          </el-icon>
          <span>{{ $t('common.file.my') }}</span>
        </ElMenuItem>
        <ElMenuItem :index="`/cloud/shareme`" router>
          <el-icon v-if="activeRoute === '/cloud/shareme'">
            <Share/>
          </el-icon>
          <el-icon v-else>
            <Share/>
          </el-icon>
          <span>{{ $t("common.file.joint") }}</span>
        </ElMenuItem>
        <ElMenuItem :index="`/cloud/toshared`">
          <el-icon v-if="activeRoute === '/cloud/toshared'">
            <Reading/>
          </el-icon>
          <el-icon v-else>
            <Collection/>
          </el-icon>
          <span>{{ $t("common.myjoint") }}</span>
        </ElMenuItem>
        <ElMenuItem :index="`/cloud/tos`">
          <el-icon v-if="activeRoute === '/cloud/tos'">
            <Promotion/>
          </el-icon>
          <el-icon v-else>
            <Promotion/>
          </el-icon>
          <span>{{ $t('common.myshare') }}</span>
        </ElMenuItem>
        <ElMenuItem :index="`/cloud/collect`">
          <el-icon v-if="activeRoute === '/cloud/collect'">
            <StarFilled/>
          </el-icon>
          <el-icon v-else>
            <Star/>
          </el-icon>
          <span>{{ $t('common.cds14') }}</span>
          <span>
          </span
          >
        </ElMenuItem>
        <ElMenuItem :index="`/cloud/recycle`">
          <el-icon v-if="activeRoute === '/cloud/recycle'">
            <DeleteFilled/>
          </el-icon>
          <el-icon v-else>
            <Delete/>
          </el-icon>
          <span>{{ $t('common.recycle') }}</span>
        </ElMenuItem>
      </ElMenu>
      <div style="margin-bottom: 40px">
        <div style="color: rgba(0, 0, 0, 0.45);font-size: 14px;text-align: right;margin-right: 15px;margin-bottom: 6px">
          {{ spaceSizeFormat(spaceSize.fileCloudLocation) }}/{{ spaceSizeFormat(spaceSize.effectiveLocation) }}
        </div>
        <el-progress style="padding: 0px 15px" :percentage="percentage" :format="format"/>
      </div>
    </div>
    <div v-bind:class="!isTarget ? 'side_inner' : 'side_inner_sp'">
      <router-view></router-view>
    </div>
  </div>
  <!--  <Development></Development>-->
</template>
<script setup>
import {ElMenu, ElMenuItem, ElMenuItemGroup, ElMessage, ElNotification, ElSubMenu} from "element-plus";
import {
  Document,
  Edit,
  Menu as IconMenu,
  Location,
  Message,
  Setting,
  Stamp,
  Memo, FolderOpened, Folder, Share, Reading, Collection, Promotion, StarFilled, Star, DeleteFilled, Delete,
} from "@element-plus/icons-vue";
import {useRoute, useRouter} from "vue-router";
import {onMounted, ref, reactive, computed, watch, onBeforeMount} from "vue";
import {getMailByDay, getDefaultMailConfig} from "../../api";
import Development from '@/views/Development'
import {useStore} from "vuex";
import Dialog from "@/components/Dialog.vue";
import {spaceSizeFormat} from "@/utils/file";

const store = useStore();
const router = useRouter();
const route = useRoute();

const format = (percentage) => (percentage === 100 ? 'Full' : `${percentage}%`)


const isChangeRouter = computed(() => store.state.cloud.isChangeRouter);
const activeRoute = computed(() => {
  console.log(route.path, 'route.path')
  store.commit("cloud/changeCurrent", false)
  // 第一次特殊处理 myf
  // if (!isChangeRouter.value && route.path.indexOf("/cloud/myf") !== -1) {
  //   store.commit("cloud/changeIsFirstPageLoaded", true)
  //   store.commit("cloud/isChangeRouterPayload", true)
  // } else {
  //   store.commit("cloud/changeIsFirstPageLoaded", false)
  // }
  store.commit("cloud/changeIsFirstPageLoaded", false)
  return route.path;
});


// 判断downlod 下的允许的路径
const arrayRouter = ['/cloud/download', '/cloud/info']
const isTarget = computed(() => {
  console.log(arrayRouter.includes(activeRoute.value), activeRoute.value, 'fff')
  return activeRoute.value.indexOf(arrayRouter[0]) !== -1 || activeRoute.value.indexOf(arrayRouter[1]) !== -1 ? true : false
})

const targetRouter = ref(route.path)
watch(() => route.path, (newValue) => {
  targetRouter.value = newValue;
})


/**
 * 文件存储的空间大小
 */
const spaceSize = computed(() => store.state.cloud.spaceSize)
const percentage = computed(() => {
  const ratio = spaceSize.fileCloudLocation / spaceSize.effectiveLocation;
  const percentageValue = Math.round(ratio * 100).toFixed(2);
  return percentageValue;
});



</script>
<style lang="less" scoped>
.side_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.side_menu :deep(.el-menu-item:nth-of-type(1)) {
  height: 40px !important;
  margin-bottom: 8px !important;
  margin-top: 10px !important;
}

.side_menu :deep(.el-menu-item) {
  height: 40px !important;
  margin-bottom: 8px !important;
}

.side_menu :deep(.el-menu-item.is-active) {
  background-color: #e6f7ff !important;
  border-right: 3px solid #1890ff !important;
}

.out_box_scroll {
  position: relative;
  height: calc(100vh - 180px);
  overflow: hidden auto;
}

.innerboxscroll {
  position: absolute;
  overflow-x: hidden;
  // overflow-y: scroll;
}

:deep(.el-menu-item:hover) {
  color: #1890ff;
  background-color: #e6f7ff !important;
}

.in {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

:deep(.el-menu-item) {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px !important;
}

:deep(.el-menu) {
  border-right: 0px solid transparent !important;
}

:deep(.el-badge) {
  vertical-align: middle;
  line-height: initial;
}

:deep(.el-scrollbar__bar.is-vertical) {
  right: 0px !important;
}

.SideTab {
  position: relative;
  z-index: 20;
  width: 86px;
  min-width: 86px !important;
  //height: calc(100vh - 64px);
  background: #f0f0f0;
  padding: 20px 0 20px 10px;

  .opt {
    width: 100%;
    height: 32px;
    background: #ffffff;
    border-radius: 100px 0px 0px 100px;
    font-weight: 600;
    color: #444444;
    font-size: 14px;
    opacity: 0.4;
    margin-bottom: 20px;
    white-space: nowrap;

    &.s {
      opacity: 1;
      color: #fff;
    }
  }
}

.inners_warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.setting_center {
  display: flex;
  justify-content: center;
}

.setting_left {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.cont {
  display: flex;

  .text {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}

.tab_name {
  padding-left: 4px;
  width: 72px;
  text-overflow: ellipsis;
  overflow: hidden;
}


.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 64px);

  & > .side_menu {
    width: 140px;
    min-width: 140px;

    & > .center_titles {
      padding: 20px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  & > .side_inner {
    width: calc(100vw - 140px);
    background: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    overflow-x: scroll;
  }

  & > .side_inner_sp {
    width: 100%;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    overflow-x: scroll;
  }
}

:deep(.el-progress__text) {
  display: none;
}
</style>
