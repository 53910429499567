import tz1 from "@/assets/tz (1).png";
import tz2 from "@/assets/tz (2).png";
import tz3 from "@/assets/tz (3).png";
import tz4 from "@/assets/tz (4).png";
import tz5 from "@/assets/tz (5).png";
import tz6 from "@/assets/tz (6).png";
import {i18n} from "@/lang";

export const conMessage = (messageArray) => {
    const newTargetMessageArray = []
    // 团队通知
    const teamNotify = i18n.global.t("common.cds33");
    // 账号通知
    const accountNotify = i18n.global.t("common.cds50")
    // 好友邀请
    const friendNotify = i18n.global.t('common.cds32')
    // 公告咨讯
    const announcementNotify = i18n.global.t('common.cds39')

    for (const messageArrayElement of messageArray) {

        const {teamName, userName, customerName} = extractData(messageArrayElement['jsonData'])
        switch (messageArrayElement.messageType * 1) {
            case 1:
                newTargetMessageArray.push({
                    icon: tz3, titles: teamNotify, innerContent: i18n.global.t("displace_server_team_invite", {
                        username: userName, teamname: teamName
                    }), ...messageArrayElement,
                })
                break;
            case 2:
                newTargetMessageArray.push({
                    icon: tz3, titles: teamNotify, innerContent: i18n.global.t("displace_server_team_agree", {
                        username: userName, teamname: teamName
                    }), ...messageArrayElement,
                })
                break;
            case 3:
                newTargetMessageArray.push({
                    icon: tz3, titles: teamNotify, innerContent: i18n.global.t("displace_server_team_refuse", {
                        username: userName, teamname: teamName
                    }), ...messageArrayElement
                })
                break;
            case 4:
                newTargetMessageArray.push({
                    icon: tz3, titles: teamNotify, innerContent: i18n.global.t("displace_server_team_quit", {
                        username: userName, teamname: teamName
                    }), ...messageArrayElement
                })
                break;
            case 5:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("displace_server_team_remove", {teamname: teamName}), ...messageArrayElement
                })
                break;
            case 6:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("displace_server_team_disband", {teamname: teamName}), ...messageArrayElement
                })
                break;
            case 7:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("common_customer_request_todo_audit", {
                        username: userName,
                        teamname: teamName,
                        name: customerName,
                    }), ...messageArrayElement
                })
                break;
            case 8:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("common_customer_request_success_audit", {
                        username: userName,
                        teamname: teamName,
                        name: customerName,
                    }), ...messageArrayElement
                })
                break;
            case 9:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("common_customer_request_faild_audit", {
                        username: userName,
                        teamname: teamName,
                        name: customerName,
                    }), ...messageArrayElement
                })
                break;
            case 10:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("common_mail_todo_audit", {
                        username: userName, teamname: teamName,
                    }), ...messageArrayElement
                })
                break;
            case 11:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("common_mail_success_audit_1", {
                        username: userName, teamname: teamName,
                    }), ...messageArrayElement
                })
                break;
            case 12:
                newTargetMessageArray.push({
                    icon: tz3,
                    titles: teamNotify,
                    innerContent: i18n.global.t("common_mail_faild_audit_1", {
                        username: userName, teamname: teamName,
                    }), ...messageArrayElement
                })
                break;


        }
    }

    return newTargetMessageArray;
}


function extractData(jsonData) {
    try {
        const data = JSON.parse(jsonData);
        const {teamName, userName, customerName} = data;
        return {teamName, userName, customerName};
    } catch (error) {
        // console.error("Error parsing JSON data:", error);
        return {teamName: "", userName: "", customerName: ""};
    }
}