import request from "@/utils/request";

//分页查询企业用户
export function queryPageList(params, config) {
    return request.get("/mailMassTask/queryPageList", params, config);
}

// 根据编号查询指定的群发任务
export function queryByTaskCode(params, config) {
    return request.get("/mailMassTask/queryByTaskCode", params, config)
}

// 改变群发任务状态
export function updateMailMassStatus(params, config) {
    return request.post('/front/mail/updateMailMassStatus', params, config)
}

// 重新重发群发邮件
export function repeatSendMassMail(params, config) {
    return request.post('/front/mail/repeatSendMassMail', params, config);
}

// 邮件批量发送 获取 全选的客户
export function getRecipientList(params, config) {
    return request.post(`/front/mail/recipientList`, params, config)
}

// 群发任务统计
export function statisticsMassTaskByTaskCode(params, config) {
    return request.get('/mailMassTask/statisticsMassTaskByTaskCode', params, config)
}

// 群发邮件详情
export function getMassTaskDetailByTaskCode(params, config) {
    return request.get("/front/mail/getMassTaskDetailByTaskCode", params, config)
}


//批量删除任务
export function batchDeleteMassTask(params, config) {
    return request.post("/mailMassTask/batchDeleteMassTask", params, config)
}