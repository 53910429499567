<template>
  <div class="container">
    <iframe :src="pdfUrl" width="100%" height="100%"></iframe>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {useRoute} from "vue-router";

const route = useRoute()
const pdfUrl = computed(() => route.query.url)
</script>

<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
}
</style>

