<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.order.detail') }}</div>
      <div class="text_box">
        <div class="top_title">{{ $t('common.base.info') }}</div>
        <div><span class="left_title">{{ $t('common.order.number') }}</span><span class="inner">{{
            selectObj.orderCode
          }}</span></div>
        <div><span class="left_title">{{ $t('common.amount.total') }}</span><span class="inner">{{
            selectObj.totalMoney
          }}</span></div>
        <!--        <div><span class="left_title">Plus会员优惠</span><span class="inner"></span></div>-->
        <!--        <div><span class="left_title">会员活动优惠</span><span class="inner"></span></div>-->
        <div><span class="left_title">{{ $t('common.amount.realpay') }}</span><span
            class="inner">¥{{ selectObj.totalMoney }}</span></div>
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%" :row-style="{height: '70px'}" v-loading="loading"
        >
          <el-table-column prop="id" :label="$t('common.cds83')" width="180"/>
          <el-table-column prop="productName" :label="$t('common.product.unit')" width="180"/>
          <el-table-column prop="num" :label="$t('common.amount')"/>
          <el-table-column prop="price" :label="$t('common.unitprice')">
          </el-table-column>
          <el-table-column prop="totalMoney" :label="$t('common.amount.total')">
          </el-table-column>
          <el-table-column prop="totalMoney" :label="$t('common.amount.realpay')">
          </el-table-column>
        </el-table>
        <div class="bottom_pay">{{ $t('common.amount.realpay.total') }}：<span class="reds">
          ¥ {{
            selectObj.totalMoney
          }}</span></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {useRouter} from "vue-router";
import {getQueryById} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const router = useRouter();
const loading = ref(false)

const orderCode = ref()
const tableData = ref([])
const selectObj = ref({})
// 通过 id 查询数据
const getQueryByIdApis = async () => {
  loading.value = true
  const res = await getQueryById(
      {orderCode: orderCode.value}
  )
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  loading.value = false
  selectObj.value = res.result.mailPayOrder;
  tableData.value = res.result.mailPayOrderTmpList
  console.log(selectObj, tableData, 'ff')
}
onMounted(async () => {
  const {payCode} = router.currentRoute.value.query
  orderCode.value = payCode
  await getQueryByIdApis()
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";

.right {
  height: max-content !important;
  padding-bottom: 40px !important;
}

.text_box {
  & > div {
    margin-bottom: 16px;
  }
}

.left_title {
  color: #999;
  margin-right: 20px;
  font-size: 14px;
}

.bottom_pay {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-top: 20px;
  font-size: 14px;

  .reds {
    color: #c8453f;
    font-size: 14px;
    font-weight: bold;
  }
}

.inner {
  font-size: 14px;
  font-weight: bold;
}


</style>