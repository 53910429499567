<template>
  <div class="container">
    <div class="outbox">
      <h2>{{ $t("common.customer.details") }}</h2>
      <!--      {{ outputArray(removeListArray.emails, "email") }}-->
      <!--      {{ outputArray(removeListArray.phones, "phoneNumber") }}-->
      <!--      {{ ruleFormUsers }}-->
      <!--      {{ contactMessage }}-->

      <ElButton size="default" type="danger" @click="handleClickDelete"
                style="position: absolute;right: 138px;top:55px;z-index: 9"
                :icon="Delete"
                v-if="isSuperAdmin"
      >{{ $t('common.delete') }}
      </ElButton
      >
      <ElButton size="default" type="primary" @click="toWritePage"
                style="position: absolute;right: 33px;top:55px;z-index: 9"
                :icon="Edit"
      >{{ $t('common_personal_register') }}
      </ElButton
      >
    </div>
    <div class="content">
      <div id="inner_box">
        <el-tabs
            v-model="activeName"
            class="demo-tabs"
            :key="activeName"
            @tab-click="handleClick"
        >
          <div class="inner">
            <el-tab-pane lazy :label="$t('common.customer.archives')" name="1">
              <template v-if="types === '1'">
                <el-scrollbar height="calc(100vh - 165px)">
                  <div class="box_content">
                    <div class="inners">
                      <Forms :isCreate="false">
                        <template #createMessage>
                          <el-row :gutter="24">
                            <el-col :span="6">
                              <el-form-item
                                  :label="$t('common.region.name')"
                                  prop="zoneAddress"
                              >
                                <el-select
                                    v-model="ruleForm.zoneAddress"
                                    :placeholder="$t('common.please.select')"
                                    disabled
                                >
                                </el-select>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="$t('common.customer.number')">
                                <el-input
                                    v-model="ruleForm.customerCode"
                                    :placeholder="$t('common.please.input')"
                                    disabled
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item
                                  :label="$t('common_customer_name')"
                                  prop="customerName"
                              >
                                <el-input
                                    v-model="ruleForm.customerName"
                                    :placeholder="$t('common.please.input')"
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item
                                  :label="$t('common.customer.source')"
                                  prop="customerOrigin"
                              >
                                <el-input
                                    v-model="ruleForm.customerOrigin"
                                    :placeholder="$t('common.please.input')"
                                />
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="6">
                              <el-form-item :label="$t('common_byinput')">
                                <el-input
                                    v-model="ruleForm.createName"
                                    :placeholder="$t('common.please.input')"
                                    disabled
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="$t('common.bybranch')">
                                <el-input
                                    v-model="ruleForm.mangerVOList[0].managerName"
                                    :placeholder="$t('common.please.input')"
                                    disabled
                                />
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </template>
                      </Forms>
                      <div class="link_user_box">
                        <Cards
                            :title="$t('common.contact.list')"
                            :isShowTitle="true"
                        >
                          <el-row :gutter="24">
                            <el-col :span="6">
                              <el-button
                                  type="primary"
                                  @click="dialogVisible = true"
                              >{{ $t("common.contact.add") }}
                              </el-button>
                            </el-col>
                          </el-row>
                          <ContactTable
                              @clickEdit="onClickEdit"
                              @clickHistory="clickHistoryChange"
                          ></ContactTable>
                        </Cards>
                      </div>
                      <!-- dialog -->
                      <el-dialog
                          v-model="dialogVisible"
                          width="1000"
                          style="min-width: 600px"
                          :append-to-body="true"
                          @close="cancelClick"
                          top="4vh"
                      >
                        <div class="tops">
                          <div class="span_block"></div>
                          <div class="inner_text">{{ $t("contact.info") }}</div>
                        </div>
                        <el-form
                            ref="ruleFormRef"
                            :model="ruleFormUsers"
                            label-width="119px"
                            class="demo-ruleForm"
                            :size="formSize"
                        >
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <el-form-item
                                  :label="$t('common.name')"
                                  prop="name"
                                  :rules=" [{required: true, message: $t('form.contact.name.reg'), trigger: ['change','blur']}]"
                              >
                                <el-input
                                    v-model="ruleFormUsers.name"
                                    :placeholder="$t('90201')"
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item :label="$t('common.sex')" prop="sex">
                                <el-radio-group
                                    v-model="ruleFormUsers.sex"
                                    class="ml-4"
                                >
                                  <el-radio :label="1" :value="1">{{
                                      $t("common.man")
                                    }}
                                  </el-radio>
                                  <el-radio :label="2" :value="2">{{
                                      $t("common.woman")
                                    }}
                                  </el-radio>
                                </el-radio-group>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <el-form-item
                                  :label="$t('common.company.position')"
                                  prop="post"
                              >
                                <el-input
                                    v-model="ruleFormUsers.post"
                                    :placeholder="$t('sys.tip.input.position')"
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item
                                  :label="$t('common.phone.number')"
                                  prop="phone"
                              >
                                <el-input
                                    v-model="ruleFormUsers.phone"
                                    :placeholder="$t('sys.tip.input.phone')"
                                />
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <el-form-item
                                  :label="$t('common.contact.phone')"
                                  prop="telPhone"
                              >
                                <el-input
                                    v-model="ruleFormUsers.telPhone"
                                    :placeholder="$t('sys.tip.input.contact.phone')"
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item
                                  :label="$t('common.dateofbirth')"
                                  prop="birthday"
                              >
                                <el-date-picker
                                    v-model="ruleFormUsers.birthday"
                                    type="date"
                                    :placeholder="$t('sys.tip.select.date')"
                                    :size="size"
                                    :aria-placeholder="$t('sys.tip.select.date')"
                                />
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="24">
                              <el-form-item
                                  :label="$t('common.remark')"
                                  prop="remark"
                              >
                                <el-input
                                    v-model="ruleFormUsers.remark"
                                    type="textarea"
                                    :placeholder="$t('sys.tip.input.remark')"
                                />
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="24">
                              <MyTitles :title="$t('common.emailbox')">
                                <template #content>
                                  <div class="bottoms">
                                    <el-form-item
                                        class="sp_form_item"
                                        v-for="(
                                      item, index
                                    ) in ruleFormUsers.mailContactEmailList"
                                        style="margin-top: 20px;padding-bottom: 0px"
                                        :key="index"
                                        :label="index === 0 ? $t('common.emailbox') : ''"
                                        :prop="
                                      'mailContactEmailList[' +
                                      index +
                                      '].email'
                                    "
                                        :rules="[
                                      {
                                        pattern:
                                          emailReg,
                                        message:$t('form.email.reg') ,
                                        trigger: ['change','blur'],
                                      },
                                    ]"
                                    >
                                      <div class="email-input">
                                        <el-input
                                            v-model="item.email"
                                            :placeholder="$t('20202')"
                                        />
                                        <el-icon
                                            v-if="
                                          ruleFormUsers.mailContactEmailList
                                            .length !== 1
                                        "
                                            style="
                                          font-size: 20px;
                                          color: #d93737;
                                          margin-left: 20px;
                                          cursor: pointer;
                                        "
                                            @click="removeEmail(index,item)"
                                        >
                                          <RemoveFilled/>
                                        </el-icon>
                                        <div v-else style="width: 44px"></div>
                                      </div>
                                    </el-form-item>
                                    <el-form-item>
                                      <el-button
                                          @click="addEmail"
                                          id="spButton"
                                          :icon="Plus"
                                      >
                                        {{ $t("common.add") }}
                                      </el-button>
                                    </el-form-item>
                                  </div>
                                </template>
                              </MyTitles>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col :span="24">
                              <MyTitles :title="$t('common.memorialday')">
                                <template #content>
                                  <div class="remembers">
                                    <!-- <RememberDate></RememberDate> -->
                                    <div>
                                      <div
                                          v-for="(
                                        event, index
                                      ) in ruleFormUsers.mailContactSouvenirList"
                                          :key="index"
                                          class="event-input"
                                      >
                                        <template v-if="!event.editing">
                                          <div class="out_box">
                                            <div
                                                class="event-text"
                                                @click="editEvent(index)"
                                            >
                                              {{ event.remark }}
                                            </div>
                                            <div class="event-text"></div>
                                          </div>
                                        </template>
                                        <el-input
                                            v-if="event.editing"
                                            v-model="event.remark"
                                            :placeholder="$t('common.contact.info.anniversary.name')"
                                            @focus="showButtons(index)"
                                            @blur="hideButtons(index)"
                                            ref="inputRef"
                                        ></el-input>
                                        <div style="margin: 0 10px"></div>
                                        <el-date-picker
                                            v-model="event.souvenirDay"
                                            type="date"
                                            class="event-text"
                                            :placeholder="$t('common.please.select')"
                                        />
                                        <div class="setting">
                                          <el-button
                                              v-if="!event.showButtons"
                                              @click="updateEvent(index)"
                                              icon="el-icon-check"
                                              type="primary"
                                              link
                                          >{{ $t("common.edit") }}
                                          </el-button>
                                          <el-button
                                              v-if="event.showButtons"
                                              @click="removeEvent(index)"
                                              icon="el-icon-delete"
                                              link
                                              type="danger"
                                          >{{ $t("common.delete") }}
                                          </el-button>
                                          <el-button
                                              v-if="!event.showButtons"
                                              @click="removeEvent(index)"
                                              icon="el-icon-delete"
                                              link
                                              type="danger"
                                          >{{ $t("common.delete") }}
                                          </el-button>
                                        </div>
                                      </div>

                                      <el-button @click="addEvent" type="primary"
                                      >
                                        {{ $t('common.memorialday.add') }}
                                      </el-button>
                                    </div>
                                  </div>
                                </template>
                              </MyTitles>
                            </el-col>
                          </el-row>
                        </el-form>
                        <template #footer>
                        <span class="dialog-footer">
                          <el-button
                              style="margin: 0px 6px"
                              @click="cancelClick"
                          >{{ $t("common.cancel") }}</el-button
                          >
                          <el-button
                              style="margin: 0px 6px"
                              type="primary"
                              :loading="loadings"
                              @click="addCustomerContact"
                          >
                            {{ $t("common.save") }}
                          </el-button>
                        </span>
                        </template>
                      </el-dialog>
                    </div>
                  </div>
                </el-scrollbar>
              </template>
              <!--              <template v-else-if="types === '2'">-->
              <template v-else>
                <div class="box_content">
                  <div>
                    <div class="inners">
                      <div>
                        <div class="titles">
                          <h3>{{ $t("common.cds25") }}</h3>
                        </div>
                        <div class="small_box">
                          <TableShows :table-data="companyInfo"/>
                        </div>
                      </div>
                      <div style="margin-top: 40px">
                        <div class="titles">
                          <h3>{{ $t("common.marketing.information") }}</h3>
                        </div>
                        <div class="small_box">
                          <TableShows :table-data="marketingInfo"/>
                        </div>
                      </div>
                      <div style="margin-top: 40px">
                        <div class="titles">
                          <h3>{{ $t("common.contact") }}</h3>
                        </div>
                        <div
                            class="small_box"
                            v-for="(item, index) in contactInfo"
                            :key="index"
                        >
                          <TableShows :table-data="item"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-tab-pane>
            <el-tab-pane lazy :label="$t('email.history')" name="2" class="linkUser">
              <div class="views">
                <CallLog
                    ref="callLog"
                    :key="mainKey"
                    @change="changeTarget"
                    @handleChangeRow="handleChange"
                    :targetCheckoutItem="{ targetCheckoutItem, islink }"
                ></CallLog>
              </div>
            </el-tab-pane>
            <el-tab-pane lazy :label="$t('follow.history')" name="3">
              <FollowLog></FollowLog>
            </el-tab-pane>
            <el-tab-pane lazy :label="$t('common.operation.log')" name="4">
              <div class="box_content">
                <div class="link_user_box">
                  <Cards :isShowTitle="false">
                    <el-table
                        ref="multipleTableRef"
                        :data="tableDataLogs"
                        style="width: 100%; margin-top: 10px"
                        @selection-change="handleSelectionChange"
                        :v-loading="isLoadingLog"
                        :header-cell-style="{
                        'text-align': 'left',
                        background: '#f9f9f9',
                        color: 'black',
                        padding: '16px 0px',
                      }"
                    >
                      <el-table-column
                          v-for="item in table_columns_logs"
                          :key="item.key"
                          :label="item.key"
                          :prop="item.value"
                          :width="item.value==='remark'?auto:300"
                          :show-overflow-tooltip="item.value==='remark'"
                      >
                      </el-table-column>
                      <template #empty>
                        <el-empty
                            :description="$t('common.data.empty')"
                            :image="emptyUrl"
                        />
                      </template>
                    </el-table>
                    <!-- 分页 -->
                    <div class="pages">
                      <el-pagination
                          v-model:current-page="currentLog"
                          v-model:page-size="sizeLog"
                          background
                          layout="total, prev, pager, next"
                          v-if="totalsLog"
                          :total="totalsLog"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                      />
                    </div>
                  </Cards>
                </div>
              </div>
            </el-tab-pane>
          </div>
        </el-tabs>
      </div>
    </div>
  </div>


  <DeleteCustomer v-if="dialogVisiblePopover" v-model="dialogVisiblePopover" @close="closeDialog"
                  :multipleSelection="multipleSelection" :isDetail="true"
  ></DeleteCustomer>
</template>

<script setup>
import {ref, computed, reactive, onMounted, nextTick} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import dayjs from "dayjs";
import {ElMessage, ElTable} from "element-plus";
import TableShows from "./Tables/TableShows.vue";
import Cards from "./Cards.vue";
import Forms from "./CreateCheck/Forms.vue";
import MyTitles from "./Detail/MyTitles.vue";
import ContactTable from "./Detail/ContactTable.vue";
import CallLog from "@/views/components/CallLog.vue";
import {throttle} from "@/utils/mylodash"

import {
  addMailCustomerContact,
  editMailCustomerContact,
  getUserLogList,
} from "@/api/customer";
import {Delete, Edit, Plus, Position} from "@element-plus/icons-vue";
import {emailReg, emptyUrl, TIME_OUT} from "@/utils/statusParams";
import {i18n} from "@/lang";
import FollowLog from "@/views/Customer/Detail/FollowLog.vue";
import {outputArray} from "../../utils/objchange";
import DeleteCustomer from "@/components/DiaPops/DeleteCustomer.vue";

const customerLevels = computed(() => store.state.customer.customerLevels)
const router = useRouter();
const route = useRoute()
const store = useStore();
const ruleForm = computed(() => store.state.customer.ruleForm);
// 客户id
const customerId = ref("");
const addEmail = () => {
  if (
      ruleFormUsers.mailContactEmailList.filter((item) => item.email === "")
          .length >= 1
  ) {
    ElMessage.error(i18n.global.t('detail.add.customer'));
  } else {
    ruleFormUsers.mailContactEmailList.push({
      email: "",
      isDefault: 0,
    });
    ruleFormUsers.mailContactEmailList[0].isDefault = 1;
  }
  // console.log(
  //     ruleFormUsers.mailContactEmailList.filter((item) => item == "").length > 1
  // );
};
const removeContactEmail = ref([])
const removeEmail = (index, item) => {
  removeContactEmail.value = [...removeContactEmail.value, item].filter(item => item.id)
  console.log(index, 'removeEmail', item, removeContactEmail.value)
  ruleFormUsers.mailContactEmailList.splice(index, 1);
  const copObj = JSON.parse(JSON.stringify(ruleFormUsers));
  const {mailContactEmailList} = copObj;
  console.log(mailContactEmailList, 'mailContactEmailList', mailContactEmailList.some(
      (item) => item.isDefault === 1
  )
      ? mailContactEmailList.filter(_ => _.email !== "")
      : mailContactEmailList.map((item, index) => {
        if (index === 0) {
          return {...item, isDefault: 1};
        } else {
          return item;
        }
      }).filter(_ => _.email !== ""))
  ruleFormUsers.mailContactEmailList = mailContactEmailList.some(
      (item) => item.isDefault === 1
  )
      ? mailContactEmailList.filter(_ => _.email !== "")
      : mailContactEmailList.map((item, index) => {
        if (index === 0) {
          if (item.id) {
            return item
          } else {
            return {...item, isDefault: 1};
          }
        } else {
          return item;
        }
      }).filter(_ => _.email !== "");
};

// 表单数据
const ruleFormUsers = reactive({
  name: "",
  sex: 1,
  post: "",
  phone: "",
  telPhone: "",
  birthday: null,
  remark: "",
  mailContactEmailList: [
    {
      email: "",
      isDefault: 0,
    },
  ],
  mailContactSouvenirList: [
    // {
    //   remark: "",
    //   souvenirDay: "",
    //   editing: true,
    //   showButtons: true,
    // },
  ],
});

const mainKey = ref(1);
const contactList = computed(() => store.state.customer.contactList);
// 权限控制
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isAdmin = computed(() => store.getters["user/isAdmin"])
// 公司信息
const companyInfo = ref([]);
// 营销信息
const marketingInfo = ref([]);
// 联系人信息
const contactInfo = ref([]);
// 页面加载直接进行展示
const showActiveItem = async () => {
  if (ruleForm.value) {
    // console.log(customerId, "sdf");
    await store.dispatch("customer/getQueryById", {
      customerId: customerId.value,
    });
    const {
      customerName,
      phoneWayList,
      setUpTime,
      economicNature,
      companyWebsite,
      companyAddress,
      companyFax,
      emailWayList,
      companyRemark,
      // ----
      customerType,
      customerLevel,
      product,
      marketingRemark,
    } = ruleForm.value;
    console.log(ruleForm.value, "ffffff");
    companyInfo.value = [
      {name: i18n.global.t("common_customer_name"), value: customerName},
      {
        name: i18n.global.t("common.contact.phone"),
        value:
            phoneWayList.length !== 0
                ? phoneWayList.filter((item) => item.isDefault === 1)[0].content
                : [],
      },
      {name: i18n.global.t("common.establishment"), value: setUpTime},
      {name: i18n.global.t("common.economicnature"), value: economicNature},
      {name: i18n.global.t("common.company.website"), value: companyWebsite},
      {name: i18n.global.t("common.cds2"), value: companyAddress},
      {name: i18n.global.t("common.faxnumber"), value: companyFax},
      {
        name: i18n.global.t("common.cds6"),
        value:
            emailWayList.length !== 0
                ? emailWayList.filter((item) => item.isDefault === 1)[0].content
                : [],
      },
      {name: i18n.global.t("common.cds3"), value: companyRemark},
    ];
    console.log(companyInfo.value, "$$$$");
    marketingInfo.value = [
      {name: i18n.global.t("common.customer.typeList"), value: customerType},
      {name: i18n.global.t("common_customer_level"), value: customerLevel},
      {name: i18n.global.t("common.interest.product"), value: product},
      {name: i18n.global.t("common.cds3"), value: marketingRemark},
    ];
    // 联系人详情
    await store.commit("customer/setContactPage", {
      page: 1,
      pageSize: 999,
    });
    await store.dispatch("customer/getContactList");
    // 清除数据干扰
    contactInfo.value = [];
    // 联系人详情
    contactInfo.value = contactList.value.map((item) => [
      {name: i18n.global.t("common.name"), value: item.name},
      {name: i18n.global.t("common.edm"), value: item.email},
    ]);
  }
};

// 添加联系人表单
const loadings = ref(false)
const ruleFormRef = ref(null);
const addCustomerContact = throttle(() => {
  ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const copObj = JSON.parse(JSON.stringify(ruleFormUsers));
      const {mailContactSouvenirList, mailContactEmailList} = copObj;
      let resMails = mailContactSouvenirList.map((item) => {
        return {
          ...item,
          souvenirDay: item.souvenirDay && dayjs(item.souvenirDay).isValid()
              ? dayjs(item.souvenirDay).format("YYYY-MM-DD")
              : null,
        };
      });

      //默认邮箱
      const selectDefault = mailContactEmailList.some(
          (item) => item.isDefault === 1
      )
          ? mailContactEmailList.filter(_ => _.email !== "")
          : mailContactEmailList.map((item, index) => {
            if (index === 0) {
              if (item.id) {
                return {...item, isDefault: 1}
              } else {
                return {isDefault: 1, email: item.email};
              }
            } else {
              return item;
            }
          }).filter(_ => _.email !== "");
      delete copObj.birthday;
      delete copObj.mailContactSouvenirList;
      delete copObj.mailContactEmailList;
      const targetObj = {
        ...copObj,
        birthday:
            (ruleFormUsers.birthday && dayjs(ruleFormUsers.birthday).isValid())
                ? dayjs(ruleFormUsers.birthday).format("YYYY-MM-DD")
                : null,
        mailContactSouvenirList: resMails,
        mailContactEmailList: selectDefault,
        customerId: customerId.value,
        teamId: teamId.value
      };
      if (!isEdit.value) {
        // console.log(targetObj, "添加");
        // 添加联系人邮箱
        loadings.value = true
        try {
          let res = await addMailCustomerContact(targetObj);
          loadings.value = false;
          ElMessage.success(res.message);
          dialogVisible.value = false;
          store.dispatch("customer/getContactList");
          isEdit.value = false;
          ruleFormRef.value.resetFields();
        } catch (e) {
          loadings.value = false;
        }
      } else {
        // console.log(targetObj, "编辑");
        try {
          loadings.value = true
          console.log(ruleFormUsers.mailContactEmailList.filter(item => item.id && item.email !== ""), contactMessage.value, 'ruleFormUsers.mailContactEmailList')

          let targetDefaultIdArray = null;
          let theLastTarget = [];
          if (ruleFormUsers.mailContactEmailList.filter(item => item.id && item.email === "").length > 0) {
            targetDefaultIdArray = ruleFormUsers.mailContactEmailList.filter(item => item.id && item.email === "").map((_) => _.id)
            theLastTarget = JSON.parse(localStorage.getItem("ContactMessage")).mailContactEmailList.filter(item => (targetDefaultIdArray.includes(item.id)));
            console.log(targetDefaultIdArray, theLastTarget, contactMessageTemp.value, 'targetDefaultIdArray')
          }
          let res = await editMailCustomerContact({
            ...targetObj,
            id: contactId.value,
            mailContactDeleteEmailList: [...removeContactEmail.value, ...theLastTarget]
          });
          loadings.value = false
          ElMessage.success(res.message);
          dialogVisible.value = false;
          store.dispatch("customer/getContactList");
          isEdit.value = false;
          removeContactEmail.value = []
          localStorage.setItem("ContactMessage", JSON.stringify([]))
          ruleFormRef.value.resetFields();
        } catch (e) {
          loadings.value = false;
        }
      }
    } else {
      // console.log("error submit!", fields);
    }
  });
}, 500);

// 添加联系人邮箱
const handleClick = async (tab, event) => {
  // console.log(tab.props.name, event);
  if (tab.props.name === "4") {
    // 操作日志
    await getTargetUserLogs();
  } else if (tab.props.name === "1") {
    await showActiveItem();
  }
};

const table_columns_logs = [
  {key: i18n.global.t('common.bybranch'), value: "userName"},
  {
    key: i18n.global.t('common.operate.time'),
    value: "operateTime",
  },
  {key: i18n.global.t('common.remarkNote'), value: "remark"},
];
const tableDataLogs = ref([]);
const isLoadingLog = ref(false);
// 分页
const currentLog = ref(1);
const sizeLog = ref(10);
const totalsLog = ref(0);

function handleCurrentChange(page) {
  currentLog.value = page;
  getTargetUserLogs();
}

// 获取相关人的日志信息
const getTargetUserLogs = async () => {
  const customerId = window.localStorage.getItem("customerId");
  isLoadingLog.value = true;
  let res = await getUserLogList({
    customerId: customerId,
    pageNo: currentLog.value,
    pageSize: sizeLog.value,
  });
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  isLoadingLog.value = false;
  const {records, current, size, total} = res.result;
  tableDataLogs.value = records;
  currentLog.value = current;
  sizeLog.value = size;
  totalsLog.value = total;
};

const dialogVisible = ref(false);
// 路由选择
const activeName = ref("1");
// 选择的item
const targetCheckoutItem = ref(i18n.global.t('common_all'));
const islink = ref(false);
const clickHistoryChange = (val) => {
  if (val) {
    activeName.value = "2";
    targetCheckoutItem.value = val.item.email;
    islink.value = true;
    // console.log(val.item.email, "ff");
  }
};
const changeTarget = (val) => {
  islink.value = val.islink;
};
// 添加纪念日
const dates = ref([{value: new Date()}]);
const addEvent = () => {
  if (ruleFormUsers.mailContactSouvenirList.filter(item => item.souvenirDay === "").length >= 1) {
    ElMessage.warning(i18n.global.t('detail.tips.text'))
    return;
  } else {
    ruleFormUsers.mailContactSouvenirList.push({
      remark: "",
      souvenirDay: "",
      editing: true,
      showButtons: true,
    });
    dates.value.push({value: new Date()});
  }
};

// 编辑
const isEdit = ref(false);
// 记录联系人行id
const contactId = ref("");
const contactMessage = computed(() => store.state.customer.contactMessage);
const contactMessageTemp = ref([])
const onClickEdit = async ({row, column, $index}) => {
  isEdit.value = true;
  const {id} = row;
  // console.log(id, "fff");
  contactId.value = id;
  const customerId = window.localStorage.getItem("customerId");
  await store.dispatch("customer/getContactDetail", {
    contactId: id,
    customerId,
  });
  dialogVisible.value = true;
  // console.log(contactMessage.value, "contactMessage");
  contactMessageTemp.value = contactMessage.value;
  const {
    name,
    post,
    phone,
    telPhone,
    birthday,
    remark,
    sex,
    mailContactEmailList,
    mailContactSouvenirList,
  } = contactMessage.value;
  ruleFormUsers.name = name;
  ruleFormUsers.post = post;
  ruleFormUsers.phone = phone;
  ruleFormUsers.telPhone = telPhone;
  ruleFormUsers.birthday = birthday;
  ruleFormUsers.sex = sex;
  ruleFormUsers.remark = remark;
  ruleFormUsers.mailContactEmailList = mailContactEmailList.length === 0 ? [{
    email: "",
    isDefault: 0,
  }] : mailContactEmailList;
  ruleFormUsers.mailContactSouvenirList = mailContactSouvenirList;
  // Object.assign(ruleFormUsers, contactMessage.value)
};

const cancelClick = () => {
  isEdit.value = false;
  dialogVisible.value = false;
  ruleFormUsers.name = "";
  ruleFormUsers.sex = 1;
  ruleFormUsers.post = "";
  ruleFormUsers.phone = "";
  ruleFormUsers.telPhone = "";
  ruleFormUsers.birthday = null;
  ruleFormUsers.remark = "";
  ruleFormUsers.mailContactEmailList = [
    {
      email: "",
      isDefault: 0,
    },
  ];
  ruleFormUsers.mailContactSouvenirList = [];
};

const editEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].editing = false;
  nextTick(() => {
    inputRefs[index].focus();
  });
};

const updateEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].editing = true;
  ruleFormUsers.mailContactSouvenirList[index].showButtons = true;
};

const removeEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList.splice(index, 1);
};

const showButtons = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].showButtons = true;
};

const hideButtons = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].showButtons = false;
  ruleFormUsers.mailContactSouvenirList[index].editing = false;

  if (ruleFormUsers.mailContactSouvenirList[index].remark === "") {
    removeEvent(index);
  }
};

const disabledDate = (time) => {
  return time.getTime() > Date.now();
};
const teamId = computed(() => store.state.email.teamID)


// 传递的值
const types = ref();
const selections = computed(() => store.state.cusList.tableData);
onMounted(async () => {

  // 获取客户等级
  // await store.dispatch("cusList/getMailCustomerLevel");
  // await store.commit('customer/setCustomerLevels', selections.value)

  const findTabKey = router.currentRoute.value.query.tabKey;
  const {userId} = router.currentRoute.value.params;
  const {type} = router.currentRoute.value.query;
  if (userId != undefined) {
    localStorage.setItem("customerId", userId);
    store.dispatch("customer/getQueryById", {customerId: userId});
    types.value = type;
    customerId.value = userId;

    // here have bugs
    // await showActiveItem();
  }
  if (findTabKey) {
    activeName.value = findTabKey;
    mainKey.value = mainKey.value + 1
  }
});

const toWritePage = () => {
  // 路由更改
  router.push({path: `/email/write_mail`, query: {customerId: route.params.userId}});
}


const callLog = ref()
const multipleSelection = ref([])
const dialogVisiblePopover = ref(false)
const handleClickDelete = throttle(() => {
  multipleSelection.value = [localStorage.getItem("customerId")]
  if (multipleSelection.value.length === 0) {
    ElMessage.warning(i18n.global.t('common.email.chooseCustomer'));
    return;
  }
  dialogVisiblePopover.value = true;
}, 2000)
const closeDialog = () => {
  dialogVisiblePopover.value = false;
}
</script>

<style lang="less" scoped>
@import "./less/detail.less";
@import "../../less/page.less";

h2 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 21px;
}

.pages {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.top_titles {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.small_box {
  width: max-content;
  margin-top: 20px;
}

:deep(.el-drawer) {
  width: 530px;
}

.box_content {
  width: 100%;

  & > div {
    background: #f5f5f5;

    .inners {
      padding: 20px;
      width: 100%;
      background: white;
    }
  }
}

h3 {
  font-size: 16px;
  font-weight: 500;
}

:deep(.el-tabs__active-bar) {
  height: 3px !important;
}

.titles {
  padding: 20px 0px;
  border-bottom: 1px solid #ebebeb;
}

:deep(.el-form-item__label) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-form-item:first-child) {
  padding: 0px 24px 12px 0px;
}

.views {
  flex-grow: 1;
  height: calc(100vh - 167px);
  overflow: hidden;
  background-color: rgb(245, 245, 245);
}

.linkUser {
  display: flex;
  overflow: hidden;
  height: calc(100vh - 182px);
}

:deep(.el-date-editor.el-input) {
  width: 100% !important;
}

:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  justify-content: center !important;
}

#spButton {
  margin-left: -40px !important;
}

.tops {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .span_block {
    width: 4px;
    height: 16px;
    padding-left: 5px;
    border-left: 4px solid #1890ff;
  }

  .inner_text {
    font-weight: 700;
    color: #000;
  }
}

.outbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.el-tabs__nav-scroll) {
  width: max-content !important;
}
</style>
