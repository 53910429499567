<template>
  <div v-if="currConversation.conversation !== null" @click="openModal" class="chat">
    <img class="avatar" :src="avatar" alt="" srcset="">
    <span v-if="currConversation.conversation.unreadCount !== 0" class="tip">{{ currConversation.conversation.unreadCount }}</span>
  </div>
  <component v-if="currConversation.showModal" 
             :is="currConversation.conversation.type === TencentCloudChat.TYPES.CONV_C2C ? PersonalChatModal : GroupChatModal"
             @closeToChat="closeToChat"
             :curConversation="currConversation.conversation" />
</template>

<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import TencentCloudChat from '@tencentcloud/chat';

import PersonalChatModal from "./PersonalChatModal/index.vue";
import GroupChatModal from './PersonalChatModal/GroupChatModal.vue';

const store = useStore();


onMounted(() => {
  document.addEventListener('keydown', handleEsc);
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleEsc);
})

function handleEsc(event) {
  if (event.key === 'Escape') {
    store.dispatch("chat/updateCurrentConversation", {
      showModal: false,
    })

    event.preventDefault(); // 阻止默认行为
  }
}

// 当前聊天会话信息
const currConversation = computed(() => store.state.chat.currConversation);
// 当前会话显示头像
const avatar = computed(() => currConversation.value.conversation.type === TencentCloudChat.TYPES.CONV_C2C
  ? currConversation.value.conversation.userProfile.avatar : currConversation.value.conversation.groupProfile.avatar)

// 进入聊天页面
const openModal = () => {
  store.dispatch("chat/updateCurrentConversation", {
    showModal: true,
  })
}

function closeToChat() {
  store.dispatch("chat/updateCurrentConversation", {
    showModal: false,
  })
}

</script>

<style scoped lang="less">
.chat{
  position: fixed;
  right: 5%;
  bottom: 5%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.avatar{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.tip{
    display: block;
    width: 15px;
    height: 15px;
    color: #fff;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -5px;
    text-align: center;
    line-height: 15px;
    font-size: 12px;
  }
</style>
