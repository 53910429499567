<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.ccxq') }}</div>
      <Echarts></Echarts>
      <div>
        <div class="results">
          <div class="buttons_pays">{{ $t('common.gmcc') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Echarts from "@/views/UserCenter/StoreDetails/Echarts.vue";

</script>

<style lang="less" scoped>
@import "../less/Tables.less";
@import "../../../less/page.less";

.inners {
  width: 1000px !important;
  min-width: 1000px !important;
  position: relative;
}

.right {
  height: max-content !important;
  padding-bottom: 70px !important;
}

.inner_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left_box {
    width: 380px;
    min-width: 380px;

    div {
      margin-bottom: 10px;
    }
  }

}

.line_title {
  display: inline-block;
  width: 126px;
  color: #999;
}

.txt {
  display: inline-block;
  color: #333;
}

.results {
  width: 1000px;
  border-top: 1px solid #e8e8e8;
  height: 53px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;

  .buttons_pays {
    padding: 0 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #d93737;
    margin-left: auto;
    cursor: pointer;
  }
}
</style>
