<template>
  <div class="file_wrap">
    <div class="info">
      <div class="val">{{ payload.fileName }}</div>
      <div class="unit">{{ formatBytes(payload.fileSize) }}</div>
    </div>
    <img :src="formatIcon(payload.fileName)" alt="" />
  </div>
  <el-progress  v-if="props.messageItem.status === 'unSend'" :percentage="props.progress" :show-text="false"/>
  <div  v-else-if="isOfficesFile" class="file_opt">
    <el-link type="primary" @click="preview">预览</el-link>
    <el-link type="primary" :href="fileUrl">下载</el-link>
    <el-link type="primary">转存</el-link>
  </div>
</template>


<script setup>
import { computed, defineProps } from "vue";
import { ElProgress } from "element-plus";

import PDF_ICON from "../../../assets/img/file/pdf.png"
import EXCEL_ICON from '../../../assets/img/file/excel.png';
import WORD_ICON from '../../../assets/img/file/word.png';
import VIDEO_ICON from '../../../assets/img/file/vedio.png'
import { useRouter } from "vue-router";
const props = defineProps(["payload", 'messageItem', 'progress']);

const router = useRouter();

const fileUrl = computed(() => props.payload?.fileUrl);
const isOfficesFile = computed(() => ['pdf', 'word', 'excel'].includes(getFileType(props.payload?.fileName)));
/**
 * 文件预览
 */
const preview = () => {
  const fileUrl = props.payload?.fileUrl;
  const fileType = getFileType(props.payload?.fileName);
  const route = router.resolve({
    path: '/filePreview',
    query: {
      fileUrl,
      fileType
    }
  })
  window.open(route.href, '_blank');
}

function getFileType(name) {
  const exits = name.split('.');
  const exit = exits[exits.length - 1];
  if (exit === 'pdf') {
    return 'pdf';
  }
  if (exit === 'xls' || exit === 'xlsx') {
    return 'excel';
  }
  if (exit === 'docx' || exit === 'doc') {
    return 'word';
  }
  return '';
}

function formatIcon(name) {
  const exits = name.split('.');
  const exit = exits[exits.length - 1];
  if (exit === 'pdf') {
    return PDF_ICON;
  }
  if (exit === 'xls' || exit === 'xlsx') {
    return EXCEL_ICON;
  }
  if (exit === 'docx' || exit === 'doc') {
    return WORD_ICON;
  }
  if (exit === 'mp3') {
    return VIDEO_ICON;
  }
  return '';
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
  );
}
</script>

<style lang="less" scoped>
.file_wrap {
  display: flex;
  align-items: center;
  .info {
    width: 320px;
  }

  .val{
    width: 80%;
  }
  img {
    width: 48px;
    height: 48px;
  }
}

.unit{
  font-size: 14px;
  color: #a0a0a0;
}

.file_opt{
  .el-link{
    margin-right: 10px;
  }
}
</style>
