<template>

  <div :class="`editor-wrapper`" :style="{width:`${width} !important`}" ref="editorBox">
    <div v-if="loading" class="loading-animation sp_box">
      <!-- 你的加载动画，可以是一个简单的 GIF，SVG 或 CSS 动画 -->
      <div v-loading="loading"></div>
    </div>
    <Editor
        :id="tinymceId"
        v-model="content"
        :api-key="tiny.apiKey"
        :tinymceScriptSrc="`/tinymce/tinymce.min.js`"
        :init="tiny.init"
        ref="tinyMCEEditor"/>
  </div>
</template>

<script setup>
import {defineExpose, ref, reactive, onBeforeUnmount, defineProps, watch, nextTick} from 'vue';
import Editor from "@tinymce/tinymce-vue";
import * as SETTING from "/public/static/setting.json"
import {string} from "mockjs/src/mock/random/basic";
import {uploadFile} from "@/utils/file";

const props = defineProps({
  editorId: {
    type: String,
    required: true,
  },
  initialContent: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: "100%"
  },
  height: {
    type: string,
    default: "100%"
  }
});
const loading = ref(true)
const content = ref("");
const tinymceId = ref(`vue-tinymce-${props.editorId}-${+new Date()}`);  // Unique ID for each instance
const editorBox = ref(null)
const tiny = reactive({
  apiKey: SETTING.TINYMEC_API_KEY, // Replace with your API key
  init: {
    content_style:
        "@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap')",
    selector: `#${tinymceId.value}`,
    inline: false,        // 禁用内联模式
    // 回车换行 变为 br
    // invert交换 Enter 和 Shift+Enter 行为。
    newline_behavior: "invert",
    language: localStorage.getItem('lang') === 'en' ? 'en' : "zh_CN",
    placeholder: localStorage.getItem('lang') === 'en' ? 'Enter text here' : "在这里输入文字",
    ui_mode: 'split',
    toolbar_sticky: true,
    toolbar_mode: 'sliding',
    quickbars_selection_toolbar: false,
    min_width: 320,
    min_height: 900,
    height: 900,
    resize: "both",
    branding: true,
    draggable_modal: true,
    fullscreen_native: true,
    menubar: false,
    paste_remove_styles: false,
    paste_remove_styles_if_webkit: false,
    paste_as_text: false,
    paste_enable_default_filters: false,
    paste_data_images: true,
    valid_elements: '*[*]', // 允许所有元素和所有属性
    extended_valid_elements: 'div[style]', // 允许 div 的 style 属性
    external_plugins: {
      "formatpainter": "/tinymce/plugins/formatpainter/plugin.min.js",
    },
    plugins: 'preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media code codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help emoticons autoresize formatpainter',
    toolbar: 'undo redo | cut paste copy pastetext | fontfamily fontsizeinput | forecolor backcolor | bold italic underline strikethrough | link anchor removeformat formatpainter | lineheight alignleft aligncenter alignright alignjustify | code | image table | outdent indent| styleselect formatselect |bullist numlist |blockquote subscript superscript |charmap emoticons |hr pagebreak| insertdatetime print |preview fullscreen',
    // font_size_formats: '8px 10px 12px 14px 16px 18px 20px 24px 36px 4l0px 48px',
    line_height_formats: '0.5 1.0 1.5 2.0 2.5 3.0',
    default_font_stack: ['-apple-system', 'Arial'],
    font_family_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=SimSun, 宋体, serif;仿宋体=FangSong, 仿宋, serif;黑体=SimHei,sans-serif;楷体=kaiti,楷体,STKaiti;helvetica;sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino,Verdana=verdana, geneva;Times New Roman=times new roman, serif;Calibri=calibri, sans-serif; Century Gothic=century gothic, sans-serif;Comic Sans MS=comic sans ms, sans-serif;Arial=arial;Tahoma;Verdana=verdana, geneva, sans-serif;Courier New=courier new, courier, monospace;',
    file_picker_types: "image",
    automatic_uploads: false,
    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', async () => {
          /*
            Note: Now we need to register the blob in TinyMCEs image blob
            registry. In the next release this part hopefully won't be
            necessary, as we are looking to handle it internally.
          */
          // const id = 'blobid' + (new Date()).getTime();
          // const blobCache = tinymce.activeEditor.editorUpload.blobCache;
          // const base64 = reader.result.split(',')[1];
          // const blobInfo = blobCache.create(id, file, base64);
          // blobCache.add(blobInfo);
          const result = await uploadFile(file);
          if (result.url) {
            /* call the callback and populate the Title field with the file name */
            // cb(blobInfo.blobUri(), {title: file.name});
            cb(result.url, {title: file.name});
          }
        });
        reader.readAsDataURL(file);
      });
      input.click();
    },
    setup: function (editor) {
      // 保留原来的格式 \n \r 的格式
      // 这里可以处理 tinyeditor 中的富文本内容
      editor.on('BeforeSetContent', (e) => {
        if (e.content) {
          // 将换行符转换为 <br> 或其他形式显示
          // 如果遇到 多个 >> >>> 所标记的邮件 需要替换
          if (e.content.indexOf('>>') !== -1 || e.content.indexOf('>>>') !== -1) {
            e.content = e.content.replace(/\r\n|\r|\n/g, '<br>');
          }
        }
      });
      editor.on('ToolbarToggle', function (e) {
        const toolbar = editor.getContainer().querySelector('.tox-toolbar');
        if (toolbar) {
          // 禁用默认的焦点行为
          toolbar.setAttribute('tabindex', '-1');
        }
      });
      editor.on('init', function () {
        loading.value = false; // 当编辑器初始化完成后隐藏加载动画

        // 确保内容在编辑器初始化后设置
        if (props.initialContent) {
          editor.setContent(props.initialContent);
          // 将光标定位在内容的最开始
          editor.selection.setCursorLocation(editor.getBody().firstChild, 0);
          editor.focus(); // 让编辑器获取焦点
        }
      });
    },
    init_instance_callback: function (editor) {
      // 确保编辑器完全初始化后再设置内容
      if (editor.initialized && props.initialContent) {
        editor.setContent(props.initialContent);
        // 将光标定位在内容的最开始
        editor.selection.setCursorLocation(editor.getBody().firstChild, 0);
        editor.focus(); // 让编辑器获取焦点
      }
    }
  },
});

const tinyMCEEditor = ref(null);

watch(() => props.initialContent, (newValue, oldValue) => {
  const editor = tinyMCEEditor.value?.getEditor();
  // console.log('initialContent', newValue, oldValue)
  if (editor && newValue && !loading.value) {
    editor.setContent(props.initialContent);
    // 将光标定位在内容的最开始
    editor.selection.setCursorLocation(editor.getBody().firstChild, 0);
    editor.focus(); // 让编辑器获取焦点
  }
}, {deep: true});

onBeforeUnmount(() => {
  const editor = tinyMCEEditor.value?.getEditor();
  if (editor) {
    editor.remove()
  }
})

defineExpose({
  tinyMCEEditor,
  editorBox,
})
</script>

<style lang="less" scoped>
.editor-wrapper {
  overflow: scroll;
  height: 100%;
}

.sp_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}


/* 滚动条的宽度设置 */
.editor-wrapper::-webkit-scrollbar {
  width: 4px; /* 这里设置滚动条的宽度 */
}

/* 滚动条轨道 */
.editor-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* 滚动条滑块 */
.editor-wrapper::-webkit-scrollbar-thumb {
  background: #dedddd;
  border-radius: 4px;
}

/* 滑块在悬停时的颜色 */
.editor-wrapper::-webkit-scrollbar-thumb:hover {
  background: #dedddd;
}

:deep(.tox-statusbar) {
  display: none !important;
}

:deep(.tox-toolbar__group) {
  padding: 0 0px 0 5px !important;
}

:deep(.tox-editor-dock-fadeout) {
  opacity: 1 !important;
  visibility: visible !important;
}
</style>