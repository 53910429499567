<template>
  <div class="wrap">
    <div class="search">
      <el-input
        v-model="searchKey"
        style="width: 240px"
        placeholder="搜索"
        :suffix-icon="Search"
      />
    </div>
    <div class="list">
      <div
        class="item"
        v-for="item in groupList"
        :key="item.groupID"
        @click="openToChat(item)"
      >
        <img
          :src="item.avatar"
          class="avatar"
          alt=""
        />
        <div class="name">{{ item.name }}</div>
        <div class="num">{{ item.memberCount }}</div>
      </div>
    </div>
  </div>
  <!-- <GroupChatModal
    v-if="chatModal"
    @closeToChat="closeToChat"
    :curConversation="curConversation"
  /> -->
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Search } from "@element-plus/icons-vue";
import GroupChatModal from "../PersonalChatModal/GroupChatModal.vue";
import TencentCloudChat from "@tencentcloud/chat";
import { chat } from "../../store/modules/chat";

const chatModal = ref(false);
const store = useStore();

const curConversation = ref();

const searchKey = ref("");
// 群组列表
const groupList = computed(() => {
  return store.state.chat.groupList.filter((group) =>
    group.name.includes(searchKey.value)
  );
});

// 打开聊天框
const openToChat = async (item) => {
  const conversationID = TencentCloudChat.TYPES.CONV_GROUP + item.groupID;
  const conversation = await chat.getConversationProfile(conversationID)
  console.log(conversation.data.conversation, 'conversiont group');
  store.dispatch("chat/updateCurrentConversation", {
    showModal: true,
    conversation: conversation.data.conversation
  })
};

// 关闭聊天框
const closeToChat = () => {
  chatModal.value = false;
};
</script>

<style lang="less" scoped>
.wrap {
  .search {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .list {
    .item {
      display: flex;
      align-items: center;
      padding: 8px 20px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .name {
        font-weight: 400;
        font-size: 14px;
        color: #0d0e15;
        flex-grow: 1;
        margin-left: 10px;
      }
      .num {
        font-weight: 400;
        font-size: 14px;
        color: #aaaaaa;
      }
    }
  }
}
</style>
