<template>

  <el-card
      :title="title"
      :style="{ height: 'auto' }"
      style="margin-top: 10px !important"
  >
    <div class="tops">
      <div style="font-size: 20px;color:'#333';font-weight: 700;">
        {{ title }}
      </div>
      <el-button
          @click="addFriend"
          slot="extra"
          v-if="extra === 'addfriend'"
          type="primary"
          link
      >{{ $t("common.friend.add") }}
      </el-button
      >
    </div>
    <div class="mainBox" slot="content">
      <div class="item fx-d-c fx-a-c" v-for="item in list" :key="item.icon">
        <div
            class="box fx-aj-c"
            :style="{ backgroundImage: `url(${item.icon})` }"
        ></div>
        <span class="content" style="margin: 6px 0 4px 0"
        >{{ item.name }}
          </span>
        <span
            class="number bold"
            :style="{ color: item.countRed ? 'red' : '#000' }"
        >{{ item.count }}</span
        >
      </div>
    </div>
  </el-card>
</template>

<script>
import {ElCard} from "element-plus";
import Card from "@/components/Card.vue";

export default {
  props: {
    title: String,
    list: {
      type: Array,
      default: () => [],
    },
    extra: String,
  },
  components: {
    ElCard,
    Card,
  },
  setup() {
    const addFriend = () => {
      // Assuming $EventEmitter is a global event emitter
      // Make sure to initialize it appropriately in your app
      $EventEmitter.emit("addFriend");
    };

    return {
      addFriend,
    };
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  color: #333;
}

.mainBox {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  text-align: center;
  margin-top: 12px;
}

.item {
  width: 118px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.content,
.number {
  line-height: 1;
}

.tops {
  display: flex;
  justify-content: space-between;
}

:deep(.el-card__body) {
  min-width: 358px !important;
}

.bold {
  font-weight: 700;
}
</style>
