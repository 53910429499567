import {ref, onUnmounted} from 'vue';
import dayjs from "dayjs";

class Socket {
    url;
    ws = null;
    opts;
    reconnectAttempts = 0;
    listeners = {};
    heartbeatInterval = null;

    constructor(url, opts = {}) {
        this.url = url;
        this.opts = {
            // 心跳检测
            heartbeatInterval: 30000,
            // 重新连接时间间隔
            reconnectInterval: 5000,
            // 重连的次数
            maxReconnectAttempts: 2,
            ...opts
        };

        this.init();
    }

    init() {
        this.ws = new WebSocket(this.url);
        this.ws.onopen = this.onOpen.bind(this);
        this.ws.onmessage = this.onMessage.bind(this);
        this.ws.onerror = this.onError.bind(this);
        this.ws.onclose = this.onClose.bind(this);
    }

    onOpen(event) {
        if (event) {
            console.log(`%c websocket 已建立连接 ${dayjs(new Date()).format("HH:mm:ss")}`, 'color: green');
            this.reconnectAttempts = 0;
            this.startHeartbeat();
            this.emit('open', event);
        }

    }

    onMessage(event) {
        this.emit('message', event.data);
    }

    onError(event) {
        console.error('WebSocket error:', event);
        this.emit('error', event);
    }

    onClose(event) {
        console.log(`%c websocket 已断开连接 ${dayjs(new Date()).format("HH:mm:ss")}`, 'color: red');
        this.stopHeartbeat();
        this.emit('close', event);

        if (!window.wsIsClosed) {
            if (this.reconnectAttempts < this.opts.maxReconnectAttempts
            ) {
                setTimeout(() => {
                    this.reconnectAttempts++;
                    this.init();
                }, this.opts.reconnectInterval);
            }
        }
    }

    startHeartbeat() {
        if (!this.opts.heartbeatInterval) return;

        this.heartbeatInterval = window.setInterval(() => {
            if (this.ws?.readyState === WebSocket.OPEN) {
                console.log(`%c ❤ 检测 ${dayjs(new Date()).format("HH:mm:ss")}`, 'color: yellow');
                this.ws.send('ping');
            }
        }, this.opts.heartbeatInterval);
    }

    stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
    }

    send(data) {
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.send(data);
        } else {
            console.error('WebSocket is not open. Cannot send:', data);
        }
    }

    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    off(event) {
        if (this.listeners[event]) {
            delete this.listeners[event];
        }
    }

    emit(event, data) {
        this.listeners[event]?.forEach(callback => callback(data));
    }
}

export function useSocket(url, opts) {
    const socket = new Socket(url, opts);

    onUnmounted(() => {
        socket.off('open');
        socket.off('message');
        socket.off('error');
        socket.off('close');
    });

    return {
        socket,
        send: socket.send.bind(socket),
        on: socket.on.bind(socket),
        off: socket.off.bind(socket)
    };
}
