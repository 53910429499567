<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.ph29')}}</div>
      <div>
        <div class="results">
          <div class="buttons_pays">{{ $t('common.ph24')}}</div>
        </div>
        <Flows></Flows>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, reactive, ref, toRefs} from "vue";
import {useRouter} from "vue-router";
import {useStore, getters} from "vuex";
import Flows from "@/views/UserCenter/FlowDetails/Flows.vue";

</script>

<style lang="less" scoped>
@import "../less/Tables.less";
@import "../../../less/page.less";

.inners {
  width: 1000px !important;
  min-width: 1000px !important;
  position: relative;
}

.right {
  height: max-content !important;
  padding-bottom: 70px !important;
}

.buys_box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.text {
  span {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    font-feature-settings: "tnum";
  }
}

.list {
  height: 97px;
  width: max-content;
  position: absolute;
  bottom: 103px;
  right: 35px;
  text-align: right;

  div {
    margin-bottom: 17px;
    color: #444;
    font-size: 14px;
  }
}

.results {
  width: 1000px;
  border-top: 1px solid #e8e8e8;
  height: 53px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;

  .text_left {
    padding-left: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #444;
  }

  .buttons_pays {
    padding: 0 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #d93737;
    margin-left: auto;
    cursor: pointer;
  }
}

.pricenum {
  font-size: 22px;
  font-weight: 600;
  color: #d93737;
  margin-left: 5px;
}

.savenum {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 5px;
}

.all_price {
  font-weight: 600;
  color: #444 !important;
}

.red {
  color: #d93737;
}

.flow_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .flow_left {
    width: 280px;

    & > div {
      margin-bottom: 10px;
    }

    .flow_title {
      display: inline-block;
      width: 100px;
      color: #999;
    }

    .flow_txt {
      display: inline-block;
      color: #333;
    }

    .sp_flow {
      color: #999 !important;
    }
  }

  .flow_right {

  }
}


</style>
