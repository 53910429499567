<template>
  <div class="image-container" @click="handleImagePreview">
    <!-- {{ props.content?.url }} -->
    <!-- <img
      class="message-image"
      mode="aspectFit"
      :src="props.content.url"
      :style="{ width: imageStyles.width, height: imageStyles.height }"
      @load="imageLoad"
    /> -->
    <el-image class="message-image" mode="aspectFit" :src="props.content.url"
      :style="{ width: imageStyles.width, height: imageStyles.height }" 
      @load="imageLoad" 
      :preview-src-list="imageUrls"
      :initial-index="curIndex" />
  </div>
</template>
<script setup>
import TencentCloudChat from '@tencentcloud/chat';
import { watchEffect, ref, defineProps } from 'vue';

const emits = defineEmits();
const props = defineProps(['content', 'messageItem', 'messageList']);


const DEFAULT_MAX_SIZE = 155;
const imageStyles = ref({ width: 'auto', height: 'auto' });
const imageUrls = ref([]);
const curIndex = ref(0);

const genImageStyles = (value) => {
  if (!value) return;

  const { width, height } = value;
  if (width === 0 || height === 0) {
    return;
  }

  let imageWidth = 0;
  let imageHeight = 0;
  if (width >= height) {
    imageWidth = DEFAULT_MAX_SIZE;
    imageHeight = (DEFAULT_MAX_SIZE * height) / width;
  } else {
    imageWidth = (DEFAULT_MAX_SIZE * width) / height;
    imageHeight = DEFAULT_MAX_SIZE;
  }
  imageStyles.value.width = imageWidth + 'px';
  imageStyles.value.height = imageHeight + 'px';
};

watchEffect(() => {
  genImageStyles(props.content);
});

const imageLoad = (event) => {
  genImageStyles(event.detail);
};

const handleImagePreview = () => {
  if (props.messageItem?.status === 'success' || props.messageItem.progress === 1) {
    // emits('previewImage');
    if (!props.messageList) {
      return;
    }
    const imageMessageIndex = [];
    const imageMessageList = props.messageList.filter((item, index) => {
      if (
        !item.isRevoked
        && !item.hasRiskContent
        && item.type === TencentCloudChat.TYPES.MSG_IMAGE
      ) {
        imageMessageIndex.push(index);
        return true;
      }
      return false;
    });
    imageUrls.value = imageMessageList.map(message => message.payload.imageInfoArray?.[2].url);
    curIndex.value = imageMessageList.map(message => message.payload.imageInfoArray?.[0].url).indexOf(props.content.url);
  }
};
</script>

<style lang="less" scoped>
.image-container {
  position: relative;
  background-color: #f4f4f4;
  font-size: 0;
  display: contents;

  .message-image {
    max-width: 150px;
  }
}
</style>