<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.team.station.detail') }}</div>
      <div>
        <div class="top_title">
          <div>
            {{
              $t('displace.team.station.totalandsurplus', {
                total: mailWorkPosition.totalPosition,
                surplus: mailWorkPosition.freePosition
              })
            }}
          </div>
          <el-button round @click="checkoutAll" :disabled="true">{{ $t('common.plxf') }}</el-button>
        </div>
        <div style="margin-top: 20px">
          <el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading"
                    :header-cell-style="{ 'text-align': 'left',background: '#f9f9f9', color: 'black',padding:'16px 0px'}">
            <el-table-column type="selection" fixed="left" width="60" :selectable="checkSelectable"/>
            <el-table-column v-for="item in column_values" :prop="item.value" :label="item.key"
                             width="180">

              <template #default="{row,column,$index}">
                <template v-if="item.value==='status'">
                  <el-button link type="success" v-if="row.status===1">{{ $t('common.kx') }}</el-button>
                  <el-button link type="danger" v-else>{{ $t('common.zy') }}</el-button>
                </template>
                <template v-else-if="item.value==='expireTime'">
                  {{ row.isModify === 1 ? $t('common.yjmf') : row.expireTime }}
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.cz')" fixed="right" width="120">
              <template #default="{ row, column, $index }">
                <el-button v-if="row.isModify === 0" type="primary" link @click="handleRenewal(row)">
                  {{ $t('common.ph11') }}
                </el-button>
                <el-button type="primary" link @click="removeToWhere(row)">
                  {{ $t('common.qyz') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
                v-model:current-page="current"
                v-model:page-size="size"
                background
                layout="total, prev, pager, next"
                v-if="totals"
                :total="totals"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>


    <Dialog
        v-model="dialogVisibleTips"
        :title="$t('common.qygw')"
        :width="'450'"
        :dialogVisible="dialogVisibleTips"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (dialogVisibleTips = val)"
    >
      <template #content>
        <div class="innerbox">
          <span>{{ $t('common.qyz') }}</span>
          <div style="margin-top: 10px">
            <el-select
                v-model="valueStation"
                class="m-2"
                :placeholder="$t('station.select.position')"
                style="width: 240px"
            >
              <el-option
                  v-for="item in optionsStation"
                  :key="item.id"
                  :label="item.workCode"
                  :value="item.workCode"
              />
            </el-select>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="setting_center">
          <el-button @click="dialogVisibleTips = false">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="moveWorkPositionApis">{{ $t('common.confirm') }}</el-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import {ElMessage} from 'element-plus'
import {useRouter} from 'vue-router'
import {getFreeWorkPosition, moveWorkPosition, queryWorkPosition} from "@/api/usercenter";
import {useStore} from "vuex";
import Dialog from "@/components/Dialog.vue";
import {i18n} from "@/lang";

const router = useRouter();
const store = useStore()
const tableData = ref([])
const column_values = [{
  key: i18n.global.t('common.station.number'), value: 'workCode',
}, {key: i18n.global.t('common.zt'), value: 'status'}, {key: i18n.global.t('common.sxsj'), value: 'effectiveTime'}, {
  key: i18n.global.t('common.endtime'),
  value: 'expireTime'
}, {key: i18n.global.t('common.sstd'), value: 'teamName'}, {key: i18n.global.t('common.sszh'), value: 'userName'}]

const dialogVisibleTips = ref(false)
// 批量选中的值
const selection = ref([])
const loading = ref(false)
// 工位信息
const mailWorkPosition = computed(() => store.state.user.mailWorkPosition)
const handleSelectionChange = (val) => {
  selection.value = val;
}

// 分页
const current = ref(1);
const size = ref(10);
const totals = ref(10)
const handleCurrentChange = async (page) => {
  current.value = page;
  await queryWorkPositionApis()
};

// 批量续费
const checkoutAll = () => {
  if (selection.value.length === 0) {
    ElMessage.error(i18n.global.t('common.please.select'))
    return
  }

  const result = selection.value.map(item => item.id)
  localStorage.setItem('rowTable', JSON.stringify(selection.value))
  router.push({path: '/personal/buystorage', query: {Renewal: 1, arr: result.join(',')}})

}
// 单行续费
const handleRenewal = (row) => {
  localStorage.setItem('rowTable', JSON.stringify([row]))
  router.push({path: '/personal/buystorage', query: {Renewal: 1, arr: row.id}})
}

// 获取工位list
const queryWorkPositionApis = async () => {
  loading.value = true
  const res = await queryWorkPosition({
    pageNo: current.value,
    pageSize: size.value,
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  const {records} = res.result;
  tableData.value = records;
  current.value = res.result.current;
  size.value = res.result.size;
  totals.value = res.result.total;
  loading.value = false;
}
// 工位列表
const optionsStation = ref([])
// 选中的工位
const valueStation = ref('')
const teamId = computed(() => store.state.email.teamID)
const getFreeWorkPositionApis = async () => {
  const res = await getFreeWorkPosition()
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  optionsStation.value = res.result
}
// 选中的item
const targetCheckItem = ref({})
const removeToWhere = async (row) => {
  dialogVisibleTips.value = true;
  targetCheckItem.value = row
}

const moveWorkPositionApis = async () => {

  const res = await moveWorkPosition({
    newWorkCode: valueStation.value,
    oldWorkCode: targetCheckItem.value.workCode,
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  valueStation.value = ''
  dialogVisibleTips.value = false;
  await queryWorkPositionApis()
}

const checkSelectable = (row, index) => {
  return row.isModify !== 1
}


onMounted(async () => {
  await queryWorkPositionApis()
  await getFreeWorkPositionApis()
  await store.dispatch("user/getPositionByLoginList");
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  width: 1000px !important;
  min-width: 1000px !important;

  .right {
    height: max-content !important;
    padding-bottom: 20px !important;
  }
}

:deep(.el-dropdown-menu) {
  background: red;
}

.top_title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-button {
    width: 100px;
    height: 30px;
    background: #d93737;
    border-radius: 16px;
    font-size: 14px;
    color: #fff;
  }
}

.setting_center {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
