<template>
  <el-card
      :title="title"
      :style="{ height: height + 'px' }"
      style="margin-top: 12px !important; min-width: 358px !important"
  >
    <div class="tops">
      <div style="font-size: 20px;color:'#333';font-weight: 700;">
        <span>{{ title }}</span>
        <span>
          <span class="tips_info">{{ $t('common.urgent') }}</span>
          <span class="tips_info">{{ $t('common.normal') }}</span>
        </span>
      </div>
      <el-button
          @click="addFriend"
          slot="extra"
          v-if="extra === 'addfriend'"
          type="primary"
          link
      >{{ $t("common.more") }}
      </el-button
      >
    </div>
    <div class="mainBox" slot="content">
      <ElCalendar></ElCalendar>
    </div>
  </el-card>
</template>

<script setup>
import {ref, defineProps, watch, computed} from 'vue'
import {ElCard} from "element-plus";
import ElCalendar from "./index.vue"
import {useStore} from "vuex";

const {title} = defineProps({
  title: {
    type: String,
    default: ''
  }
})
const date = ref();
const value = ref(new Date())

const dayTime = computed(() => store.state.calendar.dateList)
const tag = computed(() => store.state.calendar.tag)
const mainKey = ref(1);
const store = useStore()


// watch(() => tag.value, () => {
//   // 对已有的数据进行去重处理 避免 紧急、普通 点 重复
//   mainKey.value++
// },)
</script>

<style lang="less" scoped>
@import "./less/index";
</style>
