<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.team.create')"
      :footer="null"
      centered
      :width="400"
      destroyOnClose
      @cancel="close"
  >
    <span style="display: inline-block">{{ $t("common.team.name") }}:</span>
    <ElInput
        type="text"
        v-model="teamName"
        style="height: 32px; width: 100%; margin-top: 8px; margin-bottom: 20px"
        :maxLength="15"
        @keydown.enter.native="createConfirm"
    />
    <div class="fx-j-c">
      <ElButton type="primary" @click="createConfirm" :loading="loading">{{
          $t("common.confirm")
        }}
      </ElButton>
    </div>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {V2CreateTeam} from '../../api'
import {ref, defineProps, watch, defineEmits} from 'vue'
import {useStore} from "vuex";
import {i18n} from "@/lang";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const teamName = ref("")
const loading = ref(false)


const {value} = defineProps({
  value: {type: Boolean, default: false}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

const createConfirm = async () => {
  if (teamName.value !== "") {
    try {
      loading.value = true;
      let res = await V2CreateTeam({
        name: teamName.value,
      });
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      ElMessage.success(res.message)
      teamName.value = "";
      close();
      await store.dispatch("email/getTeamList", {isUpdate: true});
      await store.dispatch("home/getTeamListPages");
      // 重新拉取工位信息
      await store.dispatch("user/getPositionByLoginList");
      // this.$store.dispatch("system/getAccountInfo"); //获取全局资源
    } finally {
      loading.value = false;
    }
  } else {
    ElMessage.error(i18n.global.t("common.team.pleaseEnterRightTeamName"));
  }
}
const close = () => {
  emit("close");
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
}
</style>
