<template>
  <div class="inners">
    <div class="right">
      <div class="titles" v-if="isRenewal">{{ $t('common.xf') }}</div>
      <div class="titles" v-else-if="isVbx">{{ $t('common.ph12') }}</div>
      <div class="titles" v-else>{{ $t('common.ph13') }}</div>
      <div>
        <template v-if="!isRenewal">
          <div class="title">{{ $t('common.ph15') }}</div>
          <div class="buys_box">
            <BoxTab
                v-for="item in checkOutData"
                :item="item"
                @selectItems="selectItems"
            ></BoxTab>
          </div>
          <div class="title">{{ $t('common.buy.amount') }}</div>
          <div>
            <el-input-number
                v-model="num"
                class="mx-4"
                :min="1"
                controls-position="right"
                @change="handleChange"
            />
          </div>
          <div
              style="
            height: 0px;
            border-top: 1px solid #efefef;
            margin: 40px 0px 20px 0px;
          "
          ></div>
          <div class="text">
          <span v-if="checkIndex === 1 && !isVbx"
          >
            {{ $t('common.cds52') }}
          </span
          >
            <span v-else-if="checkIndex === 2"
            >
              {{ $t('common.cds53') }}
            </span
            ><span v-else-if="checkIndex === 3"
          >
            {{ $t('common.cds54') }}
          </span
          >
            <span v-else-if="checkIndex === 4 && isVbx">
           {{ $t('plus.vip.des') }}
            <el-button
                link type="primary" @click="toVipsText">{{ $t('common.cds87') }}</el-button>
          </span>
          </div>
        </template>
        <template v-else>
          <div class="title">{{ $t('common.ph14') }}</div>
          <div class="buys_box">
            <el-table :data="getRenewalAll" style="width: 100%" :cell-style="{ padding: '14px' }"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      v-loading="isLoading">
              <el-table-column v-for="item in column_values" :prop="item.value" :label="item.key"
                               width="180">

                <template #default="{row,column,$index}">
                  <template v-if="item.value==='price'">
                    <span class="unit_pirce">¥{{ row.price }}<img v-if="isVip" class="plus"
                                                                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAOCAYAAABO3B6yAAAAAXNSR0IArs4c6QAAAxdJREFUSEu9kl1MU2cYx3/vKaXSFCi1BSmQicKicTEz0ZQoITqJEzWNGJeARgh4gZtupomaXazFqHMxSqLbomHGGCeKSrb4gZqxDzEmONBlflwsgOIGTopSaqvlu+cYjvEoNnIHz937f9/3//yeD9GclZUnFKVSgTQmMAR0KEKUiRaHo32ik7+qcwRCNDscypuFx3yQSdIXaxBCIA8M0t/yH0+OnkV+FiJ192ZCf/+Dv6ZO+2Ka/yHmFTl0fVfNUOcTTAvmYF6WTWfFj4R7Alg+WUJ8XjZStJ7eOy10fV+N3Nuv/Y8ASFj5EbaSlfhrr4IQmJfn8PR8Pd1VtWSe2Ufgj0a6DpzQDCwFS7EWLuPfTbsZ7PAyuTCPyQV53C9xY5z9PsmudQTrbzD02AeSDt/JixCW3w1gW59PgnMRbaUewoHnZJzeqxr0/PQr6Yfc+Grq8FXVagaJnxVg/ng+9wq3qZUlbSwkbrGD1tUurEVOLPmL6Tl3hZ6aOrWLb0dEB5K3lhCbPQf/hXoM09OImZHOw/KDoCik7fqcrsoaApeuaT52dxnGWRncK9iqaimeDUS/Z+fBeg/6ZBspnjKi7YnIg0P4qi/j//m3UQwRAGl7XBimpdLf2s6w7ymBugb67rYSu3Auya4i/v/6MKGmu5pJyo6NGFKn0FbqVrX0H8oZ6PDyaGelehb6KGIXzsNW5EQyxXB/zZfIfWPsQPrh7YQDz2jfUjGKNGFVLrZiJ90nL6qzDof66bvdTJJrHXE5c/EeqEKfaMG6djneg6cI/tJAXG4WOpORgfZObKX56OJNtBV/BfIYOzB1pIIHD+n85sgoAGuxE8uqXE0LXvsL775jRE+1k1r+KVGWePUueOUG3m+rkAwG7OUbMM6cpurD/iDe/cfpvdU89ghe9k2oMx8VOgnJOEmT5L4BGA6/POsk9FOshIOhiEWLspoRkwwMebtfv39t/DxiByLWdBwFCSreBhgp+xxCnFLkNwY1DhA6IToyGxv/1AAkuBxWFM+Mpqab45DvnZYjAL/rJMmdcf16w0QmfpXrBfuIN9OhNbKJAAAAAElFTkSuQmCC"/></span>
                  </template>
                  <template v-if="item.value==='status'">
                    <el-button link type="success" v-if="row.status===1">{{ $t('common.kx') }}</el-button>
                    <el-button link type="danger" v-else>{{ $t('common.zy') }}</el-button>
                  </template>
                </template>
              </el-table-column>
              <el-table-column :label="$t('common.amount')" width="180" prop="num">
                <template #default="{ row,column,$index }">
                  <el-input-number
                      v-model="row.num"
                      model-value="1"
                      class="mx-4"
                      :min="1"
                      precision="0"
                      :max="999"
                      size="small"
                      controls-position="right"
                      @change="changeNum(row)"
                  />
                  <span>&nbsp;&nbsp;{{ $t('common.year') }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('common.xj')" width="180">
                <template #default="{ row,column,$index }">
                  <span class="unit_pirce">
                    ¥{{
                      isNaN((row.num * row.price).toFixed(2)) ? 0 : (row.num * row.price).toFixed(2)
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('repairOrder.setting')" fixed="right" width="60"
                               v-if="getRenewalAll.length>1">
                <template #default="{ row, column, $index }">
                  <el-button type="primary" link @click="handleClose(row)"><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAPpJREFUKFOVkrFKA0EQhr9/UTsTtBNBsBKSSiwu9xA+gb2FaBXfJJVJ7RP4EHcWYpXA2UVIE2xy6WLYkY17ciopbptlZ/7/m5ndFXGNO529nXb7Wt5fGXRDWDA25x7Xi8WwO5msYgyKND2W2ZOZnVeA+i7p1aTLsyybKZB3W618m7gyBtNnWfZUpOkt3g9i4gU4BQ5j+bmZzZC+Kzt3p7ckyQx6G4HZu6S+hwfBGrjxZgNJRxGQq0iSEtiv9TwV9MO5Lo755T+DYB7IGwOMBAc12PJ3S1EcySsH9wZDiyZB/nfoZzM7qXoGpsAHcPEzdONrDc5GD9f0a3wB/duFabvYdPAAAAAASUVORK5CYII="/>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <template v-if="!isRenewal">
          <div class="list">
            <div>
              {{ $t('common.ph5') }}:<span class="all_price red">¥ {{ sumUnPrice }}</span>
            </div>
            <div>
              {{ $t('common.ph6') }}:<span class="red">¥ {{ Discountedprices }}</span>
            </div>
            <div>
              {{ $t('common.ph8') }}:<span class="red">¥ {{ sumPrice }}</span>
            </div>
          </div>
          <div class="results">
            <div class="text_left">
              {{ $t('common.ph8') }}:<span class="pricenum">¥ {{ sumPrice }}</span
            >&nbsp;&nbsp;{{ $t('plus.buy.save') }}<span class="savenum"
            >¥ {{ Discountedprices }}</span>
            </div>
            <div class="buttons_pays" @click="toPay">{{ $t('common.settle.to') }}</div>
          </div>
        </template>
        <template v-else>
          <div class="list">
            <div>
              {{ $t('common.ph5') }}:<span class="all_price red">¥ {{ allSumPrice }}</span>
            </div>
            <div v-if="isVip">
              {{ $t('common.ph6') }}:<span class="red">¥ {{ DiscountedpricesRenewal }}</span>
            </div>
            <div>
              {{ $t('common.ph8') }}:<span class="red">¥ {{ endSumPrice }}</span>
            </div>
          </div>
          <div class="results">
            <div class="text_left">
              {{ $t('common.ph8') }}:<span class="pricenum">¥ {{ endSumPrice }}</span
            >&nbsp;&nbsp;<span v-if="isVip">{{ $t('plus.buy.save') }}<span class="savenum"
            >¥ {{ DiscountedpricesRenewal }}</span
            ></span>
            </div>
            <div class="buttons_pays" @click="toPay">{{ $t('common.settle.to') }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, reactive, ref, toRefs, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import BasicCard from "./Basic/BasicCard.vue";
import BoxTab from "./BuyStorage/BoxTab.vue";
import {ElMessage} from "element-plus";
import {getMailProductList, queryWorkPositionById} from '@/api/usercenter.js'
import {buyWorkPosition} from "@/api";
import {i18n} from "@/lang";

const router = useRouter();
const route = useRoute();
const store = useStore();

const tableData = ref([])
const column_values = [
  {
    key: i18n.global.t('common.station.number'), value: 'workCode',
  },
  {key: i18n.global.t('common.zt'), value: 'status'},
  {key: i18n.global.t('common.sxsj'), value: 'effectiveTime'},
  {
    key: i18n.global.t('common.endtime'),
    value: 'expireTime'
  },
  {key: i18n.global.t('common.sstd'), value: 'teamName'},
  {key: i18n.global.t('common.sszh'), value: 'userName'}, {
    key: i18n.global.t('common.unitprice'),
    value: 'price'
  }]
// 存储 续费的数组id
const queryArray = ref([])

// 判断用户是否是vip
const isVip = ref(false)

// 路由获取得到要续费的项
const getRenewalAll = ref([])

// 计算总原价
const allSumPrice = computed(() => {
  return getRenewalAll.value.reduce((pre, cur) => pre + Number(cur.price) * cur.num, 0).toFixed(2)
})
// 计算现总价
const endSumPrice = computed(() => {
  return getRenewalAll.value.reduce((pre, cur) => pre + Number(cur.price) * cur.num, 0).toFixed(2)
})
// 计算差价
const DiscountedpricesRenewal = computed(() => {
  return (Number(allSumPrice.value) - Number(endSumPrice.value)).toFixed(2)
})

const changeNum = (row) => {
  console.log(row, getRenewalAll, 'row')
  localStorage.setItem("rowTable", JSON.stringify(getRenewalAll.value))
}

const isVipInfo = computed(() => store.state.user.userInfo)
// 是否时vbx购买会员？
const isVbx = ref(false);
// 是否时续费？
const isRenewal = ref(false);
const teamId = computed(() => store.state.email.teamID)
// 判断用户类型
const isEnterpriseMainUser = computed(() => store.state.user.isEnterpriseMainUser)
// 去结算
const toPay = async () => {
  // router.push({path: '/personal/pay', query: {type: 1, realAmount: sumPrice.value}})

  let params = {
    // 1 购买 2 续费
    type: isRenewal.value ? 2 : 1,
    // 1 vip 2 非vip
    isVip: isVipInfo.value.isVip ? 2 : 1,
    teamId: teamId.value
  }


  // 续费
  if (isRenewal.value) {
    let mailWorkPositionDTOList = []
    let productIds = ''
    getRenewalAll.value.forEach(({id, num, productId}) => {
      productIds = productId;
      mailWorkPositionDTOList.push({
        workPositionId: id,
        num: num ? num : 2,
      })
    })
    console.log({...params, mailWorkPositionDTOList}, 'params')
    const res = await buyWorkPosition({...params, mailWorkPositionDTOList, productId: productIds})
    if (res.code !== 200) {
      ElMessage.error(res.message)
      return
    }
    const orderId = res.result;
    router.push({path: '/personal/pay', query: {orderId: orderId, sumPrice: endSumPrice.value}})
  } else {
    // vip 续费 购买商品
    console.log(checkOutData.value, 'vbx', num)
    const selectTarget = checkOutData.value.filter(item => item.isSelect)
    const {
      id,

    } = selectTarget[0]
    console.log({
      ...params,
      productId: id,
      num: num.value
    }, 'params');

    const res = await buyWorkPosition({
      ...params,
      productId: id,
      num: num.value
    })
    if (res.code !== 200) {
      ElMessage.error(res.message)
      return
    }
    const orderId = res.result;
    router.push({path: '/personal/pay', query: {orderId: orderId, sumPrice: sumPrice.value}})

  }

  // 购买vip
  if (!isVbx.value && !isRenewal.value && isVipInfo.value.isVip === 2) {
    params.goodsList.push({
      id: isEnterpriseMainUser.value ? 6 : 5,
      quantity: num.value
    })
  }
};

// 关闭指定的行，但是刷新后会 重置
const handleClose = (row) => {
  const newArray = JSON.parse(JSON.stringify(getRenewalAll.value))
  newArray.splice(newArray.findIndex(item => item.id === row.id), 1);
  getRenewalAll.value = newArray;
}

// table 加载
const isLoading = ref(false)

//选中的index
const checkIndex = computed(() => store.state.buystorage.checkIndex);

const checkOutData = computed(() => {
  return isVbx.value ? store.state.buystorage.vbxData : store.state.buystorage.checkOutData;
});
// 数量
const num = computed(() => store.state.buystorage.num);

// 总价格
const sumPrice = computed(() => {
  return isVbx.value ? store.getters["buystorage/sumPriceVbx"] : store.getters["buystorage/sumPrice"]
});
// 原价
const sumUnPrice = computed(() => {
  return isVbx.value ? store.getters["buystorage/sumUnPriceVbx"] : store.getters["buystorage/sumUnPrice"]
});
// 优惠价格
const Discountedprices = computed(
    () => {
      return isVbx.value ? store.getters["buystorage/DiscountedpricesVbx"] : store.getters["buystorage/Discountedprices"]
    }
);

const handleChange = (value) => {
  store.commit("buystorage/setNum", value);
};

const selectItems = (value) => {
  const {id} = value;
  console.log("target", value);
  store.commit("buystorage/setCheckIndex", id * 1);
  //更新数据
  const newObj = JSON.parse(JSON.stringify(checkOutData.value));
  const res = updateIsSelectById(newObj, id);
  console.log('res###', id * 1, res)
  store.commit("buystorage/setCheckOutData", res);
};

// 选择过滤方法
function updateIsSelectById(arr, targetId) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === targetId) {
      arr[i].isSelect = true;
    } else {
      arr[i].isSelect = false;
    }
  }
  return arr;
}

const current = ref(1);
const size = ref(10);
const total = ref(100);
const handleCurrentChange = (page) => {
};

const handleClick = () => {
  router.push("/personal/createdRepairOrder");
};

const isWhichOne = ref({})
onMounted(async () => {
  // 消除公用数量的影响
  store.commit("buystorage/setNum", 1);
  const {index, vbx, Renewal, arr} = router.currentRoute.value.query
  console.log(index, typeof (index - 0), 'index', vbx)
  if (vbx === '4') {
    console.log(vbx, 'fff')
    store.commit("buystorage/setCheckIndex", (vbx * 1));
    isVbx.value = true
    // 2 会员
    isWhichOne.value.vip = 2
  } else if (Renewal === "1") {
    // 传递的参数
    const arrs = arr.split(',')
    queryArray.value = arrs;
    getRenewalAll.value = JSON.parse(localStorage.getItem("rowTable"))
    isRenewal.value = true
  } else if (index) {
    store.commit("buystorage/setCheckIndex", (index * 1));
    const newObj = JSON.parse(JSON.stringify(checkOutData.value));
    const res = updateIsSelectById(newObj, (index * 1));
    store.commit("buystorage/setCheckOutData", res);
    // 1 商品列表
    isWhichOne.value.list = 1
  }
  await getMailProductListApis()
})

// 获取商品详情 / 会员详情
const getMailProductListApis = async () => {
  const targetObj = isWhichOne.value.vip ? isWhichOne.value.vip : isWhichOne.value.list;
  const res = await getMailProductList({type: targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  const result = res.result.map((item, index) => {
    // 初始化选中的item
    if (item.id * 1 === checkIndex.value) {
      return {...item, isSelect: true}
    } else {
      return {...item, isSelect: false}
    }
  })
  console.log('result', result, res.result)

  if (targetObj === 1) {
    //遍历默认选中第一个
    await store.commit("buystorage/setCheckOutData", result)
  } else {
    await store.commit("buystorage/setvbxData", result)
  }
}


const toVipsText = () => {
  // 这个地址到后面要进行替换
  window.open('https://web.mail-talk.com/doc/MembershipServiceAgreement-cn.html', '_blank')
}
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  width: 1000px !important;
  min-width: 1000px !important;
  position: relative;
}

.buys_box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.text {
  span {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    font-feature-settings: "tnum";
  }
}

.list {
  height: 97px;
  width: max-content;
  position: absolute;
  bottom: 103px;
  right: 35px;
  text-align: right;

  div {
    margin-bottom: 17px;
    color: #444;
    font-size: 14px;
  }
}

.results {
  width: 1000px;
  border-top: 1px solid #e8e8e8;
  height: 53px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;

  .text_left {
    padding-left: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #444;
  }

  .buttons_pays {
    padding: 0 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #d93737;
    margin-left: auto;
    cursor: pointer;
  }
}

.pricenum {
  font-size: 22px;
  font-weight: 600;
  color: #d93737;
  margin-left: 5px;
}

.savenum {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 5px;
}

.all_price {
  font-weight: 600;
  color: #444 !important;
}

.red {
  color: #d93737;
}

.unit_pirce {
  color: #d93737;
  font-weight: 700;
  font-size: 16px;
  position: relative;
}

.plus {
  position: absolute;
  left: 45px;
  top: -2px;
}

:deep(.el-table_1_column_8) {
  padding: 0px !important;
}

:deep(.el-input-number--small) {
  width: 70px !important;
}
</style>
