import { onUnmounted, watch } from 'vue';
import { useSocket } from "@/utils/websocket";
import dayjs from "dayjs";
import { showNotify } from "@/utils/notifies";
import router from "@/router";
import { truncateByBytes } from "@/utils/notifies/tool";

let messageQueue = []; // 用于存储消息的队列

export function useWebSocket(userId, store) {
    const startWS = () => {
        const {
            socket,
            on, off
        } = useSocket(`${process.env.VUE_APP_BASE_API.replace("https://", "wss://").replace("http://", "ws://")}/v3/websocket/${userId}`);
        window.socketTarget = socket
        window.wsIsClosed = false;

        on('message', data => {
            if (data !== "ping") {
                const targetObjData = JSON.parse(data);
                if (targetObjData["msg_front_from"] && targetObjData) {
                    // 邮件消息
                    messageQueue.push(targetObjData); // 将消息添加到队列中
                    document.title = `收到${messageQueue.length}封新邮件`;
                } else {
                    // 通知消息
                    store.commit("message/setNewData", data);
                    store.dispatch("message/getUnReadMessageListApisDefault", { pageNo: 1, pageSize: 999 })
                }
            }
        });

        // 在 3 秒后处理消息队列并触发 showNotify
        const processMessageQueue = () => {
            if (messageQueue.length > 0) {
                setTimeout(() => {
                    console.log('show', messageQueue);
                    store.commit("message/setEmailData", messageQueue);
                    if (messageQueue && messageQueue[0]) {
                        messageQueue[0].msgTxt = truncateByBytes(messageQueue[0]?.msgTxt, 60)
                        showNotify(messageQueue.length, messageQueue[0], () => {
                            // localStorage.setItem('activeMenu', '/email/inbox')
                            router.push('/email/inbox')
                        });
                        messageQueue = []; // 清空消息队列
                    }
                });
            }
        };
        setInterval(processMessageQueue, 3000); // 每 3 秒处理一次消息队列
    };

    const stopWS = () => {
        if (window.socketTarget) {
            window.socketTarget.ws.close();
            window.wsIsClosed = true;
        }
    }

    return {
        startWS,
        stopWS,
    };
}

document.addEventListener("visibilitychange", function () {
    if (document.visibilityState === "hidden") {
        // 离开当前标签页
        console.log('离开了');
        messageQueue = [];
        // document.title = '';
    } else if (document.visibilityState === "visible") {
        //切换到该页面时执行 
        console.log('进来了');
    }
});