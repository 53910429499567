<template>
  <div class="wrap">
    <div class="exam" ref="inboxRef">
      <div class="screen">
        <div class="btn_w">
          <ElButton size="small" :loading="btnIsLoadingResolve" type="primary" @click="() => handlePass(1)">
            {{ $t('email.list.examine') }}
          </ElButton>
          <ElButton size="small" :loading="btnIsLoadingReject" @click="() => handlePass(2)">
            {{ $t('email.list.examine.reject') }}
          </ElButton>
        </div>
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            small="small"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
      <div class="scroll_out_box" ref="scrollBox"
      >
        <ElTable v-loading="loading" :height="tableHeight" :data="list" @selection-change="handleSelectionChange"
                 size="small" show-overflow-tooltip
                 highlight-current-row @current-change="handleCurrentRowChange"
                 :cell-style="rowClassName"
                 :header-cell-style="headerClassName">
          <el-table-column type="selection" width="20"/>
          <el-table-column width="30">
            <template #header>
              <img src="@/assets/email/fj.svg"/>
            </template>
            <template #default="scope">
              <img v-if="scope.isExtend === 1" class="pointer" src="@/assets/email/fj.svg"/>
            </template>
          </el-table-column>
          <el-table-column prop="mailRecipientList" :label="$t('common.email.to')">
            <template #default="scope">
              <el-popover
                  v-if="(scope.row.customerName != null || scope.row.contactName != null)"
                  placement="right"
                  :width="'max-content'"
                  effect="dark"
                  trigger="hover"
                  show-after="500"
                  :content="scope.row.contactEmail!=null?scope.row.contactEmail:scope.row.customerName+'('+scope.row.companyEmail+')'"
              >
                <template #reference>
                  <router-link :to="'/email/customer/detail/' + scope.row.customerId + '?type=1'"
                  >
                    <span style="color: #0087ff;"> {{ scope.row.customerName }}</span>
                  </router-link>
                </template>
              </el-popover>
              <template v-else>
                <template v-if="scope.row.mailRecipientList.length>1?scope.row.mailRecipientList.length>1:false">
                  <el-popover
                      placement="right"
                      :width="'max-content'"
                      effect="dark"
                      trigger="hover"
                      :show-after="500"
                      :content="scope.row.mailRecipientList.join(' , ')"
                  >
                    <template #reference>
                      <span style="overflow: hidden">{{ `${scope.row.mailRecipientList[0]} ...` }}</span>
                    </template>
                  </el-popover>
                </template>
                <template v-else>
                  {{ scope.row.customerName ? scope.row.customerName : scope.row.mailRecipientList[0] }}
                </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="subject" :label="$t('common.subject')"/>
          <el-table-column prop="createTime" :label="$t('common.date')" width="160"/>
          <el-table-column prop="userName" :label="$t('common.staff')" width="160"/>
          <el-table-column fixed="right" :label="$t('common.cz')" width="120">
            <template #default="scope">
              <el-button link type="primary" size="small"
                         @click="() => handleSingle(scope.row, 1)"
              >{{ $t('common.pass') }}
              </el-button
              >
              <el-button link type="danger" size="small"
                         @click="() => handleSingle(scope.row, 2)">
                {{ $t('common.reject') }}
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
          </template>
        </ElTable>
        <div class="current_mail" :style="{width: '100%',height:pageframeHeight +'px'}">
          <div class="mail_info"
               :style="{ top: elementTop + 'px', left: elementLeft + 'px' }" ref="moveTarget">
            <div class="up_row" @mousedown="startDrag"></div>
            <div style="width: 100%">
              <div class="from over_text">{{ $t('common.email.from') }}：{{ currentMail.mailBody?.from }}</div>
              <div class="to over_text" ref="toRef">
                <el-popover
                    v-if="formatText(currentMail.mailRecipientVOList, 'to')"
                    :visible="visibleTo"
                    width="500"
                    :content="formatText(currentMail.mailRecipientVOList, 'to')"
                    :effect="'dark'"
                    popper-class="visible"
                >
                  <template #reference>
                    <span @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'to'),'to')"
                          @mouseleave="delayedHidePopover('to')">{{
                        $t('common.email.to')
                      }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}</span>
                  </template>
                  <div @mouseenter="keepPopoverVisible('to')" @mouseleave="hidePopover('to')">
                    {{ formatText(currentMail.mailRecipientVOList, 'to') }}
                  </div>
                </el-popover>
                <template v-else>
                  {{ $t('common.email.to') }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}
                </template>
              </div>
              <div class="cc over_text">
                <el-popover
                    v-if="formatText(currentMail.mailRecipientVOList, 'cc')"
                    :visible="visibleCC"
                    width="500"
                    :content="formatText(currentMail.mailRecipientVOList, 'cc')"
                    :effect="'dark'"
                    popper-class="visible"
                >
                  <template #reference>
                <span
                    @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'cc'),'cc')"
                    @mouseleave="delayedHidePopover('cc')"
                >
                {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                </span>
                  </template>
                  <div @mouseenter="keepPopoverVisible('cc')" @mouseleave="hidePopover('cc')">
                    {{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                  </div>
                </el-popover>
                <template v-else>
                  {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                </template>
              </div>
            </div>
            <div class="down_row" @mousedown="startDrag"></div>
          </div>
          <div v-loading="detailLoading" :style="{width: '100%',height:pageframeHeight +'px',position:'relative'}">
            <iframe ref="pageframe" id="pageframe" :srcdoc="iframeContent" frameborder="0"
                    :style="{width: '100%',height:pageframeHeight +'px',overflowY: 'scroll'}"
                    :class="detailLoading?'scroll':'scroll loaded'"
            >
              <p>Your browser does not support preview features, please download Google browser or other modern
                browsers.</p>
            </iframe>
            <div v-if="isDragging" id="mask"
                 :style="{width: '100%',height:pageframeHeight +'px',background:'rgba(0,0,0,0)',position:'absolute',zIndex:999,top:'30px'}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, computed, watch, onBeforeUnmount} from 'vue';
import {useStore} from 'vuex';
import {ElMessage} from 'element-plus';
import {requestAuditMailList, requestBatchAuditEmail} from '@/api/mail';
import {getMailDetails} from '../../api/index';
import {emptyUrl, TIME_OUT} from "@/utils/statusParams";
import {useRoute, useRouter} from "vue-router";
import {debouncePlus, throttle} from "@/utils/mylodash"
import {addImgOnErrorAttribute, convertLinksToHTML, convertToHTML} from "@/utils/iframe";
import {getScaleValue} from "@/utils/view";

export default {
  setup() {
    const store = useStore();
    const teamId = computed(() => store.state.email.teamID);
    const route = useRoute()
    const current = ref(1);
    const size = ref(10);
    const total = ref(0);
    const list = ref([]);
    const loading = ref(false);
    const currentMail = ref({});
    const multipleSelection = ref([]);
    const iframeContent = ref('');
    const tableHeight = ref(200);
    const inboxRef = ref(null);
    const router = useRouter()

    function handleSizeChange() {
    }

    function handleCurrentChange(val) {
      current.value = val
      getList()
    }

    const detailLoading = ref(false)

    async function handleCurrentRowChange(current) {
      console.log(current, 'ff');
      if (current) {
        detailLoading.value = true
        try {
          const result = await getMailDetails({id: current.id});
          let content = result.result?.mailBody?.content;
          detailLoading.value = false
          const insertionContent = `<script>
            function errorImage(img) {
                console.log(img,'img')
                img.onerror = null;
                img.src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR42mP4//8/AAX+Av4zEpUUAAAAAElFTkSuQmCC"
            }
        <\/script>`;
          if (!content) {
            iframeContent.value = ''
          } else {
            if (content.indexOf('html') !== -1 || content.indexOf('<div>') !== -1) {
              const replacedHtmlString = addImgOnErrorAttribute(content)
              console.log('content', replacedHtmlString);
              const res = replacedHtmlString.replace(/(<html[^>]*>)/, '$1' + insertionContent);
              iframeContent.value = res;
            } else {
              // 纯图片需要直接展示
              if (content.indexOf('https://s3gw.cmbimg.com') !== -1) {
                iframeContent.value = content;
              } else {
                iframeContent.value = convertLinksToHTML(convertToHTML(content))
              }
            }
          }
          currentMail.value = result.result;
        } catch (e) {
          detailLoading.value = false
        }
      }
    }

    function handleSelectionChange(val) {
      multipleSelection.value = val;
    }

    function initTableHeight() {
      // console.log(inboxRef.value, 'inboxRef.value')
      // tableHeight.value = inboxRef.value.offsetHeight - 48 - 36 - 300;
      const inboxElement = inboxRef.value;

      // 通过使用 getComputedStyle 获取元素的最终计算样式，以确保正确计算高度
      const inboxStyle = window.getComputedStyle(inboxElement);

      // 使用 offsetHeight 来获取元素的高度，可能需要调整具体的计算方式以适应不同的布局和样式
      const inboxHeight = inboxElement.offsetHeight;

      // 这里的 48、36、300 是用于计算的固定值，根据具体的布局和样式可能需要进行调整
      if (elementTopRaw.value === 0) {
        tableHeight.value = inboxHeight - parseInt(inboxStyle.paddingTop) - parseInt(inboxStyle.paddingBottom) - 48 - pageframeHeight.value
      } else {
        tableHeight.value = elementTopRaw.value
      }
    }


    async function getList() {
      loading.value = true;
      const res = await requestAuditMailList({pageNo: current.value, pageSize: size.value, teamId: teamId.value});
      loading.value = false;
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return;
      }
      list.value = res.result?.records || [];
      total.value = res.result?.total;
    }

    async function batchAuditEmail(type) {
      console.log(multipleSelection.value[0].id, 'multipleSelection.value')
      if (type === 1) {
        btnIsLoadingResolve.value = true
      } else {
        btnIsLoadingReject.value = true
      }
      const result = await requestBatchAuditEmail({
        auditStatus: type,
        idList: multipleSelection.value.map(item => item.id)
      });
      if (type === 1) {
        btnIsLoadingResolve.value = false
      } else {
        btnIsLoadingReject.value = false
      }
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(result.message)
      getList();
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')
      console.log('审核邮件', result);
    }

    function handlePass(type) {
      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }
      batchAuditEmail(type);
    }

    const btnIsLoadingResolve = ref(false)
    const btnIsLoadingReject = ref(false)
    const handleSingle = throttle((row, type) => {
      multipleSelection.value = [row];
      console.log(multipleSelection.value, '000000')
      batchAuditEmail(type);
    }, TIME_OUT)

    function formatText(list, type) {
      console.log(list, type);
      if (!list || !list.length) {
        return '';
      }
      const infoMap = list.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item.mail);
        return prev;
      }, {});
      return infoMap[type] ? infoMap[type].join(',') : '';
    }

    watch(() => teamId.value, () => {
      console.log('组件团队ID 监听', teamId.value);
      getList();
    });

    // 页面进行缩放
    const adjustContentPosition = debouncePlus(() => {
      console.log('resize')
      localStorage.setItem("resize", true)
      router.go(0)
    }, 200)

    onMounted(async () => {
      await store.dispatch("system/getTargetHeight", {userId: userInfo.value.id})
      // if (localStorage.getItem("resize") === 'true') {
      //   pageframeHeightRaw.value = getScaleValue(fileInboxHeight.value)
      //   elementTopRaw.value = getScaleValue(fileInboxTop.value)
      //   localStorage.setItem("resize", false)
      // } else {
      pageframeHeightRaw.value = fileInboxHeight.value
      elementTopRaw.value = fileInboxTop.value
      // }
      document.addEventListener('mousemove', onDragEx);
      document.addEventListener('mouseup', stopDragEx);
      if (inboxRef.value) {
        initTableHeight();
        if (elementTopRaw.value === 0) {
          elementTop.value = tableHeight.value
        }
      }
      if (teamId.value) {
        getList();
      }
      window.addEventListener('resize', adjustContentPosition);
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', adjustContentPosition);
      document.removeEventListener('mousemove', onDragEx);
      document.removeEventListener('mouseup', stopDragEx);
    });

    const userInfo = computed(() => store.state.user.userInfo)
    // 移动
    const moveTarget = ref(null);
    const scrollBox = ref(null);
    const isDragging = ref(false);
    const initialMouseY = ref(0)
    const elementLeft = ref(0)
    const draggableHeight = ref(30);
    const fileInboxHeight = computed(() => store.state.system.fileInboxHeight)
    const fileInboxTop = computed(() => store.state.system.fileInboxTop)
    const elementTopRaw = ref(fileInboxTop.value);
    const elementTop = computed({
      get() {
        return elementTopRaw.value;
      },
      set(newValue) {
        elementTopRaw.value = newValue;
      },
    });

    const pageframeHeightRaw = ref(fileInboxHeight.value);
    const pageframeHeight = computed({
      get() {
        return pageframeHeightRaw.value;
      },
      set(newValue) {
        pageframeHeightRaw.value = newValue;
      },
    });

    function startDrag(e) {
      e = e || window.event;
      isDragging.value = true;
      initialMouseY.value = e.clientY;
    }

    const saveTargetHeight = debouncePlus((userId, pageframeHeight, elementTop) => {
      store.dispatch("system/settingTargetHeight", {
        userId,
        fileInboxHeight: pageframeHeight,
        fileInboxTop: elementTop
      });
    }, 200); // 300ms 防抖延迟

    const onDragEx = (event) => {
      // 拖拽中更新元素位置
      if (isDragging.value) {
        // 阻止 iframe 事件响应
        requestAnimationFrame(() => {
          const deltaY = event.clientY - initialMouseY.value;
          elementTop.value += deltaY;
          initialMouseY.value = event.clientY;
          // 限制元素在容器内上下移动
          const tagNumber = getScaleValue(80)
          elementTop.value = Math.max(30, Math.min(inboxRef.value.clientHeight, elementTop.value > inboxRef.value.clientHeight - tagNumber ? inboxRef.value.clientHeight - tagNumber : elementTop.value));
          tableHeight.value = elementTop.value;
          pageframeHeight.value = scrollBox.value.offsetHeight - elementTop.value;
          saveTargetHeight(userInfo.value.id, pageframeHeight.value, elementTop.value);
        })
      } else {
        stopDragEx()
      }
    }

    function stopDragEx() {
      // 结束拖拽
      isDragging.value = false;
    }


    const rowClassName = ({row, column, rowIndex, columnIndex}) => {
      console.log(row, columnIndex, '333')
      if (columnIndex === 4 || columnIndex === 5) {
        return {textAlign: 'center'}
      } else {
        return {textAlign: 'left'}
      }
    }

    const headerClassName = ({row, column, rowIndex, columnIndex}) => {
      if (columnIndex === 4 || columnIndex === 5) {
        return {'text-align': 'center'}
      } else {
        return {'text-align': 'left',}
      }
    }


    const sidebarTargetNumber = computed(() => store.state.countshow.sidebarTargetNumber)
    watch(() => sidebarTargetNumber.value, async () => {
      await getList();
    })

    const visibleTo = ref(false)
    const visibleCC = ref(false)

    const hidePopoverTimeout = ref(false)

    function showPopover(string = '', flag) {

      if (moveTarget.value) {
        const itemWidth = Math.round((moveTarget.value.clientWidth - 40) * (1 / 3))
        const stringWidth = string.pxWidth('normal 12px PingFangSC, PingFang SC')
        console.log(itemWidth, stringWidth)
        if (stringWidth + 20 > itemWidth) {
          if (flag === "to") {
            visibleCC.value = false;
            visibleTo.value = true;
          } else if (flag === "cc") {
            visibleTo.value = false;
            visibleCC.value = true;
          }
          if (hidePopoverTimeout.value) {
            clearTimeout(hidePopoverTimeout.value);
            hidePopoverTimeout.value = null;
          }
        }
      }
    }

    function delayedHidePopover(flag) {
      hidePopoverTimeout.value = setTimeout(() => {
        flag === "to" ? visibleTo.value = false : visibleCC.value = false;
      }, 200); // Adjust delay as necessary
    }

    function keepPopoverVisible(flag) {
      if (hidePopoverTimeout.value) {
        clearTimeout(hidePopoverTimeout.value);
        hidePopoverTimeout.value = null;
      }
      flag === "to" ? visibleTo.value = true : visibleCC.value = true;
    }

    function hidePopover(flag) {
      flag === "to" ? visibleTo.value = false : visibleCC.value = false;
    }

    return {
      showPopover,
      delayedHidePopover, keepPopoverVisible, hidePopover,
      detailLoading,
      visibleTo,
      visibleCC,
      rowClassName,
      headerClassName,
      scrollBox,
      initialMouseY,
      draggableHeight,
      moveTarget,
      pageframeHeight,
      elementLeft,
      elementTop,
      startDrag,
      onDragEx,
      stopDragEx,
      current,
      size,
      total,
      list,
      loading,
      currentMail,
      iframeContent,
      tableHeight,
      inboxRef,
      formatText,
      handlePass,
      handleSingle,
      handleCurrentChange,
      handleSizeChange,
      handleSelectionChange,
      handleCurrentRowChange,
      emptyUrl,
      btnIsLoadingResolve,
      btnIsLoadingReject,
      isDragging
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  background-color: rgb(245, 245, 245);
  padding: 16px;
  height: -webkit-fill-available !important;
  //overflow: hidden;

  :deep(.el-form-item) {
    margin: 20px 0 0;
  }

  .exam {
    background-color: #fff;
    height: 100%;

    .screen {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      padding-bottom: 10px;
      background: #f3f3f3;
    }

    .current_mail {

      //padding: 0px 20px;

      .mail_info {
        display: flex;
        flex-direction: column;
        height: 30px;
        align-items: center;
        background: rgb(229, 229, 229);
        //user-select: text;
        cursor: n-resize;
        transform: translateZ(0);
        will-change: transform;
        font-family: "PingFangSC, PingFang SC";

        & > div:nth-of-type(2) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0px 20px;
          height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          cursor: text !important;

          & > div {
            width: 33.33%;
            min-width: 33.33% !important;
            max-width: 33.33% !important;
          }
        }
      }
    }
  }

  :deep(.el-empty__image img) {
    width: 54% !important;
  }

  :deep(.el-empty__description) {
    margin-top: -20px;
  }

  .scroll_out_box {
    position: relative;
  }

  .mail_info {
    width: 100%;
    position: absolute;
    z-index: 999;
  }

  #pageframe {
    padding: 30px 0px 8px 0px;
    background: white;
  }

  .from {
    padding-left: 20px;
  }
}

.up_row, .down_row {
  width: 100%;
  height: 2px !important;
  cursor: row-resize !important;
}

.over_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

iframe {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

iframe.loaded {
  opacity: 1;
}

:deep(.el-popper) {
  max-width: 45% !important;
}

.visible {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 8px !important;
  }
}
</style>