export const DEFAULT_BASIC_EMOJI_URL = 'https://web.sdk.qcloud.com/im/assets/emoji-plugin/';

export const DEFAULT_BASIC_EMOJI_URL_MAPPING = {
  '[TUIEmoji_Expect]': 'emoji_0@2x.png',
  '[TUIEmoji_Blink]': 'emoji_1@2x.png',
  '[TUIEmoji_Guffaw]': 'emoji_2@2x.png',
  '[TUIEmoji_KindSmile]': 'emoji_3@2x.png',
  '[TUIEmoji_Haha]': 'emoji_4@2x.png',
  '[TUIEmoji_Cheerful]': 'emoji_5@2x.png',
  '[TUIEmoji_Smile]': 'emoji_6@2x.png',
  '[TUIEmoji_Sorrow]': 'emoji_7@2x.png',
  '[TUIEmoji_Speechless]': 'emoji_8@2x.png',
  '[TUIEmoji_Amazed]': 'emoji_9@2x.png',
  '[TUIEmoji_Complacent]': 'emoji_10@2x.png',
  '[TUIEmoji_Lustful]': 'emoji_11@2x.png',
  '[TUIEmoji_Stareyes]': 'emoji_12@2x.png',
  '[TUIEmoji_Giggle]': 'emoji_13@2x.png',
  '[TUIEmoji_Daemon]': 'emoji_14@2x.png',
  '[TUIEmoji_Rage]': 'emoji_15@2x.png',
  '[TUIEmoji_Yawn]': 'emoji_16@2x.png',
  '[TUIEmoji_TearsLaugh]': 'emoji_17@2x.png',
  '[TUIEmoji_Silly]': 'emoji_18@2x.png',
  '[TUIEmoji_Wail]': 'emoji_19@2x.png',
  '[TUIEmoji_Kiss]': 'emoji_20@2x.png',
  '[TUIEmoji_Trapped]': 'emoji_21@2x.png',
  '[TUIEmoji_Fear]': 'emoji_22@2x.png',
  '[TUIEmoji_BareTeeth]': 'emoji_23@2x.png',
  '[TUIEmoji_FlareUp]': 'emoji_24@2x.png',
  '[TUIEmoji_Tact]': 'emoji_25@2x.png',
  '[TUIEmoji_Shit]': 'emoji_26@2x.png',
  '[TUIEmoji_ShutUp]': 'emoji_27@2x.png',
  '[TUIEmoji_Sigh]': 'emoji_28@2x.png',
  '[TUIEmoji_Hehe]': 'emoji_29@2x.png',
  '[TUIEmoji_Silent]': 'emoji_30@2x.png',
  '[TUIEmoji_Skull]': 'emoji_31@2x.png',
  '[TUIEmoji_Mask]': 'emoji_32@2x.png',
  '[TUIEmoji_Beer]': 'emoji_33@2x.png',
  '[TUIEmoji_Cake]': 'emoji_34@2x.png',
  '[TUIEmoji_RedPacket]': 'emoji_35@2x.png',
  '[TUIEmoji_Bombs]': 'emoji_36@2x.png',
  '[TUIEmoji_Ai]': 'emoji_37@2x.png',
  '[TUIEmoji_Celebrate]': 'emoji_38@2x.png',
  '[TUIEmoji_Bless]': 'emoji_39@2x.png',
  '[TUIEmoji_Flower]': 'emoji_40@2x.png',
  '[TUIEmoji_Watermelon]': 'emoji_41@2x.png',
  '[TUIEmoji_Cow]': 'emoji_42@2x.png',
  '[TUIEmoji_Fool]': 'emoji_43@2x.png',
  '[TUIEmoji_Surprised]': 'emoji_44@2x.png',
  '[TUIEmoji_Askance]': 'emoji_45@2x.png',
  '[TUIEmoji_Monster]': 'emoji_46@2x.png',
  '[TUIEmoji_Pig]': 'emoji_47@2x.png',
  '[TUIEmoji_Coffee]': 'emoji_48@2x.png',
  '[TUIEmoji_Ok]': 'emoji_49@2x.png',
  '[TUIEmoji_Heart]': 'emoji_50@2x.png',
  '[TUIEmoji_Sun]': 'emoji_51@2x.png',
  '[TUIEmoji_Moon]': 'emoji_52@2x.png',
  '[TUIEmoji_Star]': 'emoji_53@2x.png',
  '[TUIEmoji_Rich]': 'emoji_54@2x.png',
  '[TUIEmoji_Fortune]': 'emoji_55@2x.png',
  '[TUIEmoji_857]': 'emoji_56@2x.png',
  '[TUIEmoji_666]': 'emoji_57@2x.png',
  '[TUIEmoji_Prohibit]': 'emoji_58@2x.png',
  '[TUIEmoji_Convinced]': 'emoji_59@2x.png',
  '[TUIEmoji_Knife]': 'emoji_60@2x.png',
  '[TUIEmoji_Like]': 'emoji_61@2x.png',
};

export const Emoji = {
  '[TUIEmoji_Smile]': '[微笑]',
  '[TUIEmoji_Expect]': '[期待]',
  '[TUIEmoji_Blink]': '[眨眼]',
  '[TUIEmoji_Guffaw]': '[大笑]',
  '[TUIEmoji_KindSmile]': '[姨母笑]',
  '[TUIEmoji_Haha]': '[哈哈哈]',
  '[TUIEmoji_Cheerful]': '[愉快]',
  '[TUIEmoji_Speechless]': '[无语]',
  '[TUIEmoji_Amazed]': '[惊讶]',
  '[TUIEmoji_Sorrow]': '[悲伤]',
  '[TUIEmoji_Complacent]': '[得意]',
  '[TUIEmoji_Silly]': '[傻了]',
  '[TUIEmoji_Lustful]': '[色]',
  '[TUIEmoji_Giggle]': '[憨笑]',
  '[TUIEmoji_Kiss]': '[亲亲]',
  '[TUIEmoji_Wail]': '[大哭]',
  '[TUIEmoji_TearsLaugh]': '[哭笑]',
  '[TUIEmoji_Trapped]': '[困]',
  '[TUIEmoji_Mask]': '[口罩]',
  '[TUIEmoji_Fear]': '[恐惧]',
  '[TUIEmoji_BareTeeth]': '[龇牙]',
  '[TUIEmoji_FlareUp]': '[发怒]',
  '[TUIEmoji_Yawn]': '[打哈欠]',
  '[TUIEmoji_Tact]': '[机智]',
  '[TUIEmoji_Stareyes]': '[星星眼]',
  '[TUIEmoji_ShutUp]': '[闭嘴]',
  '[TUIEmoji_Sigh]': '[叹气]',
  '[TUIEmoji_Hehe]': '[呵呵]',
  '[TUIEmoji_Silent]': '[收声]',
  '[TUIEmoji_Surprised]': '[惊喜]',
  '[TUIEmoji_Askance]': '[白眼]',
  '[TUIEmoji_Ok]': '[OK]',
  '[TUIEmoji_Shit]': '[便便]',
  '[TUIEmoji_Monster]': '[怪兽]',
  '[TUIEmoji_Daemon]': '[恶魔]',
  '[TUIEmoji_Rage]': '[恶魔怒]',
  '[TUIEmoji_Fool]': '[衰]',
  '[TUIEmoji_Pig]': '[猪]',
  '[TUIEmoji_Cow]': '[牛]',
  '[TUIEmoji_Ai]': '[AI]',
  '[TUIEmoji_Skull]': '[骷髅]',
  '[TUIEmoji_Bombs]': '[炸弹]',
  '[TUIEmoji_Coffee]': '[咖啡]',
  '[TUIEmoji_Cake]': '[蛋糕]',
  '[TUIEmoji_Beer]': '[啤酒]',
  '[TUIEmoji_Flower]': '[花]',
  '[TUIEmoji_Watermelon]': '[瓜]',
  '[TUIEmoji_Rich]': '[壕]',
  '[TUIEmoji_Heart]': '[爱心]',
  '[TUIEmoji_Moon]': '[月亮]',
  '[TUIEmoji_Sun]': '[太阳]',
  '[TUIEmoji_Star]': '[星星]',
  '[TUIEmoji_RedPacket]': '[红包]',
  '[TUIEmoji_Celebrate]': '[庆祝]',
  '[TUIEmoji_Bless]': '[福]',
  '[TUIEmoji_Fortune]': '[发]',
  '[TUIEmoji_Convinced]': '[服]',
  '[TUIEmoji_Prohibit]': '[禁]',
  '[TUIEmoji_666]': '[666]',
  '[TUIEmoji_857]': '[857]',
  '[TUIEmoji_Knife]': '[刀]',
  '[TUIEmoji_Like]': '[赞]',
};

export function convertKeyToEmojiName(key) {
  // WeChat does not support emoji translation
  return Emoji[key];
};
export const DEFAULT_BASIC_EMOJI_KEYS = Object.keys(DEFAULT_BASIC_EMOJI_URL_MAPPING);

export const BASIC_EMOJI_NAME_TO_KEY_MAPPING = {
  ...Object.fromEntries(
    Object.entries(Emoji)?.map(([key, val]) => [val, key]),
  ),
};