<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.as9') }}</div>
      <!-- 订单表格 -->
      <div class="content">
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            :label-position="'top'"
        >
          <el-form-item id="avatar">
            <AvatarUpload v-model="ruleForm.avatar"/>
          </el-form-item>
          <el-form-item :label="$t('common.label.enterprise.name')" prop="name">
            <el-input v-model="ruleForm.name"/>
          </el-form-item>
          <el-form-item :label="$t('common.contact.phone')" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.dzyx')" prop="email">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.cds64')" prop="country">
            <el-select
                v-model="ruleForm.country"
                class="m-2"
                :placeholder="$t('please.select.country')"
            >
              <el-option
                  v-for="item in areaList"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div id="buttons">
              <el-button type="primary">{{ $t('common.save') }}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, onMounted, computed} from "vue";
import {useStore} from "vuex";
import AvatarUpload from "./EditInfo/AvatarUpload.vue";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import {i18n} from "@/lang";

const store = useStore();
const ruleForm = ref([
  {avatar: "", name: "april", sex: 1, country: "china"},
]);

// 区域列表
const areaList = computed(() => store.state.customer.areaList);

const rules = ref({
  name: [{required: true, message: i18n.global.t('edit.info.reg1'), trigger: "blur"}],
});

const userInfo = ref({})
onMounted(async () => {
  userInfo.value = JSON.parse(localStorage.getItem("userInfo"))
  ruleForm.value.name = userInfo.value.userName;
  ruleForm.value.sex = userInfo.value.sex === null ? 1 : userInfo.value.sex;
  ruleForm.value.country = userInfo.value.country;
  await store.dispatch('customer/getAreaNames')
})

</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 235px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: center;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}
</style>
