import _store from "store2";
import {getEmailGroupFileList} from "@/api/sidebar";
import {ElMessage} from "element-plus";
import {nextTick} from "vue";

const sidebar = {
    namespaced: true,
    state: {
        fileList: [],
        targetFileList: [],
        isRouterAlive: true,
    }, getters: {}, mutations: {
        async reload(state) {
            state.isRouterAlive = false;
            await nextTick(() => {
                state.isRouterAlive = true;
            })
        }
    }, actions: {
        async getEmailGroupFileListApis({state, commit}, payload) {
            const {isUpdateFileList, teamId, userId} = payload
            let res = await getEmailGroupFileList({teamId, userId})
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }
            console.log('rdddddes', res.result)
            if (isUpdateFileList) {
                state.fileList = res.result;
            }
            state.targetFileList = res.result;
        }
    },
};

export default sidebar;
