<template>
  <ElDialog :title="$t('common.block.confirm')" :model-value="showFlag">
    <div class="content">
      <div class="t">{{ $t('other.text.ask.email.blacklist') }}</div>
      <div class="mail">
        {{ list.join('；') }}
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="() => $emit('cancel')" style="margin-right: 12px;">{{ $t('common.cancel') }}</ElButton>
        <ElButton type="primary" @click="handleEntry" :loading="loading">
          {{ $t('common.confirm') }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>
<script>
import {ElMessage, useSpace} from 'element-plus';
import {useStore} from 'vuex';
import {computed, onMounted, ref} from 'vue';
import {requestBatchBlackEmail} from '../../api/mail';
import {i18n} from "@/lang"

export default {
  emits: ['succ', 'cancel'],
  props: {
    showFlag: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      default: [],
    },
  },
  setup(props, {emit}) {
    const store = useStore();
    const loading = ref(false);
    const teamId = computed(() => store.state.email.teamID);

    async function handleEntry() {
      loading.value = true;
      const result = await requestBatchBlackEmail({
        emailList: props.list,
        teamId: teamId.value,
      });
      loading.value = false;
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(i18n.global.t('sys.message.success.emailbox.blacklist'));
      emit('succ');
    }

    return {
      loading,
      handleEntry,
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  .t {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .mail {
    word-break: break-all;
    font-weight: 700;
    font-size: 14px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>