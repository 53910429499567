<template>
  <div class="detail" id="EmailDetail">
    <div class="screen">
      <!--      type来源：1.收件箱，2：草稿箱，3：发件箱-->
      <ElButton size="small" type="primary"
                @click="handleReply(route.params.type === '2'||route.params.type === '3'?'resend':'')">
        {{
          route.params.type === '3' || route.params.type === '2' ? $t('common.resend') : $t("common.reply")
        }}
      </ElButton>
      <ElButton size="small" type="primary" v-if="route.params.type === '3'" @click="handleReply('')">
        {{ $t("common.reply") }}
      </ElButton>
      <ElButton v-if="route.params.type!=='2'" size="small" type="primary"
                @click="handleReply('replayAll')">
        {{ $t("common.email.replyToAll") }}
      </ElButton>
      <ElButton size="small" @click="() => handleReply('forward')">{{ $t("common.email.forward") }}</ElButton>
      <ElDropdown size="small" @command="handleCommand">
        <ElButton size="small">
          {{ $t("common.email.marker") }}
        </ElButton>
        <template #dropdown>
          <ElDropdownMenu>
            <ElDropdownItem :command="0">{{ $t("common.email.read") }}</ElDropdownItem>
            <ElDropdownItem :command="1">{{ $t("common.email.noread") }}</ElDropdownItem>
            <ElDropdownItem :command="2">{{ $t("common.email.starmark") }}</ElDropdownItem>
            <ElDropdownItem :command="3">{{ $t("common.cancel.mark") }}</ElDropdownItem>
          </ElDropdownMenu>
        </template>
      </ElDropdown>
      <ElButton size="small" v-if="currentMail.mailBody?.delFlag === 1" @click="() => requestDelMail(0)">
        {{ $t("common.restore") }}
      </ElButton>
      <ElButton size="small" v-else @click="() => requestDelMail(1)">{{ $t("common.delete") }}</ElButton>
      <ElButton size="small" @click="() => requestDelMail(2)">{{ $t("common.delete.empty") }}</ElButton>
      <!--      <ElButton size="small">垃圾邮件</ElButton>-->
      <ElButton size="small" @click="() => handleBlack()">{{ $t("common.block") }}</ElButton>
      <ElButton size="small" @click="handlePrint">{{ $t("common.email.print") }}</ElButton>
      <ElButton size="small" v-if="rowItem.flag!==0" type="primary" @click="showReturn">
        {{ $t("common.archives.return") }}
      </ElButton>
    </div>
    <div class="info">
      <div class="info_item border">
        <div class="title">
          <div class="label">
            <span>{{ $t("common.email.from") }}</span>
          </div>
        </div>
        <div class="cnt">
          {{ currentMail.mailBody?.from }}
        </div>
      </div>
      <div class="info_item border">
        <div class="title">
          <div class="label">
            <span>{{ $t('common.email.to') }}</span>
          </div>
        </div>
        <div class="cnt">
          {{ formatText(currentMail.mailRecipientVOList, 'to') }}
        </div>
      </div>
      <!--      {{ currentMail.mailRecipientVOList }}-->
      <div class="info_item border"
           v-if="currentMail.mailRecipientVOList?.filter(item=>item.mailType==='cc').length>=1">
        <div class="title">
          <div class="label">
            <span>{{ $t("common.cc") }}</span>
          </div>
        </div>
        <div class="cnt">
          {{ formatText(currentMail.mailRecipientVOList, 'cc') }}
        </div>
      </div>
      <div class="info_item border"
           v-if="currentMail.mailRecipientVOList?.filter(item=>item.mailType==='bcc').length>=1">
        <div class="title">
          <div class="label">
            <span>{{ $t("common.bcc") }}</span>
          </div>
        </div>
        <div class="cnt">
          {{ formatText(currentMail.mailRecipientVOList, 'bcc') }}
        </div>
      </div>
      <div class="info_item border">
        <div class="title">
          <div class="label"><span>{{ $t("common.subject") }}</span></div>
        </div>
        <div class="cnt">{{ currentMail.mailBody?.subject }}</div>
      </div>
      <div class="info_item border">
        <div class="title">
          <div class="label"><span>{{ $t("common.date") }}</span></div>
        </div>
        <div class="cnt">{{ currentMail.mailBody?.sentDate }}</div>
      </div>
      <!--      {{ JSON.parse(route.params.flag).isExtend }}-->
      <div class="info_item" v-if="rowItem.isExtend===1">
        <div class="title">
          <div class="label"><span>{{ $t("common.fj") }}</span></div>
        </div>
        <div class="cnt" style="margin-right: 10px;">
          {{ currentMail.mailExtendList?.length }}个
          <template v-if="currentMail.mailExtendList.length">
            (
            <svg v-if="currentMail.mailExtendList.length>1" t="1711952641079" class="icon" viewBox="0 0 1024 1024"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="11851" width="20" height="20">
              <path
                  d="M544 128h-288c-19.2 0-32 12.8-32 32v704c0 19.2 12.8 32 32 32h512c19.2 0 32-12.8 32-32v-480h-192c-35.2 0-64-28.8-64-64v-192z m64 12.8v179.2h179.2l-179.2-179.2z m-352-76.8h352c9.6 0 16 3.2 22.4 9.6l224 224c6.4 6.4 9.6 12.8 9.6 22.4v544c0 54.4-41.6 96-96 96h-512c-54.4 0-96-41.6-96-96v-704c0-54.4 41.6-96 96-96z m64 352h160c19.2 0 32 12.8 32 32s-12.8 32-32 32h-160c-19.2 0-32-12.8-32-32s12.8-32 32-32z m0 128h384c19.2 0 32 12.8 32 32s-12.8 32-32 32h-384c-19.2 0-32-12.8-32-32s12.8-32 32-32z m0 128h384c19.2 0 32 12.8 32 32s-12.8 32-32 32h-384c-19.2 0-32-12.8-32-32s12.8-32 32-32z"
                  fill="#8a8a8a" p-id="11852"></path>
            </svg>
            <FileIcon v-else
                      :fileExtend="currentMail.mailExtendList[0].url?.substring(currentMail.mailExtendList[0].url.lastIndexOf('.')+1).toLocaleLowerCase()"
                      :fileUrl="currentMail.mailExtendList[0].url" :size="25"
                      :isAbbreviation="true"></FileIcon>
            <a @click="_scrollTo('file')">{{ currentMail.mailExtendList[0].name }}</a>
            )
          </template>
        </div>
        <el-button text size="small" @click="handleRefresh">
          <el-icon>
            <Refresh/>
          </el-icon>
          <span>{{ $t("detail.refresh.btn") }}</span>
        </el-button>
      </div>
      <div class="info_item border">
        <div class="title">
          <div class="label"><span>{{ $t("common.size") }}</span></div>
        </div>
        <div class="cnt">{{ formatSize(currentMail.contentSize) }}</div>
      </div>
    </div>
    <div class="content">
      <!--      {{ currentMail.mailExtendList}}-->
      <div v-loading="iframeLoad" style="width: 100%;height: 100%">
        <iframe frameborder="0" id="pageframe"
                :class="iframeLoad?'':'loaded'"
                :srcdoc="iframeContent" style="display:none;width: 100%;height: 100%"
                onload="this.style.display='block';"
        >
          <p>Your browser does not support preview features, please download Google browser or other modern
            browsers.</p>
        </iframe>
      </div>
      <!--            <EmailBrowser ref="EmailBrowserRef" :content="iframeContent" :isPageEqResize="true"/>-->
      <div class="files" id="file" v-if="currentMail.mailExtendList.length">
        <div class="tips"
             style="padding-left: 25px !important;height: max-content;display: flex;align-items: center;padding-top: 12px">
          <div style="display: flex;align-items: center">
            {{ $t("displace.email.numberAttachments", currentMail.mailExtendList.length) }}
          </div>
          <view style="margin-left: 20px;color: #0a84ff;cursor: pointer" @click="downloadZip">
            {{ $t("package.download") }}
          </view>
        </div>
        <div class="file_list">
          <div class="file" v-for="(item, index) in currentMail.mailExtendList" :key="index">
            <div class="file_info">
              <div class="img_wrap">
                <FileIcon :fileExtend="item.url?.substring(item.url.lastIndexOf('.')+1).toLocaleLowerCase()"
                          :fileUrl="item.url"
                          :isAbbreviation="true"></FileIcon>
              </div>
              <div class="name">
                <div class="t">{{ item.name }}</div>
                <div class="size">{{ formatSize(item.extendSize) || '-' }}</div>
              </div>
            </div>
            <div class="download_wrap">
              <a @click="handleDownload(item.url, item.name)">{{ $t("common.download") }}</a>
              <a @click="handleDetailFile(item)"
                 v-if="suportFile.includes(item.url?.substring(item.url.lastIndexOf('.') + 1).toLocaleLowerCase())">{{
                  $t("common.preview")
                }}</a>
              <a @click="handleReSave(item)">{{ $t("common.cloud.Rollover") }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReturnEmails ref="emailsReturn" v-if="dialogVisible" v-model="dialogVisible" @close="close"
                  @createCustomer="createdNewCustomer" :targetEmail="activeItemRow"

    ></ReturnEmails>
    <CustomerCreated ref="customerCreated" v-if="dialogVisibleCreatedMessage"
                     v-model="dialogVisibleCreatedMessage" @close="closeCreated" :innerDialog="dialogVisible"
                     @changeInnerDialog="close"></CustomerCreated>

    <BlackConfirmModal :showFlag="blackModalShow" :list="currentMail.mailBody?.from ? [currentMail.mailBody?.from] : []"
                       @succ="handleBlackSucc" @cancel="handleBlackCancel"/>
  </div>
</template>

<script>
import {computed, nextTick, onMounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getMailDetails, tagMail, delMail, downZip} from '../../api/index';
import {requestMailAccessory} from '../../api/mail';
import {
  audioFormat,
  downloadFile,
  excelFormat,
  formatSize,
  imgFormat,
  pdfFormat,
  pptFormat,
  textFormat, videoFormat,
  wordFormat
} from '../../utils/file';
import {ElMessage} from 'element-plus';
import EmailBrowser from '../components/EmailBrowser.vue';
import BlackConfirmModal from '../components/BlackConfirmModal.vue';
import MyTitles from "@/views/Customer/Detail/MyTitles.vue";
import Dialog from "@/components/Dialog.vue";
import Forms from "@/views/Customer/CreateCheck/Forms.vue";
import CusReturn from "@/views/components/cusReturn.vue";
import {i18n} from "@/lang";
import {
  addMailCustomerContact,
  exportCustomerTemplate,
  getMailCustomerContactByCustomerId,
  requestAllCustomer
} from "@/api/customer";
import dayjs from "dayjs";
import {useStore} from "vuex";
import {emailReg, htmlReg, TIME_OUT} from "@/utils/statusParams";
import {Plus} from "@element-plus/icons-vue";
import ReturnEmails from "@/components/DiaPops/Returns/ReturnEmails.vue";
import CustomerCreated from "@/components/DiaPops/Returns/CustomerCreated.vue";
import FileIcon from "@/components/File/FileIcon.vue";
import {throttle} from "@/utils/mylodash"
import {api as viewerApi} from "v-viewer";
import {addImgOnErrorAttribute, containsHtmlTags, convertLinksToHTML, convertToHTML} from "@/utils/iframe";

const commandList = [1, 0, 1, 0];

export default {
  computed: {
    Plus() {
      return Plus
    },
    suportFile() {
      return [...imgFormat, ...pdfFormat, ...wordFormat, ...excelFormat, ...excelFormat, ...videoFormat, ...audioFormat, ...pptFormat, ...textFormat]
    }
  },
  methods: {
    imgFormat() {
      return imgFormat
    },
    formatSize,
    emailReg() {
      return emailReg
    }
  },
  components: {
    FileIcon,
    CustomerCreated, ReturnEmails,
    CusReturn,
    Forms, Dialog, MyTitles,
    EmailBrowser,
    BlackConfirmModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute();
    const router = useRouter();
    const id = computed(() => route.params.id);
    const rowItem = computed(() => JSON.parse(route.params.flag))
    const iframeContent = ref('');
    const EmailBrowserRef = ref(null);
    const blackModalShow = ref(false);
    const currentMail = ref({
      mailExtendList: [],
    });

    // 对话框
    const dialogVisible = ref(false);
    // 对话框中的建档
    const dialogVisibleCreatedMessage = ref(false);
    const forms = reactive({email: '', customerName: '', merge: true, contactName: ''})
    const optionsCustomers = ref([])
    // 联系人
    const contactPerson = [{
      label: i18n.global.t('common.email.existingContacts'),
      value: true
    }, {label: i18n.global.t('common.email.newContacts'), value: false}];
    // 手动筛选联系人列表
    const contactList = ref([])
    // 联系人姓名
    // const contactName = ref([])
    // 表单校验规则
    const rules = reactive({
      name: [{required: true, message: i18n.global.t('sys.tip.input.name'), trigger: ["blur", "change"]}],
    });
    const teamId = computed(() => store.state.email.teamID);
    const ruleForm = computed(() => store.state.customer.ruleForm);
    // 区域列表
    const areaList = computed(() => store.state.customer.areaList);
    // 客户等级
    const tableDataLevel = computed(() => store.state.cusList.tableData);
    // 保存客户信息
    const childRef = ref(null)
    const targetEmailDetail = ref(null)
    const iframeLoad = ref(false);

    async function getDetail() {
      try {
        iframeLoad.value = true
        const result = await getMailDetails({id: id.value});
        let content = result.result?.mailBody?.content;
        targetEmailDetail.value = result.result?.mailBody;
        const insertionContent = `<script>
            function errorImage(img) {
                console.log(img,'img')
                img.onerror = null;
                img.src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR42mP4//8/AAX+Av4zEpUUAAAAAElFTkSuQmCC"
            }
        <\/script>`;
        if (!content) {
          iframeContent.value = ''
        } else {
          if (containsHtmlTags(content)) {
            const replacedHtmlString = addImgOnErrorAttribute(content)
            console.log('content', replacedHtmlString);
            const res = replacedHtmlString.replace(/(<html[^>]*>)/, '$1' + insertionContent);
            iframeContent.value = res;
          } else {
            // 纯图片需要直接展示
            if (content.indexOf('https://s3gw.cmbimg.com') !== -1) {
              iframeContent.value = content;
            } else {
              iframeContent.value = convertLinksToHTML(convertToHTML(content))
            }
          }
        }
        iframeLoad.value = false
        currentMail.value = result.result;
      } catch (e) {
        iframeLoad.value = false
      }
    }

    async function getMailAccessory() {
      const result = await requestMailAccessory({id: id.value});
      console.log(result, '获取附件');
    }

    async function requestDelMail(flag) {
      const result = await delMail({
        type: flag, // 0 还原  1 已删除 2彻底删除
        mailIdList: [currentMail.value.mailBody.id],
        originalPosition: route.params.type - 0,
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      if (flag === 0) {
        ElMessage.success('邮件已还原');
        getDetail();
      }
      if (flag === 1 || flag == 2) {
        ElMessage.success('邮件删除成功！');
        router.replace('/email/inbox');
      }
    }

    function handleBlack() {
      blackModalShow.value = true;
    }

    function handleBlackSucc() {
      blackModalShow.value = false;
      router.replace('/email/inbox');
    }

    function handleBlackCancel() {
      blackModalShow.value = false;
    }

    async function requestTagMail(tag, type, val) {
      const result = await tagMail({
        [type]: tag,
        mailIdList: [currentMail.value.mailBody.id],
      });
      console.log('标记邮件', result);
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      let tips = val === 0 ? '邮件已设为已读状态' : val === 1 ? '邮件已设为未读状态' : val === 2 ? '设置星标邮件成功！' : '取消星标邮件成功！';
      ElMessage.success(tips);
      // getMailList();
    }

    function handlePrint() {
      EmailBrowserRef.value.printPage();
      console.log(EmailBrowserRef.value, 'dom')
    }

    function formatText(list, type) {
      console.log(list, type);
      if (!list || !list.length) {
        return '';
      }
      const infoMap = list.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item.mail);
        return prev;
      }, {});
      return infoMap[type] ? infoMap[type].join(',') : '';
    }

    function handleReply(type = '') {
      console.log(currentMail, 'currentMail');
      if (!currentMail.value.mailBody?.id) {
        return;
      }
      const targetType = route.params.type
      const targetFlag = JSON.parse(route.params.flag)

      //route.params.type 代表哪个入口进来:1.收件箱,2:草稿箱,3:发件箱 mailType
      if (targetType !== '2' && type === 'resend') {
        const query = {
          id: currentMail.value.mailBody.id,
          flag: targetFlag.flag,
          type,
          mailType: route.params.type,
          customerId: targetFlag.customerId
        };
        router.push({
          path: '/email/write_mail',
          query,
        });
      } else {

        const query = {
          id: currentMail.value.mailBody.id,
          type,
          mailType: route.params.type,
          customerId: targetFlag.customerId
        }
        if (type === "") {
          delete query.type;
        }
        console.log(query, 'payload')
        router.push({
          path: '/email/write_mail',
          query,
        });
      }
    }

    function handleCommand(val) {
      console.log(val);
      const type = val === 0 || val === 1 ? 'isRead' : 'isStress';
      requestTagMail(commandList[val], type, val);
    }

    function handleDownload(url, fileName) {
      console.log(url.replace(/^http/, "https"), 'handleDownload')
      fetch(url.replace(/^http/, "https"))
          // record.doc_url为文件url地址
          .then((res) => res.blob())
          .then((blob) => {
            downloadFile(blob, fileName);
          })
          .catch((error) => {
            ElMessage.error('下载失败');
          });
    }

    function _scrollTo(id) {
      var _id = document.getElementById(id);
      document.querySelector('.view').scrollTo({
        top: _id.offsetTop,
        behavior: 'smooth'
      });
    }


    const close = () => {
      dialogVisible.value = false;
    }
    const closeCreated = () => {
      dialogVisibleCreatedMessage.value = false;
    }

    const createdNewCustomer = () => {
      dialogVisibleCreatedMessage.value = true
      store.commit('customer/setRuleForm', {
        zoneCode: "",
        customerCode: "",
        zoneAddress: "",
        customerName: "",
        setUpTime: "",
        economicNature: "",
        companyWebsite: "",
        companyAddress: "",
        phoneWayList: [],
        emailWayList: [],
        companyFax: "",
        companyRemark: "",
        customerLevel: "",
        customerType: "",
        customerTypeId: "",
        product: "",
        marketingRemark: " ",
        createBy: "",
        branchAdmin: "",
        mangerVOList: [{managerName: ''}]
      });
    }

    // 编辑邮件信息
    const activeItemRow = ref([])
    const emailsReturn = ref(null)
    // 归档
    const showReturn = async () => {
      dialogVisible.value = true
      dialogVisible.value = true
      activeItemRow.value = [{email: rowItem.value.from}]

      await nextTick();
      await emailsReturn.value.getAllCustomers();
    }
    onMounted(async () => {
      await getDetail();
      // 自动下载附件
      // 判断是否有附件如果有的话自动下载；如果没有附件则不需要下载
      console.log(targetEmailDetail.value.isExtend, 'targetEmailDetail.value')
      if (targetEmailDetail.value?.isExtend) {
        await getMailAccessory();
      }
      // nextTick(() => {
      //   EmailBrowserRef.value.render();
      // });
    })


    // 页面刷新
    const handleRefresh = () => {
      location.reload();
    }

    // 文件转存
    const handleReSave = throttle(async (item) => {
      console.log(item,)
      const payload = {
        id: "0",
        idList: [item.id]
      }
      await store.dispatch("cloud/copyEmailFileApis", payload)
    }, TIME_OUT)

    // 文件预览
    const handleDetailFile = (item) => {
      const {url, name} = item
      // 获取扩展名
      const fileExtend = name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase()
      console.log(item, imgFormat.includes(fileExtend), fileExtend, 'item')

      if (imgFormat.includes(fileExtend)) {
        // 图片预览
        showTargetFile(item)
      } else if (pdfFormat.includes(fileExtend)) {
        // pdf
        //预览
        showTargetPdf(item);
      } else if (wordFormat.includes(fileExtend) || excelFormat.includes(fileExtend) || pptFormat.includes(fileExtend)) {
        // /world/excel
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(item.url))
      } else if (textFormat.includes(fileExtend)) {
        showTargetText(item)
      } else if (videoFormat.includes(fileExtend)) {
        showTargetVideo(item)
      } else if (audioFormat.includes(fileExtend)) {
        showTargetAudio(item)
      } else {
        // alert('下载')
        handleDownload(item.url, item.name)
      }
    }


    const showTargetPdf = (row) => {
      console.log(row, 'row')
      let fullPath = `${location.origin}/showfile?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetText = (row) => {
      let fullPath = `${location.origin}/showtext?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetVideo = (row) => {
      // visitableVideo.value = true;
      let fullPath = `${location.origin}/showvideo?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetAudio = (row) => {
      let fullPath = `${location.origin}/showaudio?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}&title=${encodeURIComponent(row.name)}`;
      openNewWindow(fullPath)
    }


    const openNewWindow = (url) => {
      window.open(url, "_blank");
    };

    const showTargetFile = (row) => {
      // alert(row.name)
      let images = [];
      let initialViewIndex = 0;

      // 过滤出非文件夹的文件
      currentMail.value.mailExtendList.filter(_ => imgFormat.includes(_.name.substring(_.name.lastIndexOf(".") + 1).toLocaleLowerCase())).forEach((o, i) => {
        if (o.name === row.name) initialViewIndex = images.length
        images.push({src: o.url})
      })
      console.log(images, 'images')
      viewerApi({
        images,
        options: {
          initialViewIndex
        }
      })
    }


    const downloadZip = async () => {
      await downZip({mailId: route.params.id}, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], {type: 'application/zip;charset=UTF-8'}); // 使用 ZIP 文件的 MIME 类型
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        var timestamp = Date.now();
        link.setAttribute('download', 'attachment_' + timestamp + '.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href); // 清理创建的URL对象
      }).catch(error => {
        console.error('Error downloading file:', error);
      });
    };


    return {
      downloadZip,
      iframeLoad,
      handleDetailFile,
      handleReSave,
      handleRefresh,
      createdNewCustomer,
      activeItemRow,
      emailsReturn,
      close,
      closeCreated,
      iframeContent,
      EmailBrowserRef,
      currentMail,
      blackModalShow,
      handleDownload,
      formatText,
      handlePrint,
      handleReply,
      handleCommand,
      requestDelMail,
      handleBlack,
      handleBlackSucc,
      handleBlackCancel,
      rowItem,
      dialogVisible,
      showReturn,
      dialogVisibleCreatedMessage,
      forms,
      contactList,
      ruleForm,
      areaList,
      tableDataLevel,
      optionsCustomers,
      rules,
      childRef,
      route,
      _scrollTo,
    }
  }
}
</script>

<style lang="less" scoped>
@import "../Customer/less/detail.less";
@import "less/detail";

#pageframe {
  background: transparent;
}

iframe {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

iframe.loaded {
  opacity: 1;
}

</style>

