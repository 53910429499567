<template>
  <div id="login" class="fx-aj-c" @click="confirmSta = false">
    <!-- 注册 -->
    <div class="signUpCard">
      <img src="@/assets/logo.png" class="logoImg" @click="$router.push('/login')"/>
      <div class="titleBar">
        <p>{{ $t("common.forgetpassword") }}
          <span v-if="$route.query.type==='1'" style="font-size: 20px">【{{ $t('forget.password.person') }}】</span>
          <span v-else style="font-size: 20px">
            【{{ $t('forget.password.enterprise') }}】
          </span>
        </p>
        <span>{{ $t("other.text.forgetpassword.account.phone") }}</span>
      </div>
      <div class="inputBar tel-container">
        <div class="flex-center pointer flagareaC" style="word-break: initial">
          <CountrySelect @changeSelect="getSelectValue"></CountrySelect>
        </div>
        <el-input v-model="signUpInfo.phone" :placeholder="$t('sys.tip.input.phone')" @input="checkPhone"
                  type="number"/>
      </div>
      <div class="inputBar" style="margin-top: 20px;">
        <el-input v-model="signUpInfo.code" :placeholder="$t('sys.tip.input.sms.code')"
                  :maxLength="4"/>
      </div>
      <div class="wrapper" ref="wrapper">
        <div class="btn" ref="btn">
          <el-icon v-if="!sendFlag">
            <DArrowRight/>
          </el-icon>
          <el-icon v-else color="#67C23A">
            <SuccessFilled/>
          </el-icon>
        </div>
        <p class="text" ref="text">{{ $t('send.message.code') }}</p>
        <div class="bg" ref="bg"></div>
      </div>
      <div class="inputBar" style="position: relative">
        <el-input v-model="signUpInfo.password" :type="isTypePassword ? 'password' : 'text'"
                  :placeholder="$t('sys.tip.input.password')" @input="checkPassword" :minLength="8" :maxLength="18"
                  @keydown.native="keydown($event)"/>
        <el-icon class="passwordIcon" @click="changeType" v-if="!isTypePassword">
          <View/>
        </el-icon>
        <el-icon class="passwordIcon" @click="changeType" v-if="isTypePassword">
          <Hide/>
        </el-icon>
      </div>
      <div class="inputBar" style="position: relative">
        <el-input v-model="signUpInfo.configPassword" :type="isTypeConfirmPassword ? 'password' : 'text'"
                  :placeholder="$t('sys.tip.input.password.again')" @input="checkPassword" @blur="configPasswords"
                  :minLength="8"
                  :maxLength="18"
                  @keydown.native="keydown($event)"/>
        <el-icon class="passwordIcon" @click="changeTypeConfig" v-if="!isTypeConfirmPassword">
          <View/>
        </el-icon>
        <el-icon class="passwordIcon" @click="changeTypeConfig" v-if="isTypeConfirmPassword">
          <Hide/>
        </el-icon>
      </div>

      <!--          验证-->
      <!--      <el-button class="button" type="primary" size="medium" @click="verificationCode">人机验证</el-button>-->
      <div class="fx-j-c">
        <div class="confirmbar" @click="resetPassword"
             v-if="signUpInfo.phone && signUpInfo.password &&signUpInfo.configPassword && signUpInfo.code">
          {{ $t("common.update") }}
        </div>
        <div class="confirmbar_not" v-else>
          {{ $t("common.update") }}
        </div>
      </div>
    </div>
    <!-- 底部信息栏 -->
    <Footers></Footers>
  </div>
</template>
<script>
// import countryCodeSelector from 'vue-country-code-selector'
// import countrySelCom from '@/components/countrySelCom.vue'
import flag from '@/assets/flag/index.js'
import {sendForgetPhoneSms, resetfulpass} from '@/api/index.js';
import {ElMessage} from 'element-plus';
import CountrySelect from "@/components/CountrySelect/index.vue";
import Footers from '@/components/Footer'
import {i18n} from "@/lang"

export default {
  name: 'resetPassword',
  components: {
    CountrySelect,
    Footers
    // countryCodeSelector
    // countrySelCom
  },
  data() {
    return {
      flag,
      confirmSta: false,
      sendFlag: false,
      currentCountry: {
        countryEn: "China", country: "中国", prefix: "86", icon: "CN.png", id: "43", zoneCode: "CN"
      },
      value: 86,
      isPhone: false,
      isPassword: true,
      isTypePassword: true,
      isTypeConfirmPassword: true,
      isInput: false,
      isCode: false,
      signUpInfo: {
        phone: '',
        password: '',
        configPassword: '',
        code: '',
      },
      codeBtnWord: '',
      waitTime: 61,
      getCodeBtnDisable: false,
      isLoading: false,
      searchData: {
        ticket: 'ff',
        randStr: 'f',
      },
      phoneCode: ''
    };
  },
  computed: {
    isLangCN() {
      // return this._store('lang') == 'zh-CN'
      return true;
    }
  },
  mounted() {
    this.initSlider();
  },
  methods: {
    getSelectValue(val) {
      this.currentCountry = val;
    },
    goSignIn() {
      this.$router.push('/login');
    },
    // 人机验证
    verificationCode() {
      // 测试账号
      let captchaId = '194983649'
      let _this = this;
      // 腾讯滑块验证码appid生成一个滑块验证码对象
      var captcha = new TencentCaptcha(captchaId, function (res) {
        if (res.ret === 0) {
          // 页面上滑动正确，请求自己的业务接口
          // 记得把验证成功的票据和随机字符带到自己接口中去腾讯验证票据的真实性
          _this.searchData.ticket = res.ticket;
          _this.searchData.randStr = res.randstr
          // _this.getSearchResult()
          console.log(res, '腾讯云验证码')
          console.log(_this.searchData, '腾讯云验证码参数')
        }
      })
      captcha.langFun()
      // 滑块显示
      captcha.show()
    },
    initSlider() {
      var wrapper = this.$refs.wrapper;
      var btn = this.$refs.btn;
      var bg = this.$refs.bg;
      var text = this.$refs.text;
      var left = null;
      const self = this;
      btn.onmousedown = function (event) {
        console.log(event.clientX);
        if (!self.signUpInfo.phone) {
          ElMessage.error(i18n.global.t('forget.password.iphone'));
          return;
        }
        var downX = event.clientX;      //触发事件时 鼠标相对于浏览器的x距离
        document.onmousemove = function (e) {
          var moveX = e.clientX;
          console.log(moveX);        //触发事件时 鼠标相对于浏览器的x距离

          left = moveX - downX;      //点击down移动move 滑动的距离

          if (left < 0) {
            left = 0
          }
          if (left > wrapper.offsetWidth - btn.offsetWidth) {      //还需要减去btn的宽度
            left = wrapper.offsetWidth - btn.offsetWidth;
          }
          btn.style.left = left + 'px';
          bg.style.width = left + 'px';

          if (left >= wrapper.offsetWidth - btn.offsetWidth) {
            console.log(left, wrapper.offsetWidth, btn.offsetWidth);
            //ajax请求 判断是否可以登录成功
            wrapper.style.color = 'white';
            btn.onmousedown = null;    //防止 btn再次被点击
            document.onmousemove = null;
            document.onmouseup = null;
            console.log(self.$route)
            sendForgetPhoneSms({
              phone: self.signUpInfo.phone, phoneCode: self.currentCountry.prefix,
              type: self.$route.query.type
            }).then(result => {
              ElMessage.success(i18n.global.t('common.email.list.sendSuccess'));
              self.waitTime--;
              text.innerText = self.$t('displace.smscode.reacquire', {number: self.waitTime})
              self.sendFlag = true;
              const timer = setInterval(() => {
                if (self.waitTime > 1) {
                  self.waitTime--
                  text.innerText = self.$t('displace.smscode.reacquire', {number: self.waitTime})
                } else {
                  self.sendFlag = false;
                  btn.style.left = 0;
                  bg.style.width = 0;
                  wrapper.style.color = 'black';
                  clearInterval(timer)
                  text.innerText = i18n.global.t('send.message.code')
                  self.waitTime = 61;
                  self.initSlider();
                }
              }, 1000)
            })
          }
        }
      }

      // btn.onmousedown    //不能只监听滑块上的鼠标抬起; 在整个文档上鼠标抬起体验更好
      document.onmouseup = function (event) {
        document.onmousemove = null;
        if (left < wrapper.offsetWidth - btn.offsetWidth) {
          btn.style.left = 0;
          bg.style.width = 0;
          wrapper.style.color = 'black';
        }
        console.log('鼠标抬起' + event.clientX)
        document.onmouseup = null;
      }
    },
    changeConfirmE(e) {
      this.confirmSta = e
    },
    selectFlag(obj) {
      this.currentCountry = obj
      console.log(this.currentCountry)
    },
    // 禁止输入空格
    keydown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false
      }
    },
    phoneTimer() {

    },
    changeType() {
      this.isTypePassword = !this.isTypePassword
    },
    changeTypeConfig() {
      this.isTypeConfirmPassword = !this.isTypeConfirmPassword;
    },
    showInfo() {
    },
    checkPhone() {
      if (this.signUpInfo.phone) {
        this.isPhone = true
      } else {
        this.isPhone = false
      }
    },
    checkPassword() {
      if (this.signUpInfo.password.length >= 8 && this.signUpInfo.password.length <= 18) {
        this.isPassword = true
      } else {
        this.isPassword = false
      }
    },
    configPasswords() {
      if (this.signUpInfo.password !== this.signUpInfo.configPassword) {
        this.$message.error(this.$t('forgetPassword.configPassword'))
      }
    },
    checkCode() {
      if (this.signUpInfo.code.length == 4) {
        this.isCode = true
      } else {
        this.isCode = false
      }
    },
    resetPassword() {
      if (this.isLoading) return
      this.isLoading = true
      resetfulpass({
        phone: this.signUpInfo.phone,
        newPassword: this.signUpInfo.password,
        submitPassword: this.signUpInfo.configPassword,
        code: this.signUpInfo.code,
        phoneCode: this.currentCountry.prefix,
        type: this.$route.query.type,
      })
          .then((res) => {
            if (res.code !== 200) {
              ElMessage.error(res.message)
              return
            }
            ElMessage.success(res.message)
            this.$router.push('/login')
          })
          .catch(err => {
            this.signUpInfo.code = ''
            this.isCode = false
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper {
  position: relative;
  width: 340px;
  height: 34px;
  line-height: 34px;
  background-color: #f5f5f5;
  border: 1px solid var(--el-input-border-color, var(--el-border-color));
  // box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
  margin: 20px auto 0;
  border-radius: 4px;

  .bg {
    position: absolute;
    height: 100%;
    width: 0;
    background: #7ac23c;
    z-index: 1;
  }

  .text {
    position: absolute;
    width: 100%;
    margin: 0;
    font-size: 14px;
    text-align: center;
    z-index: 2;
  }

  .btn {
    position: absolute;
    top: 0;
    width: 40px;
    height: 32px;
    z-index: 3;
    // border: 1px solid #ccc;
    background: #fff center no-repeat;
    /***btn背景图片***/
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
}


#login {
  height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: #1890ff;
}

.el-input :deep(.el-input__wrapper) {
  background-color: #f5f5f5 !important;
  padding: 0;

  .el-input__inner {
    padding: 0 11px;
  }
}

input {
  height: 50px;
}

.infoBar {
  margin-top: 10px;
  // width: 343px;
  letter-spacing: 1px;
  font-size: 14px;
}

.ant-input:hover {
  background-color: #f5f5f5;
}

.signUpCard {
  position: relative;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  min-width: 400px;
  padding: 30px;

  .logoImg {
    position: absolute;
    width: 218px;
    height: 42px;
    left: 0;
    top: -50px;
  }
}

.confirmCard {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  width: 400px;
  padding: 30px;
}

.passwordIcon {
  position: absolute;
  right: 20px;
  top: 19px;
}

// 注册页面
.titleBar {
  p {
    margin-bottom: 10px;
    font-size: 24px;

    font-weight: 600;
    color: #333333;
    line-height: 24px;
    padding: 0;
  }

  span {
    margin-top: 10px;
    font-size: 14px;

    color: #333333;
  }
}

.inputBar {
  position: relative;
  margin-top: 20px;
  height: 50px;
}

.confirmbar {
  cursor: pointer;
  width: 340px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #d93737;
  color: white;
  border-radius: 25px;
  margin-top: 20px;
}

.confirmbar_not {
  width: 340px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f5f5f5;
  color: gray;
  border-radius: 25px;
  margin-top: 20px;
}

.loginbar {
  margin: 10px auto 0;
  text-align: center;
  font-size: 14px;

  .edit {
    color: #1890ff;
    cursor: pointer;
  }
}

.tel-container {
  display: flex;
  align-items: center;
}

:deep(.flag-list-box) {
  background: white !important;
}

// 底部版权信息等
.footerInfoBar {
  position: fixed;
  bottom: 60px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mr-10 {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.p-10 {
  padding: 10px;
}

.wrapClass {
  max-height: 70vh;
}

.flagareaC {
  font-size: 14px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flagC {
  font-size: 28px;
  margin-right: 5px;
}

:deep(.el-input__inner) {
  height: 52px !important;
  width: 100% !important;
  line-height: 52px !important;
}
</style>
