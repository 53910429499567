<template>
    <component
         :is="showFileType" 
        :src="fileUrl"
        style="height: 100vh"
        @rendered="renderedHandler"
        @error="errorHandler"/>
</template>

<script setup>
import VueOfficePdf from '@vue-office/pdf';
import VueOfficeDocx from '@vue-office/docx';
import VueOfficeExcel from '@vue-office/excel';

import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const fileUrl = ref("");
const fileType = ref("");
onMounted(() => {
  fileUrl.value = route.query.fileUrl;
  fileType.value = route.query.fileType;
})

const showFileType = computed(() => {
   if (fileType.value === 'pdf') {
    return VueOfficePdf;
  } else if (fileType.value === 'word') {
    return VueOfficeDocx;
  } else if (fileType.value === 'excel') {
    return VueOfficeExcel;
  } else {
    return;
  }
})

const renderedHandler = () => {
  console.log("渲染完成");
}

const errorHandler = () => {
  console.log("渲染错误");
}


</script>
