import request from "@/utils/request";


// 支付宝下单
export function aliPayTransactions(params, config) {
    const {orderId} = params
    return request.post(`/zfb/pay/create/${orderId}`, params, config);
}

// 查询订单状态
export function queryOrderStatusZfb(params, config) {
    const {orderNo} = params
    return request.get(`/zfb/pay/trade/query/${orderNo}`, params, config);
}