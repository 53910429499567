/**
 * @author fangzhicong
 * @deprecated 源码编辑的菜单扩展（主体功能实现）
 */

import '../style/index.less'
import '../style/sound-code.css'

import wangEditor from '@/utils/wangEditor/wangEditor.min'


const {$, BtnMenu} = wangEditor

export default class ClearFormat extends BtnMenu {
    constructor(editor) {
        const $elem = $(
            `<div class="w-e-menu"  data-title="清除样式">
                <svg t="1723014091110" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4169" width="16" height="16"><path d="M64 896h896v64H64zM936.05 425.18L663.45 152a80.52 80.52 0 0 0-56.74-24c-18.2 0-35.93 6.59-49.23 19.93L83.83 623.11c-28 28.07-26.13 75.83 4.11 106.14L190.48 832h450l299.69-300.68c27.98-28.07 26.12-75.83-4.12-106.14zM613.89 768H217l-83.78-84c-6-6.06-6.09-13.75-4.09-15.75L389.9 406.71l292.53 292.52z" p-id="4170"></path></svg>
              </div>`,
        )
        super($elem, editor)
    }

    clickHandler() {
        const {editor} = this
        let isSeleEmpty = editor.selection.isSelectionEmpty()
        let selectionText = editor.selection.getSelectionText()
        console.log(selectionText,'selectionText',editor.selection)
        if (!isSeleEmpty) {
            editor.cmd.do('insertHTML', selectionText)
        }
    }

    removeInlineStyles(html) {
        // 使用正则表达式匹配并移除所有行内样式
        return html.replace(/style="[^"]*"/gi, '');
    }

    tryChangeActive() {
    }
}
