export function truncateByBytes(str, maxBytes) {
    var bytesCount = 0;
    var result = '';

    for (var i = 0; i < str.length; i++) {
        var charCode = str.charCodeAt(i);
        if (charCode <= 0x007F) {
            bytesCount += 1;  // 英文字符占1个字节
        } else if (charCode <= 0x07FF) {
            bytesCount += 2;  // 拉丁字符（含有变音符号的字符等）占2个字节
        } else {
            bytesCount += 3;  // 中文字符占3个字节
        }

        if (bytesCount <= maxBytes) {
            result += str[i];
        } else {
            break;
        }
    }

    return result + (bytesCount > maxBytes ? '...' : '');
}