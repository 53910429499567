function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for Android, iOS, Windows Phone
    if (/android/i.test(userAgent)) {
        return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    }

    if (/windows phone/i.test(userAgent)) {
        return true;
    }

    return false;
}


// 判断 是 window 还是 mac
function getPosType() {
    var agent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
        console.log("这是windows32位系统");
        return 'win'
    } else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        console.log("这是windows64位系统");
        return 'win'
    } else if (isMac) {
        console.log("这是mac系统");
        return 'mac'
    } else {
        return "unknow"
    }

}

export {isMobile, getPosType}
