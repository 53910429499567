<template>
  <div class="chat">
    <div class="chat-tabs">
      <div @click="handleChatChange('message')" :class="['tab-item', chatTab === 'message' ? 'active' : '']">
        <el-badge :value="unreadCountConversation" :hidden="unreadCountConversation === 0" class="item" :badge-style="{ top: `0px !important` }">
          <img src="../../assets/message_ed@2x.png" v-if="chatTab === 'message'" alt="" />
          <img src="../../assets/message@2x.png" v-else alt="" />
        </el-badge>
      </div>
      <div @click="handleChatChange('book')" :class="['tab-item', chatTab === 'book' ? 'active' : '']">
        <el-badge :value="friendApplication.unreadCount" :hidden="friendApplication.unreadCount === 0" class="item">
          <img src="../../assets/book_ed.png" v-if="chatTab === 'book'" alt="" />
          <img src="../../assets/book.png" v-else alt="" />
        </el-badge>
      </div>
      <div @click="handleChatChange('group')" :class="['tab-item', chatTab === 'group' ? 'active' : '']">
        <img src="../../assets/group_ed.png" v-if="chatTab === 'group'" alt="" />
        <img src="../../assets/group.png" v-else alt="" />
      </div>
      <div class="tab-item add" @click="openDialog">
        <img src="../../assets/add.png" alt="" />
      </div>
    </div>
    <div class="content">
      <ConversationList v-if="chatTab === 'message'" />
      <ContactList v-else-if="chatTab === 'book'" />
      <GroupList v-else-if="chatTab === 'group'" />
    </div>
  </div>
  <Teleport to="body">
    <el-dialog v-model="addVisible"  v-if="addVisible" title="添加好友" width="500" custom-class="modal" @close="handleClose">
    <div class="tips" style="color: rgba(0, 0, 0, 0.85); font-size: 14px;line-height: 22px;margin: 8px 0;">
      用户手机号码/UID：
    </div>
    <div class="phone" style="margin: 8px 0;">
      <el-input v-model="phone" type="text" @keyup.enter="handleSearch" style="width: 100%;" placeholder="请输入手机号码/UID">
        <template #append>
          <el-button @click="handleSearch" :icon="Search" />
        </template>
      </el-input>
    </div>
    <div class="result_wrap" style="padding: 8px 0;">
      <el-radio-group v-if="searchUserProfileList.length !== 0" v-model="selectedVal">
        <el-radio v-for="userProfile in searchUserProfileList" :key="userProfile?.id" :value="userProfile?.id">
          <div class="result" style="display: flex;align-items: center;">
            <img :src="userProfile.avatar" width="40" height="40" style="border-radius: 50%;" class="avatar" alt="" />
            <div class="name" style="margin-left: 10px;color: #0D0E15;font-size: 14px;">{{ userProfile.userName }}</div>
          </div>
        </el-radio>
      </el-radio-group>
      <div v-else class="nobody">
        <span v-if="isShowNobody">该用户不存在，请检查UID/账号是否正确</span>
      </div>
    </div>
    <div class="tips" style="color: rgba(0, 0, 0, 0.85); font-size: 14px;line-height: 22px;margin: 8px 0;">
      发送添加朋友申请：
    </div>
    <el-input v-model="appayText" style="width: 100%; margin-top: 8px;" :autosize="{ minRows: 2 }" type="textarea"
      placeholder="我是王富贵" />
    <!-- <el-button class="add_btn" type="primary" @click="addFriendApplication">添加为好友</el-button> -->
    <div class="add_btn" :class="{ disabled: selectedVal === null }" @click="addFriendApplication">添加为好友</div>
  </el-dialog>
  </Teleport>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { ElBadge } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
import TencentCloudChat from '@tencentcloud/chat';
import ConversationList from '../ConversationList/index.vue';
import ContactList from '../ContactList/index.vue';
import GroupList from '../GroupList/index.vue';
import { getOutsideUser } from "@/api/groups";
import { useStore } from 'vuex';
import { watch } from 'vue';
import { chat } from '../../store/modules/chat';

const chatTab = ref('message');
const addVisible = ref(false);
const phone = ref(null);
const selectedVal = ref(null);
const appayText = ref('');
const store = useStore();

const conversationList = computed(() => store.state.chat.conversationList);
const friendApplication = computed(() => store.state.chat.friendApplication);
const searchUserProfileList = computed(() => store.state.chat.searchUserProfileList);
// 会话未读数
const unreadCountConversation = computed(() => conversationList.value.reduce((pre, cur) => pre + cur.unreadCount, 0))

const isShowNobody = ref(false);

function handleChatChange(type) {
  chatTab.value = type;
}
function handleClose() {
  addVisible.value = false;

  isShowNobody.value = false;
  phone.value = "";
  store.dispatch('chat/setSearchUserProfileList', []);
}

const openDialog = () => {
  addVisible.value = true;
}

async function handleSearch() {
  if (!phone.value) {
    return;
  }
  isShowNobody.value = true;
  store.dispatch('chat/searchUserProfileByUserID', phone.value);
}

/**
 * 添加为好友
 */
const addFriendApplication = () => {
  store.dispatch('chat/sendFriendApplication', {
    userID: selectedVal.value,
    wording: appayText.value,
  });
}

function onFriendListUpdate(event) {
  console.log('监听好友列表 ============', event);

}

function init() {
  chat.on(TencentCloudChat.EVENT.FRIEND_LIST_UPDATED, onFriendListUpdate);
}
onMounted(() => {
  init();
})
onUnmounted(() => {
  chat.off(TencentCloudChat.EVENT.FRIEND_LIST_UPDATED, onFriendListUpdate);
})
</script>
<style lang="less" scoped>
.chat {
  width: 280px;
  height: 350px;
  background: #FFFFFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 21, 41, 0.12);

  .chat-tabs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 56px;
    box-shadow: 0px 1px 6px 0px rgba(0, 21, 41, 0.12);

    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background: #D93737;
        }
      }

      img {
        width: 20px;
        height: 20px;
      }

      &.add {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .content {
    height: calc(100% - 56px);
    overflow-y: auto;
  }
}

::v-deep .modal {
  .tips {
    color: #D93737;
  }
}

body {
  .add_btn {
    width: 80px;
    height: 32px;
    background: #1890FF;
    border-radius: 2px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    margin: 20px auto 0;

    &.disabled {
      background-color: rgba(204, 204, 204, 1);
    }
  }
}
.nobody{
  text-align: center;
  padding: 12px 0;
}
</style>
