<template>
  <el-table
      ref="multipleTableRef"
      :data="tableData"
      style="width: 100%;margin-top: 20px"
      :v-loading="isLoading"
      :header-cell-style="{'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}">
    >
    <el-table-column
        v-for="item in table_columns"
        :key="item.key"
        :label="item.key"
        :prop="item.value"
    ></el-table-column>
    <el-table-column :label="$t('common.operate')" fixed="right" width="180">
      <template #default="{ row, column, $index }">
        <ConfirmDeletePopover
            :visible="row.popoverVisible"
            :width="164"
            :message="$t('confirm.to.delete.data')"
            @update:visible="(value) => (row.popoverVisible = value)"
            @confirm:delete="handleDelete(row, column, $index)"
        >
          <template #settings>
            <el-button link type="danger" @click="row.popoverVisible = true;">
              {{ $t('common.delete') }}
            </el-button>
          </template>
        </ConfirmDeletePopover>
      </template>
    </el-table-column>
    <template #empty>
      <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
    </template>
  </el-table>
</template>

<script setup>
import {ElTable} from "element-plus";
import {ref, defineProps, defineEmits} from "vue";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import {emptyUrl} from '@/utils/statusParams'

const emits = defineEmits()
const props = defineProps({
  tableData: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  table_columns: {
    type: Array, pe: Array,
    default: () => []
  }
})


const handleDelete = async (row) => {
  // row.popoverVisible = false;
  row.dialogVisible = true;
  emits('handleDelete', row)
}

</script>

<style lang="less" scoped>
:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
}
</style>