export const outputArray = (inputArray, string) => {
    return inputArray.map((item) => {
        return {
            content: item[string],
            isDefault: item.isDefault ? 1 : 0,
            id: item.id
        };
    });
};

export const inArray = (outputArray, string) => {
    return outputArray.map((item) => {
        return {
            [string]: item.content,
            isDefault: item.isDefault ? true : false,
            id: item.id
        };
    });
};

export function compareAndModify(obj1, obj2) {
    // 检查phones数组
    obj2.phones.forEach((phone2, index2) => {
        // const phone1 = obj1.phones[index2];
        // if (phone1) {
        //
        // }else{
        //     delete phone2.id;
        // }
        // if (phone1 && phone1.isDefault !== phone2.isDefault) {
        //     delete phone2.id;
        // }
        // phone2.content = phone2.phoneNumber;
        // delete phone2.phoneNumber;
        // phone2.isDefault = phone2.isDefault ? 1 : 0;

        const phone1 = obj1.phones.find((phoneNumber) => phoneNumber.id === phone2.id);
        if (phone1) {
        } else {
            delete phone2.id;
        }
        phone2.content = phone2.phoneNumber;
        delete phone2.phoneNumber;
        phone2.isDefault = phone2.isDefault ? 1 : 0;
    });

    // 检查emails数组
    obj2.emails.forEach((email2, index2) => {
        const email1 = obj1.emails.find((email) => email.id === email2.id);
        if (email1) {
            // if (email1.email !== email2.email) {
            //     if(email1.id){
            //
            //     }else{
            //
            //     delete email2.id;
            //     }
            // }
        } else {
            delete email2.id;
        }
        email2.content = email2.email;
        delete email2.email;
        email2.isDefault = email2.isDefault ? 1 : 0;
    });

    // 删除content为空的项
    obj2.phones = obj2.phones.filter(phone => phone.content !== "");
    obj2.emails = obj2.emails.filter(email => email.content !== "");

    return obj2;
}


export function updateIsDefault(array) {
    // 判断数组长度是否大于等于1，确保有第一项
    if (array.length >= 1) {
        // 将第一项的 isDefault 改为 0
        array[0].isDefault = 1;
    }
    return array;
}