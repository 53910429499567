import request from "@/utils/request";

//统计团队的所有数据
export function getTeamAllData(params, config) {
    return request.get('/front/index/statisticsAllTeamData', params, config)
}


//统计团队数据
export function getTeamData(params, config) {
    return request.get('/front/index/statisticsTeamData', params, config)
}


// 统计当前团队的数据
export function getCurrentTeamData(params, config) {
    return request.get('/front/index/statisticsCurrentTeamData', params, config)
}
