<template>
  <div class="header">
    <div class="tab flex1 fx-j-c">
      <div style="width: 400px"></div>
      <div class="tab flex1 fx-j-c">
        <div
            class="opt pointer fx-aj-c"
            :class="{ b: tabPath === o.path }"
            v-for="(o, i) in tabList"
            :key="i"
            @click="tabChange(o)"
        >
          <img v-if="tabPath === o.path" :src="o.src"/>
          <img v-else :src="o.srcb"/>
        </div>
      </div>
      <div data-v-2b3b0c9a="" class="fx-j-e" style="width: 410px">
          <div class="dropdown">
              <div class="dropdown-trigger"
                   @click.stop="toggleDropdown"
                   style="margin-right: 20px; position: relative">
                <img
                    style="height: 40px; width: 40px"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAAXNSR0IArs4c6QAAAMZQTFRFAAAA////////////////////////9vb29/f39/f3+Pj49/f3+Pj49fX19vb29fX19vb29vb29vb29vb29vb29vb29vb29vb29fX19vb29fX19fX19vb29fX19fX19fX19vb29vb29vb29vb29fX19fX19fX19vb29vb2AAAAAgICBgYGDQ0NGBgYICAgLy8vRUVFTExMUlJSeXl5enp6hoaGiIiIlZWVo6Ojra2tvr6+x8fH1NTU4uLi5eXl7+/v9PT09fX1PL5yJAAAACl0Uk5TAAEGBwkSFBweHyc/RU1SaGxtcXKPkpOUnbG6u8XR0tXd3+Ll7O/x+f4CaWuJAAACVUlEQVRYw8WZaVvCMAzHKw482OS+YVAZwrxPPKZovv+XEp3gsTTtuvD4f7k++T3tmiZpKgStgltudn0ZTKeB9LvNslsQ9nK8+mAGfzQb1D3HhrZVbB+BQkft4lZK3HZpBKRGpe00a62OQatx1XjlngQjSc8It9cBY3V29byDCaTQ5ECDyzUgpRo5ipfvQ2r182rezhAsNNxR8fYPwUqH+4r5WfKWRHSO+SFYa4j8x1wfMqif3OsGZFIj4c+QUX88fG+SFTj5fQo7kFmdX/EFGPQj9jiSAyi/42MNWFRdx/sxD3C8ygolYFLpK7+NuICjOBcWgU3FT2BbNfzycHeL6O7hRWXR/vQZRT5/vj4OFTq+flZUAI7aqR9PQ0Knj2rnrqMjTychqZMn1Ky+BA6wgdfzUKPzV8xusKzXZtjAfajVPWY3KwgXnfqlHniJGrqign4/0wPPUMOyaKHfY4+5iN6SQ2/RRew7qGFL9NDv8SQi3DeieBQd6wmfAC5w4IIA+kISwDkOnBNAKQICGF7dJI/yzVVIAAMxpYCkUMMpP9B0yeuV0sAgxabMTYAyhdssTIB+CseOTIBdxdELE0dvdeK+wzZq2BRl211WBQfXFqgKX3iADe0DLJ4C9DxlCsCTlJZHJCnPBkilUTTR0zg60aOlyC0hXSnCXyyxl3PsBSd/SSyqzEU7/7WC/eLDfzXjvzyyX2/5L+D8LQL2JgZ/m4W/EcTfquJvpvG3+/gbkhtomfI3dT/iY82k7Vxz/q8xvoHW/QYeF9bPH5VWb/X80WtVtM8f7xamo1tP6vw2AAAAAElFTkSuQmCC"
                    class="icon" />
              </div>
              <transition name="fade">
                  <div class="dropdown-menu" v-show="visible">
                    <Chat/>
                  </div>
              </transition>
          </div>
        <!-- <el-dropdown trigger="click" :triggerKeys="[]"  style="margin-top: -3px !important;width: 60px;cursor: pointer">
          
          <template #dropdown>
            
          </template>
        </el-dropdown> -->
        <el-dropdown trigger="click" style="margin-top: -3px !important;width: 60px;cursor: pointer">
          <div
              data-v-2b3b0c9a=""
              class="dropdown-trigger"
              style="margin-right: 15px; position: relative"
          >
            <img
                style="height: 40px; width: 40px"
                data-v-2b3b0c9a=""
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAAXNSR0IArs4c6QAAAVNQTFRFAAAA////////////////////////9vb29/f39/f3+Pj49/f3+Pj49fX19vb29fX19vb29vb29vb29vb29vb29vb29vb29vb29fX19vb29fX19fX19vb29fX19fX19fX19vb29vb29vb29vb29fX19fX19fX19vb29vb2AAAAAQEBAgICAwMDBAQEBQUFCwsLDg4ODw8PEBAQEhISExMTFxcXGBgYIiIiJSUlKCgoKysrLS0tMTExODg4OTk5PDw8QkJCSUlJSkpKS0tLTk5OUVFRV1dXWFhYX19fYWFhYmJiZGRkaGhobW1tc3Nzfn5+f39/gICAhoaGiIiIioqKj4+PmZmZp6enqampq6urrq6uubm5v7+/wcHBxsbGx8fHyMjIycnJy8vLzc3N0tLS1NTU29vb3t7e4ODg5OTk7e3t7+/v8PDw8fHx8vLy9PT09fX1MYtzuwAAACl0Uk5TAAEGBwkSFBweHyc/RU1SaGxtcXKPkpOUnbG6u8XR0tXd3+Ll7O/x+f4CaWuJAAAClUlEQVRYw8WZ6VvTQBDGl5IWIQ3pfR8Eei0g4oXifZ+oKOIt3goeMP//J0tLK7Q7szmmj+/XzP6ebDKZmX0jBK1INJYpVJxas1lzKoVMLBoR/mVYqXILBtQqpyzDD23MzDUAUSNnjnnEjdszQGrGHvey18QcaDWXcL1zywFXcixXuMk8uFb+mJ43XQcPqk9rcKE0eFQ6RPHCJfCsUhjnTVTBh6oTGG9qFnxpdgq5P5+8NlF5j+Eq+FZV8RxDJQig0vC7TkMgpYfyGQJqIMMn60GB9aNfYR4CK3+kvgCDDtUew+EAOv/qYxJYlOjXe019/rZx/dzy0sqltZe/6Rre6wo2Gfbx5rzsaeXpLyrUPuhvVD/ae3JcHtbqB6pzdXuhSfEeyAGdfEeEmx1gjohYl0M68wMPz3VypoEHfFkaBsrbeHzD0CT1QwVPLnynkztFAE+pgHITX5BqA8v45W0lTz7CV5Tb81qLSEE18C6+ohURUWLHW2rgHWJJVMR5gTGR5QVmRZEXWBQVXmBFOLxAR9R4gTXR5AU2+YHsW6Zeyms18Cr5Uoi02b2gBso3VNoQib2J8OR5vPsViE9v5zQGlBvoooyIodfWUJ5c3saLA1q+Pi/iQHkfL19ogb1G8OT8FlpgsRbwSpK6gbYArEk9poELu2iTsnzd4UW8jWKNfv0sjlu88hVv9OgosreD6ic1ipDDUlefnnX0Qhto6se5LrC70Vu6uINxTjNw7ute58G914XZLkdigD/PV09cfqu1NfpGSYJ5aOc/VrAffPiPZvyHR/bjLf8BnN8iYDcx+G0WfiOI36riN9P47T5+Q3IElim/qbtfH5NubOek8f+M8RFY9yP4udD//RHPFnu/P4rZuPb3x1/0Rpu322bkPAAAAABJRU5ErkJggg=="
                class="icon"
            />
            <el-badge :value="allDataTotal" v-show="allDataTotal!==0" class="items_message">
            </el-badge>
            <span
                data-v-2b3b0c9a=""
                class="ant-badge"
                style="zoom: 0.8; position: absolute; top: 25px"
            ><a data-v-2b3b0c9a="" href="#"></a
            ><!----></span
            >
          </div>

          <template #dropdown>
            <div class="boxs">
              <div class="tops_side">
                <div class="top_message">{{ $t('sys.notice.unreadas') }}</div>
                <el-button link style="color: rgba(0, 0, 0, .65);font-size: 12px;cursor: pointer"
                           @click="handleClickIsRead">
                  {{ $t('common.cds41') }}
                </el-button>
              </div>
              <div class="inner_boxs">
                <div class="empty" style="padding-bottom: 60px" v-if="resultTargetDataList.length===0">
                  {{ $t('common.notice.nomore') }}
                </div>
                <!--here-->
                <template v-else>
                  <Message :resultTargetDataList="resultTargetDataList" @changePages="changePage"
                           @getUnReads="getUnReadsList">
                    <template #loadings>
                      <div style="display: flex;justify-content: center;width: 100%;"
                           v-loading="allNewDataIsLoading"></div>
                    </template>
                  </Message>
                </template>
                <div class="item_box">
                  <el-button link type="primary" style="padding: 12px 0px" @click="toAllNotify">
                    {{ $t('common.notice.viewall') }}
                  </el-button>
                </div>
              </div>
            </div>
          </template>
        </el-dropdown>
        <el-dropdown trigger="click" style="margin-top: -3px !important;cursor: pointer">
          <div data-v-2b3b0c9a="" class="dropdown-trigger">
            <el-avatar
                v-if="userInfo.avatar"
                :src="userInfo.avatar"
            />
            <el-avatar v-else :size="40"
            >
              <img style="height: 18px; width: 18px" :src="require('@/assets/default/avatar.png')"/>
            </el-avatar>
          </div>
          <template #dropdown>
            <div class="tops">
              <div>
                <el-avatar v-if="userInfo.avatar"
                           :src="userInfo.avatar"
                           :size="54"
                />
                <el-avatar v-else
                           :size="54"
                >
                  <img style="height: 25px; width: 25px" :src="require('@/assets/default/avatar.png')"/>
                </el-avatar>
              </div>
              <div class="username">{{ userInfo.userName }}</div>
              <div class="ids" v-if="userInfo.guid">ID:{{ userInfo.guid }}</div>
              <div>
                <el-button @click="goToUserCenter" type="primary" size="small" round plain
                >{{ $t('common.person.manageMyAccount') }}
                </el-button
                >
              </div>
            </div>
            <el-dropdown-menu>
              <el-dropdown-item @click="goToStation">
                <div class="items">
                  <div class="left_text">
                    <span><img src="@/assets/q1.png"/></span><span class="texts">{{ $t('common.seat') }}</span>
                  </div>
                  <div class="right_text">
                    <span>
                      {{ $t('common.person.remainG', {count: mailWorkPosition.freePosition}) }}
                    </span>
                    <el-icon>
                      <ArrowRightBold/>
                    </el-icon>
                  </div>
                </div>
              </el-dropdown-item>
              <!--              存储-->
              <el-dropdown-item @click="goToStoreDetails">
                <div class="items">
                  <div class="left_text">
                    <span><img src="@/assets/q2.png"></span><span class="texts">{{ $t('common.space') }}</span>
                  </div>
                  <div class="right_text">
                    <span> {{
                        spaceSizeFormat(spaceSize.fileCloudLocation)
                      }}/{{ spaceSizeFormat(spaceSize.effectiveLocation) }}</span>
                    <el-icon>
                      <ArrowRightBold/>
                    </el-icon>
                  </div>
                </div>
              </el-dropdown-item
              >
              <el-dropdown-item @click="goToSetting">
                <div class="items">
                  <div class="left_text">
                    <span class="sp_out"><img height="18" src="@/assets/usercenter/setting.svg"/></span><span
                      class="texts">{{ $t('common.setting') }}</span>
                  </div>
                  <div class="right_text">
                    <el-icon>
                      <ArrowRightBold/>
                    </el-icon>
                  </div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item @click="goToRepairOrder">
                <div class="items">
                  <div class="left_text">
                    <span class="sp_out"><img height="18" src="@/assets/usercenter/message.svg"/></span><span
                      class="texts">{{ $t('common.cds42') }}</span>
                  </div>
                  <div class="right_text">
                    <el-icon>
                      <ArrowRightBold/>
                    </el-icon>
                  </div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item @click="handleLogout">
                <div class="items">
                  <div class="left_text">
                    <span class="sp_out"><img height="18" src="@/assets/usercenter/signout.svg"/></span><span
                      class="texts">{{ $t('common.exit') }}</span>
                  </div>
                  <div class="right_text">
                    <el-icon>
                      <ArrowRightBold/>
                    </el-icon>
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div data-v-2b3b0c9a="" style="width: 50px"></div>
      </div>
    </div>
    <CreateTeamPop v-if="visitable" v-model="visitable" @close="close"/>
    <Dialog
        v-model="dialogVisibleTips"
        :title="$t('sys.team.info')"
        :width="'400'"
        :dialogVisible="dialogVisibleTips"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (dialogVisibleTips = val)"
    >
      <template #content>
        <div class="inners_warning">
          <div>
            <el-icon style="font-size: 25px;color: #448ef7">
              <Warning/>
            </el-icon>
          </div>
          <div style="font-size: 16px;">{{ $t('common.p2h4') }}</div>
        </div>
      </template>
      <template #footer>
        <div class="setting_center">
          <el-button type="primary" @click="dialogVisibleTips = false">{{ $t('common.data.know') }}</el-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {useStore} from "vuex";
import home from "@/assets/img/tab/home.png";
import homeB from "@/assets/img/tab/home-b.png";
import email from "@/assets/img/tab/email.png";
import emailB from "@/assets/img/tab/email-b.png";
import file from "@/assets/img/tab/file.png";
import fileB from "@/assets/img/tab/file-b.png";
import {useRoute, useRouter} from "vue-router";
import CreateTeamPop from "../DiaPops/CreateTeamPop.vue";
import Dialog from "@/components/Dialog.vue";
import {ArrowRightBold, Back} from "@element-plus/icons-vue";

import {conMessage, tzIcons} from "@/utils/mes/messageMap";
import Message from "@/components/Message"
import {ElMessage} from "element-plus";
import {spaceSizeFormat} from "../../utils/file";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import Chat from '../Chat/index.vue';
import {i18n} from "@/lang";

const store = useStore();

export default {
  methods: {spaceSizeFormat},
  components: {ArrowRightBold, Back, Dialog, CreateTeamPop, Message, Chat},
  setup() {
    const userInfo = computed(() => store.state.user.userInfo);
    const isEnterpriseMainUser = computed(() => store.getters["user/isEnterpriseMainUser"])
    const isEnterpriseChildUser = computed(() => store.getters["user/isEnterpriseChildUser"])
    // const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // 工位信息
    const dialogVisibleTips = ref(false);
    const mailWorkPosition = computed(() => store.state.user.mailWorkPosition)
    const teamId = computed(() => store.state.email.teamID)
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const visitable = ref(false);

    const teamList = computed(() => store.state.email.teamList);

    const tabPath = computed(() => {
      const path = route.path.split("/")[1];
      return `/${path}`;
    });


    // 通知图标类型

    // heander 最新消息
    const newData = computed(() => store.state.message.newData)
    const resultTargetDataList = computed(() => {
      if (newData.value.length !== 0) {
        return conMessage(newData.value);
      } else {
        return newData.value;
      }
    })


    const visible = ref(false);
    const dropdownRef = ref(null);

    function toggleDropdown(event) {
      console.log(111);
      visible.value = !visible.value;
      event.stopPropagation();
    }

    function handleClick() {
      visible.value = false;
    }


    const tabList = ref([
      {
        src: home,
        srcb: homeB,
        path: "/dashboard",
        query: {},
      },
      {
        src: email,
        srcb: emailB,
        path: `/email`,
        cacheName: "",
        query: {},
        params: {
          teamId: teamId.value,
        },
      },
      {
        src: file,
        srcb: fileB,
        path: "/cloud",
        cacheName: "",
        query: {},
        params: {pg: "f"},
      },
    ]);

    // 通知
    function toAllNotify() {
      router.push('/notificationList/systemNotification')
    }

    const handleClickIsRead = async () => {
      if (resultTargetDataList.value.length === 0) {
        ElMessage.warning(i18n.global.t('sys.notice.unread'));
        return
      }
      await store.dispatch("message/isReadAllApis")
      await store.dispatch("message/getUnReadMessageListApisDefault", {pageNo: 1, pageSize: 999})
      await store.dispatch("message/getUnReadMessageListApis", {pageNo: pageNo.value, pageSize: pageSize.value})
    }

    async function tabChange(item) {
      console.log('fff', item.path, isEnterpriseChildUser.value, teamList.value)
      if (item.path === `/email` && isEnterpriseChildUser.value && teamList.value.length === 0) {
        dialogVisibleTips.value = true;
        return
      }

      if (item.path === `/email` && !isEnterpriseChildUser.value && teamList.value.length === 0) {
        visitable.value = true;
        return;
      }
      // 路由初始化控制
      if (item.path === `/email`) {
        // const targetRouter = localStorage.getItem('activeMenu') == null || localStorage.getItem("activeMenu") == "/email/account_setting" ? '/email/inbox' : localStorage.getItem('activeMenu')
        const targetRouter = `/email/inbox`
        router.replace(targetRouter);
      } else {
        router.replace(item.path);
      }
      // 更新权限
      await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value, isUpdate: true})
    }

    function close() {
      visitable.value = false;
    }

    const pageNo = ref(1);
    const pageSize = ref(10);

    const flagNumbers = computed(() => {
      if (newData.value.length > pageSize.value) {
        return allDataTotal.value
      } else {
        return newData.value.length
      }
    })

    const allDataTotal = computed(() => store.state.message.allNewData)
    const isBottom = ref(false)
    const allNewDataIsLoading = computed(() => store.state.message.allNewDataIsLoading)
    const changePage = async () => {
      const startIndex = (pageNo.value - 1) * pageSize.value; // 计算当前页的起始索引
      const endIndex = Math.min(startIndex + pageSize.value, allDataTotal.value); // 计算当前页的结束索引
      console.log(endIndex, allDataTotal.value, 'fff')
      if (endIndex >= allDataTotal.value) {
        // ElMessage.warning("已经到底了")
        isBottom.value = true;
        return
      }
      pageNo.value = pageNo.value + 1;
      // 需要更新数据
      await store.dispatch("message/getUnReadMessageListApis", {pageNo: pageNo.value, pageSize: pageSize.value})
    }

    const getUnReadsList = async () => {
      await store.commit("message/resetNewData", [])
      // 需要更新数据
      await store.dispatch("message/getUnReadMessageListApis", {pageNo: pageNo.value, pageSize: pageSize.value})
    }


    onMounted(async () => {
      await store.dispatch('email/getTeamList', {isUpdate: true})
      // await store.dispatch("user/getPositionByLoginList");
      // 所有消息的
      // await store.dispatch("message/getMessageListApis", {pageNo: pageNo.value, pageSize: pageSize.value})
      // 获取最新未读消息
      // 展示数据即可
      await store.dispatch("message/getUnReadMessageListApis", {pageNo: pageNo.value, pageSize: pageSize.value})
      console.log(route.path.split("/"), "route", tabPath);

      document.addEventListener('click', closeHandleClick, {
        capture: true
      })
    });
    onUnmounted(() => {
      document.removeEventListener('click', closeHandleClick)
    })

    const closeHandleClick = (event) => {
        if (!event.target.closest('.dropdown-menu')) {
          visible.value = false;
        }
      }

    function goToUserCenter() {
      router.push(isEnterpriseMainUser.value ? '/personal/qinfo' : '/personal/basic')
    }

    function goToStation() {
      router.push('/personal/station')
    }

    function goToStoreDetails() {
      router.push('/personal/storeDetails')
    }

    function goToFlowDetails() {
      router.push('/personal/flowDetails')
    }

    function goToSetting() {
      router.push('/personal/security')
    }

    function goToRepairOrder() {
      router.push('/personal/repairOrder')
    }

    // 退出登陆
    async function handleLogout() {
      await store.dispatch('user/UserQuit')
      await store.dispatch('chat/logout')
      router.push('/login')
    }


    /**
     * 文件存储的空间大小
     */
    const spaceSize = computed(() => store.state.cloud.spaceSize)
    onMounted(async () => {
      await store.dispatch("cloud/queryByUserIdApis")
    })

    return {
      spaceSize,
      getUnReadsList,
      flagNumbers,
      allNewDataIsLoading,
      allDataTotal,
      isBottom,
      changePage,
      resultTargetDataList,
      newData,
      handleClickIsRead,
      tabList,
      tabPath,
      tabChange,
      close,
      visitable,
      goToUserCenter,
      goToStation,
      goToStoreDetails,
      goToSetting,
      goToRepairOrder,
      handleLogout,
      userInfo, mailWorkPosition,
      dialogVisibleTips,
      toAllNotify,
      dropdownRef,
      visible,
      handleClick,
      toggleDropdown,
    };
  },
};
</script>
<style lang="less" scoped>
@import "./less/Header.less";

.left_text {
  span {
    display: inherit;
    margin-right: 8px;

    img {
      text-align: center;
    }
  }
}

.right_text {
  display: flex;
  align-items: center;
}

.sp_out {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #eef1f3;
  position: relative;

  & > img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

:deep(.el-dropdown-menu__item) {
  transition: all .3s;
}

:deep(.el-dropdown-menu__item:not(.is-disabled):focus) {
  background: #f9f9f9;
  color: #121212;
  transition: all .3s;
}

:deep(.el-dropdown-menu__item:nth-of-type(4):not(.is-disabled):focus) {
  background: transparent !important;

}

.inners_warning {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;

  div {
    margin-left: 10px;
  }
}

.setting_center {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

:deep(.el-avatar) {
  cursor: pointer;
  background: #f3f3f3 !important;
}

.texts {
  color: #000000 !important;
}

.inner_notify_box {
  width: 350px;
  padding: 0 20px;
  background: #fff;
}

.boxs {
  width: 350px;
  min-width: 350px;
  height: max-content !important;
  max-height: 300px !important;

  .empty {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
  }

  .item_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    align-items: center;
    z-index: 9;
    bottom: 0px;
    width: 100%;
    background: white;
  }

  .tops_side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 15px;
    position: absolute;
    width: 100%;
    background: white;

    .top_message {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #333;
    }
  }
}

.inner_boxs {
  min-height: 106px;
}


:deep(.el-badge__content) {
  position: absolute;
  right: calc(-8px + var(--el-badge-size) / 2) !important;
  top: -40px !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50% , 0);
    background-color: red;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}


/* 定义淡入淡出动画 */
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
  transform: translate(-50%, -10px);
  opacity: 0;
}

.fade-enter-to, .dropdown-leave-from{
  opacity: 1;
  transform: translate(-50%, 0);
}

</style>
