<template>
  <ElDialog
      v-model="isShow"
      :title="$t('sys.team.info')"
      :footer="null"
      centered
      :width="408"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column">
      <div><img src="../../../assets/jinggao.png" width="60" height="52"/></div>
      <div class="font" style="margin-top: 10px">
        {{ $t('system.test.team.des') }}
      </div>
      <div class="font" style="margin-top: 10px">
        {{ $t("system.test.team.des2") }}
      </div>
    </div>

    <div class="dialog-footer">
      <ElButton @click="close">
        {{
          $t("common.cancel")
        }}
      </ElButton>
      <ElButton type="primary" @click="goToEmail" :loading="loading">
        {{ $t("system.test.go") }}
      </ElButton>
    </div>

  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits} from 'vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const fileDirName = ref("")
const loading = ref(false)
const router = useRouter();


const {value, isHeadLine} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}

const goToEmail = () => {
  router.push('/email/inbox')
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

.dialog-footer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.font {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.font_middle {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 32px;
}
</style>
