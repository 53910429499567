<template>

  <ElDialog
      v-model="isShow"
      :title="$t('batch.select.contact.email')"
      :footer="null"
      centered
      top="2em"
      :width="'85%'"
      style="min-width:800 !important;"
      destroyOnClose
      @cancel="close"
  >

    <div>
      <TopSearch :adminShow="true" :searchShow="true" @searchKey="getSearchKey"/>
    </div>
    <template v-if="!isLoading">
      <div v-if="customerList.length!==0" style="margin-top: 20px">
        <div id="checkoutAll" ref="checkoutAll">
          <el-checkbox v-model="isSelectAll" :label="$t('tree.select.all')" size="large" @change="selectAll">
            {{
              $t('common.customer.checkout.all', {
                count: isSelectAll ? totals : Object.values(selectedRows) ? Object.values(selectedRows).filter(item => item.email && item.level == 1).length : 0
              })
            }}&nbsp;;&nbsp;
            {{
              !isLoadingAll ? isSelectAll ? $t('tree.count.sum', {count: allTargetEmailList.length}) : $t('tree.count.sum', {
                count: Array.from(
                    Object.values(selectedRows)
                        .filter(item => item.email && item.level === 2 && emailReg.test(item.email))
                        .reduce((map, current) => map.set(current.email, current), new Map())
                        .values()
                ).length
              }) : $t('tree.count.wating')
            }}
          </el-checkbox>
          <!--          <el-button type="primary" size="small" @click="counterElection" style="margin-left: 10px">-->
          <!--            {{ $t('checkout.election') }}-->
          <!--            {{ isCounterElection }}-->
          <!--          </el-button>-->
        </div>
        <div style="margin-top: 15px">
          <el-table
              v-loading="isLoading||isLoadingAll"
              :data="customerList"
              style="width: 100%;margin-top: 16px"
              lazy
              :height="'45vh'"
              id="treeTable"
              :row-key="(row)=>row.id"
              ref="treeTable"
              @select="handleSelectionChangeCustomerList"
              @select-all="handleClickSelectAll"
              @selection-change="handleSelectionChange"
              :header-cell-class-name="cellClass"
              :cell-style="rowClassName"
              :header-cell-style="headerClassName"
              @current-change="resetSelection"
          >
            <el-table-column type="selection" :reserve-selection="true" width="40" :selectable="checkSelectable"
                             fixed="left"/>
            <el-table-column
                v-for="(item) in table_columns_public_list_group"
                :key="item.key"
                :label="item.key"
                :prop="item.value"
                label="Date"
                :width="item.value==='customerCode'?'200':item.value==='customerOrigin'?'240':item.value==='customerName'?auto:flexWidth(item.value, customerList, item.key)"
                :min-width="flexWidth(item.value, customerList, item.key)"
                :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='customerOrigin'?true:false"
            >
              <template #default="{ row, column, $index }">
                <template v-if="item.key === $t('common.customer.inChargeTheLast') && !row.isContact">
                  <el-popover
                      effect="dark"
                      placement="bottom"
                      width="max-content"
                      trigger="hover"
                      :show-after="500"
                      v-if="row.mangerVOList&&row.mangerVOList.length > 0"
                  >
                    <!-- 联系人 -->
                    <div
                        v-html="
                      '<div>' +
                      row.mangerVOList
                        .map((item) => {
                          return `${item.managerName}<br/>`;
                        })
                        .join('') +
                      '</div>'
                    "
                    ></div>
                    <template #reference>
                      <el-button link type="primary">
                        {{ row.mangerVOList && row.mangerVOList.length }}
                      </el-button>
                    </template>
                  </el-popover
                  >
                  <template v-else>
                    <el-button link type="primary">
                      {{ row.mangerVOList && row.mangerVOList.length }}
                    </el-button>
                  </template>
                </template>
                <template v-else-if="item.key === $t('common.customer.Region')">
                  <div style="cursor: pointer">
                    <el-popover
                        effect="dark"
                        placement="right"
                        width="max-content"
                        trigger="hover"
                        :content="row.zoneAddress"
                        :show-after="500"
                    >
                      <template #reference>
                        <el-image :src="flag[row.zoneCode]"
                                  style="width:26px;margin-right:2px;vertical-align: middle;" lazy/>
                      </template>
                    </el-popover>
                  </div>
                </template>
                <template v-else-if="item.key ===$t('common.lastcontact.time')">
                  <div>{{ row.lastTime }}</div>
                </template>
                <template v-else-if="item.value==='customerName'">
                  <div>{{ row.customerName ? truncateByBytes(row.customerName, 45) : '' }}</div>
                </template>
                <template v-else-if="item.value==='customerOrigin'">
                  <div v-if="row.level===2">{{ row.email }}</div>
                  <div v-else>{{ row.customerOrigin }}</div>
                </template>
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </el-table>
          <div class="pages">
            <el-pagination
                popper-class="page"
                v-model:current-page="current"
                v-model:page-size="size"
                :page-sizes="[10,30,50,100]"
                background
                layout="total, prev, pager, next"
                v-if="totals"
                :total="totals"
                @current-change="handleCurrentChange"
            />
          </div>

        </div>
      </div>
      <div v-else class="display:flex;">
        <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
      </div>
    </template>
    <div style="height: 260px" v-if="isLoading" v-loading="isLoading"></div>
    <template #footer>
      <div class="settings">
        <ElButton @click="close">
          {{ $t('common.cancel') }}
        </ElButton>
        <ElButton type="primary" @click="addToEmails" :loading="isLoadingAll">
          {{ $t('common.confirm') }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElTable,} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed, onMounted, nextTick, onBeforeUnmount} from 'vue'
import {useStore} from "vuex";
import {getMassCustomerList} from "@/api/customer";
import TopSearch from "@/views/Customer/TopComponents/TopSearch.vue";
import {emailReg, emptyUrl} from "@/utils/statusParams";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import {TableV2FixedDir} from 'element-plus'
import {table_columns_public_list, table_columns_public_list_group} from "@/views/Customer/map";
import {flexWidth} from "@/utils/tableWidth";
import flag from "@/assets/flag";
import {truncateByBytes} from "@/utils/notifies/tool";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import {applyStyles, auto} from "@popperjs/core";
import {extractEmails, flattenChildren, replaceMailMassEmailVOListWithChildren} from "@/utils/filters";
import {isEmpty} from "@/utils/mylodash";
import loadingObj from "@/utils";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const teamName = ref("")
const loading = ref(false);


const current = ref(1);
const size = ref(20)
const totals = ref(0)

const treeTable = ref(null)
const allCustomerList = ref([])
const baseAllCustomerList = ref([])
const isSelectAll = ref(false)
const baseCustomerList = ref([])

// 页面 进行翻页
const handleCurrentChange = async (val) => {
  current.value = val
  allCustomerList.value = computedTargetArray.value.endArray
  console.log(computedTargetArray.value.endArray, 'computedTargetArray.value.endArray')
  //  重新获取数据
  await getCustomerList()
  console.log(isSelectAll.value, JSON.parse(isCounterElection.value), 'flag')
  // 全选
  if (isSelectAll.value) {
    await selectAll()
  }

  // 反选
  if (JSON.parse(isCounterElection.value)) {
    counterElection()
  }
}


const counterElection = () => {
  console.log('反选')
  if (isSelectAll.value) {
    //   取消全部勾选
    isSelectAll.value = false
    customerList.value.forEach((item) => {
      treeTable?.value.toggleRowSelection(item, isSelectAll.value)
    })
  } else {
    //   针对所有的数据进行反选

    // 反选这里有问题 ==== 全部数据 无法实现
    const selectedIds = new Set(Object.values(selectedRows.value).map(row => row.id));
    customerList.value.forEach(item => {
      if (selectedIds.has(item.id)) {
        treeTable?.value.toggleRowSelection(item); // 如果已经选中则取消选中
      } else {
        treeTable?.value.toggleRowSelection(item); // 如果没有选中则选中
      }
    });
  }
}


// 是否可以进行勾选
const checkSelectable = () => {
  return !isSelectAll.value;
}

const targetEmailList = ref([])
const selectedRows = computed(() => store.state.customer.selectedRows)
const globalSelectedRows = computed(() => store.state.customer.globalSelectedRows)

// select 选择每一行
const handleSelectionChangeCustomerList = async (val) => {
  console.log(val, '当前页勾选的值');
  // isSelectAll.value = false
  // 选择 底部选择 取消 全部勾选
  await nextTick(async () => {
    // 获取当前页的页码，假设分页数据存储在 store 中
    const currentPage = current.value;

    // 获取全局勾选记录
    const globalSelectedRows = store.state.customer.globalSelectedRows || {};

    // 更新当前页的勾选数据到全局记录
    globalSelectedRows[currentPage] = val;

    // 提交更新全局勾选记录到 store
    await store.commit("customer/setGlobalSelectedRows", globalSelectedRows);

    // 合并所有页的勾选数据
    const allSelectedRows = Object.values(globalSelectedRows).flat();

    // 更新 `selectedRows` 和 `targetEmailList`
    await store.commit("customer/setSelectedRows", allSelectedRows);
    targetEmailList.value = allSelectedRows
        .filter(item => item.email && item.level === 2)
        .map(item => item.email);

    console.log(targetEmailList.value, 'targetEmailList');
  })
}
// 一页中的勾选全部
const handleClickSelectAll = async (val) => {
  console.log(val, '当前页勾选的值');
  // 获取当前页的页码，假设分页数据存储在 store 中
  const currentPage = current.value;

  // 获取全局勾选记录
  const globalSelectedRows = store.state.customer.globalSelectedRows || {};

  // 更新当前页的勾选数据到全局记录
  globalSelectedRows[currentPage] = val;

  // 提交更新全局勾选记录到 store
  await store.commit("customer/setGlobalSelectedRows", globalSelectedRows);

  // 合并所有页的勾选数据
  const allSelectedRows = Object.values(globalSelectedRows).flat();

  // 更新 `selectedRows` 和 `targetEmailList`
  await store.commit("customer/setSelectedRows", allSelectedRows);
  targetEmailList.value = allSelectedRows
      .filter(item => item.email && item.level === 2)
      .map(item => item.email);
  console.log(targetEmailList.value, 'targetEmailList');
}
// 勾选发生变化的时候
// const handleSelectChange = async (selection) => {
//   await store.commit("customer/setSelectedRows", selection);
// }

// 页面发生变化的时候
// watch(() => current.value, (newPage) => {
//   // 获取当前页的数据
//   const globalSelectedRows = store.state.customer.globalSelectedRows || {};
//   const currentPageSelections = globalSelectedRows[newPage] || [];
//   // 恢复当前页的勾选状态
//   treeTable.value.setSelection(currentPageSelections);
// });

// 勾选 选择全部
const selectAll = async () => {
  if (allCustomerList.value.length === 0) {
    await getCustomerAllList()
  }
  console.log(selectedRows.value, 'selectedRows', globalSelectedRows.value)

  // if (!isEmpty(globalSelectedRows.value[current.value])) {
  //   // 当前页数据全部勾选 / 不勾选
  //   // 取交集
  //   console.log(globalSelectedRows.value[current.value].filter(item => item.level == 1), 'globalSelectedRows.value[current.value]')
  //   const pageResult = globalSelectedRows.value[current.value].filter(item => item.level == 1)
  //   customerList.value.forEach((item) => {
  //     const isSelected = pageResult.value.some((selected) => selected.id === item.id);
  //     console.log(item, isSelected, selectedRows.value.map(item => item.id), 'isSelected')
  //     treeTable?.value.toggleRowSelection(item, isSelected);
  //   })
  // } else {
  customerList.value.forEach((item) => {
    treeTable?.value.toggleRowSelection(item, isSelectAll.value)
  })
  // await store.commit("customer/setSelectedRows", customerList.value);
  // }
}

//
const computedTargetArray = computed(() => {
  const historyArray = Object.values(globalSelectedRows.value).flat();
  const selectRowArray = selectedRows.value;
  const merged = [...new Set([...historyArray, ...selectRowArray])]
  const uniqueArray = merged.reduce((acc, current) => {
    // 检查当前元素的 id 是否已经在 accumulator 中
    if (!acc.some(item => item.id === current.id)) {
      acc.push(current);
    }
    return acc;
  }, []);

  // 取差集 计算 单页 未选中的项
  const difference = customerList.value.filter(item2 =>
      !uniqueArray.some(item1 => item1.id === item2.id)
  );
  // 与总数据 做差集；计算出剩余的项
  const endArray = allCustomerList.value.filter(item1 =>
      !difference.some(item2 => item2.id === item1.id)
  );

  console.log(uniqueArray, 'uniqueArray', endArray, difference, allCustomerList.value)
  // 数据总条数
  const total = selectedRows.value.length > 0 ? endArray.length : totals.value
  const childArray = replaceMailMassEmailVOListWithChildren(endArray)
  const emailTotal = selectedRows.value.length > 0 ? flattenChildren(childArray).filter(item => item.email && item.level == 2 && emailReg.test(item.email)).map(item => item.email) : allTargetEmailList.value;
  // return computedTargetArray;
  return ({total, emailTotal, endArray, difference})
})


const isCounterElection = computed(() => store.state.customer.isCounterElection);

const query = ref('')

const {value, isChecked} = defineProps({
  value: {type: Boolean, default: false},
  isChecked: {type: Array, default: []}
})

watch(() => value, (val) => {
  isShow.value = val;
  localStorage.removeItem("selectedRows")
  localStorage.removeItem("globalSelectedRows")
}, {immediate: true})


const customerList = ref([])
const teamId = computed(() => store.state.email.teamID);
const isLoading = ref(false);
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const userInfo = computed(() => store.state.user.userInfo)
// 搜索
const searchValue = ref({});
const getSearchKey = async (value) => {
  const newObj = JSON.parse(JSON.stringify(value));
  searchValue.value = newObj;
  console.log(searchValue.value, 'searchValue.value')
  current.value = 1;
  // 列表数据
  await getCustomerList();
};


// 获取所有的客户以及客户的邮箱地址
const getCustomerList = async () => {
  isLoading.value = true;
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  await getMassCustomerList({
    // managerId: isSuperAdmin.value ? managerId === undefined ? '' : managerId : userInfo.value.id,
    ...searchValue.value,
    teamId: teamId.value,
    pageSize: size.value,
    pageNo: current.value,
  }).then((res) => {
    customerList.value = replaceMailMassEmailVOListWithChildren(res.result.records)
    baseCustomerList.value = res.result.records;
    current.value = res.result.current;
    totals.value = res.result.total
    isLoading.value = false;
  });
};


const cellClass = (row) => {
  if (row.columnIndex === 0) {
    return isSelectAll.value ? "disabledCheck" : "abledCheck"
  }
}

// 获取全部的客户
const getCustomerAllList = async () => {
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  isLoadingAll.value = true
  await getMassCustomerList({
    ...searchValue.value,
    teamId: teamId.value,
    pageSize: 99999,
    pageNo: 1,
  }).then((res) => {
    baseAllCustomerList.value = res.result.records;
    allCustomerList.value = replaceMailMassEmailVOListWithChildren(res.result.records)
    isLoadingAll.value = false
  });
}

const allTargetEmailList = computed(() => flattenChildren(allCustomerList.value).filter(item => item.email && item.level == 2 && emailReg.test(item.email)) // 确保 email 存在
    .map(item => item.email))


//收集邮箱并添加
const isLoadingAll = ref(false)
const addToEmails = async () => {
  if (isSelectAll.value) {
    console.log(computedTargetArray.value.emailTotal, 'computedTargetArray.value.emailTotal')
    emit('addToEmail', computedTargetArray.value.emailTotal)
  } else {
    emit('addToEmail', targetEmailList.value)
  }
  await store.commit("customer/setGlobalSelectedRows", {});
  store.commit("customer/setSelectedRows", []);
}

// 全部勾选 监听
watch(() => isSelectAll.value, async (newValue) => {
  if (!newValue) {
    targetEmailList.value = [];
    await store.commit("customer/setSelectedRows", []);
    await store.commit("customer/setGlobalSelectedRows", {});
  }
})

const close = async () => {
  await store.commit("customer/setGlobalSelectedRows", {});
  store.commit("customer/setSelectedRows", []);
  emit("close");
}

onMounted(async () => {
  await store.dispatch("cusList/getMailCustomerLevel");
  // Shift监听/取消监听
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('keyup', handleKeyUp);
});

const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}

// 记录历史记录
const resetSelection = async () => {
  await nextTick(() => {
    console.log(treeTable?.value, 'treeTable?.value')
    if (treeTable?.value) {
      customerList.value.forEach((item) => {
        const isSelected = Object.values(selectedRows.value).some((selected) => selected.id === item.id);
        treeTable?.value.toggleRowSelection(item, isSelected);
      })
    }
  })
}

// 当前页数据发生变化
watch(() => customerList.value, (newValue) => {
  if (newValue) {
    console.log('newValue')
    resetSelection()
  }
}, {immediate: true})


// shift 选择
let endIndex = -1;
// table 选择增加 shift
const isShiftPressed = ref(false); // 标记 Shift 键是否被按下
const handleKeyDown = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    // console.log("进来了-----------------", event.key, event.keyCode);
    isShiftPressed.value = true;
  }
};

const handleKeyUp = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    isShiftPressed.value = false;
  }
};

const handleSelectionChange = (val) => {
  // console.log("val-----------------------", val)
  //判断是否按住shirt键盘
  // console.log(isShiftPressed.value);
  endIndex = val.length > 1 ? customerList.value.findIndex(item => item.id === val[val.length - 1].id) : -1;
  const startIndex = val.length > 1 ? customerList.value.findIndex(item => item.id === val[val.length - 2].id) : -1;
  console.log(endIndex, startIndex);
  if (isShiftPressed.value && val.length > 1 && endIndex !== startIndex) {
    const start = startIndex < endIndex ? startIndex : endIndex;
    const end = endIndex < startIndex ? startIndex : endIndex;
    // console.log(start, end, '===============');
    for (let i = start; i <= end; i++) {
      const row = customerList.value[i];
      if (start === i || i === end) {
        continue;
      }
      console.log(row, '更新');
      if (row) {
        treeTable.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
      }
    }
    nextTick(async () => {
      const currentPage = current.value;
      const globalSelectedRows = store.state.customer.globalSelectedRows || {};
      globalSelectedRows[currentPage] = treeTable.value.getSelectionRows();
      await store.commit("customer/setGlobalSelectedRows", globalSelectedRows)
      console.log(treeTable.value.getSelectionRows(), '当前选中行');
    });
    // Shift 键被按下，进行批量选中处理
    // const firstRow = val[0];
    // const lastRow = val[val.length - 1];
    // const firstIndex = tableData.value.findIndex(item => item.id === firstRow.id);
    // const lastIndex = tableData.value.findIndex(item => item.id === lastRow.id);


    // if (firstIndex !== -1 && lastIndex !== -1) {
    //   const start = Math.min(firstIndex, lastIndex);
    //   const end = Math.max(firstIndex, lastIndex);
    //   console.log("start", start)
    //   console.log("end:", end)
    //   for (let i = start; i <= end; i++) {
    //     const row = tableData.value[i];
    //     if (row) { // 检查行是否存在，以防索引超出范围
    //       tableRef.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
    //     }
    //   }
    //   for (let i = 0; i < 40; i++) {
    //     if (start <= i && i <= end) {
    //       continue
    //     }
    //     const row = tableData.value[i];
    //     if (row) { // 检查行是否存在，以防索引超出范围
    //       tableRef.value.toggleRowSelection(row, false); // 调用表格组件的方法来更新选中状态
    //     }
    //   }
    //   //批量勾选
    //   multipleSelection.value = tableData.value.slice(start, end + 1).map(row => ({...row}));
    // }
    // console.log("multipleSelection.value-------------", multipleSelection.value)
  } else {
    //走正常逻辑
    const currentPage = current.value;
    const globalSelectedRows = store.state.customer.globalSelectedRows || {};
    globalSelectedRows[currentPage] = val
  }
}
</script>
<style scoped lang="less">
@import "../../less/page.less";

.fx-j-c {
  display: flex;
  justify-content: center;
}

.settings {
  //margin-top: 20px;
  display: flex;
  justify-content: center;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: 10px;
}

:deep(.el-checkbox__inner::after) {
  top: 1px !important;
  left: 5px !important;
}

#checkoutAll {
  padding-left: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:deep(.disabledCheck .el-checkbox__input) {
  display: none !important;
  padding: 0px !important;
}

:deep(.abledCheck .el-checkbox__input) {
  display: block !important;
  padding: 0px !important;
}

:deep(.disabledCheck) {
  padding: 0px !important;
}

:deep(.abledCheck) {
  padding: 0px !important;
}

:deep(.el-tooltip>div) {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}


</style>
