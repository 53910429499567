import _store from 'store2'
import {addCalendar, deleteCalendar, getCalendarList, queryById, updateCalendar} from "@/api/calendar";
import {ElMessage} from "element-plus";
import {
    auditExternalPerson,
    auditExternalPersonApis,
    getMessageList,
    getUnReadMessageList,
    isReadAll
} from "@/api/message";
import {replaceKeys} from "@/utils/mes";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const message = {
    namespaced: true,
    state: {
        allDataList: [],
        allDataTotal: 0,
        allDataIsLoading: false,
        newData: [],
        allNewData: 0,
        allNewDataIsLoading: false,
        emailData: [],
    },
    getters: {},
    mutations: {
        setAllDataList(state, payload) {
            state.allDataList = payload;
        },
        setNewData(state, payload) {
            const res = replaceKeys(JSON.parse(payload))
            console.log(res, 'res')
            state.newData = [res, ...state.newData]

        },
        resetNewData(state, payload) {
            state.newData = payload;
        },
        //     邮件信息
        setEmailData(state, payload) {
            state.emailData = payload;
        }
    },
    actions: {
        // 列表查询-all
        async getMessageListApis({state, dispatch, rootState}, payload) {
            state.allDataIsLoading = true
            const res = await getMessageList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }
            state.allDataIsLoading = false;
            console.log(res.result.records, 'res.result.records')
            state.allDataTotal = res.result.total;
            state.allDataList = [...state.allDataList, ...res.result.records];
            // state.allDataList = res.result.records
        },
        // 初始化全部数据
        async getMessageListApisDefault({state, dispatch, rootState}, payload) {
            state.allDataIsLoading = true
            const res = await getMessageList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }
            state.allDataIsLoading = false;
            console.log(res.result.records, 'res.result.records')
            state.allDataTotal = res.result.total;
            // state.allDataList = [...state.allDataList, ...res.result.records];
            state.allDataList = res.result.records
        },

        //    未读数据
        async getUnReadMessageListApis({state, dispatch, rootState}, payload) {
            state.allNewDataIsLoading = true;
            let res = await getUnReadMessageList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            state.allNewDataIsLoading = false;
            state.allNewData = res.result.total;
            state.newData = [...state.newData, ...res.result.records]
            // sessionStorageUtil.setItem("getUnReadMessageListData", {
            //     newData: state.newData,
            //     allNewData: state.allNewData
            // })
        },
        // 初始化数据
        async getUnReadMessageListApisDefault({state, dispatch, rootState}, payload) {
            let res = await getUnReadMessageList(payload)
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
            state.allNewData = res.result.total;
            state.newData = res.result.records
        },
        //     是否同意进入团队？
        async auditExternalPersonApis({state, dispatch, rootState}, payload) {
            let res = await auditExternalPerson(payload);
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return;
            }
            ElMessage.success(res.message)
        },

        //     一键已读
        async isReadAllApis({state, dispatch,}, payload) {
            const res = await isReadAll();
            if (res.code !== 200) {
                ElMessage.error(res.message);
                return
            }
        }
    },
}

export default message;
