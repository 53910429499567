<template>
  <el-select :placeholder="props.placeholder || $t('common.please.select')" style="width: 200px"
             :model-value="props.modelValue"
             @change="emitValue"
             popper-class="my-select"
             :filter-method="customFilter"
  >
    <!-- 循环 -->
    <el-option :value="9999" :label="$t('customer.select')" v-if="props.newCusomerFlag"/>
    <div @scroll="handleScroll" :style="`max-height: ${props.newCusomerFlag ? 226 : 260}px;overflow-y: scroll;`">
      <el-option v-for="item in customerListAll" :label="item.customerName" :value="item.id" :key="item.id"/>
      <el-option v-if="loadingCustomer" :value="null" disabled>{{ $t('common.loading') }}</el-option>
    </div>
  </el-select>
</template>

<script setup>
import {defineProps, onMounted, ref, watch, defineExpose} from 'vue';
import {ElMessage} from 'element-plus';
import {requestAllCustomer} from '../api/customer';

const props = defineProps({
  modelValue: String,
  teamId: Number,
  placeholder: {type: String, default: () => ''},
  modelModifiers: {default: () => ({})},
  newCusomerFlag: {default: () => false, type: Boolean},
})
const customerListAll = ref([]);
const loadingCustomer = ref(false);
const page = ref(1);
const size = ref(20);
const total = ref(0);
const isFinish = ref(true);


const emit = defineEmits(['update:modelValue']);

function emitValue(value) {
  console.log('组件更新', value);
  emit('update:modelValue', value);
  emit('change', value);
}


async function getAllCustomer() {
  loadingCustomer.value = true
  await requestAllCustomer({
    teamId: props.teamId,
    type: 1,
    pageNo: page.value,
    pageSize: size.value
  }).then((res) => {
    console.log('客户列表', res);
    if (res.result.records.length > 0) {
      // if (props.newCusomerFlag) {
      //   res.result.records.unshift({id: 9999, customerName: "新建客户"});
      // }
      customerListAll.value = customerListAll.value.concat([
        ...res.result.records,
      ]);
    } else {
      isFinish.value = false;
    }
    loadingCustomer.value = false
  }).catch(e => {
    ElMessage.error(e);
    loadingCustomer.value = false
  }).finally(() => {
    loadingCustomer.value = false
  });
}

const customFilter = () => {
  console.log("filter",)
}

function handleScroll(event) {
  if (loadingCustomer.value) {
    return;
  }
  if (isFinish.value) {
    page.value = page.value + 1;
    getAllCustomer();
  }
}

function initGetAllCustomer() {
  if (loadingCustomer.value) {
    return;
  }
  page.value = 1;
  getAllCustomer();
}

watch(() => props.teamId, () => {
  console.log(props.teamId);
  getAllCustomer();
});

defineExpose({
  initGetAllCustomer,
});

onMounted(() => {
  getAllCustomer();
});
</script>

<style lang="less" scoped>
.my-select .el-select-dropdown__item {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
