import _store from "store2";

import {ElMessage} from "element-plus";
import {getTeamData} from "@/api/home";

const home = {
    namespaced: true,
    state: {
        currentTeam: 1,
        sizeTeam: 5,
        totalTeam: 0,
        teamListPage: [],
        loading: false,
    },
    getters: {},
    mutations: {
        setPages(state, payload) {
            state.currentTeam = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        }
    },
    actions: {
        async getTeamListPages({state}) {
            state.loading = true;
            let res = await getTeamData({
                pageNo: state.currentTeam,
                pageSize: state.sizeTeam
            });
            if (res.code !== 200) {
                ElMessage.error(res.message)
                return
            }
            state.loading = false;
            if (res.result == null) {
                state.teamListPage = []
                state.totalTeam = 0;
                return
            }
            const {records, size, current, total} = res.result;
            console.log(res.result, '@@@')
            state.teamListPage = records;
            state.totalTeam = total;
            state.currentTeam = current;
            state.sizeTeam = size;

        }
    },
};

export default home;
