<template>
  <div class="alert_box">
    <div class="left_icon">
      <el-icon style="font-size: 30px; color: #1890ff;">
        <Warning/>
      </el-icon>
    </div>
    <div class="right_text">
      <div class="top_title">
        <span>{{ title }}</span>
      </div>
      <div>
        <div class="tip" v-for="item in textInner" :key="item.text">
          <span class="dot_outbox">
            <span class="inners"> </span>
          </span>
          <span>
            {{ item.text }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import {i18n} from "../../../lang/index";

const props = defineProps({
  title: {
    type: String,
    default: i18n.global.t('common.operation.tips'),
  },

  textInner: {
    type: Array,
    default: () => [
      {text: i18n.global.t("common.customer.info1")},
      {text: i18n.global.t("common.customer.info2")},
      {
        text: i18n.global.t("common.customer.info3"),
      },
    ],
  },
});
</script>

<style lang="less" scoped>
.alert_box {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  position: relative;
  padding: 20px 15px 15px 64px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  border-radius: 4px;
  min-width: 420px;

  & > .el-alert {
    background: #e6f7ff;
  }
}

.left_icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}

.top_title {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.tip {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px !important;
}

:deep(.el-badge__content.is-dot) {
  width: 10px;
  height: 10px;
}

:deep(.el-badge) {
  display: flex;
  justify-content: center;
}

.inners::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s ease-in-out infinite;
  content: "";
}

@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

.dot_outbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  margin-right: 6px;

  & > .inners {
    position: relative;
    background-color: #1890ff;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%;
  }
}
</style>
