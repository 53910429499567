<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.realname.certification') }}</div>
      <div class="content">
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
            :label-position="'top'"
        >
          <el-form-item :label="$t('common.zsxm')" prop="realName">
            <el-input
                v-model="ruleForm.realName"
                :placeholder="$t('real.name.placeholder')"
            ></el-input>
          </el-form-item>
          <el-form-item
              :label="$t('common.sfzh')"
              prop="cardId"
              :aria-placeholder="$t('90202')"
          >
            <el-input v-model="ruleForm.cardId" :placeholder="$t('90202')">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round @click="mailFrontUserEditApis">
              {{ $t('common.confirm') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {mailFrontUserEdit} from "@/api/usercenter";
import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {idCardReg} from '@/utils/statusParams'
import {i18n} from "@/lang";

const store = useStore()
const router = useRouter()

const ruleForm = ref({});
const ruleFormRef = ref(null)
const rules = ref({
  realName: [
    {required: true, message: i18n.global.t('real.name.placeholder'), trigger: "blur"},
  ],
  cardId: [{required: true, message: i18n.global.t('real.name.id.card'), trigger: "blur"}, {
    pattern: idCardReg,
    message: i18n.global.t('real.name.message.reg'),
    trigger: "blur"
  }],
});

const userInfo = computed(() => store.state.user.userInfo)
const mailFrontUserEditApis = async () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      const res = await mailFrontUserEdit({
        realName: ruleForm.value.realName,
        idCard: ruleForm.value.cardId,
        id: userInfo.value.id,
      })
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return
      }
      ElMessage.success(res.message);
      await store.dispatch('user/getUserInfoForGUID')
      ruleFormRef.value.resetFields();
      router.push('/personal/basic')
    } else {
      return false;
    }
  })
}
const isChange = ref('')
onMounted(() => {
  isChange.value = router.currentRoute.value.query.isChange
  if (isChange.value === '1') {
    ruleForm.value = userInfo.value
  }
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 200px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: center;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}

#sp_input {
  border-right: none !important;
}

:deep(.el-input__wrapper) {
  padding: 1px 0px 1px 11px !important;
}
</style>
