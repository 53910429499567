<template>
  <div class="tables" style="width: 100%">
    <el-table class="tableAuto" v-loading="isLoading" :data="tableData" style="width: 100%"
              :header-cell-style="{'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}">
      <el-table-column
          v-for="(item, index) in targetMaps"
          :key="item.key"
          :label="item.key"
          :prop="item.value"
          label="Date"
          :width="item.value==='email'||item.value==='name'?300:200"
          :show-overflow-tooltip="item.value==='email'||item.value==='name'||item.value==='post'?true:false"
      >
        <template v-if="item.value==='remark'" #default="scope">
          <el-icon
              :color="scope.row.remark ? '#40a9ff' : '#dddddd'">
            <el-tooltip
                v-if="scope.row.remark"
                class="box-item"
                :content="scope.row.remark"
                placement="right"
                popper-class="tips_width"
            >
              <Memo/>
            </el-tooltip>
            <Memo v-else/>
          </el-icon>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.operate')" fixed="right" width="180">
        <template #default="{ row, column, $index }">
          <el-button type="primary" link @click="handleClickHistroy(row)">{{ $t('common.contact.record') }}</el-button>
          <el-button
              type="primary"
              link
              @click="clickEdit(row, column, $index)"
          >{{ $t('common.edit') }}
          </el-button
          >
          <ConfirmDeletePopover
              :visible="row.popoverVisible"
              :width="168"
              :message="$t('sys.message.ask.contect.delete')"
              @update:visible="(value) => (row.popoverVisible = value)"
              @confirm:delete="handleDelete(row, column, $index)"
          >
            <template #settings>
              <el-button type="danger" link @click="handleDeleteT(row, column, $index)">{{ $t('common.delete') }}
              </el-button>
            </template>
          </ConfirmDeletePopover>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
      </template>
    </el-table>
    <div class="pages">
      <el-pagination
          v-model:current-page="pageNo"
          v-model:page-size="pageSize"
          v-if="total"
          layout="total, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>

</template>
<script setup>
import {ref, computed, reactive, onMounted, nextTick, defineEmits} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import {emptyUrl} from "@/utils/statusParams";
import {i18n} from "@/lang";

const router = useRouter();
const store = useStore();
const tableData = computed(() => store.state.customer.contactList);
const pageSize = computed(() => store.state.customer.contactPage.pageSize);
const pageNo = computed(() => store.state.customer.contactPage.pageNo);
const total = computed(() => store.state.customer.contactPage.total);
const contactLoading = computed(() => store.state.customer.contactLoading);

const emit = defineEmits(["clickEdit"]);

const handleCurrentChange = (page) => {
  store.commit("customer/setContactPage", {
    total: total.value,
    pageNo: page,
    pageSize: pageSize.value,
  });
  store.dispatch("customer/getContactList");
};

const targetMaps = [
  {key: i18n.global.t('common.name'), value: "name"},
  {key: i18n.global.t('common.position'), value: "post"},
  {key: i18n.global.t('common.contact.phone'), value: "telPhone"},
  {key: i18n.global.t('common.phone.number'), value: "phone"},
  {key: i18n.global.t('common.emailbox'), value: "email"},
  {key: i18n.global.t('common.birthday'), value: "birthday"},
  {key: i18n.global.t('common.record.time'), value: "createTime"},
  {key: i18n.global.t('common.remark'), value: "remark"},
];

onMounted(async () => {
  const customerId = router.currentRoute.value.params.userId;
  window.localStorage.setItem("customerId", customerId);
  store.dispatch("customer/getContactList");
});

const popoverVisible = ref(false);
const handleDelete = async (row, column, $index) => {
  await store.dispatch("customer/deleteContact", {id: row.id});
  await store.dispatch("customer/getContactList");
  row.popoverVisible = false;
};
const handleDeleteT = async (row, column, $index) => {
  row.popoverVisible = true;
}

const handleClickHistroy = (row) => {
  emit('clickHistory', {history: true, item: row})
}

const clickEdit = (row, column, $index) => {
  emit("clickEdit", {row, column, $index});
};

</script>
<style lang="less" scoped>
@import "../../../less/page.less";

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-table__header) {
  width: 100% !important;
}

:deep(.el-table__body) {
  width: 100% !important;
}

.tableAuto.el-table .cell {
  white-space: nowrap;
}
</style>
