<template>
  <Cards>
    <TopSearch :adminShow="true" :search-show="true" @searchKey="getSearchKey"/>
  </Cards>
  <Cards>
    <div>
      <div>
        <!-- header -->
        <div class="top_tips">
          <h2 style="font-weight: 500;font-size: 21px !important;">{{ $t('common.customer.public') }}</h2>
          <div>
            <el-button
                :disabled="totalsPublic===0"
                type="danger"
                :icon="Delete"
                @click="handleClickDelete"
                v-if="isSuperAdmin"
            >{{ $t('common.delete') }}
            </el-button>
            <ConfirmDeletePopover
                :visible="dialogVisiblePopover"
                :width="164"
                :message="$t('common.cds7')"
                @update:visible="(value) => (dialogVisiblePopover = value)"
                @confirm:delete="handleDelete(row, column, $index)"
            >
              <template #settings>
                <el-button
                    type="primary"
                    :icon="Download"
                    @click="handleClickExport"
                >{{ $t("common.batch.claim") }}
                </el-button
                >
              </template>
            </ConfirmDeletePopover>
            <el-button
                type="primary"
                :icon="DocumentRemove"
                @click="handleClickRecent"
            >{{ $t("common.claim.record") }}
            </el-button
            >
          </div>
        </div>
        <!-- 公海客户表 -->
        <el-table
            v-loading="isLoading"
            :data="customerList"
            ref="targetTable"
            style="width: 100%;margin-top: 16px"
            @selection-change="handleSelectionChange"
            @row-click="handleClickTargetData"
            :cell-style="rowClassName"
            :header-cell-style="headerClassName"
        >
          <el-table-column type="selection" width="60" fixed="left"/>
          <el-table-column
              v-for="(item, index) in table_columns_public_list"
              :key="item.key"
              :label="item.key"
              :prop="item.value"
              label="Date"
              :width="item.value==='customerCode'?200:item.value==='customerOrigin'?240:flexWidth(item.value, customerList, item.key)"
              :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='customerOrigin'?true:false"
          >
            <template #default="{ row, column, $index }">
              <template v-if="item.key === $t('common.customer.inChargeTheLast')">
                <el-popover
                    effect="dark"
                    placement="bottom"
                    width="max-content"
                    trigger="hover"
                    :show-after="500"
                    v-if="row.mangerVOList.length > 0"
                >
                  <!-- 联系人 -->
                  <div
                      v-html="
                      '<div>' +
                      row.mangerVOList
                        .map((item) => {
                          return `${item.managerName}<br/>`;
                        })
                        .join('') +
                      '</div>'
                    "
                  ></div>
                  <template #reference>
                    <el-button link type="primary">
                      {{ row.mangerVOList.length }}
                    </el-button>
                  </template>
                </el-popover
                >
                <template v-else>
                  <el-button link type="primary">
                    {{ row.mangerVOList.length }}
                  </el-button>
                </template>
              </template>
              <template v-else-if="item.key ===$t('common.customer.Region')">
                <div style="cursor: pointer">
                  <el-popover
                      effect="dark"
                      placement="right"
                      width="max-content"
                      trigger="hover"
                      :content="row.zoneAddress"
                      :show-after="500"
                      v-if="row.zoneAddress"
                  >
                    <template #reference>
                      <el-image :src="flag[row.zoneCode]"
                                style="width:26px;margin-right:2px;vertical-align: middle;" lazy/>
                    </template>
                  </el-popover>
                </div>
              </template>
              <template v-else-if="item.key === $t('common.contact')">
                <el-popover
                    effect="dark"
                    placement="bottom"
                    width="max-content"
                    trigger="hover"
                    :show-after="500"
                    v-if="row.maiContactVOList.length > 0"
                >
                  <!-- 联系人 -->
                  <div
                      v-html="
                      '<div>' +
                      row.maiContactVOList
                        .map((item) => {
                          return `${item.contactName}<br/>`;
                        })
                        .join('') +
                      '</div>'
                    "
                  ></div>
                  <template #reference>
                    <el-button link type="primary">
                      {{ row.maiContactVOList.length }}
                    </el-button>
                  </template>
                </el-popover
                >
                <template v-else>
                  <el-button link type="primary">
                    {{ row.maiContactVOList.length }}
                  </el-button>
                </template>
              </template>
              <template v-else-if="item.key ===$t('common.lastcontact.time')">
                <div>{{ row.lastTime }}</div>
              </template>
              <template v-else-if="item.value==='customerName'">
                <div>{{ truncateByBytes(row.customerName, 45) }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.cz')" fixed="right" :width="120">
            <template #default="{ row, column, $index }">
              <ConfirmDeletePopover
                  :visible="row.popoverVisible"
                  :width="164"
                  :message="$t('common.cds7')"
                  :ref="`popover-${$index}`"
                  @update:visible="(value) => (row.popoverVisible = value)"
                  @confirm:delete="handleDeleteRow(row, column, $index)"
              >
                <template #settings>
                  <el-button
                      link
                      type="primary"
                      @click.native.stop="row.popoverVisible = true"
                  >{{ $t('common.claim') }}
                  </el-button
                  >
                </template>
              </ConfirmDeletePopover>
              <!--删除-->
              <ConfirmDeletePopover
                  :visible="row.popoverVisibleDelete"
                  :width="164"
                  :message="$t('sys.message.ask.delete')"
                  :ref="`popover-${$index}`"
                  @update:visible="(value) => (row.popoverVisibleDelete = value)"
                  @confirm:delete="handleDeleteRowCustomer(row, column, $index)"
              >
                <template #settings>
                  <el-button
                      link
                      type="danger"
                      @click.native.stop="row.popoverVisibleDelete = true"
                  >{{ $t('common.delete') }}
                  </el-button
                  >
                </template>
              </ConfirmDeletePopover>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
          </template>
        </el-table>
      </div>
      <!-- 对话框 -->
      <el-dialog
          v-model="dialogVisible"
          width="950"
          :title="i18n.global.t('common.claim.record')"
          class="noheadline"
          top="2em"
      >
        <el-form>
          <el-form-item :label="$t('common.claim.user')" style="width: 200px">
            <el-select
                @change="clickSelection"
                v-model="selectKeys"
                :placeholder="$t('common.setting.allTime')"
            >
              <el-option
                  v-for="item in tableDataSelect"
                  :label="item.userName"
                  :value="item.userId"
                  :key="item.userId"
              />
            </el-select>
          </el-form-item>
        </el-form>

        <!-- table -->
        <el-scrollbar>
          <el-table
              v-loading="loading"
              ref="multipleTableRef"
              :data="tableData"
              style="width: 100%; margin-top: 10px"
              :cell-style="{ textAlign: 'left' }"
              :header-cell-style="{ 'text-align': 'left',background: '#f9f9f9', color: 'black',padding:'16px 0px'}"
          >
            <el-table-column
                v-for="item in table_columns_public_2"
                :key="item.value"
                :label="item.key"
                :prop="item.value"
                :fixed="item.key === $t('common_customer_number') ? 'left' : auto"
                :width="item.value==='customerCode'?200:item.value==='applyTime'||item.value==='userName'?180: flexWidth(item.value, tableData, item.key)"
                :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='userName'?true:false"
            >
              <template #default="{ row, column, $index }">
                <template v-if="item.key === $t('common_customer_name')">
                  <router-link :to="'/email/customer/detail/' + row.customerId + '?type=1'">
                    <el-button link type="primary">{{ row.customerName }}</el-button>
                  </router-link>
                </template>
                <template v-if="item.key === $t('common.examine.state')">
                  <template v-if="row.auditStatus === 1">
                    <el-button link>{{ $t('common.examine.wait') }}</el-button>
                  </template>
                  <template v-else-if="row.auditStatus === 2">
                    <el-button link type="success">{{ $t('common.email.passed') }}</el-button>
                  </template>
                  <template v-else-if="row.auditStatus === 3">
                    <el-button link type="danger">{{ $t('common.email.rejected') }}</el-button>
                  </template>
                </template>
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </el-table>
          <div class="pages">
            <el-pagination
                v-model:current-page="currentDia"
                v-model:page-size="sizeDia"
                background
                :pager-count="4"
                layout="total, prev, pager, next, jumper"
                v-if="totalsDia"
                :total="totalsDia"
                @current-change="handleCurrentChangeDia"
            />
          </div>
        </el-scrollbar>
        <!-- 分页 -->
      </el-dialog>
      <!-- 分页 -->
      <div class="pages">
        <el-pagination
            popper-class="page"
            v-model:current-page="currentPublic"
            v-model:page-size="sizePublic"
            :page-sizes="[10,30,50,100]"
            background
            :pager-count="4"
            layout="sizes, total, prev, pager, next, jumper"
            v-if="totalsPublic"
            :total="totalsPublic"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </Cards>
  <!-- 抽屉 -->
  <el-drawer size="530px" v-model="isShowDrawers" :direction="direction">
    <template #header>
      <div style="display: flex">
        <h4>{{ activeItems.customerName }}</h4>
        <router-link
            :to="
            '/email/customer/detail/' +
            activeItems.id +
            '?tabKey=1&type=' +
            activeItems.type
          "
            style="color: #409eff"
        >
          <el-button v-if="isShowEdit" link type="primary">{{ $t('common.edit') }}</el-button>
        </router-link>
      </div>
    </template>
    <template #default>
      <div class="top_titles">
        <div>
          <div class="text_long">{{ $t('common.customer.numberList') }}：{{ activeItems.customerCode }}</div>
          <div class="text_long">{{ $t("common.byinput") }}：{{ activeItems.customerName }}</div>
        </div>
      </div>
      <div style="margin-top: 40px">
        <!-- table -->
        <div>
          <div><h3>{{ $t('common.cds25') }}</h3></div>
          <div class="small_box">
            <TableShows :table-data="companyInfo"/>
          </div>
        </div>
        <div style="margin-top: 40px">
          <div><h3>{{ $t('common.marketing.information') }}</h3></div>
          <div class="small_box">
            <TableShows :table-data="marketingInfo"/>
          </div>
        </div>
        <div style="margin-top: 40px">
          <div><h3>{{ $t('common.contact') }}</h3></div>
          <div
              class="small_box"
              v-for="(item, index) in contactInfo"
              :key="index"
          >
            <TableShows :table-data="item"/>
          </div>
        </div>
      </div>
    </template>
  </el-drawer>
  <DeleteCustomer v-if="delVisiblePopover" v-model="delVisiblePopover" @close="closeDialog"
                  :multipleSelection="selectTargetList" :getCustomerList="getCustomerList"
                  :isSelectAll="isSelectAll" :searchValue="searchValue"></DeleteCustomer>
</template>
<script setup>
import {ref, onMounted, computed, watch, onBeforeUnmount, nextTick} from "vue";
import {useStore} from "vuex";
import {Delete, DocumentRemove, Download} from "@element-plus/icons-vue";
import {ElMessage, ElTable} from "element-plus";

import Cards from "./Cards.vue";
import TopSearch from "./TopComponents/TopSearch.vue";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import TableShows from "./Tables/TableShows.vue";
import {table_columns_public_list, table_columns_public_2} from './map'
import {
  getMailCustomerList,
  getMailCustomerRecord,
  addMailCustomerRecord,
  getMailCustomerRecordById, batchDeleteCustomerById,
} from "@/api/customer.js";
import {i18n} from "@/lang";
import {emptyUrl, TIME_OUT} from "@/utils/statusParams";
import flag from "@/assets/flag";
import {truncateByBytes} from "../../utils/notifies/tool";
import {flexWidth} from "@/utils/tableWidth";
import {isEmpty, throttle} from "@/utils/mylodash";
import DeleteCustomer from "@/components/DiaPops/DeleteCustomer.vue";

const isSelectAll = ref(false);
const targetTable = ref(null);

// 搜索
const searchValue = ref({});
const getSearchKey = async (value) => {
  const newObj = JSON.parse(JSON.stringify(value));
  searchValue.value = newObj;
  // 列表数据
  await getCustomerList();
  isSelectAll.value ? targetTable.value?.toggleAllSelection() : targetTable.value?.clearSelection()
};

const store = useStore();

// 权限控制
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isAdmin = computed(() => store.getters["user/isAdmin"])

const tableDataSelect = computed(() => {
  return isSuperAdmin.value || isAdmin.value ? [...store.state.groups.AllTableData, {
    userName: i18n.global.t('common.all'),
    userId: 'all'
  }] : store.state.groups.AllTableData;
});

const multipleTableRef = ref();
const multipleSelection = ref();
const handleSelectionChange = (val) => {
  selectTargetList.value = JSON.parse(JSON.stringify(val));
  console.log(val, 'val')
  endIndex = val.length > 1 ? customerList.value.findIndex(item => item.id === val[val.length - 1].id) : -1;
  const startIndex = val.length > 1 ? customerList.value.findIndex(item => item.id === val[val.length - 2].id) : -1;
  console.log(endIndex, startIndex);
  if (isShiftPressed.value && val.length > 1 && endIndex !== startIndex) {
    const start = startIndex < endIndex ? startIndex : endIndex;
    const end = endIndex < startIndex ? startIndex : endIndex;
    // console.log(start, end, '===============');
    for (let i = start; i <= end; i++) {
      const row = customerList.value[i];
      if (start === i || i === end) {
        continue;
      }
      console.log(row, '更新');
      if (row) {
        targetTable.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
      }
    }
    nextTick(() => {
      multipleSelection.value = targetTable.value.getSelectionRows();
      console.log(targetTable.value.getSelectionRows(), '当前选中行');
    });
  } else {
    multipleSelection.value = val;
  }
};

const tableData = ref([{}]);
const dialogVisible = ref(false);
const selectKeys = ref();

// 分页
const currentPublic = ref(1);
const sizePublic = ref(10);
const totalsPublic = ref(0);
const isLoading = ref(false);

const delVisiblePopover = ref(false);

// 全部公海客户列表数据
const customerList = ref([]);
const teamId = computed(() => store.state.email.teamID);
const getCustomerList = async () => {
  isLoading.value = true;
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  let res = await getMailCustomerList({
    ...searchValue.value,
    pageSize: sizePublic.value,
    pageNo: currentPublic.value,
    teamId: teamId.value,
    type: 2,
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  isLoading.value = false;
  const {records, size, total, current} = res.result;
  customerList.value = records;
  totalsPublic.value = total;
  sizePublic.value = size;
  currentPublic.value = res.result.current;
};

const handleCurrentChange = (page) => {
  console.log(page);
  currentPublic.value = page;
  getCustomerList();
}

const handleSizeChange = async (pageSize) => {
  sizePublic.value = pageSize;
  await getCustomerList();
}

const currentDia = ref(1);
const sizeDia = ref(10);
const totalsDia = ref(0);
const isLoadingDia = ref(false);

// 领用记录
const userInfo = computed(() => store.state.user.userInfo)
const handleClickRecent = async () => {
  console.log(userInfo.value, 'userInfo.value')
  dialogVisible.value = true;
  isLoadingDia.value = true;
  selectKeys.value !== 'all' ? selectKeys.value = userInfo.value.id : selectKeys.value = "all";
  await getFollowList()
};

const handleCurrentChangeDia = (page) => {
  console.log(page, selectKeys.value);
  currentDia.value = page;

  handleClickRecent();
};

// const handleSizeChangeDia = (pageSize) => {
//   sizeDia.value = pageSize;
//   handleClickRecent();
// }


// 确认框
const dialogVisiblePopover = ref(false);
// 批量选中的值
const selectTargetList = ref([]);
const myEmailList = ref([]);
const handleClickExport = () => {
  if (selectTargetList.value.length === 0) {
    ElMessage.warning(i18n.global.t('common.email.chooseCustomer'));
    return;
  }
  dialogVisiblePopover.value = true;
};

const handleDelete = async (row, column, $index) => {
  // 领用
  const result = selectTargetList.value.map((item) => ({
    customerId: item.id, customerName: item.customerName
  }));
  let targetObj = null;
  if (selectTargetList.value.length >= 1) {
    // 批量领用
    targetObj = {
      customerDTOList: result,
      teamId: teamId.value,
    };
    let res = await addMailCustomerRecord(targetObj);
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return;
    }
    ElMessage.success(res.message);
    // 章徽
    // store.commit('customer/setApprovalBadge', targetObj.customerDTOList.length)
    await store.dispatch('countshow/getTeamAllDataApis')
    await getCustomerList();
  }
};

const handleDeleteRow = async (row, column, $index) => {

  let targetObj = null;
  if (selectTargetList.value.length === 0) {
    // 行内领用
    targetObj = {
      customerDTOList: [{customerId: row.id, customerName: row.customerName}],
      teamId: teamId.value,
    };
    let res = await addMailCustomerRecord(targetObj);
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return;
    }
    ElMessage.success(res.message);
    // 章徽
    // store.commit('customer/setApprovalBadge', targetObj.customerDTOList.length)
    await store.dispatch('countshow/getTeamAllDataApis')
    await getCustomerList();
  } else {
    ElMessage.warning(i18n.global.t('please.batch.accept'))
  }
}


const closeDialog = () => {
  console.log('关闭', targetTable.value);
  targetTable.value.clearSelection();
  delVisiblePopover.value = false;
}

// const handleSelectionChangeCustomerList = (data) => {
//
// };
// 选择领用人 select

const loading = ref(false);
const clickSelection = async (value) => {
  selectKeys.value = value;
  console.log(selectKeys.value, "selectKeys");
  await getFollowList()
};

const getFollowList = async () => {
  try {
    loading.value = true
    let res = await getMailCustomerRecord({
      userId: selectKeys.value === "all" ? null : selectKeys.value,
      pageSize: sizeDia.value,
      pageNo: currentDia.value,
      teamId: teamId.value,
    });
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return;
    }
    loading.value = false
    const {records, size, total} = res.result;
    tableData.value = records;
    currentDia.value = res.result.current;
    sizeDia.value = size;
    totalsDia.value = total;
  } catch (e) {
    loading.value = false
  }
}

// 删除按钮
const handleClickDelete = throttle(() => {
  console.log(selectTargetList.value);
  if (selectTargetList.value.length === 0) {
    ElMessage.warning(i18n.global.t('common.email.chooseCustomer'));
    return;
  }
  delVisiblePopover.value = true;
}, 2000)

// table 中的 confirm
const requestHandleDelete = (row) => {
  row.popoverVisible = true;
};
const cancelPopover = (row) => {
  console.log(row, "row");
  row.popoverVisible = false;
};
// 抽屉的展示
const isShowDrawers = ref(false);
// 公司信息
const companyInfo = ref([]);
// 营销信息
const marketingInfo = ref([]);
// 联系人信息
const ruleFormCustomer = computed(() => store.state.customer.ruleForm);
const contactList = computed(() => store.state.customer.contactList);
// 联系人
const contactInfo = ref([]);
// 选中的行
const activeItems = ref({});
// 档案信息
const handleClickTargetData = async (row) => {
  isShowDrawers.value = true;
  activeItems.value = row;
  window.localStorage.setItem('customerId', row.id)
  await store.dispatch("customer/getQueryById", {
    customerId: row.id,
  });
  if (ruleFormCustomer.value) {
    const {
      customerName,
      phoneWayList,
      setUpTime,
      economicNature,
      companyWebsite,
      companyAddress,
      companyFax,
      emailWayList,
      companyRemark,
      // ----
      customerType,
      customerLevel,
      product,
      marketingRemark,
    } = ruleFormCustomer.value;
    console.log(ruleFormCustomer.value, "fff");

    companyInfo.value = [
      {name: i18n.global.t('common_customer_name'), value: customerName},
      {
        name: i18n.global.t('common.contact.phone'),
        value:
            phoneWayList.length !== 0
                ? phoneWayList.filter((item) => item.isDefault === 1)[0].content
                : [],
      },
      {name: i18n.global.t('common.establishment'), value: setUpTime},
      {name: i18n.global.t('common.economicnature'), value: economicNature},
      {name: i18n.global.t('common.company.website'), value: companyWebsite},
      {name: i18n.global.t('common.cds2'), value: companyAddress},
      {name: i18n.global.t('common.faxnumber'), value: companyFax},
      {
        name: i18n.global.t('common.cds6'),
        value:
            emailWayList.length !== 0
                ? emailWayList.filter((item) => item.isDefault === 1)[0].content
                : [],
      },
      {name: i18n.global.t('common.cds3'), value: companyRemark},
    ];
    marketingInfo.value = [
      {name: i18n.global.t('common.customer.typeList'), value: customerType},
      {name: i18n.global.t('common_customer_level'), value: customerLevel},
      {name: i18n.global.t('common.interest.product'), value: product},
      {name: i18n.global.t('common.cds3'), value: marketingRemark},
    ];
    // 联系人详情
    await store.commit("customer/setContactPage", {
      page: 1,
      pageSize: 999,
    });
    await store.dispatch("customer/getContactList");
    // 清除数据干扰
    contactInfo.value = [];
    // 联系人详情
    contactInfo.value = contactList.value.map((item) => [
      {name: i18n.global.t('common.name'), value: item.name},
      {name: i18n.global.t('common.edm'), value: item.email},
    ]);
  }
};

watch(() => teamId.value, async () => {
  await getCustomerList();
  await store.dispatch("groups/getUserList");
})

// 获取数据
onMounted(async () => {
  // Shift监听/取消监听
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);
  await store.dispatch("groups/getUserList");
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('keyup', handleKeyUp);
});

const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  console.log(row, columnIndex)
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 11) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 11) {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}

const handleDeleteRowCustomer = throttle(async (row) => {
  const params = {
    customerIdList: [row.id],
    teamId: teamId.value,
    customerOrigin: searchValue.value.customerOrigin,
    customerTypeId: searchValue.value.customerTypeId,
    customerName: searchValue.value.customerName,
    customerCode: searchValue.value.customerCode,
    email: searchValue.value.email,
    zoneAddress: searchValue.value.zoneAddress,
  }
  console.log(row, params, 'row')
  await batchDeleteCustomerById(params).then(res => {
    ElMessage.success(res.result)
    getCustomerList()
  }).catch(e => {
    ElMessage.success(e.message)
  })
}, TIME_OUT)


let endIndex = -1;
// table 选择增加 shift
const isShiftPressed = ref(false); // 标记 Shift 键是否被按下
const handleKeyDown = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    // console.log("进来了-----------------", event.key, event.keyCode);
    isShiftPressed.value = true;
  }
};

const handleKeyUp = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    isShiftPressed.value = false;
  }
};
</script>

<style scoped lang="less">
@import "../../less/page.less";

.table_name {
  font-size: 20px;
}

.top_tips {
  display: flex;
  justify-content: space-between;
}

.pages {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.settings {
  display: flex;
  justify-content: center;
}

:deep(.el-table__cell) {
  text-align: center;
}

.inners {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .el-icon {
    color: #faad14;
    margin-right: 2px;
  }
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
}

:deep(.el-table__header) {
  width: 100% !important;
}

:deep(.el-table__body) {
  width: 100% !important;
}

.pages :deep(.el-pagination .el-select) {
  width: 100px !important;
}

.text_long {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

:deep(.el-table_1_column_4) {
  .el-tooltip {
    margin: auto !important;
  }
}
</style>
