<template>
  <teleport to="body">
  <div class="personal" @click="hanldePopo">
    <div class="content">
      <div class="title">
        <img :src="props.curConversation?.userProfile.avatar" alt="" />
        <div class="name">{{ props.curConversation.remark || props.curConversation.userProfile.nick || props.curConversation.userProfile.userID }}</div>
        <el-icon size="20" color="rgba(51, 51, 51, 1)" @click="closeModal">
          <Close />
        </el-icon>
      </div>

      <div class="message_list" ref="chatRef">
        <div v-if="!historyMessage.isCompleted" @click="loadMoreMessage" class="message_more">更多</div>
        <template v-for="(item, index) in historyMessage.list" :key="index">
          <MessageTimestamp :currTime="item.time" :prevTime="index > 0 ? historyMessage.list[index - 1].time : 0" />
          <MessageTip v-if="item.type === TencentCloudChat.TYPES.MSG_GRP_TIP || isCreateGroupCustomMessage(item)"
            :content="getMessageContent(item)" />
          <template v-else>
            <div :class="['item', item.flow === 'in' ? 'left' : 'right']">
              <img :src="item.avatar" alt="" class="avt" />
              <div class="info">
                <div class="msg" v-if="item.type === TencentCloudChat.TYPES.MSG_TEXT">
                  <MessageText :payload="item.payload" />
                </div>
                <div class="img" v-else-if="item.type === TencentCloudChat.TYPES.MSG_IMAGE">
                  <MessageImage :content="item.payload.imageInfoArray[0]" :messageItem="item"
                    :messageList="historyMessage.list" @previewImage="handleImagePreview(index)" />
                </div>
                <div class="msg file" v-else-if="item.type === TencentCloudChat.TYPES.MSG_FILE">
                  <MessageFile :payload="item.payload" :messageItem="item" :progress="progress" />
                </div>
                <div v-else-if="item.type === TencentCloudChat.TYPES.MSG_VIDEO">
                  <MessageVideo :messageItem="item" 
                                :payload="getVideoContent(item.payload)" 
                                :progress="progress"/>
                </div>
                <div v-else-if="item.type === TencentCloudChat.TYPES.MSG_AUDIO">
                  <MessageAudio  :messageItem="item"  :payload="item.payload" />
                </div>
                <!-- 消息发送失败显示 -->
                <div v-if="item.flow === 'out' && item.status === 'fail'" class="msg_error">!</div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="input">
        <input type="text" 
               class="inp" 
               v-model="inputVal"
               ref="inpRef" 
                @keypress.enter="sendMessage"
               placeholder="请输入消息" />
        <img @click="sendMessage" src="../../assets/send.png" class="send" alt="" />
        <img src="../../assets/emoj.png" @click="openEmojiBox" class="emoj" alt="" />
        <el-upload
          ref="uploadRef"
          class="file"
          :auto-upload="false"
          accept="image/*"
          :on-change="uploadImageSuccess"
        >
          <template #trigger>
            <img src="../../assets/file.png"  alt="" />
          </template>
        </el-upload>
        <el-upload
          ref="uploadRef"
          class="file"
          :auto-upload="false"
          accept="*"
          :on-change="uploadFileSuccess"
        >
          <template #trigger>
            <img src="../../assets/upload.png" class="upload" alt="" />
          </template>
        </el-upload>
        <div v-if="emojiVisible" class="emoji_picker">
          <div class="emoji_item" @click="checkEmoji(item)" v-for="item, index in DEFAULT_BASIC_EMOJI_KEYS" :key="index">
            <img class="emoji_img" :src="DEFAULT_BASIC_EMOJI_URL + DEFAULT_BASIC_EMOJI_URL_MAPPING[item]" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  </teleport>
</template>

<script setup>
import { nextTick, onUnmounted, watch } from 'vue';
import { Close } from '@element-plus/icons-vue';
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import TencentCloudChat from '@tencentcloud/chat';
import { ElUpload } from 'element-plus';

import MessageTimestamp from './components/MessageTimestamp.vue';
import MessageText from './components/MessageText.vue';
import MessageImage from './components/MessageImage.vue';
import MessageTip from './components/MessageTip.vue';
import MessageFile from './components/MessageFile.vue';
import MessageVideo from './components/MessageVideo.vue';
import MessageAudio from './components/MessageAudio.vue';

import { chat } from "../../store/modules/chat";
import { DEFAULT_BASIC_EMOJI_KEYS, DEFAULT_BASIC_EMOJI_URL_MAPPING, DEFAULT_BASIC_EMOJI_URL, convertKeyToEmojiName } from '../../utils/emoji';

const store = useStore();

const chatRef = ref(null);
const uploadRef = ref();

const props = defineProps({
  curConversation: {
    type: Object
  }
})

const emit = defineEmits(['closeToChat']);


onMounted(() => {
  console.log(props.curConversation, '当前会话');  
  chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);

  // 消除未读
  setMessageRead();

  // 查询当前消息列表
  queryMessageList();
})

onUnmounted(() => {
  chat.off(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
})

const getVideoContent = (payload) => {
  return {
    snapshotHeight: payload.snapshotHeight,
    snapshotUrl: payload.snapshotUrl,
    snapshotWidth: payload.snapshotWidth,
    url: payload.videoUrl
  }
}

/**
 * 消息更新处理
 */
const onMessageReceived = ({ data }) => {
  // 消除未读
  setMessageRead();
  historyMessage.value.list.push(...data.filter(item => item.conversationID === props.curConversation.conversationID));
}

/**
 * 未读消息状态设置为已读
 */
const setMessageRead = () => {
  // 将某会话下所有未读消息已读上报
  let promise = chat.setMessageRead({ conversationID: props.curConversation.conversationID });
  promise.then(function (imResponse) {
    // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
  }).catch(function (imError) {
    // 已读上报失败
    console.warn('setMessageRead error:', imError);
  });
}

/**
 * 加载更多
 */
const loadMoreMessage = () => {
  queryMessageList();
  curHeight.value = chatRef.value.scrollHeight;
}

/**
 * 当前历史消息记录
 */
const historyMessage = ref({
  list: [],
  nextReqMessageID: undefined,
  isCompleted: false
});

// 用于处理滚动条高度，点更多获取数据时设置为当前高度，其余时候都为0
const curHeight = ref(0);

watch(() => historyMessage.value.list, () => {
  setTimeout(() => {
    chatRef.value && (chatRef.value.scrollTop = chatRef.value.scrollHeight - curHeight.value);
    curHeight.value = 0;
  }, 0)
}, {
  deep: true
})

watch(() => props.curConversation, (old, newA) => {
  if (old.conversationID === newA.conversationID) {
    return;
  }
  historyMessage.value = {
    list: [],
    nextReqMessageID: undefined,
    isCompleted: false
  };
  queryMessageList();
})

const isCreateGroupCustomMessage = (message) => {
  return (
    message.type === TencentCloudChat.TYPES.MSG_CUSTOM
    && getMessageContent(message)?.businessID === 'group_create'
  );
}

const getMessageContent = (message) => {
  const payload = message.payload;

  if (payload?.data) {
    const data = JSON.parse(payload.data);
    return {
      ...data,
      custom: `${data.opUser} ${data.content}`,
      showName: data.opUser
    }
  }
  return {
    text: payload?.text || ''
  }
}

const closeModal = () => {
  emit('closeToChat');
}

/**
     * 获取消息列表
     */
const queryMessageList = () => {
  /**
   *  会话 ID。会话 ID 组成方式：
   *  C2C${userID}（单聊）
   *  GROUP${groupID}（群聊）
   *  GROUP${topicID}（话题）
   *  @TIM#SYSTEM（系统通知会话）
   */
  // 打开某个会话时，第一次拉取消息列表，注意！第一次拉取时不要传入 nextReqMessageID
  let promise = chat.getMessageList({ conversationID: props.curConversation.conversationID, nextReqMessageID: historyMessage.value.nextReqMessageID });
  promise.then(function (imResponse) {
    historyMessage.value = {
      list: [...imResponse.data.messageList, ...historyMessage.value.list], // 消息列表。
      nextReqMessageID: imResponse.data.nextReqMessageID, // 用于续拉，分页续拉时需传入该字段。
      isCompleted: imResponse.data.isCompleted // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""
    }
  });
}


/**
 * 消息发送
 */
const inputVal = ref("");
const emojiVisible = ref(false);
const inpRef = ref();

nextTick(() => {
  inpRef.value.addEventListener('paste', function(e) {
    let clipboardData = e.clipboardData;
    let file;
    let fileCopy;
    if (clipboardData && clipboardData.files && clipboardData.files.length > 0) {
      file = clipboardData.files[0];
      // 图片消息发送成功后，file 指向的内容可能被浏览器清空，如果接入侧有额外的渲染需求，可以提前复制一份数据
      fileCopy = file.slice();
    }
  
    if (typeof file === 'undefined') {
      console.warn('file 是 undefined，请检查代码或浏览器兼容性！');
      return;
    }
    sendMessageToType(props.curConversation, {
      file
    }, 'image')
  })
})

// 
const openEmojiBox = () => {
  emojiVisible.value =  emojiVisible.value ? false : true;
}

/**
 * 选择emoji
 * @param emoji 图标
 */
const checkEmoji = (emoji) => {
  const opt = {
    emoji: { key: emoji, name: convertKeyToEmojiName(emoji) },
    type: 'basic',
  };
  inputVal.value += opt?.emoji?.name;
}

const hanldePopo = (e) => {
  if([...e.target.classList].includes('emoj')) return;
  if(!([...e.target.classList].includes('emoji_img') || [...e.target.classList].includes('emoji_item'))){
    emojiVisible.value = false;
  }
}


/**
 * 上传成功时的钩子
 * @param response 
 * @param uploadFile 
 * @param uploadFiles 
 */
const uploadImageSuccess = (response, uploadFile, uploadFiles) => {
  console.log(response, uploadFile);
  sendMessageToType(props.curConversation, {
    file: response.raw
  }, 'image');
}

const uploadFileSuccess = (response, uploadFile, uploadFiles) => {
  const extension = response.raw.name.split('.').pop().toLowerCase();
  // 图片格式
  const imageValidExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
  if (imageValidExtensions.includes(extension)) {
    sendMessageToType(props.curConversation, {
      file: response.raw
    }, 'image');
    return;
  }
  // 视频格式
  const videoValidExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm'];
  if (videoValidExtensions.includes(extension)) {
    sendMessageToType(props.curConversation, {
      file: response.raw
    }, 'video');
    return;
  }
  sendMessageToType(props.curConversation, {
    file: response.raw
  }, 'file');
}


/**
 * 发送消息
 */
const sendMessage = () => {
  if(inputVal.value.trim() === '') return;
  sendMessageToType(props.curConversation,  {
      text: inputVal.value
    });
}

const sendMessageToType = (conversation, payload, messageType = 'text') => {
  // console.log(payload, conversation.type, '当前会话类型 ====', TencentCloudChat.TYPES.CONV_C2C);
  const { type, userProfile, groupProfile } = conversation;
  const opt = {
    to: '',
    conversationType: TencentCloudChat.TYPES.CONV_C2C,
    payload
  }
  // 私聊
  if (TencentCloudChat.TYPES.CONV_C2C === type) {
    opt.to = userProfile?.userID;
    opt.conversationType = TencentCloudChat.TYPES.CONV_C2C;
  }
  // 群聊
  if (TencentCloudChat.TYPES.CONV_GROUP === type) {
    opt.to = groupProfile?.groupID;
    opt.conversationType = TencentCloudChat.TYPES.CONV_GROUP;
  }
  // console.log('消息对象配置', opt);  
  // 1. 创建消息实例，接口返回的实例可以上屏
  let message;
  if(messageType === 'text'){
    message = chat.createTextMessage(opt);
  }else if(messageType === 'image'){
    message = chat.createImageMessage(opt);
  } else if (messageType === 'file') {
    opt.onProgress = handleProgress;
    message = chat.createFileMessage(opt);
    historyMessage.value.list.push(message);
  } else if (messageType === 'video') {
    opt.onProgress = handleProgress;
    message = chat.createVideoMessage(opt);
    historyMessage.value.list.push(message);
  }
  // 2. 发送消息
  let promise = chat.sendMessage(message);
  promise.then(function (imResponse) {
    // 发送成功
    console.log('success!!!', imResponse.data.message);
    if (messageType === 'video' || messageType === 'file') {
      // 替换
      historyMessage.value.list = historyMessage.value.list.map(msg => {
        if (msg.ID === imResponse.data.message.ID) {
          return imResponse.data.message;
        }
        return msg;
      });
      // 进度条清0
      progress.value = 0;
    } else {
     historyMessage.value.list.push(imResponse.data.message); 
    }
    inputVal.value = "";
  }).catch(function (imError) {
    // 发送失败
    console.log('sendMessage error:', imError.data.message);
    historyMessage.value.list.push(imError.data.message);
    inputVal.value = "";
  });
}

/**
 * 处理视频进度加载显示
 * @param {Number} progress 进度值
 */
const progress = ref(0);
const handleProgress = (value) => {
  progress.value = Math.floor(value * 100);
}

</script>

<style lang="less" scoped>
.personal {
  background-color: var(--el-overlay-color-lighter);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;

  .content {
    width: 400px;
    position: absolute;
    top: 15vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;

    .title {
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0 12px;
      box-shadow: 0px 1px 6px 0px rgba(0, 21, 41, 0.12);

      >img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      .name {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 16px;
        margin-left: 10px;
        flex-grow: 1;
      }
    }

    .message_list {
      height: 394px;
      overflow-y: auto;
      padding-bottom: 10px;

      .message_more {
        margin: 10px auto;
        color: #999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .time {
        margin: 10px auto 0;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 12px;
        text-align: center;
      }

      .item {
        display: flex;
        align-items: end;
        padding: 0 10px;
        margin-top: 10px;

        .avt {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .msg {
          background: #EFEFEF;
          padding: 7px 10px;
          border-radius: 6px;
          max-width: 288px;
          box-sizing: border-box;
        }

        .voice {
          background: #EFEFEF;
          padding: 7px 10px;
          border-radius: 6px;
          display: flex;
          min-width: 90px;
          box-sizing: border-box;
          align-items: center;

          .icon {
            width: 22px;
            height: 22px;
          }

          .val {
            margin-left: 8px;
            font-weight: 400;
            font-size: 14px;
            color: #444444;
          }
        }

        &.left {
          justify-content: flex-start;

          .info {
            margin-left: 4px;

            .msg {
              border-bottom-left-radius: 0;
            }
          }
        }

        &.right {
          // justify-content: flex-end;
          flex-direction: row-reverse;

          .info {
            margin-right: 4px;
            display: flex;
            flex-direction: row-reverse;
            align-items: end;

            .msg {
              background-color: rgba(29, 57, 196, 1);
              border-bottom-right-radius: 0;
              color: #fff;

                &.file{
                  background-color: #f0f0f0;
                  color: #333;
                }
            }

            .msg_error{
              width: 18px;
              height: 18px;
              font-size: 12px;
              line-height: 18px;
              background: red;
              border-radius: 50%;
              color: #fff;
              text-align: center;
              margin: 0 5px;
            }
          }
        }
      }
    }

    .input {
      height: 50px;
      background: #F8F8F8;
      display: flex;
      align-items: center;
      position: relative;

      .inp {
        background: #FFFFFF;
        border-radius: 15px;
        border: 1px solid #EFEFEF;
        height: 30px;
        margin-left: 20px;
        width: 224px;
        padding: 0 8px;
        box-sizing: border-box;
      }

      >img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
      }

      >.file{
        width: 24px;
        height: 24px;
        margin-left: 10px;
        img{
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}


.emoji_picker {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 400px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding: 0 8px;
  background-color: #F8F8F8;
  height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none
  }

  .emoji_item {
    padding: 5px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
