import { chat } from "../../store/modules/chat";
// import TencentCloudChat from "@tencentcloud/chat";

export async function sendMessages(messageList, currentConversation) {
  // In case of messageJumping, the sent message is automatically cleared and returns to the bottom
  messageList?.forEach(async (content) => {
    try {
      let options = {
        to: currentConversation?.groupProfile?.groupID || currentConversation?.userProfile?.userID,
        conversationType: currentConversation?.type,
        payload: {},
        // needReadReceipt: isEnabledMessageReadReceiptGlobal(),
      };
      let textMessageContent;
      switch (content?.type) {
        case 'text':
          textMessageContent = JSON.parse(JSON.stringify(content.payload?.text));
          // Do not send empty messages
          if (!textMessageContent) {
            break;
          }
          options.payload = {
            text: textMessageContent,
          };
          options = chat.createTextMessage(options);
          break;
        case 'image':
          options.payload = {
            file: content.payload?.file,
          };
          options = chat.createImageMessage(options);
          break;
        case 'video':
          options.payload = {
            file: content.payload?.file,
          };
          options = chat.createVideoMessage(options);
          break;
        case 'file':
          options.payload = {
            file: content.payload?.file,
          };
          options = chat.createFileMessage(options);
          break;
        default:
          break;
      }
      // enableSampleTaskStatus('sendMessage');
      console.log(options, 'options');      
      console.log(content, 'content');      
      chat.sendMessage(options).then((imResponse) => {
        console.log(imResponse, '发送消息成功');        
      }).catch((imError) => {
        // 发送失败
        console.warn('sendMessage error:', imError);
      });
    } catch (error) {
      console.log(error, '发送失败');      
      uni.showToast({
        message: '发送失败',
      });
    }
  });
};


// calculate timestamp
export function calculateTimestamp(timestamp) {
  const todayZero = new Date().setHours(0, 0, 0, 0);
  const thisYear = new Date(
    new Date().getFullYear(),
    0,
    1,
    0,
    0,
    0,
    0,
  ).getTime();
  const target = new Date(timestamp);

  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * oneDay;

  const diff = todayZero - target.getTime();

  function formatNum(num) {
    return num < 10 ? '0' + num : num.toString();
  }

  if (diff <= 0) {
    // today, only display hour:minute
    return `${formatNum(target.getHours())}:${formatNum(target.getMinutes())}`;
  } else if (diff <= oneDay) {
    // yesterday, display yesterday:hour:minute
    return `昨天 ${formatNum(
      target.getHours(),
    )}:${formatNum(target.getMinutes())}`;
  } else if (diff <= oneWeek - oneDay) {
    // Within a week, display weekday hour:minute
    const weekdays = [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ];
    const weekday = weekdays[target.getDay()];
    return `${weekday} ${formatNum(
      target.getHours(),
    )}:${formatNum(target.getMinutes())}`;
  } else if (target.getTime() >= thisYear) {
    // Over a week, within this year, display mouth/day hour:minute
    return `${target.getMonth() + 1}/${target.getDate()} ${formatNum(
      target.getHours(),
    )}:${formatNum(target.getMinutes())}`;
  } else {
    // Not within this year, display year/mouth/day hour:minute
    return `${target.getFullYear()}/${
      target.getMonth() + 1
    }/${target.getDate()} ${formatNum(target.getHours())}:${formatNum(
      target.getMinutes(),
    )}`;
  }
}