<template>
  <!--  <div>我的文件</div>-->
  <SideFile :type="6"></SideFile>
</template>

<script setup>
import SideFile from "@/views/Cloud/Components/SideFile.vue";
</script>

<style lang="less" scoped>
</style>