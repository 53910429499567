<template>
  <Guide :titleList="titleList" :isHasChild="true" :titleChildList="titleChildList">
    <template #innerContent>
      <div style="width: 100%;height: 50px;margin-top: 30px">
      </div>
      <h1>感谢您使用 MailTalk 的邮件功能！</h1>
      <div
          id="part1"
          style="
              height: max-content;
              margin-top: 30px;
            "
      >
        <h2><span @click="isShow1=!isShow1"><el-icon v-if="!isShow1"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          1、注意事项
        </h2>
        <el-collapse-transition>
          <div v-show="isShow1">
            <p class="bold">
              第一次绑定邮箱的时候，需要您填写<span class="red">邮箱授权码（第三方安全码/客户端密码）</span>才能成功关联。
            </p>
            <p class="bold">
              注意，对于大多数品牌的邮箱，<span class="red">邮箱授权码≠邮箱密码（仅个别品牌可直接用邮件密码）</span>，授权码需要到邮箱后台生成，一般是一串大小写混合的英文字母。生成授权码时，设备的备注名可以自由填写，不影响后续使用，并且生成后记得<span
                class="red">点击保存</span>，方可生效。
            </p>
            <p class="bold">
              <span
                  class="red">邮箱协议类型可以选择POP或者IMAP，对应的服务器地址和端口需到企业邮箱官网查询，或者百度搜索。</span>
            </p>
            <p class="bold">
              以下是获取邮箱授权码的操作引导，可根据您的邮箱品牌查看，如果不在此列，可咨询企业邮箱服务商。
            </p>
            <hr/>
            <ul>
              <li><span class="cicle"></span>126 邮箱 （后缀为@126.com）</li>
              <li><span class="cicle"></span>163 邮箱 （后缀为@163.com）</li>
              <li><span class="cicle"></span>新浪邮箱 （后缀为@sina.com）</li>
              <li><span class="cicle"></span>QQ 邮箱 （后缀为@qq.com）</li>
              <li><span class="cicle"></span>搜狐邮箱 （后缀为@sohu.com）</li>
              <li><span class="cicle"></span>139邮箱 （后缀为@139.com）</li>
              <li><span class="cicle"></span>wo 邮箱 （后缀为@wo.cn）</li>
              <li><span class="cicle"></span>88 邮箱 （后缀为@88.com）</li>
              <li><span class="cicle"></span>Microsoft 邮箱 （含Outlook 及 Hotmail 邮箱，后缀为@outlook.com和@hotmail.com）
              </li>
              <li><span class="cicle"></span>谷歌Gmail邮箱 （后缀为@gmail.com）</li>
              <li><span class="cicle"></span>阿里云企业邮箱</li>
              <li><span class="cicle"></span>腾讯云企业邮箱</li>
              <li><span class="cicle"></span>263 邮箱 （后缀为@263.com）</li>
            </ul>
            <hr/>
            <p class="bold">
              此外，部分邮箱也对收取邮件日期做了限制，您如需 MailTalk 获取全部邮件也需要手动导入。
              具体每个邮箱的 <span class="red">邮箱授权码获取</span> 和 <span class="red">收取邮件日期</span>
              设置步骤如下，需要更多帮助可以联系我们。
            </p>
            <hr/>
          </div>
        </el-collapse-transition>
      </div>
      <div
          id="part2"
          style="
              height: max-content;
              margin-top: 30px;
            "
      >
        <h2>
          <span @click="isShow2=!isShow2"><el-icon v-if="!isShow2"><CaretRight/></el-icon><el-icon v-else><CaretBottom/></el-icon></span>
          2、邮箱配置
        </h2>
        <el-collapse-transition>
          <div v-show="isShow2">
            <!--              -->
            <div id="part_child1"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow1Child=!isShow1Child"><el-icon v-if="!isShow1Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                1、126 邮箱（后缀为@126.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow1Child">
                  <p class="bold">
                    输入登录网址 https://www.126.com/ 或 <a href="https://www.126.com/">点击这里</a>，输入邮箱地址和密码，登录自己的
                    126 邮箱。
                    点击邮箱右上角 ，进入 <span class="red">POP3/SMTP/IMAP</span> 设置。
                  </p>
                  <ImageShow :url="image1"></ImageShow>
                  <ImageShow :url="image2"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child2"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow2Child=!isShow2Child"><el-icon v-if="!isShow2Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                2、163 邮箱（后缀为@163.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow2Child">
                  <p class="bold">
                    输入登录网址 https://mail.163.com/ 或 <a href="https://mail.163.com/">点击这里</a>，输入邮箱地址和密码，登录自己的
                    163 邮箱。
                    点击邮箱右上角设置，进入 <span class="red">POP3/SMTP/IMAP</span> 设置。
                  </p>
                  <ImageShow :url="image3"></ImageShow>
                  <hr/>
                  <p class="bold">
                    红框 1：选择开启服务。
                  </p>
                  <p class="bold">
                    红框 2：如果想要 MailTalk 导入全部邮件，则需要在收取选项处选择
                    <span class="red">收取全部邮件。</span>
                  </p>
                  <p class="bold">
                    红框 3：管理授权密码，点击下方<span class="red">新增授权密码</span>按钮，即可获得新的邮箱授权码。
                  </p>
                  <ImageShow :url="image4"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child3"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow3Child=!isShow3Child"><el-icon v-if="!isShow3Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                3、新浪邮箱（后缀为@sina.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow3Child">
                  <p class="bold">
                    输入登录网址 https://mail.sina.com.cn/ 或 <a href="https://mail.sina.com.cn/">点击这里</a>，输入邮箱地址和密码，登录自己的新浪邮箱。
                    点击邮箱右上角的<span class="red">设置</span>按钮。
                  </p>
                  <ImageShow :url="image5"></ImageShow>
                  <hr/>
                  <p class="bold">
                    左侧红框：选择<span class="red">客户端 POP/IMAP/SMTP</span>，进入界面。
                  </p>
                  <p class="bold">
                    右侧红框 1：授权码状态选择<span class="red">开启</span>，下方点击<span class="red">重置授权码</span>，获取授权码。
                  </p>
                  <p class="bold">
                    右侧红框 2 和 3：建议服务状态都选择<span>开启</span>，收取设置都选择<span
                      class="red">收取全部邮件</span>。
                  </p>
                  <ImageShow :url="image6"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child4"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow4Child=!isShow4Child"><el-icon v-if="!isShow4Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                4、QQ邮箱（后缀为@qq.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow4Child">
                  <p class="bold">
                    输入登录网址 https://mail.qq.com/ 或 <a href="https://mail.qq.com/">点击这里</a>，输入邮箱地址和密码，登录自己的邮箱。
                    点击账号下方的<span class="red">设置</span>按钮，进入设置界面。
                  </p>
                  <ImageShow :url="image7"></ImageShow>
                  <hr/>
                  <p class="bold">
                    在邮箱设置界面，<span class="red">选择账户</span>。
                  </p>
                  <ImageShow :url="image8"></ImageShow>
                  <hr/>
                  <p class="bold">
                    下拉至 <span class="red">POP3/IMAP/SMTP/Exchange/CardDAV/CalDAV</span> 服务板块。
                  </p>
                  <p class="bold">
                    红框 1：开启服务，<span class="red">开启前两项</span>，开启后会弹出验证码。
                  </p>
                  <p class="bold">
                    红框 2：：如果想要 MailTalk 导入全部邮件，则需要在收取选项处选择<span class="red">收取全部邮件</span>。
                  </p>
                  <ImageShow :url="image9"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child5"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow5Child=!isShow5Child"><el-icon v-if="!isShow5Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                5、搜狐邮箱（后缀为@sohu.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow5Child">
                  <p class="bold">
                    输入登录网址 https://mail.sohu.com 或 <a href="https://mail.sohu.com">点击这里</a>，输入邮箱地址和密码，登录自己的搜狐邮箱。
                    登录后，点击导航栏中的<span class="red">选项</span>，选择<span class="red">设置</span>。
                  </p>
                  <ImageShow :url="image10"></ImageShow>
                  <hr/>
                  <p class="bold">
                    点击右侧基本信息里的邮件服务 - <span class="red">POP3/SMTP/IMAP</span> 一项。
                  </p>
                  <ImageShow :url="image11"></ImageShow>
                  <hr/>
                  <p class="bold">
                    进入设置页面
                  </p>
                  <p class="bold">
                    红框 1：两项全部勾选
                  </p>
                  <p class="bold">
                    红框 2：点击<span class="red">重置独立密码按钮</span>
                  </p>
                  <ImageShow :url="image12"></ImageShow>
                  <hr/>
                  <p class="bold">
                    进入手机验证弹窗，点击<span class="red">获取验证码</span>，输入邮箱注册手机上接受到的验证码，点击<span
                      class="red">确定</span>
                  </p>
                  <ImageShow :url="image13"></ImageShow>
                  <hr/>
                  <p class="bold">
                    生成 12 位的独立密码，复制到 MailTalk 的密码栏即可。
                  </p>
                  <ImageShow :url="image14"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child6"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow6Child=!isShow6Child"><el-icon v-if="!isShow6Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                6、139邮箱（后缀为@139.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow6Child">
                  <p class="bold">
                    输入登录网址 https://mail.10086.cn/ 或 <a href="https://mail.10086.cn/">点击这里</a>，输入邮箱地址和密码，登录自己的
                    139 邮箱。
                    点击首页<span class="red">右侧头像</span>，下方的<span class="red">账号管理</span>按钮，进入设置界面。
                  </p>
                  <ImageShow :url="image15"></ImageShow>
                  <hr/>
                  <p class="bold">
                    在设置界面，下拉或直接左侧选择<span class="red">邮箱协议设置。</span>
                  </p>
                  <p class="bold">
                    右侧红框 1：服务开关，两项开启
                  </p>
                  <p class="bold">
                    右侧红框 2：此处查看邮箱授权码
                  </p>
                  <p class="bold">
                    右侧红框 3：收取选项，建议选择收取全部邮件
                  </p>
                  <p class="bold">
                    设置完点击页面下方的<span class="red">保存</span>按钮
                  </p>
                  <ImageShow :url="image16"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child7"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow7Child=!isShow7Child"><el-icon v-if="!isShow7Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                7、wo 邮箱（后缀为@wo.cn）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow7Child">
                  <p class="bold">
                    输入登录网址 https://mail.wo.cn/ 或 <a href="https://mail.wo.cn/">点击这里</a>，输入邮箱地址和密码，登录自己的
                    wo 邮箱。在首页，<span class="red">点击头像</span>，点击进入<span class="red">个人设置</span>。
                  </p>
                  <ImageShow :url="image17"></ImageShow>
                  <hr/>
                  <ImageShow :url="image18"></ImageShow>
                  <hr/>
                  <p class="bold">
                    点击<span class="red">个人信息</span>板块的<span class="red">密码和别名</span>板块。
                  </p>
                  <p class="bold">
                    在下方，客户端专用密码右侧，点击加号。
                  </p>
                  <ImageShow :url="image19"></ImageShow>
                  <hr/>
                  <p class="bold">
                    点击加号后，跳出<span class="red">专用密码生成弹窗</span>，输入任意的密码名称。
                  </p>
                  <ImageShow :url="image20"></ImageShow>
                  <p class="bold">
                    <span class="red">点击生成</span>，即可生成专用的 16 位密码。<span>点击复制</span>可直接复制到
                    MailTalk。
                  </p>
                  <ImageShow :url="image21"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child8"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow8Child=!isShow8Child"><el-icon v-if="!isShow8Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                8、88邮箱（后缀为@88.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow8Child">
                  <p class="bold">
                    输入登录网址 https://www.88.com/#/ 或 <a href="https://www.88.com/#/">点击这里</a>，输入邮箱地址和密码，登录自己的
                    88 邮箱。在首页，<span class="red">点击头像</span>，点击进入<span class="red">个人设置</span>。
                  </p>
                  <ImageShow :url="image22"></ImageShow>
                  <hr/>
                  <ImageShow :url="image23"></ImageShow>
                  <hr/>
                  <p class="bold">
                    在设置界面，选择<span class="red">邮箱设置，客户端设置</span>。
                  </p>
                  <p class="bold">
                    右侧红框 1：两项全部开启
                  </p>
                  <p class="bold">
                    右侧红框 2：专用密码，点击<span class="red">新建专用密码</span>
                  </p>
                  <ImageShow :url="image24"></ImageShow>
                  <hr/>
                  <p class="bold">
                    输入注册邮箱时的手机号码、手机验证码和名称，点击确定
                  </p>
                  <ImageShow :url="image25"></ImageShow>
                  <hr/>
                  <p class="bold">
                    即可生成专用的 16 位密码。点击<span class="red">复制密码</span>可直接复制到 MailTalk。
                  </p>
                  <ImageShow :url="image26"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child9"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow9Child=!isShow9Child"><el-icon v-if="!isShow9Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                9、Microsoft 邮箱（含Outlook和Hotmail，即后缀为@outlook.com 或 @hotmail.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow9Child">
                  <p class="bold">
                    输入登录网址 https://outlook.live.com 或 <a href="https://outlook.live.com">点击这里</a>，输入邮箱地址和密码，登录自己的
                    outlook 邮箱。
                    点击首页右侧，从右往左数第三个齿轮状按钮。
                  </p>
                  <ImageShow :url="image27"></ImageShow>
                  <hr/>
                  <p class="bold">
                    在下方界面中下拉至底部，选择<span class="red">查看全部 Outlook 设置。</span>
                  </p>
                  <ImageShow :url="image28"></ImageShow>
                  <hr/>
                  <p class="bold">
                    进入设置界面后，选择<span class="red">邮件-同步电子邮件，</span>下拉至 POP 和 IMAP 板块
                  </p>
                  <p class="bold">
                    第一项：允许设备和应用使用 POP，选择是
                  </p>
                  <p class="bold">
                    第二项：使用 POP 的设备和应用可设置为在下载后删除来自 Outlook 的邮件，选择<span class="red">允许应用
                    和设备删除来自 Outlook 的邮件</span>
                  </p>
                  <p class="bold">
                    设置完后，点击右下角的<span class="red">保存</span>按钮。
                  </p>
                  <ImageShow :url="image29"></ImageShow>
                  <hr/>
                  <p class="bold">
                    回到主页，点击右上角最右侧的头像，点击<span class="red">我的个人资料</span>
                  </p>
                  <ImageShow :url="image30"></ImageShow>
                  <hr/>
                  <p class="bold">
                    在个人资料界面，点击<span class="red">安全</span>板块，点击<span class="red">高级安全选项</span>
                  </p>
                  <ImageShow :url="image31"></ImageShow>
                  <hr/>
                  <p class="bold">
                    进入高级安全选项界面后，首先开启右侧的<span class="red">双重验证</span>，根据开启步骤操作即可。
                    开启后，再下拉至下方的<span class="red">应用密码</span>板块，点击<span
                      class="red">创建新应用密码</span>
                  </p>
                  <ImageShow :url="image32"></ImageShow>
                  <ImageShow :url="image33"></ImageShow>
                  <ImageShow :url="image34"></ImageShow>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child10"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow10Child=!isShow10Child"><el-icon v-if="!isShow10Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                10、谷歌Gmail邮箱（后缀为@gmail.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow10Child">
                  <p class="bold">
                    进入谷歌主页
                    https://www.google.com，登录谷歌账号，点击右上角的账号头像-管理账户，进入谷歌账户后台，如下图所示，选择安全性，点击两步验证。如两步验证处于关闭状态，需开启才能继续。
                  </p>
                  <ImageShow :url="image35"></ImageShow>
                  <hr/>
                  <p class="bold">
                    页面拉到底部，应用专用密码，点击进入创建界面
                  </p>
                  <ImageShow :url="image36"></ImageShow>
                  <p class="bold">
                    谷歌开启了两步验证，但页面拉到底部依旧没有发现应用密码的选项，可以试试直接打开下方的链接，看能不能直接跳转到到应用密码后台，https://myaccount.google.com/apppasswords
                  </p>
                  <hr/>
                  <p class="bold">
                    点击创建按钮，可以生成应用专用密码，即邮箱授权码，应用名称可自定义，不影响使用，仅备注功能
                  </p>
                  <ImageShow :url="image37"></ImageShow>
                  <hr/>
                  <p class="bold">
                    生成下图所示的授权码，点击完成保存，并复制粘贴进Mailtalk后台的指定位置即可。每个授权码通常都是一次性的，也只展示这一次，做好备份工作，如果忘记也没关系，再次生成，重新绑定就可以，不影响后续邮箱使用
                  </p>
                  <ImageShow :url="image38"></ImageShow>
                  <hr/>
                  <p class="bold">
                    绑定邮箱界面，填写完邮箱和授权码之后需要填服务器端口，谷歌邮箱的端口可参考下图所示
                  </p>
                  <ImageShow :url="image39"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child11"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow11Child=!isShow11Child"><el-icon v-if="!isShow11Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                11、阿里云企业邮箱
              </h3>
              <el-collapse-transition>
                <div v-show="isShow11Child">
                  <p class="bold">
                    登录阿里云企业邮箱后台，点击右上角齿轮图标设置，账户安全-三方客户端安全密码，打钩，点击生成新密码
                  </p>
                  <ImageShow :url="image40"></ImageShow>
                  <hr/>
                  <p class="bold">
                    注意：如果对应位置没有找到生成第三方密的选项，则需要去企业邮箱管理账号确认
                  </p>
                  <p class="bold">
                    1、安全管理-禁止第三方客户端登录，已经关闭。
                  </p>
                  <ImageShow :url="imageAdd1"></ImageShow>
                  <hr/>
                  <p class="bold">
                    2、组织与用户-批量设置，服务开关已打开，或者直接咨询客户经理。
                  </p>
                  <ImageShow :url="imageAdd2"></ImageShow>
                  <hr/>
                  <p class="bold">
                    一般会需要使用注册手机收取验证短信，继续操作
                  </p>
                  <ImageShow :url="image41"></ImageShow>
                  <hr/>
                  <p class="bold">
                    即可生成下图所示的邮箱授权码，点击确定保存，并复制粘贴进Mailtalk后台的指定位置即可。每个授权码通常都是一次性的，也只展示这一次，做好备份工作，如果忘记也没关系，再次生成，重新绑定就可以，不影响后续邮箱使用。设备名称可以自由填写，仅备注，不影响识别和验证。
                  </p>
                  <ImageShow :url="image42"></ImageShow>
                  <hr/>
                  <p class="bold">
                    绑定界面填完邮箱和授权码后，需要填服务器端口，阿里云的POP/IMAP/SMTP服务器端口可参考下图填写
                  </p>
                  <ImageShow :url="image43"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child12"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow12Child=!isShow12Child"><el-icon v-if="!isShow12Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                12、腾讯云企业邮箱
              </h3>
              <el-collapse-transition>
                <div v-show="isShow12Child">
                  <p class="bold">
                    进入腾讯云企业邮箱后台，点击顶部导航栏的设置，选择收发信设置，点击开启IMAP和POP服务
                    页面中间的设置方法里的“接受服务器”和“发送服务器”可以记录下，稍后绑定邮箱还会用到的
                  </p>
                  <ImageShow :url="image44"></ImageShow>
                  <hr/>
                  <p class="bold">
                    开启服务之后，打开邮箱绑定界面，选择开启安全登录
                  </p>
                  <ImageShow :url="image45"></ImageShow>
                  <hr/>
                  <p class="bold">
                    客户端专用密码-生成新密码，即为邮箱授权码，点击确定保存，并复制粘贴进Mailtalk后台的指定位置即可。每个授权码通常都是一次性的，也只展示这一次，做好备份工作，如果忘记也没关系，再次生成，重新绑定就可以，不影响后续邮箱使用。设备名称可以自由填写，仅备注，不影响识别和验证。
                  </p>
                  <ImageShow :url="image46"></ImageShow>
                  <hr/>
                  <p class="bold">
                    绑定邮箱界面，填写完邮箱和授权码之后需要填服务器端口，如下图所示
                  </p>
                  <ImageShow :url="image47"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
            <!--            -->
            <div id="part_child13"
                 style="
              height: max-content;
              margin-top: 30px;
            ">
              <h3>
                <span @click="isShow13Child=!isShow13Child"><el-icon v-if="!isShow13Child"><CaretRight/></el-icon><el-icon
                    v-else><CaretBottom/></el-icon></span>
                13、263邮箱（后缀为@263.com）
              </h3>
              <el-collapse-transition>
                <div v-show="isShow13Child">
                  <p class="bold">
                    进入263企业邮箱后台，点击右上角头像，菜单中选择设置
                  </p>
                  <ImageShow :url="image48"></ImageShow>
                  <hr/>
                  <p class="bold">
                    左侧导航栏，点击文件夹设置，如下图所示修改管理信息。<span class="red">注意，下方列表的“已归档”中是否有邮件，如果有的话，需要前往收件箱列表，把已归档下的所有邮件都转移到收件箱才行。</span>
                  </p>
                  <ImageShow :url="image49"></ImageShow>
                  <hr/>
                  <ImageShow :url="image50"></ImageShow>
                  <hr/>
                  <p class="bold">
                    左侧导航栏，点击账号安全，客户端授权码-新增授权码
                  </p>
                  <ImageShow :url="image51"></ImageShow>
                  <hr/>
                  <p class="bold">
                    即可生成如下的授权码，点击确定保存，并复制粘贴进Mailtalk后台的指定位置即可。密码用途可以随意备注，不影响验证，仅仅是备注，可以写“Mailtalk邮箱关联”都行。每个授权码通常都是一次性的，也只展示这一次，做好备份工作，如果忘记也没关系，再次生成，重新绑定就可以，不影响后续邮箱使用。
                  </p>
                  <ImageShow :url="image52"></ImageShow>
                  <hr/>
                  <p class="bold">
                    绑定邮箱界面，填写完邮箱和授权码之后需要填服务器端口，如下图所示
                  </p>
                  <ImageShow :url="image53"></ImageShow>
                  <hr/>
                </div>
              </el-collapse-transition>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div style="width: 100%;height: 300px;margin-top: 30px">
      </div>
    </template>

  </Guide>
</template>

<script setup>
import image1 from "@/assets/docimg/image1.png";
import image2 from "@/assets/docimg/image2.png";
import image3 from "@/assets/docimg/image3.png";
import image4 from "@/assets/docimg/image4.png";
import image5 from "@/assets/docimg/image4.png";
import image6 from "@/assets/docimg/image4.png";
import image7 from "@/assets/docimg/image7.png";
import image8 from "@/assets/docimg/image8.png";
import image9 from "@/assets/docimg/image9.png";
import image10 from "@/assets/docimg/image10.png";
import image11 from "@/assets/docimg/image11.png";
import image12 from "@/assets/docimg/image12.png";
import image13 from "@/assets/docimg/image13.png";
import image14 from "@/assets/docimg/image14.png";
import image15 from "@/assets/docimg/image15.png";
import image16 from "@/assets/docimg/image16.png";
import image17 from "@/assets/docimg/image17.png";
import image18 from "@/assets/docimg/image18.png";
import image19 from "@/assets/docimg/image19.png";
import image20 from "@/assets/docimg/image20.png";
import image21 from "@/assets/docimg/image21.png";
import image22 from "@/assets/docimg/image22.png";
import image23 from "@/assets/docimg/image23.png";
import image24 from "@/assets/docimg/image24.png";
import image25 from "@/assets/docimg/image25.png";
import image26 from "@/assets/docimg/image26.png";
import image27 from "@/assets/docimg/image27.png";
import image28 from "@/assets/docimg/image28.png";
import image29 from "@/assets/docimg/image29.png";
import image30 from "@/assets/docimg/image30.png";
import image31 from "@/assets/docimg/image31.png";
import image32 from "@/assets/docimg/image32.png";
import image33 from "@/assets/docimg/image33.png";
import image34 from "@/assets/docimg/image34.png";
import image35 from "@/assets/docimg/image35.png";
import image36 from "@/assets/docimg/image36.png";
import image37 from "@/assets/docimg/image37.png";
import image38 from "@/assets/docimg/image38.png";
import image39 from "@/assets/docimg/image39.png";
import image40 from "@/assets/docimg/image40.png";
import image41 from "@/assets/docimg/image41.png";
import image42 from "@/assets/docimg/image42.png";
import image43 from "@/assets/docimg/image43.png";
import image44 from "@/assets/docimg/image44.png";
import image45 from "@/assets/docimg/image45.png";
import image46 from "@/assets/docimg/image46.png";
import image47 from "@/assets/docimg/image47.png";
import image48 from "@/assets/docimg/image48.png";
import image49 from "@/assets/docimg/image49.png";
import image50 from "@/assets/docimg/image50.png";
import image51 from "@/assets/docimg/image51.png";
import image52 from "@/assets/docimg/image52.png";
import image53 from "@/assets/docimg/image53.png";

import imageAdd1 from "@/assets/docimg/imageAdd1.jpg"
import imageAdd2 from "@/assets/docimg/imageAdd2.jpg"

import Guide from "@/views/FileDoc/components/Guide.vue";
import ImageShow from "@/views/FileDoc/components/ImageShow.vue";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";

const router = useRouter()


const flags = true
const isShow1 = ref(flags);
const isShow2 = ref(flags)
const isShow1Child = ref(flags)
const isShow2Child = ref(flags)
const isShow3Child = ref(flags)
const isShow4Child = ref(flags)
const isShow5Child = ref(flags)
const isShow6Child = ref(flags)
const isShow7Child = ref(flags)
const isShow8Child = ref(flags)
const isShow9Child = ref(flags)
const isShow10Child = ref(flags)
const isShow11Child = ref(flags)
const isShow12Child = ref(flags)
const isShow13Child = ref(flags)

const titleList = [];
const titleChildList = []

const listMap = {
  1: "注意事项",
  2: "邮箱配置",
}
const listMapChild = {
  1: "126 邮箱（后缀为@126.com）",
  2: "163 邮箱（后缀为@163.com）",
  3: "新浪邮箱（后缀为@sina.com）",
  4: "QQ邮箱（后缀为@qq.com）",
  5: "搜狐邮箱（后缀为@sohu.com）",
  6: "139邮箱（后缀为@139.com）",
  7: "wo 邮箱（后缀为@wo.cn）",
  8: "88邮箱（后缀为@88.com）",
  9: "Microsoft 邮箱（含Outlook和Hotmail，即后缀为@outlook.com 或 @hotmail.com）",
  10: "谷歌Gmail邮箱（后缀为@gmail.com）",
  11: "阿里云企业邮箱",
  12: "腾讯云企业邮箱",
  13: "263邮箱（后缀为@263.com）"
}

const createList = () => {
  for (let i = 1; i <= Object.keys(listMap).length; i++) {
    titleList.push({href: `#part${i}`, title: `${i}.${listMap[i]}`});
  }
}

const createListChild = () => {
  for (let i = 1; i <= Object.keys(listMapChild).length; i++) {
    titleChildList.push({href: `#part_child${i}`, title: `${i}.${listMapChild[i]}`});
  }
}


const containerRef = ref(null)

const handleClick = (e) => {
  e.preventDefault()
}

const rebackHome = () => {
  router.push("/dashboard")
}

onMounted(() => {
  createList()
  createListChild()
})
</script>
<style lang="less" scoped>
@import "../index";

:deep(.el-icon) {
  font-size: 16px !important;
}
</style>