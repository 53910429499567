<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ isQs == 1 ? $t('common.as9') : $t('common.bjgrxx') }}</div>
      <!-- 订单表格 -->
      <div class="content">
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
            :label-position="'top'"
        >
          <el-form-item id="avatar">
            <AvatarUpload @update:modelValue="getAvatarUrl" :isQs="isQs"
                          :imgUrl="isQs===1?ruleForm.logo?ruleForm.logo:require('@/assets/default/com.png'):ruleForm.avatar?ruleForm.avatar:require('@/assets/default/avatar.png')"
            />

          </el-form-item>
          <el-form-item v-if="isQs===1" :label="$t('common.label.enterprise.name')" prop="comName">
            <el-input v-model="ruleForm.comName"/>
          </el-form-item>
          <el-form-item v-else :label="$t('common.zhmc')" prop="name">
            <el-input v-model="ruleForm.name"/>
          </el-form-item>
          <template v-if="isQs===1">
            <el-form-item :label="$t('common.contact.phone')" prop="phone">
              <el-input v-model="ruleForm.phone"/>
            </el-form-item>
            <el-form-item :label="$t('common.dzyx')" prop="email">
              <el-input v-model="ruleForm.email"/>
            </el-form-item>
          </template>
          <el-form-item v-if="isQs===0" :label="$t('common.sex')" prop="sex">
            <el-select v-model="ruleForm.sex" class="m-2" :placeholder="$t('please.select.sex')">
              <el-option
                  v-for="item in optionsSex"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.cds64')" prop="country">
            <el-select
                v-model="ruleForm.country"
                class="m-2"
                filterable
                :placeholder="$t('please.select.country')"
            >
              <el-option
                  v-for="item in areaList"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div id="buttons">
              <el-button type="primary" @click="handleSaves">{{ $t('common.save') }}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, onMounted, computed} from "vue";
import {useStore} from "vuex";
import AvatarUpload from "./EditInfo/AvatarUpload.vue";
import router from "@/router";
import {mailCompanyEdit, mailFrontUserEdit} from "@/api/usercenter";
import {ElMessage} from "element-plus";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import {i18n} from "@/lang";

const store = useStore();
const ruleForm = ref({});

const optionsSex = ref([
  {label: i18n.global.t('common.man'), value: 1},
  {label: i18n.global.t('common.woman'), value: 0},
]);
const userInfo = ref({})
const companyInfo = ref({})
// 标记是否是 企业账号 1 是企业账号 0 非企业账号
const isQs = ref(0)

// 区域列表
const areaList = computed(() => store.state.customer.areaList);

const rules = ref({
  name: [{required: true, message: i18n.global.t('edit.info.reg1'), trigger: "blur"}],
  comName: [{required: true, message: i18n.global.t('edit.info.reg2'), trigger: "blur"}]
});

const getAvatarUrl = (val) => {
  if (isQs.value === 1) {
    ruleForm.value.logo = val;
  } else {
    ruleForm.value.avatar = val;
  }
}

const handleSaves = async () => {
  console.log(isQs, 'dd', typeof isQs)
  if (isQs.value === 1) {
    const targetObj = {
      name: ruleForm.value.comName,
      email: ruleForm.value.email,
      phone: ruleForm.value.phone,
      country: ruleForm.value.country,
      logo: ruleForm.value.logo,
      id: companyInfo.value.id,
    }
    console.log(targetObj, '企业')
    //   企业
    const res = await mailCompanyEdit(targetObj)
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return
    }
    ElMessage.success(res.message)
    router.push('/personal/qinfo')
  } else {
    //   非企业
    const targetObj = {
      userName: ruleForm.value.name,
      sex: ruleForm.value.sex,
      country: ruleForm.value.country,
      avatar: ruleForm.value.avatar,
      id: userInfo.value.id
    }
    const res = await mailFrontUserEdit(targetObj)
    if (res.code !== 200) {
      ElMessage.error(res.message)
      return
    }
    ElMessage.success(res.message);
    await store.dispatch('user/getUserInfoForGUID')
    router.push('/personal/basic')
  }
}


onMounted(async () => {
  const isQ = router.currentRoute.value.query.isQ;
  userInfo.value = JSON.parse(localStorage.getItem("userInfo"));
  companyInfo.value = JSON.parse(localStorage.getItem('companyInfo'))
  isQs.value = isQ * 1;
  if (isQ == 1) {
    //   是企业
    ruleForm.value.comName = companyInfo.value.name;
    ruleForm.value.email = companyInfo.value.email;
    ruleForm.value.phone = companyInfo.value.phone;
    ruleForm.value.logo = companyInfo.value.logo;
    ruleForm.value.country = companyInfo.value.country;
  } else {
    //   不是企业账号
    ruleForm.value.name = userInfo.value.userName;
    ruleForm.value.sex = userInfo.value.sex === null ? 1 : userInfo.value.sex;
    ruleForm.value.country = userInfo.value.country;
    ruleForm.value.avatar = userInfo.value.avatar;
  }
  await store.dispatch('customer/getAreaNames')
})

</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 235px;
  margin-top: 40px;
}

:deep(.el-form) {
  width: 100% !important;
}

:deep(.el-form-item__content),
:deep(.el-select) {
  width: 100% !important;
}

:deep(.el-form-item__content) {
  display: flex;
  justify-content: center;
}

#buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#avatar {
  display: flex;
  justify-content: center;
}

:deep(.el-avatar) {
  cursor: pointer;
  background: #f3f3f3 !important;
}
</style>
