<template>
  <div class="content">
    <el-row :gutter="24">
      <el-col :span="leftSpan">
        <div class="left">
          <div id="content" ref="containerRef">
            <div>
              <slot name="innerContent"></slot>
            </div>
          </div>
        </div>
      </el-col>
      <div class="right" ref="rightRef">
        <div class="right_inner">
          <div class="btn" ref="btnRef" @click="handleClickShow">
            <el-icon v-if="isClickFlag">
              <ArrowLeft/>
            </el-icon>
            <el-icon v-else>
              <ArrowRight/>
            </el-icon>
          </div>
          <el-anchor
              :container="containerRef"
              direction="vertical"
              type="default"
              :offset="30"
              @click="handleClick"
          >
            <el-anchor-link
                v-for="item in titleList"
                :key="item.title"
                :href="item.href"
                :title="item.title"
            >
              <template #sub-link v-if="item.title === $t('email.setting.2')">
                <el-anchor-link
                    v-for="subItem in titleChildList"
                    :key="subItem.title"
                    :href="subItem.href"
                    :title="subItem.title"
                ></el-anchor-link>
              </template>
            </el-anchor-link>
          </el-anchor>
        </div>
      </div>
    </el-row>


  </div>
</template>

<script setup>
import {ref} from "vue";
import docImg1 from "@/assets/docimg/1.png"
import ImageShow from "@/views/FileDoc/components/ImageShow.vue";

const leftSpan = ref(24);
const rightSpan = ref(0)

const isClickFlag = ref(false)
const rightRef = ref(null)
const btnRef = ref(null)
const handleClickShow = () => {
  if (!isClickFlag.value) {
    isClickFlag.value = true
    rightRef.value.style.width = "0px"
    btnRef.value.style.borderRadius = "4px 0px 0px 4px"
  } else {
    isClickFlag.value = false
    rightRef.value.style.width = "300px"
    btnRef.value.style.borderRadius = "4px"
  }
}

const containerRef = ref(null)

const handleClick = (e) => {
  e.preventDefault()
}


const {titleList, isHasChild, titleChildList} = defineProps({
  titleList: {
    type: Array,
    default: () => []
  },
  titleChildList: {
    type: Array,
    default: () => []
  },
  isHasChild: {
    type: Boolean,
    default: false
  }
})

</script>
<style lang="less" scoped>

.content {
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  overflow: hidden;

  .left {
    width: 100%;
    height: max-content;
    text-align: left;
    //background: pink;
    position: relative;


    #content {
      width: 100%;
      height: 100vh;
      overflow: scroll;
      //background: red;
      margin: auto;

      & > div {
        max-width: 850px;
        height: 100vh;
        margin: auto;
      }
    }
  }

  .right {
    width: 300px;
    height: max-content;
    margin: 40px 0px;
    border-left: 2px solid #e7eaef;
    position: fixed;
    right: 4px;
    transition: all 0.3s;

    .right_inner {
      width: 100%;
      height: max-content;
      position: relative;
      transition: all 0.3s;

      .btn {
        width: 14px;
        height: 35px;
        color: gray;
        //background: yellow;
        box-shadow: rgb(230, 234, 240) 0px 0px 0px 1px, rgba(12, 31, 80, 0.04) 0px 4px 6px;
        right: 97%;
        top: 10px;
        border-radius: 4px;
        position: absolute;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 99;
        background: white;
        transition: all 0.3s;
      }

      .btn:hover {
        background: #417df7;
        color: white;
      }

    }
  }
}

:deep(.el-row) {
  height: 100%;
  transition: all 0.3s;
}

:deep(.el-col) {
  transition: all 0.3s;
}

:deep(.is-guttered) {
  padding: 0px !important;
}

:deep(.el-anchor__list:nth-of-type(2)) {
  padding: 40px 40px 40px 30px !important;
}

//#content {
//  width: 100%; /* 设置容器宽度 */
//  height: 300px; /* 设置容器高度 */
//  overflow: auto; /* 显示滚动条，根据内容决定是否显示 */
//
//  /* 隐藏默认的滚动条样式 */
//  scrollbar-width: none; /* Firefox */
//  -ms-overflow-style: none; /* IE and Edge */
//}
//
//#content::-webkit-scrollbar {
//  display: none; /* Chrome, Safari, and Opera */
//}

body {
  overflow: scroll;
}

:deep(.el-anchor__list div a) {
  font-size: 14px !important;
}

</style>
