import {i18n} from "@/lang";
import icon6 from "@/assets/dashboard/6.png";
import icon7 from "@/assets/dashboard/7.png";
import icon8 from "@/assets/dashboard/8.png";
import icon9 from "@/assets/dashboard/9.png";
import icon10 from "@/assets/dashboard/10.png";

export const objToArrays = (obj) => {
    //   将数据进行格式化
    let formatData = [];

    for (let key in obj) {
        const value = obj[key];
        if (key === 'unReadCount') {
            formatData.push({name: i18n.global.t("common.email.noread"), count: value, icon: icon6, countRed: true,});
        } else if (key === 'followCount') {
            formatData.push({name: i18n.global.t("common.followup.emailremind"), count: value, icon: icon7,});
        } else if (key === 'auditCount') {
            formatData.push({name: i18n.global.t("common.email.toexamine"), count: value, icon: icon8,});
        } else if (key === 'failCount') {
            formatData.push({name: i18n.global.t("common.send.failed"), count: value, icon: icon9,});
        } else if (key === 'receiveCount') {
            formatData.push({name: i18n.global.t("common.claim.toexamine"), count: value, icon: icon10,});
        } else if (key === 'customerFollowCount') {
            formatData.push({name: '客户跟进', count: value})
        }
    }

    return formatData
}