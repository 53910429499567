import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";

const routes = [
    {
        path: '/test',
        name: 'Test',
        component: () => import('../views/Test.vue')
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: "登录",
            langKey: "common.login",
            noAuth: true,
        },
        component: () => import("../views/Login/Login.vue"),
    },
    {
        path: '/guide',
        name: "Guide",
        meta: {
            title: "引导",
            noAuth: true,
        },
        component: () => import('../views/FileDoc/view/HomeIndex.vue')
    },
    {
        path: '/settingguide',
        name: "SettingGuide",
        meta: {
            title: "邮件设置引导",
            noAuth: true,
        },
        component: () => import('../views/FileDoc/view/SettingIndex.vue')
    },
    {

        path: '/can',
        name: 'Can',
        component: () => import("@/components/Dashboard/ElCalender/index.vue"),
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/Register.vue"),
    },
    {
        path: "/forgetPassword",
        name: 'ForgetPassword',
        component: () => import('../views/ForgetPassword/index.vue')
    },
    {
        path: "/companyregister",
        name: "CompanyRegister",
        component: () => import("../views/CompanyRegister.vue"),
    },
    {
        path: "/",
        component: () => import("../layout/index.vue"),
        meta: {
            icon: "dashboard",
            title: "Index",
            langKey: "common.dashboard",
            signTab: true,
            uninclude: ["dir"],
        },
        redirect: `/dashboard`,
        children: [
            {
                path: 'notificationList',
                name: 'NotificationList',
                component: () => import('../views/NotificationList/index.vue'),
                children: [{
                    path: 'systemNotification',
                    name: 'SystemNotification',
                    component: () => import('../views/Study/SystemNotification.vue')
                }, {
                    path: "announcement",
                    name: 'Announcement',
                    component: () => import('../views/Study/Announcement.vue')
                }]
            },
            {path: "/success", name: 'Success', component: () => import('@/views/Success/Index.vue')},
            {
                path: "dashboard",
                name: "Dashboard",
                meta: {title: "首页", langKey: "common.dashboard"},
                component: () => import("../views/Home/Dashboard.vue"),
            },
            {
                path: "email",
                name: "Email",
                meta: {
                    title: "邮件"
                },
                component: () => import("../views/Email.vue"),
                redirect: `/email/inbox`,
                children: [
                    {
                        path: 'folder/:folderId',
                        name: "Folder",
                        meta: {
                            title: "文件夹"
                        },
                        component: () => import("../views/Mail/Folder.vue")
                    },
                    {
                        path: 'foldergroup',
                        name: "FolderGroup",
                        meta: {
                            title: "团队文件夹"
                        },
                        component: () => import("../views/Mail/FolderGroup.vue")
                    },
                    {
                        path: "group_setting",
                        name: "GroupSetting",
                        meta: {title: "团队管理"},
                        component: () => import("../views/Group/index.vue"),
                    },
                    {
                        path: "account_setting",
                        name: "AccountSetting",
                        meta: {title: "邮箱设置"},
                        component: () => import("../views/AccountSetting.vue"),
                    },
                    {
                        path: "write_mail",
                        name: "WriteMail1_Mail",
                        component: () => import("../views/Mail/WriteMail.vue"),
                    },
                    {
                        path: "send_all",
                        name: "Send_All",
                        component: () => import('../views/Mail/SendAll.vue')
                    },
                    {
                        path: "inbox",
                        name: "Inbox",
                        meta: {
                            title: "收件箱",
                            keepAlive: true,
                        },
                        component: () => import("../views/Inbox.vue"),
                    },
                    {
                        path: "has_send",
                        name: "HasSend",
                        meta: {
                            title: "已发送"
                        },
                        component: () => import("../views/HasSend.vue"),
                    },
                    {
                        path: "draft",
                        name: "Draft",
                        meta: {
                            title: "草稿箱"
                        },
                        component: () => import("../views/Draft.vue"),
                    },
                    {
                        path: "waste",
                        name: "Waste",
                        meta: {
                            title: "垃圾箱"
                        },
                        component: () => import("../views/Waste.vue"),
                    },
                    {
                        path: "examine",
                        name: "Examine",
                        meta: {
                            title: "审核邮件"
                        },
                        component: () => import("../views/Mail/Examine.vue"),
                    },
                    {
                        path: 'follow_up',
                        name: 'FollowUp',
                        meta: {
                            title: "邮件跟进"
                        },
                        component: () => import("../views/Mail/FollowUp.vue"),
                    },
                    {
                        path: "customer",
                        name: "Customer",
                        meta: {
                            title: "客户"
                        },
                        children: [
                            {
                                path: "list",
                                name: "List",
                                meta: {
                                    title: "客户列表"
                                },
                                component: () => import("../views/Customer/List.vue"),
                            },
                            {
                                path: "public",
                                name: "Public",
                                meta: {
                                    title: "公海客户"
                                },
                                component: () => import("../views/Customer/Public.vue"),
                            },
                            {
                                path: "setting",
                                name: "Setting",
                                meta: {
                                    title: "客户管理"
                                },
                                component: () => import("../views/Customer/Setting.vue"),
                            },
                            {
                                path: "sending",
                                name: "Sending",
                                meta: {
                                    title: "客户群发"
                                },
                                component: () => import("../views/SendGroup/Sending.vue"),
                            },
                            {
                                path: "senddetail/:massTaskId/:type/:taskId/:userId",
                                name: "SendDetail",
                                meta: {
                                    title: "系统任务详情"
                                },
                                component: () => import("../views/SendGroup/SendingDetail.vue"),
                            },
                            {
                                path: "follow",
                                name: "Follow",
                                meta: {
                                    title: "客户跟进"
                                },
                                component: () => import("../views/Customer/Follow.vue"),
                            },
                            {
                                path: "create",
                                name: "Create",
                                meta: {
                                    title: "客户建档"
                                },
                                component: () => import("../views/Customer/Create.vue"),
                            },
                            {
                                path: `detail/:userId`,
                                name: "Detail",
                                meta: {
                                    title: "客户详情"
                                },
                                component: () => import("../views/Customer/Detail.vue"),
                            },
                        ],
                    },
                    {
                        path: 'detail/:id/:flag/:type',
                        name: "DetailEmail",
                        meta: {
                            title: "邮件详情"
                        },
                        component: () => import('../views/Mail/Detail.vue'),
                    }
                ],
            },
            {
                path: "/cloud",
                name: "Cloud",
                meta: {
                    title: '云文件'
                },
                redirect: `/cloud/myf`,
                component: () => import("../views/Cloud/Cloud.vue"),
                children: [
                    {
                        path: "download/:shareId",
                        name: "Download",
                        meta: {
                            title: "分享链接"
                        },
                        component: () => import('../views/Cloud/Download/index.vue')
                    },
                    {
                        path: "info",
                        name: "Info",
                        meta: {
                            title: "分享内容"
                        },
                        component: () => import('../views/Cloud/Download/Info.vue')
                    },
                    {
                        path: 'myf',
                        name: "MyFile",
                        component: () => import("../views/Cloud/Myfile/index.vue")
                    }, {
                        path: "shareme",
                        name: "ShareMe",
                        component: () => import("../views/Cloud/ShareMe/index.vue")
                    }, {
                        path: "tos",
                        name: "ToShare",
                        component: () => import("../views/Cloud/ToShare/index.vue")
                    }, {
                        path: "toshared",
                        name: "ToShared",
                        component: () => import("../views/Cloud/ToShared/index.vue")
                    }, {
                        path: "collect",
                        name: "Collect",
                        component: () => import("../views/Cloud/Collect/index.vue")
                    }, {path: "recycle", name: "Recycle", component: () => import("../views/Cloud/Recycle/index.vue")}]
            },

        ],
    },
    {
        path: "/personal",
        name: "Personal",
        meta: {
            title: "账户中心"
        },
        component: () => import("../views/UserCenter/index.vue"),
        children: [
            {
                path: 'childuser',
                name: 'ChildUser',
                component: () => import('../views/UserCenter/ChildUser.vue')
            },
            {
                path: 'qbasic',
                name: 'qbasic',
                component: () => import('../views/UserCenter/Qbasic.vue')
            },
            {
                path: 'qinfo',
                name: 'qinfo',
                component: () => import('../views/UserCenter/Qinfo.vue')
            },
            {
                path: "basic",
                name: "Basic",
                meta: {
                    title: "账户信息"
                },
                component: () => import("../views/UserCenter/Basic.vue"),
            },
            {
                path: "station",
                name: "Station",
                component: () => import("../views/UserCenter/Station.vue"),
            },
            {
                path: "order",
                name: "Order",
                meta: {
                    title: "我的订单"
                },
                component: () => import("../views/UserCenter/Order.vue"),
            },
            {
                path: "invoice",
                name: "Invoice",
                meta: {
                    title: "发票管理"
                },
                component: () => import("../views/UserCenter/Invoice.vue"),
            },
            {
                path: "security",
                name: "Security",
                meta: {
                    title: "安全设置"
                },
                component: () => import("../views/UserCenter/Security.vue"),
            },
            {
                path: "repairOrder",
                name: "RepairOrder",
                meta: {
                    title: "意见反馈"
                },
                component: () => import("../views/UserCenter/RepairOrder.vue"),
            },
            {
                path: "editInfo",
                name: "EditInfo",
                component: () => import("../views/UserCenter/EditInfo.vue"),
            },
            {
                path: 'editQInfo',
                name: 'EditQInfo',
                component: () => import("../views/UserCenter/EditQInfo.vue")
            },
            {
                path: "setemail",
                name: "SetEmail",
                meta: {
                    title: "绑定邮箱"
                },
                component: () => import("../views/UserCenter/SetEmail.vue"),
            },
            {
                path: "setphone", meta: {
                    title: "绑定手机"
                }, name: 'unBindPhone', component: () => import("../views/UserCenter/SetPhone.vue")
            },
            {
                path: "realName",
                name: "realName",
                component: () => import("../views/UserCenter/RealName.vue"),
            },
            {
                path: "applyOpen",
                name: "ApplyOpen",
                component: () => import("../views/UserCenter/ApplyOpen.vue"),
            },
            {
                path: "createdRepairOrder",
                name: "createdRepairOrder",
                component: () => import("../views/UserCenter/CreatedRepairOrder.vue"),
            },
            {
                path: "buystorage",
                name: "BuyStorage",
                component: () => import("../views/UserCenter/BuyStorage.vue"),
            },
            {
                path: 'storeDetails',
                name: "StoreDetails",
                component: () => import("../views/UserCenter/StoreDetails/Index.vue")
            },
            {
                path: 'flowDetails',
                name: "FlowDetails",
                component: () => import("../views/UserCenter/FlowDetails/Index.vue")
            }, {
                path: 'repairOrderDetails',
                name: 'RepairOrderDetails',
                meta: {
                    title: "工单详情"
                },
                component: () => import("../views/UserCenter/RepairOrderDetails.vue")
            }
            , {
                path: 'orderDetails',
                name: 'OrderDetails',
                component: () => import("../views/UserCenter/OrderDetails.vue")
            }, {
                path: 'invoiceDetails',
                name: 'InvoiceDetails',
                component: () => import("../views/UserCenter/InvoiceDetails.vue")
            }, {
                path: 'pay',
                name: 'Pay',
                component: () => import("../views/UserCenter/Pay.vue")
            }, {
                path: 'paySuccess',
                name: 'PaySuccess',
                component: () => import("../views/UserCenter/Pay/PaySuccess.vue")
            }
        ],
    },
    {
        path: "/showfile",
        name: "ShowFile",
        component: () => import("../components/ShowTargetFile/showFile.vue")
    },
    {
        path: "/showtext",
        name: "ShowText",
        component: () => import('../components/ShowTargetFile/showText.vue')
    },
    {
        path: "/showvideo",
        name: "ShowVideo",
        component: () => import('../components/ShowTargetFile/showVideo.vue')
    },
    {
        path: "/showaudio",
        name: "ShowAudio",
        component: () => import('../components/ShowTargetFile/showAudio.vue')
    },
    {
        path: "/filePreview",
        name: "FilePreview",
        component: () => import('../components/FilePreview.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
