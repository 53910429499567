import _store from "store2";
import {
    getMailCustomerLevelList,
    addMailCustomerFollowLevel,
    editMailCustomerLevel,
} from "@/api/customer.js";
import {ElMessage} from "element-plus";

const customerReturns = {
    namespaced: true,
    state: {

    },
    mutations: {},
    actions: {},
};

export default customerReturns;
