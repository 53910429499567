<template>
  <ElDialog
      v-model="isShow"
      :title="$t('common.link.share.create')"
      :footer="null"
      centered
      :width="520"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div v-if="loading" v-loading="loading" style="width: 100%;text-align: center">
      <div style="width: 100%">{{ $t("common.create.share.link") }}</div>
    </div>
    <div v-else>
      <span class="title_cdir"
            style="display:inline-block;width: 100px;line-height: 30px">{{ $t("common.link.address") }}:</span>
      <ElInput
          type="text"
          v-model="linkAddress"
          style="height: 32px; width: 100%;"
      />
      <span class="title_cdir"
            style="display:inline-block;width: 100px;line-height: 30px">{{ $t("common.extractcode") }}:</span>
      <ElInput
          type="text"
          v-model="shareCode"
          style="height: 32px; width: 100%;"
      />
      <span class="title_cdir"
            style="display:inline-block;width: 100px;line-height: 30px">{{ $t("common.link.validity") }}:</span>
      <ElInput
          disabled
          type="text"
          v-model="shareDay"
          style="height: 32px; width: 100%;"
      />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <ElButton @click="close">{{
            $t("common.cancel")
          }}
        </ElButton>
        <ElButton type="primary" @click="createLink" :loading="loading">{{
            $t("common.confirm")
          }}
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, onMounted, computed} from 'vue'
import {useStore} from "vuex";
import useClipboard from "vue-clipboard3";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const loading = ref(false)
const {toClipboard} = useClipboard();

const {value, isHeadLine, activeItem} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  activeItem: {type: Array, default: []}
})

const linkAddress = computed(() => {
      const targetHref = `${window.location.origin}/cloud/download/`
      return `${targetHref}${store.state.cloud.fileLink.linkAddress}`
      // 是否要转换为 https ？
      // .replace(/http:\/\//g, "")
    }
)

const shareCode = computed(() => store.state.cloud.fileLink.shareCode)
const shareDay = computed(() => store.state.cloud.fileLink.shareDay)

onMounted(async () => {
  await getSharedLink()
})

// 获取分享链接
const getSharedLink = async () => {
  const res = activeItem.map(_ => _.id)
  const payload = {
    fileIdList: res,
  }
  console.log(payload, activeItem, 'activeItem')
  loading.value = true
  await store.dispatch("cloud/mailFileShareApis", payload)
  loading.value = false;
}

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

const close = () => {
  emit("close");
}

// 关闭链接并将内容复制到剪切板
const createLink = async () => {
  try {
    await toClipboard(`分享链接: ${linkAddress.value}  提取码: ${shareCode.value}`)
    ElMessage.success("复制分享链接成功")
  } catch (e) {
    ElMessage.error("复制链接失败")
  }
  close();
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

</style>
