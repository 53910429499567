import request from '../utils/request';

// 消息列表-all
export function getMessageList(params, config) {
    return request.get("/front/mailFrontMessage/list", params, config);
}

// 未读消息
export function getUnReadMessageList(params, config) {
    return request.get("/front/mailFrontMessage/unReadList",params,config)

}

// 一键已读
export function isReadAll(params, config) {
    return request.get("/front/mailFrontMessage/allRead", params, config)
}


// 邀请团队是否进行进入？
export function auditExternalPerson(params, config) {
    return request.get("/front/mailTeamMember/auditExternalPerson", params, config)
}


