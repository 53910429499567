<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t("common.pay") }}</div>
      <div class="tips">
        <div>{{ $t("other.text.order.create.success.peyment") }}</div>
        <div>{{ $t("common.amount.realpay") }}:<span class="red"> ¥ {{ realAmountAll }}</span></div>
      </div>
      <div class="content">
        <div style="color:rgb(121, 130, 137);font-size: 14px">{{ $t('sys.tip.select.payment.type') }}:</div>
        <div>
          <div :class="[item.isSelect?'out_box_pay_select':'out_box_pay']" v-for="item in payWays" :key="item.pay"
               @click="handleClick(item)">
            <div class="inner_item_pay">
              <div style="margin-right: 10px"><img height="30" :src="item.img"/></div>
              <div>{{ item.pay }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="results">
        <el-button class="buttons_pays" :disabled="buttonDisabled" @click="truePays">{{
            $t('common.settle.to')
          }}
        </el-button>
      </div>
    </div>
  </div>
  <Dialog
      v-model="dialogVisible"
      :title="$t('common.wechat.pay')"
      :width="'400'"
      :dialogVisible="dialogVisible"
      :isHeadLine="true"
      @update:dialogVisible="handleCancelPay"
  >
    <template #content>
      <div class="inners_wx">
        <div class="tips">{{ $t('sys.tip.wechat.scan.code.payment') }}</div>
        <qrcode-vue style="height: 200px;width: 200px" :value="code_url_wx" level="H"/>
        <el-button style="margin-top: 20px" type="primary" link @click="refresh">
          <el-icon>
            <RefreshRight/>
          </el-icon>
          {{ $t('common.sxewm') }}
        </el-button>
        <el-button style="margin-top: 20px;border-style: dashed" @click="wxCancelPay">{{ $t('common.qxzf') }}
        </el-button>
      </div>
    </template>
  </Dialog>
  <!--  是否完成支付-->
  <Dialog
      v-model="dialogVisibleInner"
      :width="'400'"
      :dialogVisible="dialogVisibleInner"
      @update:dialogVisible="(val) => (dialogVisibleInner = val)"
  >
    <template #content>
      <div class="cont">
        <span><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
        <span class="text">{{ $t('common.as12') }}</span>
      </div>
    </template>
    <template #footer>
      <div class="settingss">
        <el-button @click="handleClickCancel">{{ $t('common.as14') }}</el-button>
        <el-button type="primary" @click="handleClickSure">{{ $t('common.as13') }}</el-button>
      </div>
    </template>
  </Dialog>
</template>
<script setup>

import {onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import wechatPay from "@/assets/img/personal/wechatPay.png";
import QrcodeVue from 'qrcode.vue'
import {wxPayQuery, wxPayTransactions} from "@/api/wxPay";
import Dialog from "@/components/Dialog.vue";
import {ElMessage} from "element-plus";
import {aliPayTransactions, queryOrderStatusZfb} from "@/api/aliPay";
import {throttle} from "@/utils/mylodash"
import {i18n} from "@/lang";

const dialogVisibleInner = ref(false)
// const payWays = ref([{pay: i18n.global.t('common.alipay'), isSelect: true, img: aliPay, flag: "aliPay"}, {
//   pay: i18n.global.t('common.wechat'),
//   isSelect: false,
//   img: wechatPay,
//   flag: "wechatPay"
// }])
const payWays = ref([{
  pay: i18n.global.t('common.wechat'),
  isSelect: true,
  img: wechatPay,
  flag: "wechatPay"
}])
const dialogVisible = ref(false)
const router = useRouter()
const handleClick = (item) => {
  payWays.value = updateIsSelectById(payWays.value, item.pay);
}

function updateIsSelectById(arr, targetId) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].pay === targetId) {
      arr[i].isSelect = true;
    } else {
      arr[i].isSelect = false;
    }
  }
  return arr;
}

const code_url_wx = ref('')
const timers = ref(null)
const buttonDisabled = ref(false)

// 支付宝订单号
const aliPayOrderNo = ref('');
const aliTimer = ref(null)
const truePays = async () => {
  buttonDisabled.value = true
//  唤起指定的接口方式
  const flags = payWays.value.filter(item => item.isSelect)[0].flag;
  console.log(flags)
  if (flags === 'wechatPay') {
    //   微信支付
    await getWxCode()

    // 轮询查询订单状态
    timers.value = setInterval(async () => {
      //   查询订单是否支付成功
      await queryOrderStatus();
    }, 3000)

  } else {
    //   支付宝支付
    const res = await aliPayTransactions({orderId: targetOrderId.value})
    if (res.code !== 200) {
      ElMessage.error(res.message)
      return
    }
    // console.log(res.result, typeof res.result, 'ffff')
    dialogVisibleInner.value = true;
    let newTab = window.open();
    newTab.document.write(res.result);
  }
}
// 查询订单号 wx
const queryOrderStatus = async () => {
  const res = await wxPayQuery({orderNo: targetOrderId.value})
  if (res.trade_state === 'SUCCESS') {
    clearInterval(timers.value);
    //   三秒后跳转到支付成功的页面
    setTimeout(() => {
      router.push({path: '/personal/paySuccess', query: {orderNo: targetOrderId.value}})
    }, 3000)
  } else {
    console.log('等待支付中...')
  }
}

//支付状态说明
// 0支付失败； 1 支付成功；2 等待中 3.支付取消；

// 查询 支付宝订单号状态
const queryOrderStatusApis = async () => {
  const res = await queryOrderStatusZfb({
    orderNo: aliPayOrderNo.value
  })
  if (res.code === 200 && res.message === null) {
    console.log('等待支付中...')
    return
  }
  console.log(res.message, typeof res.message, JSON.parse(res.message), 'res.message')
  const {trade_status} = JSON.parse(res.message).alipay_trade_query_response
  console.log(trade_status, 'trade_status')
  if (trade_status === 'TRADE_SUCCESS') {
    clearInterval(aliTimer.value)

  }
}

// 取消支付
const handleClickCancel = () => {
  dialogVisibleInner.value = false;
  buttonDisabled.value = false
  clearInterval(timers.value)
  console.log('取消支付')
}

const handleClickSure = () => {
  dialogVisibleInner.value = false;
  buttonDisabled.value = false
  clearInterval(timers.value)
  router.push('/personal/order')
}

watch(() => dialogVisibleInner.value, () => {
  if (!dialogVisibleInner.value) {
    clearInterval(aliTimer.value)
    console.log('取消支付')
  }
})


// 微信二维码刷新
const refresh = throttle(async () => {
  await getWxCode()
}, 2000)

const getWxCode = async () => {
  await wxPayTransactions({type: "native", orderCode: targetOrderId.value}).then(res => {
    code_url_wx.value = res.code_url
    dialogVisible.value = true
  }).catch(err => {
    ElMessage.error(err)
  })
}
const wxCancelPay = () => {
  dialogVisible.value = false;
  dialogVisibleInner.value = true;
  buttonDisabled.value = false
  clearInterval(timers.value)
}

const handleCancelPay = (val) => {
  dialogVisible.value = val;
  dialogVisibleInner.value = true
  buttonDisabled.value = false
  clearInterval(timers.value)
}

const realAmountAll = ref(0)
// 订单号
const targetOrderId = ref('')
onMounted(() => {
  const {orderId, sumPrice} = router.currentRoute.value.query
  realAmountAll.value = sumPrice;
  targetOrderId.value = orderId;
})

</script>

<style scoped lang="less">
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  //width: 1000px !important;
  //min-width: 1000px !important;
  position: relative;
}

.tips {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #595959;

  & > div:nth-of-type(1) {
    font-size: 18px;
    font-weight: 700;
  }
}

.red {
  color: #d93737;
  font-weight: 500;
  font-size: 20px;

}

.content {
  margin-top: 20px;
}

.out_box_pay {
  width: 100%;
  height: 78px;
  margin-top: 10px;
  border: 2px solid #d8d8d8;
  padding: 22px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 700;
  color: #444;
  cursor: pointer;
  transition: all .3s;
}

.out_box_pay_select {
  width: 100%;
  height: 78px;
  margin-top: 10px;
  border: 2px solid #3171f6;
  padding: 22px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 700;
  color: #444;
  cursor: pointer;
  transition: all .3s;
  position: relative;

  &::after {
    content: "";
    background: url("../../assets/img/personal/check.png") 50%/cover;
    width: 30px;
    height: 30px;
    bottom: 0px;
    right: 0px;
    position: absolute;
  }
}

.inner_item_pay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.results {
  width: 100%;
  border-top: 1px solid #e8e8e8;
  height: 53px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;

  .text_left {
    padding-left: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #444;
  }

  .buttons_pays {
    padding: 0 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #d93737;
    margin-left: auto;
    cursor: pointer;
  }
}

.inners_wx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tips {
  margin-bottom: 20px;
}

:deep(.el-select__caret) {
  margin-right: 6px !important;
}

.settingss {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}


#sendRequire {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cont {
  display: flex;

  .text {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}
</style>