/**
 * @description: 文件大小转换
 * @param {*} BNum 文件大小 单位B
 * @param {*} unit 转换单位 空值转换成最小单位
 * @param {*} dec 小数点保留位数
 */
const FileUnitConver = function (BNum = 0, unit, dec = 2) {
    let n = 0
    let u = ['B', 'KB', 'MB', 'GB']
    if (!unit) {
        let num = BNum
        do {
            num = num / 1024
            n++
        } while (parseInt(num).toString().length > 3)
    } else {
        n = u.indexOf(unit)
    }

    let v = BNum / Math.pow(1024, n)
    v = parseInt(v * 100) > 1 ? v : 0.01 //处理0.00GB/10GB这种情况 给个0.01GB 好看点
    let isInteger = parseInt(v) == v //是整数
    let val = isInteger ? v + u[n] : v.toString().match(`\\d+\\.\\d{0,${dec}}`)[0] + u[n]
    return BNum ? val : BNum + 'B'
}


function replaceMailMassEmailVOListWithChildren(data) {
    // 如果数据是数组，则遍历数组
    if (Array.isArray(data)) {
        return data.map((item) => replaceMailMassEmailVOListWithChildren(item));
    }

    // 如果数据是对象，则处理对象
    if (data && typeof data === "object") {
        const result = {...data};

        // 如果存在 mailMassEmailVOList 属性，处理它
        if (result.mailMassEmailVOList !== undefined) {
            // 递归调用，处理 mailMassEmailVOList 中的项
            result.children = replaceMailMassEmailVOListWithChildren(
                result.mailMassEmailVOList
            );
            delete result.mailMassEmailVOList;
        }

        // 处理子项
        if (result.children !== undefined) {
            result.children = replaceMailMassEmailVOListWithChildren(result.children);
        }

        return result;
    }

    // 对于其他类型的数据直接返回
    return data;
}

function extractEmails(nodes) {
    let emails = [];
    nodes.forEach((node) => {
        if (node.email && node.level == 2) emails.push(node.email);
        if (node.children && node.children.length > 0) {
            emails = emails.concat(extractEmails(node.children));
        }
    });
    return emails;
}

// 扁平化函数
function flattenChildren(data) {
    let result = [];

    function recurse(children) {
        for (const item of children) {
            result.push(item);
            if (item.children && item.children.length > 0) {
                recurse(item.children);
            }
        }
    }

    recurse(data);
    return result;
}

export {FileUnitConver, replaceMailMassEmailVOListWithChildren, extractEmails,flattenChildren}
