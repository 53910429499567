<template>
  <div :class="['message-text-container']">
    <span
      v-for="(item, index) in data.text"
      :key="index"
    >
      <span
        v-if="item.name === 'text'"
        class="text"
      >{{ item.text }}</span>
      <img
        v-else
        class="emoji"
        :src="item.src"
        :alt="item.emojiKey"
      >
    </span>
  </div>
</template>

<script setup>
import { watchEffect, ref, defineProps } from 'vue';
// // import { CUSTOM_BASIC_EMOJI_URL, CUSTOM_BASIC_EMOJI_URL_MAPPING } from '../../emoji-config';
import { DEFAULT_BASIC_EMOJI_URL, BASIC_EMOJI_NAME_TO_KEY_MAPPING, DEFAULT_BASIC_EMOJI_URL_MAPPING } from '../../../utils/emoji';

const props = defineProps(['payload']);
const data = ref({});

watchEffect(() => {
  data.value.text = props.payload.text.match(/(\[+.*?\]+)|./g);
  data.value.text = data.value.text.map(item =>
    BASIC_EMOJI_NAME_TO_KEY_MAPPING[item]
      ? {
          src: DEFAULT_BASIC_EMOJI_URL + DEFAULT_BASIC_EMOJI_URL_MAPPING[BASIC_EMOJI_NAME_TO_KEY_MAPPING[item]],
          emojiKey: item,
        }
      : { name: 'text', text: item }
    );
  // data.value.text?.forEach((item) => {
    
  //   item.src = CUSTOM_BASIC_EMOJI_URL + CUSTOM_BASIC_EMOJI_URL_MAPPING[item.emojiKey];
  // });
});
</script>
<style lang="less" scoped>
.message-text-container {
  display: inline;
}

.text-select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.emoji {
  vertical-align: bottom;
  width: 20px;
  height: 20px;
}

.text {
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 14px;
  text-size-adjust: none;
}
</style>