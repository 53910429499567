<template>
  <el-avatar v-if="imgFormat.includes(fileExtend)" shape="square" size="small" :src="fileUrl"
             :style="{width:isAbbreviation?`24px`: `100%`,height: isAbbreviation?`24px`:`100%`,minWidth:'24px'}"/>
  <!--                excel 表格-->
  <img :style="{width: size+'px',height: size+'px',minWidth:size+'px'}" src="@/assets/img/file/excel.png"
       v-else-if="excelFormat.includes(fileExtend)">
  <!--                pdf-->
  <img :style="{width: size+'px',height: size+'px',minWidth:size+'px'}" src="@/assets/img/file/pdf.png"
       v-else-if="pdfFormat.includes(fileExtend)">
  <!--                doc-->
  <img :style="{width: size+'px',height: size+'px',minWidth:size+'px'}" src="@/assets/img/file/word.png"
       v-else-if="wordFormat.includes(fileExtend)">
  <!--  audio-->
  <svg t="1716877637875" v-else-if="audioFormat.includes(fileExtend)" class="icon" viewBox="0 0 1024 1024"
       version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="13272" :width="size" :height="size" :style="{minWidth:size+'px'}">
    <path d="M0.8192 0h1018.88v1018.88H0.8704z" fill="#FFFFFF" fill-opacity="0" p-id="13273"></path>
    <path
        d="M151.9616 39.168c-16.9984 0-33.536 17.2032-33.536 33.9456v837.12c0 17.152 16.9984 33.8432 33.536 33.8432h705.3824c16.9984 0 33.536-17.2032 33.536-33.8944V237.4144L695.296 39.6288H151.9616v-0.512z"
        fill="#FF4C5E" p-id="13274"></path>
    <path d="M692.224 39.168V202.752c0 17.8176 17.7152 35.072 35.0208 35.072H890.88L692.224 39.168z" fill="#FFC3C9"
          p-id="13275"></path>
    <path
        d="M509.952 286.1056l174.2336 60.4672 23.04 69.12-164.6592-41.1648-19.6608 294.6048H522.24c-6.4 51.8656-55.2448 92.16-114.5344 92.16-63.5904 0-115.2-46.3872-115.2-103.68 0-57.2416 41.5744-102.4 105.1648-102.4 40.3968 0 72.9088 17.408 93.4912 45.6704l18.7904-314.7776z"
        fill="#FFFFFF" p-id="13276"></path>
  </svg>
  <!--  video-->
  <svg t="1716876795190" v-else-if="videoFormat.includes(fileExtend)" class="icon"
       viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5211" :width="size"
       :height="size" :style="{minWidth:size+'px'}">
    <path
        d="M160 0h512l256 256v704c0 35.3472-28.6528 64-64 64H160c-35.3472 0-64-28.6528-64-64V64c0-35.3472 28.6528-64 64-64z"
        fill="#7C8EEE" p-id="5212"></path>
    <path
        d="M702.2976 579.2896l-298.5664 177.984c-19.9488 12.0192-45.3312-2.4128-45.3312-25.856v-355.968c0-22.848 25.3824-37.2736 45.3312-25.856l298.56 177.984c19.3408 12.032 19.3408 40.288 0 51.712z"
        fill="#FFFFFF" p-id="5213"></path>
    <path d="M672 0l256 256h-192c-35.3472 0-64-28.6528-64-64V0z" fill="#CAD1F8" p-id="5214"></path>
  </svg>
  <!--  ppt-->
  <svg t="1716877959757" v-else-if="pptFormat.includes(fileExtend)" class="icon" viewBox="0 0 1024 1024"
       version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15178" :width="size" :height="size" :style="{minWidth:size+'px'}">
    <path
        d="M740.088 0.529H177.466c-47.732 0-85.24 37.508-85.24 85.256v852.43c0 47.732 37.492 85.256 85.24 85.256h664.906c47.732 0 85.256-37.508 85.256-85.256V188.07L740.088 0.53zM506.517 563.15H426.38v136.39c0 6.822-1.7 13.643-5.12 17.045-3.402 3.42-10.223 6.821-17.044 6.821-8.523 0-15.344-1.7-18.746-6.82-3.419-5.12-5.12-10.24-5.12-17.045V339.819c0-11.94 1.701-18.762 6.821-22.18 3.402-3.403 13.642-5.12 27.285-5.12h93.762c44.33 0 78.418 10.24 98.882 30.703 22.165 20.447 32.388 51.134 32.388 93.762-1.701 83.538-46.03 126.166-132.971 126.166z"
        fill="#FF9843" p-id="15179"></path>
    <path
        d="M506.517 353.445H426.38V522.24h80.137c30.687 0 52.851-6.838 64.776-20.48 13.642-13.626 18.762-35.79 18.762-64.776 0-28.986-6.82-51.15-22.164-64.793-13.643-13.626-34.106-18.746-61.374-18.746z"
        fill="#FF9843" p-id="15180"></path>
    <path d="M740.088 0.529h-3.42v136.39c0 17.06 8.523 54.569 59.674 54.569h131.286L740.088 0.528z" fill="#FFCCA1"
          p-id="15181"></path>
  </svg>
  <!--              text-->
  <svg t="1716876955590" v-else-if="textFormat.includes(fileExtend)" class="icon"
       viewBox="0 0 1024 1024" version="1.1"
       xmlns="http://www.w3.org/2000/svg" p-id="10118" :width="size" :height="size" :style="{minWidth:size+'px'}">
    <path
        d="M937.140706 222.509176V968.583529a56.500706 56.500706 0 0 1-55.536941 55.416471H144.564706a56.500706 56.500706 0 0 1-55.416471-55.416471V55.536941A56.621176 56.621176 0 0 1 144.564706 0h570.066823"
        fill="#2897FF" p-id="10119"></path>
    <path
        d="M714.390588 0a12.047059 12.047059 0 0 0-0.843294 4.577882v173.477647A44.935529 44.935529 0 0 0 758.964706 222.870588h179.019294L715.233882 0z m0 0"
        fill="#98D3FF" p-id="10120"></path>
    <path
        d="M469.835294 439.235765h-116.495059v-73.487059h317.31953v73.487059H554.164706v313.223529h-84.329412z m0 0"
        fill="#FFFFFF" p-id="10121"></path>
  </svg>
  <!--              压缩文件-->
  <svg t="1716877061988" v-else-if="zipFormat.includes(fileExtend)" class="icon" viewBox="0 0 1024 1024"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg" p-id="12216" :width="size-3" :height="size-3" :style="{minWidth:size-3+'px'}">
    <path
        d="M967.111111 281.6V910.222222c0 62.862222-50.915556 113.777778-113.777778 113.777778H170.666667c-62.862222 0-113.777778-50.915556-113.777778-113.777778V113.777778c0-62.862222 50.915556-113.777778 113.777778-113.777778h514.844444L967.111111 281.6z"
        fill="#FFC63A" p-id="12217"></path>
    <path
        d="M685.511111 167.822222V0L967.111111 281.6H799.288889c-62.862222 0-113.777778-50.915556-113.777778-113.777778"
        fill="#DD9F08" p-id="12218"></path>
    <path
        d="M436.565333 68.437333h68.437334V0h-68.437334zM505.002667 136.874667h68.437333V68.437333h-68.437333zM436.565333 205.312h68.437334V136.874667h-68.437334zM505.002667 273.749333h68.437333V205.312h-68.437333z"
        fill="#FFFFFF" p-id="12219"></path>
    <path
        d="M436.565333 342.158222h68.437334V273.720889h-68.437334zM505.002667 410.624h68.437333V342.186667h-68.437333z"
        fill="#FFFFFF" p-id="12220"></path>
    <path
        d="M436.565333 479.032889h68.437334v-68.437333h-68.437334zM505.002667 547.470222h68.437333v-68.437333h-68.437333zM470.784 762.225778h68.437333v-68.437334h-68.437333v68.437334z m-34.218667-136.874667v136.874667c0 18.915556 15.331556 34.218667 34.218667 34.218666h68.437333c18.915556 0 34.218667-15.303111 34.218667-34.218666v-136.874667h-136.874667z"
        fill="#FFFFFF" p-id="12221"></path>
  </svg>

  <svg v-else t="1716876676458" class="icon" viewBox="0 0 1024 1024" version="1.1"
       xmlns="http://www.w3.org/2000/svg" p-id="3086" :width="size" :height="size" :style="{minWidth:size+'px'}">
    <path
        d="M160 0h512l256 256v704c0 35.3472-28.6528 64-64 64H160c-35.3472 0-64-28.6528-64-64V64c0-35.3472 28.6528-64 64-64z"
        fill="#CCCCCC" p-id="3087"></path>
    <path d="M672 0l256 256h-192c-35.3472 0-64-28.6528-64-64V0z" fill="#EAEAEA" p-id="3088"></path>
    <path
        d="M384 499.2c0-25.6 5.12-46.08 10.24-58.88 5.12-12.8 15.36-25.6 28.16-35.84 12.8-12.8 25.6-20.48 43.52-25.6 15.36-5.12 30.72-7.68 48.64-7.68 35.84 0 64 10.24 89.6 30.72C627.2 422.4 640 448 640 481.28c0 15.36-5.12 28.16-10.24 40.96s-17.92 28.16-38.4 46.08-28.16 30.72-35.84 38.4c-7.68 7.68-10.24 17.92-15.36 28.16-5.12 10.24-2.56 17.92-2.56 43.52h-51.2c0-25.6 2.56-38.4 5.12-51.2s7.68-23.04 15.36-33.28 15.36-23.04 33.28-40.96c17.92-17.92 30.72-30.72 35.84-38.4 5.12-7.68 10.24-20.48 10.24-38.4s-7.68-30.72-20.48-43.52-30.72-20.48-53.76-20.48c-51.2 0-76.8 35.84-76.8 87.04h-51.2z m153.6 281.6h-51.2v-51.2h51.2v51.2z"
        fill="#FFFFFF" p-id="3089"></path>
  </svg>
</template>

<script setup>

import {
  audioFormat,
  excelFormat,
  imgFormat,
  pdfFormat,
  pptFormat,
  textFormat,
  videoFormat,
  wordFormat, zipFormat
} from "@/utils/file";

const {fileExtend, fileUrl, size, isAbbreviation} = defineProps({
  fileExtend: {type: String},
  fileUrl: {type: String},
  size: {type: Number, default: 25},
  isAbbreviation: {type: Boolean, default: false}
})


</script>
<style lang="less" scoped></style>