<template>
  <el-image style="height: 100%;margin:10px 0px;cursor: pointer;border-radius: 6px;border: 1px solid #e7e9e8" :src="url" :fit="'fit'" @click="showTargetFile"/>
</template>

<script setup>
import {defineProps} from "vue"
import {imgFormat} from "@/utils/file";
import {api as viewerApi} from "v-viewer";

const {url} = defineProps({url: String})


const showTargetFile = (row) => {
  // alert(row.name)
  let images = [];
  images.push({src: url})
  viewerApi({
    images,
  })

}
</script>


<style lang="less" scoped>
:deep(.el-image) {
  margin: 10px 0px;
  border-radius: 4px;
}
</style>