// 获取所有数据
export const getApi1 = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve([
                {
                    id: 1000,
                    date: '2016-05-03',
                    name: 'Tom1',
                    contentSize: 12231,
                    isCollect: true,
                    createName: "april",
                    shareDate: "2016-05-03",
                    sDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                    imgUrl:'https://p.qqan.com/up/2024-2/17082192388322767.jpg'
                },
                {
                    id: 1001,
                    date: '2016-05-02',
                    name: 'Tom2',
                    contentSize: 3123,
                    isCollect: true,
                    createName: "april",
                    shareDate: "2016-05-03",
                    sDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                    imgUrl:'https://p.qqan.com/up/2024-2/17082192382955121.jpg'
                },
                {
                    id: 1001,
                    date: '2016-05-04',
                    name: 'Tom3',
                    contentSize: 3243123,
                    isCollect: false,
                    createName: "april",
                    shareDate: "2016-05-03",
                    sDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                    imgUrl:'https://p.qqan.com/up/2024-2/17082192388002790.jpg'
                },
                {
                    id: 1002,
                    date: '2016-05-01',
                    name: 'Tom4',
                    contentSize: 1234123,
                    isCollect: false,
                    createName: "april",
                    shareDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    sDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                    imgUrl:'https://img.nanrentu.cc/listImg/c2023/11/09/eiqevgslyk0.jpg'
                },
                {
                    id: 1003,
                    date: '2016-05-08',
                    name: 'Tom5',
                    contentSize: 12341234,
                    isCollect: false,
                    createName: "april",
                    shareDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    sDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                    imgUrl:'https://img2.woyaogexing.com/2022/03/26/157c23fab4e94c089423d2111daa3215!400x400.png'
                },
                {
                    id: 1004,
                    date: '2016-05-06',
                    name: 'Tom6',
                    contentSize: 1234123,
                    isCollect: false,
                    createName: "april",
                    shareDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    sDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                    imgUrl:"https://p1.itc.cn/q_70/images03/20230427/97e4cf398c1c453f98f8135b202479d6.jpeg"
                },
                {
                    id: 1005,
                    date: '2016-05-07',
                    name: 'test',
                    contentSize: 123412,
                    isCollect: true,
                    createName: "april",
                    shareDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    sDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 1,
                },
            ])
        }, 2000)
    });
}

// 根据id 获取 path
export const getTargetPath = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {

        }, 2000)
    })
}

//获取指定id的数据
export const getTargetFile = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve([
                {
                    id: 1006,
                    date: '2016-05-03',
                    name: 'Tom1',
                    contentSize: 12231,
                    isCollect: true,
                    createName: "april",
                    shareDate: "2016-05-03",
                    sDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                },
                {
                    id: 1007,
                    date: '2016-05-02',
                    name: 'Tom2',
                    contentSize: 3123,
                    isCollect: true,
                    createName: "april",
                    shareDate: "2016-05-03",
                    sDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 0,
                }, {
                    id: 1008,
                    date: '2016-05-02',
                    name: 'Tom2',
                    contentSize: 3123,
                    isCollect: true,
                    createName: "april",
                    shareDate: "2016-05-03",
                    sDate: "2016-05-03",
                    deleteDate: "2016-05-03",
                    shareStatus: 1,
                    isFolder: 1,
                },
            ])
        }, 2000)
    });
}
