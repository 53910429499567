export function processData(data) {
    // 创建一个空对象用于存储每个 time 的统计信息
    const timeMap = {};

    // 遍历数据数组
    data.forEach((item) => {
        const {alertTime, isUrgent} = item;

        // 如果 time 已经存在于 timeMap 中，则更新统计信息
        if (timeMap[alertTime]) {
            timeMap[alertTime].count++;
            if (isUrgent === 1) {
                timeMap[alertTime].hasUrgent = true;
            } else if (isUrgent === 0) {
                timeMap[alertTime].hasNonUrgent = true;
            }
        } else {
            // 如果 time 不存在于 timeMap 中，则创建新的统计信息
            timeMap[alertTime] = {
                count: 1,
                hasUrgent: isUrgent === 1 ? true : false,
                hasNonUrgent: isUrgent === 0 ? true : false,
            };
        }
    });

    // 根据统计信息确定返回的 flag
    const result = Object.keys(timeMap).map((alertTime) => {
        const {count, hasUrgent, hasNonUrgent} = timeMap[alertTime];
        if (hasUrgent && !hasNonUrgent) {
            return {alertTime, count, flag: 1};
        } else if (!hasUrgent && hasNonUrgent) {
            return {alertTime, count, flag: 0};
        } else {
            return {alertTime, count, flag: 2};
        }
    });

    return result;
}