<template>
  <div class="outbox">
    <vue3VideoPlay
        v-bind="optionsTarget"
    />
<!--        poster="https://cdn.jsdelivr.net/gh/xdlumia/files/video-play/ironMan.jpg"-->
  </div>
</template>

<script setup>
import {computed, reactive} from "vue";
import {useRoute} from "vue-router";
const route = useRoute()

const optionsTarget = computed(() => {
  return {
    width: "890px", //播放器宽度
    height: "500px", //播放器高度
    color: "#409eff", //主题色
    title: "", //视频名称
    src: route.query.url, //视频源
    muted: false, //静音
    webFullScreen: false,
    speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
    autoPlay: false, //自动播放
    loop: false, //循环播放
    mirror: false, //镜像画面
    ligthOff: false, //关灯模式
    volume: 0.3, //默认音量大小
    control: true, //是否显示控制
    controlBtns: [
      "audioTrack",
      "quality",
      "speedRate",
      "volume",
      "setting",
      "pip",
      "pageFullScreen",
      "fullScreen",
    ], //显示所有按钮,
  }
})

</script>

<style lang="less" scoped>
.outbox{
  width: 100%;
  height: 100%;
  display: flex;
  background: #f5f5f5;
  justify-content: center;
  align-items: center;
}
</style>

<!--<template>-->
<!--  <ElDialog-->
<!--      v-model="isShow"-->
<!--      :footer="null"-->
<!--      centered-->
<!--      :width="'max-content'"-->
<!--      destroyOnClose-->
<!--      @cancel="close"-->
<!--      :class="[isHeadLine ? 'headline' : 'noheadline']"-->
<!--  >-->
<!--    <template #default>-->
<!--      <vue3VideoPlay-->
<!--          v-bind="options"-->
<!--          poster="https://cdn.jsdelivr.net/gh/xdlumia/files/video-play/ironMan.jpg"-->
<!--      />-->
<!--    </template>-->
<!--  </ElDialog>-->
<!--</template>-->

<!--<script setup>-->
<!--import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";-->
<!--import {ref, defineProps, watch, defineEmits, reactive} from 'vue'-->
<!--import {useStore} from "vuex";-->

<!--const store = useStore();-->
<!--const emit = defineEmits()-->
<!--const isShow = ref(false);-->
<!--const fileDirName = ref("")-->
<!--const loading = ref(false)-->


<!--const options = reactive({-->
<!--  width: "800px", //播放器宽度-->
<!--  height: "450px", //播放器高度-->
<!--  color: "#409eff", //主题色-->
<!--  title: "", //视频名称-->
<!--  src: "https://cdn.jsdelivr.net/gh/xdlumia/files/video-play/IronMan.mp4", //视频源-->
<!--  muted: false, //静音-->
<!--  webFullScreen: false,-->
<!--  speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速-->
<!--  autoPlay: false, //自动播放-->
<!--  loop: false, //循环播放-->
<!--  mirror: false, //镜像画面-->
<!--  ligthOff: false, //关灯模式-->
<!--  volume: 0.3, //默认音量大小-->
<!--  control: true, //是否显示控制-->
<!--  controlBtns: [-->
<!--    "audioTrack",-->
<!--    "quality",-->
<!--    "speedRate",-->
<!--    "volume",-->
<!--    "setting",-->
<!--    "pip",-->
<!--    "pageFullScreen",-->
<!--    "fullScreen",-->
<!--  ], //显示所有按钮,-->
<!--});-->

<!--const {value, isHeadLine} = defineProps({-->
<!--  value: {type: Boolean, default: false},-->
<!--  isHeadLine: {type: Boolean, default: false}-->
<!--})-->

<!--watch(() => value, (val) => {-->
<!--  isShow.value = val;-->
<!--}, {immediate: true})-->
<!--const close = () => {-->
<!--  emit("close");-->
<!--}-->


<!--const createDir = () => {-->
<!--  emit("addDir", fileDirName.value)-->
<!--}-->
<!--</script>-->
<!--<style scoped lang="less">-->
<!--.fx-j-c {-->
<!--  display: flex;-->
<!--  justify-content: center;-->
<!--  margin-top: 40px;-->
<!--}-->

<!--.title_cdir {-->
<!--  color: rgba(0, 0, 0, .85);-->
<!--  font-weight: 700;-->
<!--  font-size: 14px;-->
<!--}-->

<!--.fx-j-r {-->
<!--  display: flex;-->
<!--  justify-content: right;-->
<!--  margin-top: 40px;-->
<!--}-->
<!--:deep(.el-dialog__body){-->
<!--  padding: 0px !important;-->
<!--}-->

<!--</style>-->
