<template>
  <div class="inners">
    <div class="right">
      <div class="right_inners">
        <div class="titles">{{ $t('common.workorder.detail') }}</div>
        <div class="scroll_box_inner">
          <div ref="chatContainer" @scroll="handleScroll"
               :class="[data.status===1?'scroll_content':'scroll_content_long']"
               :style="tableHeight"
          >
            <div class="content_title">{{ data.title }}</div>
            <div v-if="arrImgs[0]!==''">
              <el-image
                  v-for="item in arrImgs"
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="arrImgs"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  :initial-index="4"
                  fit="cover"
              />
            </div>
            <hr style="margin: 20px 0px "/>
            <!--        评论区 内容-->
            <div v-for="item in sortCreateTimes">
              <ReSend :data="item"></ReSend>
            </div>
          </div>
          <template v-if="data.status===1">
            <div class="inner_side_box">
              <div class="submit_box">
                <div class="top_textarea">
                  <el-input v-model="textarea" maxlength="300" rows="5" :resize="false"
                            type="textarea"
                            :placeholder="$t('repair.order.detail')">
                  </el-input>
                </div>
                <div class="class_upload">
                  <el-upload :action="action" :limit="3" ref="upload" list-type="picture-card"
                             :on-error="handleUploadError"
                             :on-success="handleUploadSuccess"
                             :headers="{
                          'X-Access-Token': getToken(),
                       }">
                    <div class="upload_inner">
                      <el-icon>
                        <Plus/>
                      </el-icon>
                    </div>
                    <template #file="{ file }">
                      <div>
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            alt=""
                        />
                        <span class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                    >
                      <el-icon><zoom-in/></el-icon>
                    </span>
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                    >
                      <el-icon><Delete/></el-icon>
                    </span>
                  </span>
                      </div>
                    </template>
                  </el-upload>
                </div>
              </div>
              <div class="setting">
                <el-button type="danger" @click="handleReback">{{ $t('common.cds65') }}</el-button>
                <el-button type="primary" @click="dialogVisibleInner = true">{{ $t('common.cds66') }}</el-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <el-dialog width="max-content" v-model="dialogVisible">
      <img w-full :src="dialogImageUrl" alt="Preview Image"/>
    </el-dialog>

    <Dialog
        v-model="dialogVisibleInner"
        :width="'400'"
        :dialogVisible="dialogVisibleInner"
        :isHeadLine="false"
        @update:dialogVisible="(val) => (dialogVisibleInner = val)"
    >
      <template #content>
        <div class="cont">
          <span><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
          <span class="text">{{ $t('repair.order.detail.is.ok.question') }}</span>
        </div>
      </template>
      <template #footer>
        <div class="settingss">
          <el-button @click="dialogVisibleInner = false">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="handleClickSure">{{ $t('common.confirm') }}</el-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import {ref, onMounted, computed, nextTick} from "vue";
import ReSend from './RepairOrderDetails/reSend.vue'
import {getToken} from '../../utils/auth';
import {getQueryByOptionId, mailOpinionContentAdd, mailOpinionContentEdit} from "@/api/usercenter";
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import Dialog from "@/components/Dialog.vue";
import {i18n} from "@/lang";

const router = useRouter();
// 上传的地址
const action = ref(`${process.env.VUE_APP_BASE_API}/sys/oss/qiniu/upload`);
// 所有的数据
const multipartFiles = ref([]);
const textarea = ref('')
const dialogVisible = ref(false)
const dialogImageUrl = ref('');
const data = ref([])
const upload = ref(null)
const targetOptionId = ref()
const dialogVisibleInner = ref(false)


const handleRemove = (uploadFile, uploadFiles) => {
  console.log(uploadFile, uploadFiles)
  upload.value.handleRemove(uploadFile);
  multipartFiles.value.splice(multipartFiles.value.findIndex((item) => item.name === name), 1);
  console.log(multipartFiles, 'multipartFiles')
}

const handlePictureCardPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}

function handleUploadError(error) {
  console.log('上传失败', error);
}

function handleUploadSuccess(e) {
  console.log('上传成功', e);
  multipartFiles.value.push(e.result);
  console.log(multipartFiles, 'multipartFiles')
}

const mailOpinionContentVO = ref([])
const getQueryByOptionIdApis = async () => {
  getQueryByOptionId(
      {optionId: targetOptionId.value}
  ).then((res) => {
    if (res.code !== 200) {
      ElMessage.error(res.message)
      return
    }
    data.value = res.result
    mailOpinionContentVO.value = res.result.mailOpinionContentVO;
    scrollToBottom()
  })
}

// 按照时间先后顺序进行排序
const sortCreateTimes = computed(() => {
  return sortByCreateTime(mailOpinionContentVO.value)
})

function sortByCreateTime(data) {
  return data.sort((a, b) => new Date(a.createTIme) - new Date(b.createTIme));
}

// 聊天信息保持在底部
const chatContainer = ref(null)
const userScrolled = ref(false)

function scrollToBottom() {
  nextTick(() => {
    // let scrollHeight = chatContainer.value.scrollHeight;
    // const currentScroll = chatContainer.value.scrollTop;
    // const difference = scrollHeight - currentScroll;
    // const perTick = difference / 20; // 调整此值以更改滚动速度
    // console.log(scrollHeight, chatContainer.value.scrollTop, currentScroll, currentScroll, difference, '@@@')
    //
    // scrollInterval.value = setInterval(() => {
    //   chatContainer.value.scrollTop += perTick;
    //   if (Math.ceil(chatContainer.value.scrollTop) >= scrollHeight) {
    //     clearInterval(scrollInterval.value);
    //   }
    // }, 15); // 调整此值以更改滚动间隔
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
  })

}


function handleScroll(event) {
  const scrollTops = event.target.scrollTop
  chatContainer.value.scrollTop = scrollTops
}

const handleReback = async () => {
  const multipartFilesToString = multipartFiles.value.map((item) => item.url).join(',')
  const targetObj = {
    content: textarea.value,
    opinionId: targetOptionId.value,
    url: multipartFilesToString
  }
  if (textarea.value !== '' || multipartFilesToString !== '') {
    console.log(targetObj, 'targetObj')
    const res = await mailOpinionContentAdd(targetObj)
    if (res.code !== 200) {
      ElMessage.error(res.message)
      return
    }
    ElMessage.success(res.message)
    textarea.value = '';
    multipartFiles.value = [];
    upload.value.clearFiles();
    await getQueryByOptionIdApis()
  } else {
    ElMessage.warning(i18n.global.t('repair.order.detail.no.content'))
  }
}

const handleClickSure = async () => {
  const res = await mailOpinionContentEdit({
    opinionId: targetOptionId.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  dialogVisibleInner.value = false
  router.push("/personal/repairOrder")
}

const tableHeight = ref(200);

onMounted(async () => {
  targetOptionId.value = router.currentRoute.value.query.id
  await getQueryByOptionIdApis()

  scrollToBottom()

  nextTick(() => {
    initTableHeight()
  })
})

const arrImgs = computed(() => {
  let result = []
  if (data.value.url && data.value.url.indexOf(',') != -1) {
    result = data.value.url.split(',')
  } else {
    result.push(data.value.url)
  }
  return result
});

</script>

<style lang="less" scoped>
@import "./less/Tables.less";

.right {
  height: 90% !important;
  width: 840px !important;
  margin: 0 auto !important;
  min-height: 100% !important;
  background: #fff !important;
  position: relative;
  padding-bottom: 40px !important;
}

.inners {
  width: 840px !important;
  min-width: 840px !important;
  height: 100% !important;
}

.scroll_content {
  //height: calc(56vh - 50px);
  overflow: auto;
  flex: 1;
}

.scroll_content_long {
  height: calc(100vh - 250px);
  overflow: auto;
}

.content_title {
  margin: 12px 0px 18px;
}

hr {
  background-color: #efefef;
  height: 1px;
  border: none;
}

.submit_box {
  width: 100% !important;
  background: #f5f5f5;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
}

:deep(.el-input__count) {
  background: #F5F5F5;
}

:deep(.el-textarea .el-textarea__inner) { // 然后找到对应的类名，在这里将拉伸去掉即可
  resize: none;
  background: #F5F5F5;
  box-shadow: none !important;
}

.setting {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & > .el-button {
    margin: 0px 10px;
  }
}

.settingss {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

:deep(.el-upload--picture-card) {
  --el-upload-picture-card-size: 58px !important;
}

:deep(.el-upload-list--picture-card) {
  --el-upload-list-picture-card-size: 58px !important;
}

.cont {
  display: flex;

  .text {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}

.right_inners {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.scroll_box_inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 0px 35px;
  margin-top: -19px
}

</style>