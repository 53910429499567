<template>
  <div class="notify_boxs" @scroll="handleScroll">
    <div class="item_box">
      <div v-for="item in resultTargetDataList" class="item">
        <div class="items sp_items" @click="handleClick(item)"
             :style="item.messageType===1? item.isClick===0?'cursor: pointer':'':''">
          <div class="left_box">
            <div class="left">
              <el-avatar
                  :size="36"
                  :src="item.icon"
              />
            </div>
            <div class="right">
              <div class="tops">{{ item.titles }}</div>
              <div class="content">{{ item.innerContent }}</div>
            </div>
          </div>
          <div class="right_box">
            {{ item.createTime }}
          </div>
        </div>
      </div>
      <slot name="loadings"></slot>
    </div>
  </div>

  <!--  加入团队-->
  <JoinTeam v-if="dialogVisible" v-model="dialogVisible"
            @close="close" :targetItem="activeItem"></JoinTeam>
</template>

<script setup>

import JoinTeam from "@/components/DiaPops/Message/JoinTeam/index.vue";
import {defineEmits, ref} from "vue";

const emit = defineEmits()
const {resultTargetDataList} = defineProps({
  resultTargetDataList: {
    type: Array,
    default: []
  }
})


const dialogVisible = ref(false)
const close = () => {
  dialogVisible.value = false
}


const activeItem = ref(null)
const handleClick = (item) => {
  if (item.messageType === 1 && item.isClick === 0) {
    activeItem.value = item;
    dialogVisible.value = true
  }
}


function handleScroll(event) {
  const target = event.target;
  if (target.scrollHeight - target.scrollTop === target.clientHeight) {
    // Reached the bottom
    console.log('fff')
    emit('changePages')
  }
}


</script>

<style lang="less" scoped>
.notify_boxs {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  flex: auto;
  height: 0;
  overflow-y: auto;
}

.item_box {
  background: white;
  width: 100%;

  .sp_items {
    width: 100%;
  }

  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    padding: 8px 15px;
    height: auto;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_box {
      display: flex;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .right_box {
      color: rgb(153, 153, 153);
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      width: 86px;
    }

    .right {
      height: 100%;
      display: flex;
      margin-left: 4px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .tops {
        color: rgba(0, 0, 0, .65);
        height: auto;
        font-size: 16px;
        font-weight: bold;
      }

      .content {
        height: auto;
        overflow-wrap: break-word;
        font-size: 14px;
        font-weight: 400;
        color: #999;
      }
    }
  }
}


</style>