import request from "@/utils/request";


// 查询当前用户空间情况
export function queryByUserId(params, config) {
    return request.get(`/front/mailStorage/queryByUserId`, params, config)
}

// 文件收藏
export function hideFile(params, config) {
    return request.post(`/front/mailFile/hideFile`, params, config)
}


// 获取文件列表
export function getFileList(params, config) {
    return request.get(`/front/mailFile/list`, params, config);
}

// 空间文件通过id查询 根目录 0 其他为文件夹 id
export function getFilePath(params, config) {
    return request.get(`/front/mailFile/queryByParentId`, params, config)
}

//空间文件添加
export function addFile(params, config) {
    return request.post(`/front/mailFile/createFolder`, params, config)
}


//删除指定文件
export function deleteFile(params, config) {
    return request.post(`/front/mailFile/deleteBatch`, params, config)
}


//修改指定文件【文件/文件夹】的名字
export function editFile(params, config) {
    return request.put(`/front/mailFile/edit`, params, config)
}

// 清空回收站
export function clearFile(params, config) {
    return request.post(`/front/mailFile/clearFile`, params, config)
}

// 文件移动
export function moveFile(params, config) {
    return request.post(`/front/mailFile/moveFile`, params, config)
}

// 文件共享
export function filePublicShare(params, config) {
    return request.put(`/front/mailFilePublicShare/filePublicShare`, params, config)
}

// 共享给我的
export function publicShareOtherList(params, config) {
    return request.get(`/front/mailFilePublicShare/publicShareOtherList`, params, config);
}

// 我共享的
export function myPublicShareList(params, config) {
    return request.get(`/front/mailFilePublicShare/myPublicShareList`, params, config);
}

// 共享 通过共享id 获取共享用户
export function getPublicShareUserList(params, config) {
    return request.get(`/front/mailFilePublicShare/getPublicShareUserList?fileId=${params}`)
}

// 取消共享
export function fileCancelPublicShare(params, config) {
    return request.post(`/front/mailFilePublicShare/fileCancelPublicShare`, params, config);
}

// 文件分享
export function addFileShare(params, config) {
    return request.post(`/front/mailFileShare/addFileShare`, params, config)
}

// 文件分享列表
export function mailFileShareList(params, config) {
    return request.get(`/front/mailFileShare/queryPageList`, params, config)
}

// 文件分享--根据父id 查询子孙
export function queryShareFileByParentId(params, config) {
    return request.get(`/front/mailFileShare/queryShareFileByParentId`, params, config)
}

// 获取分享人相关信息
export function getShareNameByLinkCode(params, config) {
    return request.get(`/front/mailFileShare/getShareNameByLinkCode`, params, config)
}

// 文件分享-提取文件列表
export function getFileByLinkAddress(params, config) {
    return request.get(`/front/mailFileShare/getFileByLinkAddress`, params, config);
}

// 取消分享
export function fileCancelShare(params, config) {
    return request.post(`/front/mailFileShare/fileCancelShare`, params, config)
}

// 文件转存
export function copyFile(params, config) {
    return request.post(`/front/mailFile/copyFile`, params, config)
}

// 邮件文件转存
export function copyMailFile(params, config) {
    return request.post(`/front/mailFile/copyMailFile`, params, config)
}


