<template>
  <div class="flow_box">
    <div class="flow_left">
      <div><span class="flow_title">{{ $t('common.cloud.flow.total') }}</span><span class="flow_txt">{{
          data.totalStr
        }}</span></div>
      <div><span class="flow_title">{{ $t('common.bh') }}</span><span class="flow_txt sp_flow">{{
          data.totalStr
        }}({{ $t('common.mf') }})</span>
      </div>
      <div><span class="flow_title">{{ $t('common.cloud.flow.total') }}</span><span
          class="flow_txt">{{ data.remainingStr }}</span></div>
    </div>
    <div class="flow_right">
      <el-table :data="data.list" style="width: 100%" :cell-style="{ padding: '10px' }"
                :header-cell-style="{padding:'16px', background: '#eef1f6', color: '#606266' }">
        <el-table-column prop="recordDate" :label="$t('common.time.use')" sortable width="180"/>
        <el-table-column prop="fileName" :label="$t('common.file.name')" width="160"/>
        <el-table-column prop="sizeStr" :label="$t('common.flow.hasuse')" :formatter="formatter" width="160"/>
      </el-table>
      <!-- 分页 -->
      <div class="pages">
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            background
            layout="total, prev, pager, next"
            v-if="totals"
            :total="totals"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>

    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";

const data = ref({
  "total": 10737418240,
  "totalStr": "10GB",
  "remaining": 10673390238,
  "remainingStr": "9.94GB",
  "used": 64028002,
  "usedStr": "61.06MB",
  "flowList": [
    {
      "unit": "GB",
      "quantity": 10,
      "status": -1
    }
  ],
  "list": [
    {
      "userID": 1032,
      "recordDate": "2023-06-27 10:48:40",
      "qiniuFileName": "TMP_2023_06_27_d2ef090d40c14d799cd1c6fbecc01f84MOV",
      "fileName": "IMG_1597.MOV",
      "size": 64028002,
      "sizeStr": "61.06MB",
    },
    {
      "userID": 1034,
      "recordDate": "2023-08-27 10:48:40",
      "qiniuFileName": "TMP_2023_06_27_d2ef090d40c14d799cd1c6fbecc01f84MOV",
      "fileName": "IMG_1597.MOV",
      "size": 64028002,
      "sizeStr": "61.06MB",
    },
    {
      "userID": 1036,
      "recordDate": "2023-09-27 10:48:40",
      "qiniuFileName": "TMP_2023_06_27_d2ef090d40c14d799cd1c6fbecc01f84MOV",
      "fileName": "IMG_1597.MOV",
      "size": 64028002,
      "sizeStr": "61.06MB",
    }
  ]
})


// 分页
const current = ref(1);
const size = ref(10);
const totals = ref(10)
const handleCurrentChange = async (page) => {
  current.value = page;
};


</script>
<style lang="less" scoped>
@import "../../../less/page.less";

.pages {
  display: flex;
  justify-content: flex-end;
}

.inners {
  width: 100% !important;
  min-width: 100% !important;
  position: relative;
}

.right {
  height: max-content !important;
  padding-bottom: 70px !important;
}

.buys_box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.text {
  span {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    font-feature-settings: "tnum";
  }
}

.list {
  height: 97px;
  width: max-content;
  position: absolute;
  bottom: 103px;
  right: 35px;
  text-align: right;

  div {
    margin-bottom: 17px;
    color: #444;
    font-size: 14px;
  }
}

.results {
  width: 1000px;
  border-top: 1px solid #e8e8e8;
  height: 53px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;

  .text_left {
    padding-left: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #444;
  }

  .buttons_pays {
    padding: 0 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #d93737;
    margin-left: auto;
    cursor: pointer;
  }
}

.pricenum {
  font-size: 22px;
  font-weight: 600;
  color: #d93737;
  margin-left: 5px;
}

.savenum {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 5px;
}

.all_price {
  font-weight: 600;
  color: #444 !important;
}

.red {
  color: #d93737;
}

.flow_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .flow_left {
    width: 280px;

    & > div {
      margin-bottom: 10px;
    }

    .flow_title {
      display: inline-block;
      width: 100px;
      color: #999;
    }

    .flow_txt {
      display: inline-block;
      color: #333;
    }

    .sp_flow {
      color: #999 !important;
    }
  }

  .flow_right {

  }
}
</style>