<template>
  <div class="wrap" ref="scrollBox">
    <div class="inbox" ref="inboxRef">
      <div class="bg_w">
        <div>
          <span class="span">{{ $t('common.edm') }}：</span>
          <el-select
              v-model="emailType"
              @change="handleChangeEmail"
              class="m-2"
              :default-first-option="true"
          >
            <el-option
                v-for="item in emailListTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
          <span class="span">{{ $t('common.contact') }}：</span>
          <el-select
              v-model="emailSelect"
              @change="handleChangeContact"
              class="m-2"
          >
            <el-option
                v-for="item in emailList"
                :key="item.email"
                :label="item.email"
                :value="item.email"
            />
          </el-select>
        </div>
        <el-input
            v-model="searchCount"
            :placeholder="$t('common.search.p')"
            class="input-with-select"
            clearable
            @clear="handleClickSearch('clear')"
            @keydown.enter.native="handleClickSearch"
        >
          <template #append>
            <el-button type="primary" :icon="Search" @click="handleClickSearch"/>
          </template>
        </el-input>
      </div>
      <div class="screen">
        <div class="screen_btn">
          <template v-if="type === 1 || type === 3">
            <ElButton size="small" @click="requestTagAllRead"
            >{{ $t('common.read.all') }}
            </ElButton
            >
            <ElButton size="small" @click="() => handleCommand('0')"
            >{{ $t('common.read.set') }}
            </ElButton
            >
            <ElButton size="small" @click="requestDelMail">{{ $t("common.delete") }}</ElButton>
            <ElButton size="small" v-if="type === 1">{{ $t('common.email.rubbish') }}</ElButton>
          </template>
          <ElButton size="small" v-if="type === 2" @click="requestDelMail(1)"
          >{{ $t('common.draft.delete') }}
          </ElButton
          >
          <ElButton size="small" v-if="type === 1">{{ $t('common.block') }}</ElButton>
          <template v-if="type === 5">
            <ElButton size="small" @click="requestTagAllRead"
            >{{ $t('common.read.all') }}
            </ElButton
            >
            <ElButton size="small" @click="() => requestDelMail(2)"
            >{{ $t('common.delete.empty') }}
            </ElButton
            >
            <ElButton size="small">{{ $t('common.block') }}</ElButton>
            <ElButton size="small" @click="() => requestDelMail(0)"
            >{{ $t('common.restore') }}
            </ElButton
            >
          </template>
        </div>
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            small="small"
            v-if="total"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
      <div class="scroll_out_box"
      >
        <ElTable
            v-loading="loading"
            :data="tableData"
            size="small"
            :height="tableHeight"
            highlight-current-row
            :default-sort="{ prop: 'date', order: 'descending' }"
            @current-change="handleCurrentRowChange"
            :cell-style="rowClassName"
            :header-cell-style="headerClassName"
            @row-dblclick="handleViewDetail"
            @cell-mouse-enter="mouseEnters"
            @cell-mouse-leave="mouseLeaves"
            @select="handleClickSelect"
        >
          <el-table-column type="selection" width="30" fixed="left"/>
          <el-table-column width="30">
            <template #header>
              <img style="position: absolute;left: 8px;top:10px" src="@/assets/email/fj.svg"/>
            </template>
            <template #default="scope">
              <img style="position: absolute;left: 8px;top:10px" v-if="scope.row.isExtend === 1" class="pointer"
                   src="@/assets/email/fj.svg"
                   @click.stop="() => handleDownload(scope)"/>
            </template>
          </el-table-column>
          <el-table-column prop="contactName" :label="$t('common.contact')" width="270">
            <template #default="scope">
              {{ scope.row.contactName != null ? scope.row.contactName : scope.row.customerName }}
            </template>
          </el-table-column>
          <el-table-column prop="subject" sortable show-overflow-tooltip
                           :label="$t('common.subject')">
            <template #default="scope">
              <a @click.stop="handleViewDetail(scope.row)">{{ scope.row.subject }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$t('common.remark')" width="80">
            <template #default="scope">
              <div class="flex">
                <!--                         @click.stop="handleRef(refMap[`${scope.row.id}`], scope.row, 1)"-->
                <el-icon :ref="(el) => (refMap[`${scope.row.id}`] = el)"
                         @click.stop="handleClickRemark(scope.row)"
                         :color="scope.row.remark ? '#40a9ff' : '#dddddd'" style="padding-top: -2px">
                  <el-tooltip
                      v-if="emailListCheckoutTarget.remark"
                      class="box-item"
                      :content="emailListCheckoutTarget.remark"
                      placement="right"
                      :show-after="600"
                  >
                    <Memo/>
                  </el-tooltip>
                  <Memo v-else/>
                </el-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="type" :label="$t('sys.note.jc')" width="50">
            <template #default="scope">
              <span v-if="scope.row.type === 1" style="color:#4c9f33">{{ $t('sys.note.j') }}</span>
              <span v-else style="color:#b32518">{{ $t('sys.note.c') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="userName" :label="$t('common.number')" width="100"/>
          <el-table-column prop="sentDate" :label="$t('common.date')" sortable width="140"/>
          <el-table-column fixed="right" :label="$t('common.cz')" width="100">
            <template #default="scope">
              <el-button
                  v-if="scope.row.type===1"
                  link
                  type="primary"
                  size="small"
                  @click.stop="handleReply(scope.row)"
              >
                {{ $t('common.reply') }}
              </el-button>
              <el-button v-else link
                         type="primary"
                         size="small"
                         @click.stop="handleResend(scope.row)">
                {{ $t('common.resend') }}
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
          </template>
        </ElTable>
        <div class="current_mail" :style="{width: '100%',height:pageframeHeight +'px'}">
          <div class="mail_info"
               :style="{ top: elementTop + 'px', left: elementLeft + 'px' }" ref="moveTarget">
            <div class="up_row" @mousedown="startDrag"></div>
            <div style="width: 100%">
              <div class="from over_text">{{ $t('common.email.from') }}：{{ currentMail.mailBody?.from }}</div>
              <div class="to over_text" ref="toRef">
                <el-popover
                    v-if="formatText(currentMail.mailRecipientVOList, 'to')"
                    :visible="visibleTo"
                    width="500"
                    :content="formatText(currentMail.mailRecipientVOList, 'to')"
                    :effect="'dark'"
                    popper-class="visible"
                >
                  <template #reference>
                    <span @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'to'),'to')"
                          @mouseleave="delayedHidePopover('to')">{{
                        $t('common.email.to')
                      }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}</span>
                  </template>
                  <div @mouseenter="keepPopoverVisible('to')" @mouseleave="hidePopover('to')">
                    {{ formatText(currentMail.mailRecipientVOList, 'to') }}
                  </div>
                </el-popover>
                <template v-else>
                  {{ $t('common.email.to') }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}
                </template>
              </div>
              <div class="cc over_text">
                <el-popover
                    v-if="formatText(currentMail.mailRecipientVOList, 'cc')"
                    :visible="visibleCC"
                    width="500"
                    :content="formatText(currentMail.mailRecipientVOList, 'cc')"
                    :effect="'dark'"
                    popper-class="visible"
                >
                  <template #reference>
                <span
                    @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'cc'),'cc')"
                    @mouseleave="delayedHidePopover('cc')"
                >
                {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                </span>
                  </template>
                  <div @mouseenter="keepPopoverVisible('cc')" @mouseleave="hidePopover('cc')">
                    {{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                  </div>
                </el-popover>
                <template v-else>
                  {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                </template>
              </div>
            </div>
            <div class="down_row" @mousedown="startDrag"></div>
          </div>
          <div v-loading="detailLoading" :style="{width: '100%',height:pageframeHeight +'px',position:'relative'}">
            <iframe ref="pageframe" id="pageframe" :srcdoc="iframeContent" frameborder="0"
                    :style="{width: '100%',height:pageframeHeight +'px',overflowY: 'scroll'}"
                    :class="detailLoading?'scroll':'scroll loaded'"
            >
              <p>Your browser does not support preview features, please download Google browser or other modern
                browsers.</p>
            </iframe>
            <div v-if="isDragging" id="mask"
                 :style="{width: '100%',height:pageframeHeight +'px',background:'rgba(0,0,0,0)',position:'absolute',zIndex:999,top:'30px'}">
            </div>
          </div>
        </div>
      </div>
      <!--   添加备注   -->
      <Remark v-if="visitableRemark" v-model="visitableRemark" @close="closeRemark" :isHeadLine="true"
              :currentItem="currentItem" @getList="getCustomerMailRecordApisList" :mailId="true"></Remark>
    </div>
  </div>
</template>
<script>
import {onMounted, ref, watch, computed, defineEmits, nextTick} from "vue";
import {
  getDefaultMailConfig,
  tagMail,
  tagAllRead,
  delMail,
  getMailDetails,
} from "../../api/index";
import {ElMessage} from "element-plus";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {Search} from "@element-plus/icons-vue";
import {getContactEmailListApis, getCustomerMailRecordApis, requestUpdateMailRemark} from "@/api/mail";
import {emptyUrl, htmlReg} from '@/utils/statusParams'
import {debouncePlus, throttle} from "@/utils/mylodash"
import {formatSize} from "@/utils/file";
import {getToken} from "@/utils/auth";
import {addImgOnErrorAttribute, containsHtmlTags, convertLinksToHTML, convertToHTML} from "@/utils/iframe";
import {i18n} from "@/lang";
import {getScaleValue} from "@/utils/view";
import Remark from "@/components/Remark/index.vue";

export default {
  components: {Remark},
  methods: {formatSize},
  // (-1:失败邮件,1:收件箱,2:草稿箱,3:已发送,4:已删除,5:垃圾邮件)
  props: ["type", 'targetCheckoutItem'],
  setup(props, context) {
    const route = useRoute()
    const tableHeight = ref(200);
    const inboxRef = ref(null)
    const configId = ref("");
    const emailList = ref([]);
    const searchCount = ref("");
    const current = ref(1);
    const size = ref(20);
    const total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const multipleTableRef = ref(null);
    const multipleSelection = ref([]);
    const currentMail = ref({});
    const router = useRouter();
    const iframeContent = ref('');
    const emailListTypes = ref([{label: i18n.global.t('common.all'), value: 0}, {
      label: i18n.global.t('sys.note.j'),
      value: 1
    }, {
      label: i18n.global.t('sys.note.c'),
      value: 2
    },])
    // 选中的 邮件
    const emailType = ref(0)

    const userInfo = computed(() => store.state.user.userInfo)

    // 选中的 联系人
    const emailSelect = computed(() => {
      return props.targetCheckoutItem.islink ? props.targetCheckoutItem.targetCheckoutItem : emailSelectTarget.value
    })
    const emailSelectTarget = ref(i18n.global.t('common_all'))

    const store = useStore();
    const teamId = window.localStorage.getItem("teamId");

    watch(() => emailSelect.value, () => {
      if (props.targetCheckoutItem.islink) {
        getCustomerMailRecordApisList()
      }
    })

    // async function initTableHeight() {
    //   await nextTick()
    //   tableHeight.value = inboxRef.value.offsetHeight - 48 - 36 - 300;
    //   elementTop.value = tableHeight.value
    // }
    function initTableHeight() {
      // console.log(inboxRef.value, 'inboxRef.value')
      // tableHeight.value = inboxRef.value.offsetHeight - 48 - 36 - 300;
      const inboxElement = inboxRef.value;

      // 通过使用 getComputedStyle 获取元素的最终计算样式，以确保正确计算高度
      const inboxStyle = window.getComputedStyle(inboxElement);

      // 使用 offsetHeight 来获取元素的高度，可能需要调整具体的计算方式以适应不同的布局和样式
      const inboxHeight = inboxElement.offsetHeight;

      // 这里的 48、36、300 是用于计算的固定值，根据具体的布局和样式可能需要进行调整
      if (elementTopRaw.value === 0) {
        tableHeight.value = inboxHeight - parseInt(inboxStyle.paddingTop) - parseInt(inboxStyle.paddingBottom) - 80 - pageframeHeight.value;
      } else {
        tableHeight.value = elementTopRaw.value
      }
    }

    const commandList = [1, 0, 1, 0];


    async function handleDownload(scope) {
      // const result = await requestMailAccessory({ id: scope.row.id });
      // if (result.code !== 200) {
      //   ElMessage.error(result.message);
      //   return;
      // }
      window.open(`${location.origin}/email/detail/${scope.row.id}/${JSON.stringify(scope.row)}`);
    }

    const handleViewDetail = (row) => {
      //邮件已读
      multipleSelection.value = [row]
      requestTagMail(commandList[0], 'isRead');
      // 没有 type 来源，这里指定 type 类型
      const targetType = row.type === 1 ? 1 : 3;
      window.open(`${location.origin}/email/detail/${row.mailId}/${JSON.stringify({
        flag: row.flag ? row.flag : 0,
        isExtend: row.isExtend ? row.isExtend : 0,
        from: row.from,
        customerId: row.customerId,
      })}/${targetType}`);
      getCustomerMailRecordApisList()
    }

    // 回复
    function handleReply(row) {
      localStorage.setItem('reSend', JSON.stringify(row))
      router.push({
        path: '/email/write_mail',
        query: {id: row.mailId, customerId: row.customerId},
      });
    }

    function handleResend(row) {
      localStorage.setItem('reSend', JSON.stringify(row))
      const query = {
        id: row.mailId,
        flag: row.flag,
        type: "resend",
        mailType: 3 || 2,
        customerId: route.params.userId,
      };
      if (row.flag === 0 && row.customerId) {
        query.customerId = row.customerId;
      }
      router.push({
        path: '/email/write_mail',
        query,
      });
    }

    async function getConfig() {
      const res = await getDefaultMailConfig(teamId);
      const {id} = res.result;
    }


    function handleSizeChange() {
    }

    function handleCurrentChange(page) {
      current.value = page;
      // getMailList();
      getCustomerMailRecordApisList()
    }

    function handleSelectionChange(val) {
      multipleSelection.value = val;
    }

    async function requestTagMail(tag) {
      const result = await tagMail({
        [type]: tag,
        mailIdList: multipleSelection.value.map(item => item.id),
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      // getMailList();
      getCustomerMailRecordApisList()
    }

    async function requestTagAllRead() {
      const result = await tagAllRead();
      // getMailList();
      getCustomerMailRecordApisList()
    }

    async function requestDelMail(flag = 1, isSelect) {
      if (isSelect) {
        multipleSelection.value.push(isSelect)
      }
      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }
      const result = await delMail({
        type: flag, // 0 还原  1 已删除 2彻底删除
        mailIdList: multipleSelection.value.map(item => item.mailId),
        originalPosition: isSelect?.originalPosition,
      });
      if (result.code !== 200) {
        ElMessage.error(result.msg);
        return;
      }
      ElMessage.success(i18n.global.t("sys.message.success.delete"));
      setTimeout(() => {
        // getMailList();
        getCustomerMailRecordApisList()
      }, 1500);
    }

    const activeItems = ref({})
    const detailLoading = ref(false)

    const getTargetMailId = ref("")

    async function handleCurrentRowChange(current) {
      if (current) {
        getTargetMailId.value = current.mailId;
      }
      activeItems.value = current
      if (current !== null) {
        detailLoading.value = true
        try {
          const result = await getMailDetails({id: current.mailId});
          let content = result.result?.mailBody?.content;
          const insertionContent = `<script>
            function errorImage(img) {
                img.onerror = null;
                img.src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR42mP4//8/AAX+Av4zEpUUAAAAAElFTkSuQmCC"
            }
        <\/script>`;
          if (!content) {
            iframeContent.value = ''
          } else {
            if (containsHtmlTags(content)) {
              const replacedHtmlString = addImgOnErrorAttribute(content)
              const res = replacedHtmlString.replace(/(<html[^>]*>)/, '$1' + insertionContent);
              iframeContent.value = res;
            } else {
              if (content.indexOf('https://s3gw.cmbimg.com') !== -1) {
                iframeContent.value = content;
              } else {
                iframeContent.value = convertLinksToHTML(convertToHTML(content))
              }
            }
          }
          currentMail.value = result.result;
          detailLoading.value = false
        } catch (e) {
          detailLoading.value = false
        }
      }
    }

    function handleCommand(val) {
      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }
      requestTagMail(val);
    }

    function formatText(list, type) {
      if (!list || !list.length) {
        return '';
      }
      const infoMap = list.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item.mail);
        return prev;
      }, {});
      return infoMap[type] ? infoMap[type].join(',') : '';
    }

    function handleSettingStress(scope, type) {
      multipleSelection.value = [scope.row];
      handleCommand(type);
    }


// 获取邮件配置
    const getCustomerMailRecordApisList = async () => {
      loading.value = true
      const customerId = window.localStorage.getItem('customerId');
      const teamId = window.localStorage.getItem('teamId')
      const targetObj = {
        customerId,
        "pageNo": current.value,
        "pageSize": size.value,
        "search": isClear.value ? "" : searchCount.value,
        teamId,
        email: emailSelect.value === i18n.global.t('common_all') ? "" : emailSelect.value,
        "type": emailType.value
      }
      let res = await getCustomerMailRecordApis({...targetObj});
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      loading.value = false
      const {records} = res.result;
      tableData.value = records;
      total.value = res.result.total;
      current.value = res.result.current;
      size.value = res.result.size;
    }


    const isClear = ref(false)
// 点击搜索框
    const handleClickSearch = (flag) => {
      if (flag === "clear") {
        isClear.value = true;
      } else {
        isClear.value = false;
      }
      getCustomerMailRecordApisList()
    }

    const getContactEmailListApisList = async () => {
      // customerId 不同步问题
      const customerId = router.currentRoute.value.params
      window.localStorage.setItem('customerId', customerId.userId);
      let res = await getContactEmailListApis({customerId: customerId.userId});
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return;
      }
      emailList.value = [...res.result, {
        email: i18n.global.t('common_all'),
        contactEmail: i18n.global.t('common_all'),
      }].filter(item => item.email !== "");
    }

    // 页面进行缩放
    const adjustContentPosition = debouncePlus(() => {
      console.log('resize')
      localStorage.setItem("resize", true)
      router.go(0)
    }, 200)

    onMounted(async () => {
      await store.dispatch("system/getTargetHeight", {userId: userInfo.value.id})
      // if (localStorage.getItem("resize") === 'true') {
      //   pageframeHeightRaw.value = getScaleValue(customerInboxHeight.value)
      //   elementTopRaw.value = getScaleValue(customerInboxTop.value)
      //   localStorage.setItem("resize", false)
      // } else {
      pageframeHeightRaw.value = customerInboxHeight.value
      elementTopRaw.value = customerInboxTop.value
      // }
      await nextTick()
      if (inboxRef.value) {
        await initTableHeight();
        if (elementTopRaw.value === 0) {
          elementTop.value = tableHeight.value
        }
      }
      document.addEventListener('mousemove', onDragCall);
      document.addEventListener('mouseup', stopDragCall);
      await getContactEmailListApisList();
      await getCustomerMailRecordApisList()

      window.addEventListener('resize', adjustContentPosition);
    });

    const handleChangeEmail = () => {
      getCustomerMailRecordApisList()
    }
    const handleChangeContact = (val) => {
      emailSelectTarget.value = val
      context.emit('change', {islink: false})
      getCustomerMailRecordApisList()
    }

    async function setRemark(id, val) {
      if (val.length > 30) {
        ElMessage.warning(i18n.global.t('common.email.setBlockSize'))
        activeItems.value.remark = "";
        return;
      } else {
        const result = await requestUpdateMailRemark({id, remark: val});
        if (result.code !== 200) {
          ElMessage.error(result.message);
          return;
        }
      }
    }


    // 移动
    const moveTarget = ref(null);
    const scrollBox = ref(null);
    const isDragging = ref(false);
    const initialMouseY = ref(0)
    const elementLeft = ref(0)
    const customerInboxHeight = computed(() => store.state.system.customerInboxHeight)
    const customerInboxTop = computed(() => store.state.system.customerInboxTop)

    const elementTopRaw = ref(customerInboxTop.value);
    const elementTop = computed({
      get() {
        return elementTopRaw.value;
      },
      set(newValue) {
        elementTopRaw.value = newValue;
      },
    });

    const pageframeHeightRaw = ref(customerInboxHeight.value);
    const pageframeHeight = computed({
      get() {
        return pageframeHeightRaw.value;
      },
      set(newValue) {
        pageframeHeightRaw.value = newValue;
      },
    });

    function startDrag(event) {
      // 阻止 iframe 事件响应
      // document.getElementById("pageframe").style.pointerEvents = "none";
      isDragging.value = true;
      initialMouseY.value = event.clientY;
    }

    const saveTargetHeight = debouncePlus((userId, pageframeHeight, elementTop) => {
      store.dispatch("system/settingTargetHeight", {
        userId,
        customerInboxHeight: pageframeHeight,
        customerInboxTop: elementTop
      });
    }, 200); // 300ms 防抖延迟

    const onDragCall = (event) => {
      // 拖拽中更新元素位置
      if (isDragging.value) {
        requestAnimationFrame(() => {
          const deltaY = event.clientY - initialMouseY.value;
          elementTop.value += deltaY;
          initialMouseY.value = event.clientY;
          // 限制元素在容器内上下移动
          const tagNumber = getScaleValue(160)
          elementTop.value = Math.max(30, Math.min(inboxRef.value.clientHeight, elementTop.value > inboxRef.value.clientHeight - tagNumber ? inboxRef.value.clientHeight - tagNumber : elementTop.value));
          tableHeight.value = elementTop.value;
          pageframeHeight.value = scrollBox.value.offsetHeight - elementTop.value
          saveTargetHeight(userInfo.value.id, pageframeHeight.value, elementTop.value);
        })
      } else {
        stopDragCall()
      }
    }

    document.onmouseup = function (e) {
      document.onmousemove = null;
      document.onmouseup = null;
    };

    function stopDragCall() {
      // 结束拖拽
      isDragging.value = false;
    }

    const rowClassName = ({row, column, rowIndex, columnIndex}) => {
      if (columnIndex === 3 || columnIndex === 2 || columnIndex === 0 || columnIndex === 1) {
        return {textAlign: 'left'}
      } else {
        return {textAlign: 'center'}
      }
    }

    const headerClassName = ({row, column, rowIndex, columnIndex}) => {
      if (columnIndex === 3 || columnIndex === 2 || columnIndex === 0 || columnIndex === 1) {
        return {'text-align': 'left',}
      } else {
        return {'text-align': 'center'}
      }
    }


    const visibleTo = ref(false)
    const visibleCC = ref(false)

    const hidePopoverTimeout = ref(false)

    function showPopover(string = '', flag) {

      if (moveTarget.value) {
        const itemWidth = Math.round((moveTarget.value.clientWidth - 40) * (1 / 3))
        const stringWidth = string.pxWidth('normal 12px PingFangSC, PingFang SC')
        if (stringWidth + 20 > itemWidth) {
          if (flag === "to") {
            visibleCC.value = false;
            visibleTo.value = true;
          } else if (flag === "cc") {
            visibleTo.value = false;
            visibleCC.value = true;
          }
          if (hidePopoverTimeout.value) {
            clearTimeout(hidePopoverTimeout.value);
            hidePopoverTimeout.value = null;
          }
        }
      }
    }

    function delayedHidePopover(flag) {
      hidePopoverTimeout.value = setTimeout(() => {
        flag === "to" ? visibleTo.value = false : visibleCC.value = false;
      }, 200); // Adjust delay as necessary
    }

    function keepPopoverVisible(flag) {
      if (hidePopoverTimeout.value) {
        clearTimeout(hidePopoverTimeout.value);
        hidePopoverTimeout.value = null;
      }
      flag === "to" ? visibleTo.value = true : visibleCC.value = true;
    }

    function hidePopover(flag) {
      flag === "to" ? visibleTo.value = false : visibleCC.value = false;
    }


    const refMap = ref([])
    const tempRef = ref(null)
    const visiblePopover = ref(false)
    const emailListCheckoutTarget = ref({remark: ''})
    const handleRef = debouncePlus((ref, item, type) => {
      tempRef.value = ref
      visiblePopover.value = true;
      emailListCheckoutTarget.value = item;
    }, 500)

    const hidePopoverTimeoutModel = ref(false)
    // 这里是开始点
    const mouseEnters = debouncePlus((row) => {
      emailListCheckoutTarget.value = row;
    }, 200)

    const mouseLeaves = () => {
      hidePopoverTimeoutModel.value = setTimeout(() => {
        visiblePopover.value = false;
      }, 300);
    }
    const keepPopoverVisibleModel = () => {
      if (hidePopoverTimeoutModel.value) {
        clearTimeout(hidePopoverTimeoutModel.value);
        hidePopoverTimeoutModel.value = null;
      }
      visiblePopover.value = true;
    }

    const hidePopoverModel = () => {
      visiblePopover.value = false;
    }

    const cancelPopover = () => {
      visiblePopover.value = false;
      emailListCheckoutTarget.value.remark = ''
    }

    const handleClickSelect = (val) => {
      console.log(val, 'val')
      multipleSelection.value = val;
    }


    // 弹窗进行编辑备注
    const visitableRemark = ref(false)
    const currentItem = ref({remark: ""})
    const handleClickRemark = (row) => {
      const {id} = row
      console.log(id, row, 'id')
      currentItem.value = row
      visitableRemark.value = true
    }
    const closeRemark = () => {
      visitableRemark.value = false
    }

    return {
      currentItem,
      closeRemark,
      visitableRemark,
      handleClickRemark,
      handleClickSelect,
      cancelPopover,
      emailListCheckoutTarget,
      hidePopoverModel,
      keepPopoverVisibleModel,
      mouseLeaves,
      mouseEnters,
      handleRef,
      visiblePopover,
      tempRef,
      refMap,
      showPopover,
      delayedHidePopover, keepPopoverVisible, hidePopover,
      detailLoading,
      visibleTo,
      visibleCC,
      handleDownload,
      handleViewDetail,
      handleResend,
      headerClassName,
      rowClassName,
      pageframeHeight,
      elementLeft,
      elementTop,
      stopDragCall,
      onDragCall,
      startDrag,
      scrollBox,
      moveTarget,
      setRemark,
      configId,
      emailList,
      tableData,
      current,
      size,
      total,
      loading,
      multipleTableRef,
      handleCommand,
      currentMail,
      handleSizeChange,
      handleCurrentChange,
      handleCurrentRowChange,
      handleSettingStress,
      handleSelectionChange,
      requestTagAllRead,
      requestDelMail,
      getCustomerMailRecordApisList,
      getContactEmailListApisList,
      emailListTypes,
      emailType,
      emailSelect,
      searchCount,
      Search,
      handleClickSearch,
      handleReply,
      formatText,
      iframeContent,
      handleChangeEmail,
      handleChangeContact,
      tableHeight,
      inboxRef,
      emptyUrl,
      isDragging,
      customerInboxHeight,
      customerInboxTop,
      elementTopRaw,
      pageframeHeightRaw

    };
  },
}
;
</script>
<style lang="less" scoped>
:deep(.el-table .read_0) {
  font-weight: 700 !important;
}

.wrap {
  background-color: rgb(245, 245, 245);
  padding: 16px;
  height: -webkit-fill-available !important;

  .inbox .bg_w {
    & :deep(.el-select) {
      width: 200px !important;
    }
  }
}

.fx-j-e {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.pointer {
  cursor: pointer;
}

.inbox {
  background-color: #fff;
  height: 100%;
  position: relative;
}

.bg_w {
  padding: 8px;
  display: flex;
  align-items: center;

  .span {
    font-size: 14px;
    color: rgba(0, 0, 0, .65);
  }
}

.input-with-select {
  width: 350px;
  margin-right: 10px;
}

.screen {
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  background-color: #f5f5f5;

  .el-button + .el-button {
    margin-left: 2px;
  }

  .el-dropdown {
    margin-left: 2px;
  }
}

.current_mail {

  .mail_info {
    display: flex;
    flex-direction: column;
    height: 30px;
    align-items: center;
    background: rgb(229, 229, 229);
    //user-select: text;
    cursor: n-resize;
    transform: translateZ(0);
    will-change: transform;
    font-family: "PingFangSC, PingFang SC";

    & > div:nth-of-type(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 20px;
      height: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      cursor: text !important;

      & > div {
        width: 33.33%;
        min-width: 33.33% !important;
        max-width: 33.33% !important;
      }
    }
  }
}

.box_titles {
  position: relative;
  height: max-content;
  border-top: 1px solid #e8e8e8;
}

.inner_card {
  margin: 30px 0px 30px 40px;
}

.sp_box {
  width: 45% !important;
}

.footer_box {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;

  .el-button {
    margin: 0px 10px;
  }
}

.box_titles:nth-of-type(1)::after {
  content: '选择建档邮箱';
  position: absolute;
  top: -12px;
  left: 34px;
  text-align: center;
  font-size: 16px;
  color: black;
  background: white;
  padding: 0px 10px;
}

.box_titles:nth-of-type(2)::after {
  content: '选择归档客户';
  position: absolute;
  top: -12px;
  left: 34px;
  text-align: center;
  font-size: 16px;
  color: black;
  background: white;
  padding: 0px 10px;
}

.box_titles:nth-of-type(3)::after {
  content: '选择已有联系人';
  position: absolute;
  top: -12px;
  left: 34px;
  text-align: center;
  font-size: 16px;
  color: black;
  background: white;
  padding: 0px 10px;
}

.box_titles_created {
  position: relative;
  height: max-content;
  border-top: 1px solid #e8e8e8;
}

.box_titles_created::after {
  content: '新建联系人';
  position: absolute;
  top: -12px;
  left: 34px;
  text-align: center;
  font-size: 16px;
  color: black;
  background: white;
  padding: 0px 10px;
}

.sp_buttons {
  margin-left: 20px;
  position: absolute;
  left: 105%;
}

.my_forms {
  margin-top: 20px;
}

:deep(.el-form-item__content) {
  flex-wrap: nowrap !important;
}

.wrap :deep(.el-form-item) {
  margin: 20px 0 0;
}

.el-form-item__content > div {
  position: relative;
}

:deep(.el-scrollbar__bar.is-horizontal) {
  height: 0 !important;
}

:deep(.el-scrollbar__wrap) {
  overflow-x: hidden !important;
}

:deep(.el-input-group__append) {
  padding: 0 20px 0 0;
}

:deep(.el-input-group__append button.el-button) {
  background: #5a9cf8;
  color: white;
  width: 76px;
  border-radius: 0px 4px 4px 0px !important;
}

.bg_w {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
  }

  .span {
    width: max-content;
    min-width: max-content;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
}

:deep(.el-select) {
  margin-right: 20px;
}


.scroll_out_box {
  position: relative;
  min-height: calc(100vh - 164px) !important;
  max-height: calc(100vh - 164px) !important;
}

.mail_info {
  width: 100%;
  position: absolute;
  z-index: 999;
}

#pageframe {
  padding: 30px 0px 8px 0px;
}

.screen_btn {
  button:nth-of-type(1) {
    margin-left: 0px !important;
  }

  button {
    margin-left: 2px !important;
  }
}

.up_row, .down_row {
  width: 100%;
  height: 2px !important;
  cursor: row-resize !important;
}

.over_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

iframe {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

iframe.loaded {
  opacity: 1;
}

:deep(.el-popper) {
  max-width: 45% !important;
}

.visible {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 8px !important;
  }
}

.sp_model_popover {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 12px !important;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.el-scrollbar__bar.is-horizontal) {
  height: 6px !important;
}
</style>
