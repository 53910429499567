<template>

  <ElTable
      ref="multipleTableRef"
      :data="tableData"
      :style="{width: '100%', marginTop: '16px',minHeight:tableData.length===0?'200':tableData.length-1<10?'max-content':'580px'}"
      @selection-change="handleSelectionChange"
      @select="handleCheck"
      @select-all="handleClickSelectAll"
      @row-click="handle"
      v-loading="isLoading"
      id="sp_tables"
      :row-class-name="rowClass"
      :header-cell-class-name="cellClass"
      :cell-style="rowClassName"
      :header-cell-style="headerClassName"
      :row-key="row => row.id"
      :height="tableData.length-1<10?'max-content':'580px'"
      @cellMouseLeave="()=>visiblePopover = false"
  >
    <!--    :row-key="row => row.id"-->
    <!--    :reserve-selection="true"-->
    <el-table-column type="selection" :selectable="checkSelectable" :reserve-selection="true" fixed="left">
    </el-table-column>
    <el-table-column
        v-for="(item, index) in tableColumTitles"
        :key="item.value"
        :label="item.key"
        :prop="item.value"
        @render-header="handleTableRenderComplete"
        :fixed="item.key === $t('common.cz')&&!item.sp? 'right' :item.value==='customerCode'?'left': auto"
        :width="item.value==='customerCode'?200:item.value==='customerOrigin'?240:item.value==='customerName'?auto:(flexWidth(item.value, tableData, item.key))"
        :min-width="item.value==='customerName'?200:auto"
        :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='customerOrigin'?true:false"
    >
      <!--      :width="(flexWidth(item.value, tableData, item.key))"-->
      <!--      :width="-->
      <!--      item.value === 'setting' && item.value !== 'customerName'-->
      <!--      ? 100-->
      <!--      : item.value === 'customerName'-->
      <!--      ? 300-->
      <!--      : item.value==='lastTime'?160:(flexWidth(item.value, tableData, item.key))-->
      <!--      "-->
      <template #default="{ row, column, $index }" v-if="isSpTable">
        <template v-if="item.key === $t('common.cz')&&!row.sp">
          <slot name="settings" :row="{ row, column, $index }"></slot>
          <router-link :to="'/email/customer/detail/' + row.id + '?type=1&tabKey=2&teamId='+teamId">
            <slot name="linkUserHistory"></slot>
          </router-link>
        </template>
        <template v-else-if="item.key === $t('common_customer_name')&&!row.sp">
          <!--          <el-button id="customerName_id" link type="primary"-->
          <!--                     @click.native.stop="toDetail(row)">-->
          <!--            &lt;!&ndash;            {{ truncateByBytes(row.customerName, 45) }}&ndash;&gt;-->

          <!--            -->
          <!--          </el-button>-->
          <span style="color:#5a9cf8;cursor:pointer;" @click.native.stop="toDetail(row)">
            {{ row.customerName }}
            </span>
        </template>
        <template v-else-if="item.key === $t('common_customer_type')&&!row.sp">
          <el-button link type="primary">
            {{ row.customerType }}
          </el-button>
        </template>
        <template v-else-if="item.key === $t('common_customer_level')&&!row.sp">
          <el-button link type="primary">
            {{ row.customerLevel }}
          </el-button>
        </template>
        <template v-else-if="item.key === $t('common.contact')&&!row.sp">
          <div class="hover" v-if="row.maiContactVOList.length > 0">
            <!-- 触发内容1 -->
            <el-button link type="primary" :ref="ref => (refMap[row.id] = ref)"
                       @mouseover.stop="handleRef(refMap[row.id], row, 1)">
              {{ row.maiContactVOList.length }}
            </el-button>
          </div>
          <div v-else>
            <el-button link type="info">
              {{ row.maiContactVOList.length }}
            </el-button>
          </div>
        </template>
        <template v-else-if="item.key === $t('common.bybranch')&&!row.sp">
          <div class="hover" v-if="row.mangerVOList.length > 0">
            <!-- 触发内容1 -->
            <el-button link type="primary" :ref="ref => (refMap[row.customerCode] = ref)"
                       @mouseover.stop="handleRef(refMap[row.customerCode], row, 2)">
              {{ row.mangerVOList.length }}
            </el-button>
          </div>
          <template v-else>
            <el-button link type="info">
              {{ row.mangerVOList.length }}
            </el-button>
          </template>
        </template>
        <template v-else-if="item.key ===$t('common.customer.Region')&&!row.sp">
          <div style="cursor: pointer">
            <!-- 触发内容1 -->
            <el-image :ref="ref => (refMap[`${row.id}_img`] = ref)"
                      @mouseover.stop="handleRef(refMap[`${row.id}_img`], row, 3)"
                      v-if="row.zoneAddress"
                      :src="flag[row.zoneCode]"
                      style="width:26px;margin-right:2px;vertical-align: middle;" :key="row.zoneCode"
                      lazy
            />
          </div>
        </template>
        <template v-else-if="item.key ===$t('common.lastcontact.time')&&!row.sp">
          <div>{{ row.lastTime }}</div>
        </template>
      </template>
    </el-table-column>
    <template #empty>
      <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
    </template>
  </ElTable>
  <div id="checkoutAll" ref="checkoutAll">
    <el-checkbox v-model="isSelectAll" :label="$t('tree.select.all')" size="large" @change="selectAll">
      {{ $t('common.customer.checkout.all', {count: isSelectAll ? totals : 0}) }}
    </el-checkbox>
  </div>

  <!--  el-popover-->
  <el-popover
      v-model:visible="visiblePopover"
      :virtual-ref="tempRef"
      virtual-triggering
      :width="'max-content'"
      trigger="hover"
      :show-after="500"
      effect="dark"
  >
    <template v-if="popoverType === 1">
      <!-- 业务逻辑1 -->
      <div
          v-html="
                          '<div>' +
                          activeItemElpopover.maiContactVOList
                            .map((item) => {
                              return `${item.contactName}<br/>`;
                            })
                            .join('') +
                          '</div>'
                        "
      ></div>
    </template>
    <template v-else-if="popoverType === 2">
      <!-- 业务逻辑2 -->
      <div
          v-html="
                '<div>' +
                activeItemElpopover.mangerVOList
                  .map((item) => {
                    return `${item.managerName}<br/>`;
                  })
                  .join('') +
                '</div>'
              "
      ></div>
    </template>
    <template v-else-if="popoverType===3">
      <!-- 业务逻辑3 -->
      {{ activeItemElpopover.zoneAddress }}
    </template>
  </el-popover>
  <!-- 抽屉 -->
  <el-drawer
      size="530px"
      v-if="isShowDrawer"
      v-model="drawer2"
      :direction="direction"
  >
    <template #header>
      <div style="display: flex">
        <h4 style="color: rgba(0, 0, 0, .85);font-weight: 500;font-size: 16px;line-height: 22px;">
          {{ activeItem.customerName }}
        </h4>
        <router-link
            :to="
            '/email/customer/detail/' +
            activeItem.id +
            '?tabKey=1&type=' +
            activeItem.type+'&teamId='+teamId
          "
            style="color: #409eff"
        >
          <el-button v-if="isShowEdit" link type="primary">{{ $t('common.edit') }}</el-button>
        </router-link>
      </div>
    </template>
    <template #default>
      <div class="top_titles">
        <div>
          <div class="font_targert">{{ $t("common.customer.number") }}：{{ activeItem.customerCode }}</div>
          <div class="font_targert" style="margin: 10px 0px;">{{ $t("common_byinput") }}：{{
              activeItem.createName
            }}
          </div>
          <div class="font_targert">{{
              $t("common.bybranch")
            }}：{{ activeItem.mangerVOList.map(item => item.managerName).join(',') }}
          </div>
        </div>
      </div>
      <div style="margin-top: 40px">
        <!-- table -->
        <div>
          <div><h3>{{ $t('common.cds25') }}</h3></div>
          <div class="small_box">
            <TableShows :table-data="companyInfo"/>
          </div>
        </div>
        <div style="margin-top: 40px">
          <div><h3>{{ $t('common.marketing.information') }}</h3></div>
          <div class="small_box">
            <TableShows :table-data="marketingInfo"/>
          </div>
        </div>
        <div style="margin-top: 40px">
          <div><h3>{{ $t('common.contact') }}</h3></div>
          <div
              class="small_box"
              v-for="(item, index) in contactInfo"
              :key="index"
          >
            <TableShows :table-data="item"/>
          </div>
        </div>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
import {useStore} from "vuex";
import {
  ref,
  defineProps,
  computed,
  defineEmits,
} from "vue";
import {useRoute, useRouter} from "vue-router";
import TableShows from "./TableShows.vue";
import flag from '@/assets/flag/index.js'
import {emptyUrl} from "@/utils/statusParams";
import {ElTable} from "element-plus";
import {flexWidth} from "@/utils/tableWidth";
import {debouncePlus} from "@/utils/mylodash";
import {i18n} from "@/lang"

const route = useRoute()

const {isShowDrawer, isSpTable, tableData, totals, tableColumTitles} = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  isShowPages: {
    type: Boolean,
    default: () => false,
  },
  isShowEdit: {
    type: Boolean,
    default: () => false,
  },
  tableColumTitles: {
    type: Array,
    default: () => [],
  },
  isShowDrawer: {
    type: Boolean,
    default: () => false,
  },
  isLoading: {
    type: Boolean,
    default: () => false,
  },
  isSpTable: {
    type: Boolean,
    default: () => true,
  },
  totals: {
    type: Number,
    default: 0,
  }
});

const store = useStore();
const router = useRouter();

const drawer2 = ref(false);
const direction = ref("rtl");

const emit = defineEmits(["selections"]);

const teamId = computed(() => store.state.email.teamID);

const ruleForm = computed(() => store.state.customer.ruleForm);
const contactList = computed(() => store.state.customer.contactList);
// 选中的item项
const activeItem = ref({});
// 公司信息
const companyInfo = ref([]);
// 营销信息
const marketingInfo = ref([]);
// 联系人信息
const contactInfo = ref([]);


// el-popover 优化
const refMap = ref({})
const tempRef = ref()
const visiblePopover = ref(false)
// -1-字句审核 0-整句审核 1-日志查看
const popoverType = ref(0)
const activeItemElpopover = ref(null)
const handleRef = debouncePlus((ref, item, type) => {
  console.log('refMap', refMap.value)
  tempRef.value = ref
  popoverType.value = type
  activeItemElpopover.value = item;
  if (type === 1) {
    // ...业务逻辑1
  } else {
    // ...业务逻辑2、3
  }
  visiblePopover.value = true
}, 400)


// 选中的客户信息
const handle = async (item) => {
  if (item.sp) {
    return
  }
  if (isShowDrawer) {
    const {id} = item;
    await store.dispatch("customer/getQueryById", {customerId: id});
    activeItem.value = item;
    drawer2.value = true;
    // 通过客户id查询客户信息
    // console.log(item, "rer");
    localStorage.setItem("customerId", id);
    // 整理表格数据
    if (ruleForm) {
      const {
        customerName,
        phoneWayList,
        setUpTime,
        economicNature,
        companyWebsite,
        companyAddress,
        companyFax,
        emailWayList,
        companyRemark,
        // ----
        customerType,
        customerLevel,
        product,
        marketingRemark,
      } = ruleForm.value;
      console.log('dddddd', ruleForm.value)
      companyInfo.value = [
        {name: i18n.global.t('common.customer.name'), value: customerName},
        {
          name: i18n.global.t("common.contact.phone"),
          value:
              phoneWayList.length !== 0 ? phoneWayList.filter((item) => item.isDefault === 1)[0].content :
                  [],
        },
        {name: i18n.global.t('common.establishment'), value: setUpTime},
        {name: i18n.global.t('common.economicnature'), value: economicNature},
        {name: i18n.global.t('common.company.website'), value: companyWebsite},
        {name: i18n.global.t('table.address'), value: companyAddress},
        {name: i18n.global.t('common.faxnumber'), value: companyFax},
        {
          name: i18n.global.t('common.cds6'),
          value:
              emailWayList.length !== 0 ? emailWayList.filter((item) => item.isDefault === 1)[0].content :
                  [],
        },
        {name: i18n.global.t('other.remark'), value: companyRemark},
      ];
      marketingInfo.value = [
        {name: i18n.global.t('common.customer.type'), value: customerType},
        {name: i18n.global.t('common_customer_level'), value: customerLevel},
        {name: i18n.global.t('common.interest.product'), value: product},
        {name: i18n.global.t('other.remark'), value: marketingRemark},
      ];
      // 联系人详情
      await store.commit("customer/setContactPage", {
        page: 1,
        pageSize: 999,
      });
      await store.dispatch("customer/getContactList");
      // 清除数据干扰
      contactInfo.value = [];
      // 联系人详情
      contactInfo.value = contactList.value.map((item) => [
        {name: i18n.global.t('common.name'), value: item.name},
        {name: i18n.global.t('common.edm'), value: item.email},
      ]);
    }
  }
};

const handleSelectionChange = (value) => {
  // 选择被重新触发了
  // 清除全部勾选
  selectedData.value = value
  emit("selections", value);
};
const handleCheck = (value) => {
  if (isSelectAll.value) {
    isSelectAll.value = false;
  }
  emit("selectionChange", value);
  console.log(value, 'value')
}

const handleClickSelectAll = (value) => {
  emit("select-all", value)
}

const toDetail = (row) => {
  const newUrl = router.resolve('/email/customer/detail/' + row.id + '?type=' + row.type)
  openNewWindow(newUrl.href);
}
const openNewWindow = (url) => {
  window.open(url, "_blank");
};

const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  // console.log(row, columnIndex)
  if (columnIndex === 1 || columnIndex === 2) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2) {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}


const isSelectAll = ref(false)
const selectedData = ref([])
const multipleTableRef = ref(null)
const isNotSelectAll = ref(false)
const selectAll = (val) => {
  console.log(val, 'isSelectAll')
  val ? checkAllItem() : unCheckAllItem()
  emit("getSelect", val)
}
const checkAllItem = () => {
  multipleTableRef.value.toggleAllSelection()
}

const setIsSelectAll = (res) => {
  multipleTableRef.value.clearSelection()
  isSelectAll.value = res
}
const toggleRowSelection = (item, val) => {
  multipleTableRef.value.toggleRowSelection(item, val)
}
const getSelectionRows = () => {
  multipleTableRef.value.getSelectionRows()
}

const setIsSelectSingle = (res) => {
  isSelectAll.value = res
}

const unCheckAllItem = () => {
  multipleTableRef.value.clearSelection()
}

const checkSelectable = (row, index) => {
  return !isSelectAll.value;
}

const cellClass = (row) => {
  if (row.columnIndex === 0) {
    return isSelectAll.value ? "disabledCheck" : "abledCheck"
  }
}


const handleTableRenderComplete = () => {
  const targetWidth = document.querySelectorAll('.el-table-column--selection')
  console.log('targetWidthoffsetWidth', targetWidth[0].offsetWidth)
}


const rowClass = ({row, column, rowIndex, columnIndex}) => {
  if (row.sp) {
    return 'fixed-row'
  }
}


defineExpose({
  checkAllItem,
  unCheckAllItem,
  selectAll,
  setIsSelectAll,
  setIsSelectSingle,
  toggleRowSelection,
  getSelectionRows
})
</script>

<style lang="less" scoped>

.pages {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.top_titles {
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.font_targert {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, .85);
}

.small_box {
  width: max-content;
  margin-top: 20px;
}

:deep(.el-drawer) {
  width: 530px;
}

:deep(.el-table th.gutter) {
  display: table-cell !important;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
  position: relative;
}

#sp_tables {
  position: relative;

  :deep(.el-table__header) {
    width: 100% !important;
  }

  :deep(.el-table__body) {
    width: 100% !important;
  }
}

:deep(.disabledCheck .el-checkbox__input) {
  display: none !important;
  padding: 0px !important;
}

:deep(.abledCheck .el-checkbox__input) {
  display: block !important;
  padding: 0px !important;
}

:deep(.disabledCheck) {
  padding: 0px !important;
}

:deep(.abledCheck) {
  padding: 0px !important;
}

.sp_item {
  transition: all .3s;
}

:deep(.el-table__inner-wrapper::before) {
  content: none !important;
  display: none !important;
}

.demo-image__lazy .el-image {
  display: block;
  min-height: 526px;
  margin-bottom: 10px;
}

.demo-image__lazy .el-image:last-child {
  margin-bottom: 0;
}

:deep(.el-table__body) {
  // 吸底
  .fixed-row {
    background: white;
    position: sticky;
    z-index: 9;
    bottom: 0;
    width: 100%;
    border-top: 1px solid red;

    td {
      background: white;
    }

  }
}

:deep(.el-table-column--selection .cell) {
  width: 60px !important;
  display: flex;
  justify-content: center;
}

#checkoutAll {
  position: absolute;
  margin-top: 16px;

  :deep(.el-checkbox__input) {
    width: 60px !important;
    display: flex;
    justify-content: center;
  }

  :deep(.el-checkbox__label) {
    padding-left: 0px !important;
  }
}

:deep(.el-table__empty-block) {
  height: 200px !important;
}
</style>
