<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t("common.pay") }}</div>
      <div class="success">
        <div style="height: 70px;width: 70px">
          <el-icon style="color: #72c240;height: 70px;width: 70px">
            <SuccessFilled style="height: 70px;width: 70px"/>
          </el-icon>
        </div>
        <span class="success_text">{{ $t("common.payment.success") }}</span>
        <el-button type="primary" @click="toShowOrderList">{{ $t('common.order.view') }}</el-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {i18n} from "@/lang";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute()
const toShowOrderList = () => {
  //跳转到订单列表页面
  if (route.query.out_trade_no) {
    router.push({path: '/personal/orderDetails', query: {payCode: route.query.out_trade_no}})
  } else {
    router.push({path: '/personal/orderDetails', query: {payCode: orderNo.value}})
  }
}
const orderNo = ref('')
onMounted(() => {
  const orderNos = router.currentRoute.value.query.orderNo
  orderNo.value = orderNos
})

</script>
<style lang="less" scoped>
@import ".././less/Tables.less";
@import "../../../less/page.less";

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
}

.success_text {
  font-weight: 700;
  font-size: 24px;
  margin-top: 30px;
  color: #595959;
  margin-bottom: 30px;
}
</style>