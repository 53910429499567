<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.cds73') }}</div>
      <div class="text_box">
        <div class="top_title">{{ $t('common.base.info') }}</div>
        <div><span class="left_title">{{ $t('common.cds74') }}</span><span class="inner">{{
            mailInvoice.invoiceCode
          }}</span></div>
        <div><span class="left_title">{{ $t('common.cds75') }}</span><span class="inner">{{
            mailInvoice.invoiceContent
          }}</span></div>
        <div><span class="left_title">{{ $t('common.cds76') }}</span><span class="inner">¥{{
            mailInvoice.invoiceMoney
          }}</span></div>
        <div><span class="left_title">{{ $t('common.cds77') }}</span><span class="inner">{{
            mailInvoice.invoiceContent
          }}</span></div>
        <div><span class="left_title">{{ $t('common.cds78') }}</span><span class="inner">{{
            mailInvoice.createTime
          }}</span></div>
        <div><span class="left_title">{{ $t('common.cds79') }}</span><span class="inner">
          <template v-if="mailInvoice.invoiceStatus===1">
            {{ $t('invoice.status1') }}
          </template>
          <template v-else-if="mailInvoice.invoiceStatus===2">
            {{ $t('invoice.status2') }}
          </template>
        </span></div>
        <div><span class="left_title">{{ $t('common.cds81') }}</span><span
            class="inner">{{ mailInvoice.invoiceEmail }}</span></div>
      </div>
      <div>
        <el-table :data="mailPayOrder" style="width: 100%" :row-style="{height: '70px'}" v-loading="loading"
        >
          <el-table-column prop="orderCode" :label="$t('common.order.number')" width="180"/>
          <el-table-column prop="productName" :label="$t('common.cds83')" width="180"/>
          <el-table-column prop="payTime" :label="$t('common.payment.time')"/>
          <el-table-column prop="payMoney" :label="$t('common.amount.realpay')">
            <template #default="{ row, column, $index }">
                      <span style="font-size: 22px">
                     {{ row.payMoney !== null && Object.keys(row).length > 0 ? row.payMoney.toFixed(2) : row.payMoney }}
                    </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {useRouter} from "vue-router";
import {getQueryByIdInvoice} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const router = useRouter();
const loading = ref(false)

const orderCode = ref()
const mailPayOrder = ref([])
const mailInvoice = ref({})
// 通过 id 查询数据
const getQueryByIdInvoiceApis = async () => {
  loading.value = true
  const res = await getQueryByIdInvoice(
      {id: orderCode.value}
  )
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  loading.value = false
  // const {mailInvoice, mailPayOrder} = res.result;
  // console.log(mailInvoice, mailPayOrder, 'fff')
  mailInvoice.value = res.result.mailInvoice;
  mailPayOrder.value = [res.result.mailPayOrder]
}
onMounted(async () => {
  const {id} = router.currentRoute.value.query
  orderCode.value = id
  await getQueryByIdInvoiceApis()
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";

.text_box {
  & > div {
    margin-bottom: 16px;
  }
}

.left_title {
  color: #999;
  margin-right: 20px;
  font-size: 14px;
}

.bottom_pay {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-top: 20px;
  font-size: 14px;

  .reds {
    color: #c8453f;
    font-size: 14px;
    font-weight: bold;
  }
}

.inner {
  font-size: 14px;
  font-weight: bold;
}


</style>