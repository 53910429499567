import _store from "store2";

import {ElMessage} from "element-plus";

const buystorage = {
    namespaced: true,
    state: {
        // 数据源
        vbxData: [],
        checkOutData: [],
        // 选中的data
        selectTargetData: [],
        //选中的目标
        checkIndex: 1,
        // 选择的数量
        num: 1,
        //     打折
        priceDz: 0.8,
    },
    getters: {
        //计算总价格
        sumPrice: (state) => {
            const res = state.checkOutData.filter(
                (item) => item.isSelect
            );
            if (res.length !== 0) {
                const {productPrice} = res[0];
                return (productPrice * state.num).toFixed(2);
            }
        },
        // 计算原价
        sumUnPrice: (state) => {
            const res = state.checkOutData.filter(
                (item) => item.isSelect
            );
            if (res.length !== 0) {
                const {productPrice} = res[0];
                return (productPrice * state.num).toFixed(2);
            }
        },
        // 优惠价格
        Discountedprices: (state) => {
            const res = state.checkOutData.filter(
                (item) => item.isSelect
            );
            if (res.length !== 0) {
                const {productPrice} = res[0];
                return (productPrice * state.num - productPrice * state.priceDz * state.num).toFixed(2);
            }
        },
        sumPriceVbx: (state) => {
            const res = state.vbxData.filter(
                (item) => item.isSelect
            );
            if (res.length !== 0) {
                const {productPrice} = res[0];
                return (productPrice * state.num).toFixed(2);
            }
        },
        // 计算原价
        sumUnPriceVbx: (state) => {
            const res = state.vbxData.filter(
                (item) => item.isSelect
            );
            if (res.length !== 0) {
                const {productPrice} = res[0];
                return (productPrice * state.num).toFixed(2);
            }
        },
        // 优惠价格
        DiscountedpricesVbx: (state) => {
            const res = state.vbxData.filter(
                (item) => item.isSelect
            );
            if (res.length !== 0) {
                const {productPrice} = res[0];
                return (productPrice * state.num - productPrice * state.priceDz * state.num).toFixed(2);
            }
        },
    },
    mutations: {
        // 设置检查索引
        setCheckIndex(state, index) {
            state.checkIndex = index;
        },
        // 更新数据源
        setCheckOutData(state, data) {
            state.checkOutData = data;
        },
        // 更新会员数据
        setvbxData(state, data) {
            state.vbxData = data;
        },
        // 更新数量
        setNum(state, payload) {
            state.num = payload;
        },
    },
    actions: {},
};

export default buystorage;
