<template>
  <div class="inner_box">
    <div class="left_box">
      <div><span class="line_title">{{ $t('common.cloud.file.space.total') }}:</span><span class="txt">{{
          spaceSizeFormat(spaceSize.effectiveLocation)
        }}</span></div>
      <div><span class="line_title">{{ $t('common.bh') }}:</span><span class="txt">{{
          spaceSizeFormat(spaceSize.containsLocation)
        }}({{ $t('common.mf') }})</span></div>
      <div><span class="line_title">{{ $t('common_cloud_file_space_available') }}:</span><span
          class="txt">{{ spaceSizeFormat(spaceSize.freeLocation) }}</span></div>
      <div><span class="line_title">{{ $t('common_file_cloud') }}:</span><span
          class="txt">{{ $t('common.ysy') }}{{ spaceSizeFormat(spaceSize.fileCloudLocation) }}</span></div>
    </div>
    <div class="right_box">
      <div id="main" :style="{ width: '500px', height: '400px' }"></div>
    </div>
  </div>
</template>

<script setup>
import {echarts} from "@/utils/echarts";
import {i18n} from "@/lang";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {ElMessage} from "element-plus";
import {spaceSizeFormat} from "@/utils/file";
import {useStore} from "vuex";

const store = useStore();
// 初始化 环形图
const myChartTargetDom = ref(null)

// 计算结果和百分比
const computedTargetInit = computed(() => store.state.cloud.echartsData)


const initEcharts = () => {

  console.log(computedTargetInit.value, 'computedTargetInit.value')
  const result = computedTargetInit.value.map(item => {
    return {...item, name: i18n.global.t(item.name), value: item.percentage}
  })
  console.log(result, 'ffff')
  const chartDom = document.getElementById('main');
  const myChart = echarts.init(chartDom);
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return (params.data.name + " " + params.data.value + '%')
      }
    },
    legend: {
      bottom: '2%',
      left: 'center',
      icon: "square",
      formatter: (valueName) => {
        // 获取每个对应的值
        const percentageNums = computedTargetInit.value.filter(item => i18n.global.t(item.name) === valueName)[0].percentage
        // formatter格式化函数动态呈现数据
        return valueName + ` ${percentageNums}%`
      },
    },
    color: ["#678ef2", "#7dd5a9", "#616f8f"],
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
          formatter: function (params) {
            return (
                '{a|' +
                params.name +
                '}' +
                '\n\n' +
                '{b|' +
                params.data.numStr +
                '}'
            );
          },
          rich: {
            a: {
              color: '#8c8c8c',
              fontSize: 20,
              backgroundColor: 'white'
            },
            b: {
              color: '#8c8c8c',
              fontSize: 20,
              backgroundColor: 'white'
            }
          }
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: result
      }
    ]
  }
  myChart.setOption(option);
  myChartTargetDom.value = myChart;
}


const tableData = ref([{no: '0'}, {no: '1'}, {no: '2'}]);
// 批量选中的值
const selection = ref([])
const handleSelectionChange = (val) => {
  selection.value = val;
}
// 批量续费
const checkoutAll = () => {
  if (selection.value.length === 0) {
    ElMessage.error(i18n.global.t('common.please.select'))
    return
  }

  console.log(selection.value)
}

// 分页
const current = ref(1);
const size = ref(10);
const totals = ref(10)
const handleCurrentChange = async (page) => {
  current.value = page;
};

/**
 * 文件存储的空间大小
 */
const spaceSize = computed(() => store.state.cloud.spaceSize)

onMounted(async () => {
  await store.dispatch("cloud/queryByUserIdApis")
  await initEcharts()
})

onUnmounted(() => {
  myChartTargetDom.value.dispose()
})


</script>

<style lang="less" scoped>

.inners {
  width: 100% !important;
  min-width: 100% !important;
  position: relative;
}

.right {
  height: max-content !important;
  padding-bottom: 70px !important;
}

.inner_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left_box {
    width: 280px;
    min-width: 280px;

    div {
      margin-bottom: 10px;
    }
  }

}

.line_title {
  display: inline-block;
  width: 126px;
  color: #999;
}

.txt {
  display: inline-block;
  color: #333;
}

.results {
  width: 100%;
  border-top: 1px solid #e8e8e8;
  height: 53px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;

  .buttons_pays {
    padding: 0 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #d93737;
    margin-left: auto;
    cursor: pointer;
  }
}
</style>