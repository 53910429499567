<template>
  <ElDialog
      v-model="isShow"
      :title="$t('system.area')"
      :footer="null"
      centered
      :width="'max-content'"
      destroyOnClose
      @cancel="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
  >
    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column">
      <div style="margin-top: 5px">
        <span style="margin-right: 10px">{{ $t('common.fp')}}</span>
        <el-input-number size="small" v-model="num" @change="handleChange"/>
        <span style="color: #0a84ff;font-weight: 600"> MB </span>
        <span>{{ $t('system.space.to')}} <span style="font-weight: 600">{{ currentItem?.account }}</span></span>
      </div>
    </div>

    <div class="dialog-footer">
      <ElButton @click="close">{{
          $t("common.cancel")
        }}
      </ElButton>
      <ElButton type="primary" @click="handleClick" :loading="loading">{{ $t('common.confirm')}}</ElButton>
    </div>

  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElInput, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits} from 'vue'
import {useStore} from "vuex";
import DialogTwo from "@/components/DiaPops/ChildUserDelete/DialogTwo.vue";
import {distributionLocation} from "@/api/usercenter";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const loading = ref(false)
const num = ref(1)
const {value, isHeadLine, currentItem} = defineProps({
  value: {type: Boolean, default: false},
  isHeadLine: {type: Boolean, default: false},
  currentItem: {type: Object, default: {}}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})
const close = () => {
  emit("close");
}

const update = () => {
  emit("update");
}


const visibleDialogTwo = ref(false)
const handleDelete = () => {
  visibleDialogTwo.value = true
}

const deleteTarget = () => {
  emit("deleteTarget")
}

const handleChange = (val) => {
  console.log(val, 'val')
}
// 分配空间
const handleClick = async () => {
  try {
    loading.value = true
    const payload = {
      id: currentItem?.id,
      size: num.value * 1024,
    }
    await distributionLocation(payload).then(res => {
      if (res.code === 200) {
        ElMessage.success(res.result)
        update()
        close()
      }
    })
  } catch (e) {
    loading.value = false
  }
}
</script>
<style scoped lang="less">
.fx-j-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title_cdir {
  color: rgba(0, 0, 0, .85);
  font-weight: 700;
  font-size: 14px;
}

.fx-j-r {
  display: flex;
  justify-content: right;
  margin-top: 40px;
}

.dialog-footer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.font {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.font_middle {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 32px;
}
</style>
