<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('child.user.title') }}</div>
      <div>
        <el-button type="primary" @click="handleClickAddCount">{{ $t('common.xzzzh') }}</el-button>
      </div>
      <div style="margin-top: 20px">
        <el-table
            :data="tableData"
            height="max-content"
            style="width: 100%"
            v-loading="loading"
            :cell-style="{ textAlign: 'left' }"
            :header-cell-style="{ 'text-align': 'left',background: '#f9f9f9', color: 'black',padding:'16px 0px'}">
          >
          <el-table-column prop="account" :label="$t('common.zhh')" width="auto">
            <template #default="{row,column,$index}">
              <div v-if="row.accountType===2">{{ row.account }}<span class="red"> ({{ $t('common.user.main') }})</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="guid" :label="'UID'" width="200"/>
          <el-table-column prop="userName" :label="$t('common.user.nick1')" width="auto"/>
          <el-table-column prop="position" :label="$t('common.position')" width="auto"/>
          <el-table-column prop="effectiveLocation" :label="$t('common.space')" width="100">
            <template #default="{row,column,$index}">
              <span>{{ spaceSizeFormat(row.effectiveLocation) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('repairOrder.setting')" fixed="right" width="240">
            <template #default="{ row, column, $index }">
              <template v-if="row.accountType!==2">
                <el-button link type="primary" @click="updateCompanyUserSp(row)">{{ $t('common.edit') }}</el-button>
                <span class="col">｜</span>
                <el-button link type="primary" @click="handleAllocateSpace(row)">{{ $t('common.fp') }}</el-button>
                <span class="col">｜</span>
                <el-button link type="primary" @click="changeCompanyUserApisActive(row)">
                  {{ $t('common.reset.password') }}
                </el-button>
                <span class="col">｜</span>
                <el-button
                    link
                    type="danger"
                    @click="deleteDiaOne(row)"
                >{{ $t('common.delete') }}
                </el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="pages">
          <el-pagination
              v-model:current-page="current"
              v-model:page-size="size"
              v-if="total"
              layout="total, prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

    </div>
  </div>
  <!--  这里可以进行优化-->
  <!--  添加-->
  <Dialog
      v-model="dialogVisible"
      :title="$t('common.xzzzh')"
      :dialogVisible="dialogVisible"
      :isHeadLine="false"
      :width="'550'"
      @update:dialogVisible="changeAddChild"
  >
    <template #content>
      <!--     表单-->
      <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item :label="$t('common.zzz')" prop="account">
          <el-input v-model="ruleForm.account">
            <template #append>@{{ comFlag.endItem }}</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('common.zzzn')" prop="userName">
          <el-input v-model="ruleForm.userName"/>
        </el-form-item>
        <el-form-item :label="$t('common.zzzp')" prop="password">
          <el-input type="password" show-password v-model="ruleForm.password"/>
        </el-form-item>
        <el-form-item :label="$t('common.position')" prop="position">
          <el-input v-model="ruleForm.position"/>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div class="settings">
        <el-button @click="dialogVisible = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="addCompanyUserApis" :loading="isLoadingAddCompanyUserApis">
          {{ $t('common.confirm') }}
        </el-button>
      </div>
    </template>
  </Dialog>
  <!--  修改-->
  <Dialog
      v-model="dialogVisibleUpdate"
      :title="$t('child.edit.child.user')"
      :dialogVisible="dialogVisibleUpdate"
      :isHeadLine="false"
      :width="'550'"
      @update:dialogVisible="editComChild"
  >
    <template #content>
      <!--     表单-->
      <el-form
          ref="ruleFormRefUpdate"
          :model="ruleFormUpdate"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item label="子账号" prop="account">
          <el-input v-model="ruleFormUpdate.account">
            <template #append>@{{ comFlag.endItem }}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="子账号昵称" prop="userName">
          <el-input v-model="ruleFormUpdate.userName"/>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-input v-model="ruleFormUpdate.position"/>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div class="settings">
        <el-button @click="dialogVisibleUpdate = false">取消</el-button>
        <el-button type="primary" @click="UpdateCompanyUserApis" :loading="isLoadingEditCompanyUserApis">确定
        </el-button>
      </div>
    </template>
  </Dialog>
  <!--修改密码-->
  <Dialog
      v-model="dialogVisibleChange"
      :title="'修改密码'"
      :dialogVisible="dialogVisibleChange"
      :isHeadLine="false"
      :width="'34%'"
      @update:dialogVisible="changePassword"
  >
    <template #content>
      <!--     表单-->
      <el-form
          ref="ruleFormRefChange"
          :model="ruleFormChange"
          :rules="rulesChange"
          label-width="120px"
          class="demo-ruleForm"
      >
        <el-form-item label="请输入新密码" prop="newPassword">
          <el-input type="password" show-password v-model="ruleFormChange.newPassword">
          </el-input>
        </el-form-item>
        <el-form-item label="再次确认新密码" prop="rePassword" style="margin-top: 30px">
          <el-input type="password" show-password v-model="ruleFormChange.rePassword"/>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div class="settings">
        <el-button @click="dialogVisibleChange = false">取消</el-button>
        <el-button type="primary" @click="changeCompanyUserApis" :loading="changePasswordLoading">确定</el-button>
      </div>
    </template>
  </Dialog>

  <!--  删除弹窗1-->
  <!--  <DialogOne v-if="visibleDialogOne" v-model="visibleDialogOne" @close="closeOne" :is-head-line="true"-->
  <!--             @deleteTarget="handleDelete"></DialogOne>-->
  <DialogTwo v-if="visibleDialogOne" v-model="visibleDialogOne" @close="closeOne" :is-head-line="true"
             @delete="handleDelete" :loading="deleteLoading"></DialogTwo>
  <AllocateSpace v-if="visibleDialogSpace" v-model="visibleDialogSpace" @update="handleUpdate" @close="closeSpace"
                 :is-head-line="true"
                 :currentItem="currentItem"></AllocateSpace>
</template>

<script setup>

import {computed, onMounted, reactive, ref} from "vue";
import Dialog from "@/components/Dialog.vue";
import {ElMessage, ElTable} from "element-plus";
import {
  addCompanyUser,
  deleteCompanyUser,
  getQuerySubAccountList,
  updateCompanyUser,
  updateCompanyUserPassword
} from "@/api/usercenter";
import {useStore} from "vuex";
import {childAccountNameReg, passwordReg} from "@/utils/statusParams";
import {i18n} from "@/lang";
import {throttle} from "@/utils/mylodash"
import DialogTwo from "@/components/DiaPops/ChildUserDelete/DialogTwo.vue";
import AllocateSpace from "@/components/DiaPops/ AllocateSpace.vue";
import {spaceSizeFormat} from "@/utils/file";


const store = useStore()
const dialogVisible = ref(false);
const tableData = ref([])
const ruleForm = ref({})
const loading = ref(false);

const dialogVisibleUpdate = ref(false)
const ruleFormUpdate = ref({})
const ruleFormRefUpdate = ref(null)
const companyUserFlags = ref('')


const ruleFormChange = ref({})
const dialogVisibleChange = ref(false)

const newValValidate = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else if (passwordReg.test(value)) {
    callback()
  } else {
    callback(new Error(i18n.global.t("common.password.text")))
  }
}

//更新页面
const handleUpdate = () => {
  getQuerySubAccountListApis();
}

const childAccount = (rule, value, callback) => {
  if (childAccountNameReg.test(value)) {
    callback();
  } else {
    callback(new Error("子账户只能输入字母、数字、下划线，且以字母开头，最长不超过10位。"))
  }
}

const validatePass2 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== ruleFormChange.value.newPassword) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}


const rulesChange = reactive({
  newPassword: [{
    required: true,
    message: '请输入您的新密码',
    trigger: ['blur', 'change']
  }, {validator: newValValidate, trigger: ['blur', 'change']}],
  rePassword: [{required: true, validator: validatePass2, trigger: ['blur', 'change']}]
})
// 计算公司后缀
const comFlag = computed(() => {
  const preItem = companyUserFlags.value.split('@')[0];
  const endItem = companyUserFlags.value.split('@')[1]
  return {preItem, endItem}
});


const rules = reactive({
  account: [{required: true, message: '请输入账号', trigger: 'blur'}, {
    validator: childAccount,
    trigger: ['blur', 'change']
  }],
  userName: [{required: true, message: '请输入用户名', trigger: ['blur', 'change']}],
  password: [{required: true, message: '请输入密码', trigger: ['blur', 'change']}, {
    validator: newValValidate,
    trigger: ['blur', 'change']
  }],
  position: [{required: true, message: '请输入职位', trigger: ['blur', 'change']}]
})

const ruleFormRef = ref(null)
const ruleFormRefChange = ref(null)

const current = ref(1)
const size = ref(10)
const total = ref(0)
const handleCurrentChange = (page) => {
  current.value = page;
  getQuerySubAccountListApis()
};

// 修改密码
const activeItems = ref({})
const changeCompanyUserApisActive = (row) => {
  dialogVisibleChange.value = true
  activeItems.value = row;
}
// 确认修改
const changePasswordLoading = ref(false)
const changeCompanyUserApis = throttle(async () => {
  ruleFormRefChange.value.validate(async (valid, fields) => {
    if (valid) {
      const targetObj = {
        id: activeItems.value.id,
        newPassword: ruleFormChange.value.newPassword,
        submitPassword: ruleFormChange.value.rePassword
      }
      changePasswordLoading.value = true
      const res = await updateCompanyUserPassword(targetObj)
      changePasswordLoading.value = false
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return
      }
      ElMessage.success(res.message)
      dialogVisibleChange.value = false
      ruleFormRefChange.value.resetFields()
      await getQuerySubAccountListApis()
    } else {
      ElMessage.error('您输入信息不符合规范');
    }
  })
}, 1500)

const changePassword = (val) => {
  dialogVisibleChange.value = val;
  ruleFormRefChange.value.resetFields();
}


const userInfo = computed(() => store.state.user.userInfo)
const getQuerySubAccountListApis = async () => {
  loading.value = true
  const res = await getQuerySubAccountList({
    companyId: userInfo.value.companyId,
    pageNo: current.value,
    pageSize: size.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  const {records} = res.result
  tableData.value = records
  current.value = res.result.current;
  size.value = res.result.size;
  total.value = res.result.total;
  loading.value = false
}


const handleClickAddCount = () => {
  dialogVisible.value = true;
  ruleForm.value = {}
}

const isLoadingAddCompanyUserApis = ref(false)
const addCompanyUserApis = throttle(async () => {
  ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const {account, position, userName, password} = ruleForm.value
      const targetObj = {
        account: `${account}@${comFlag.value.endItem}`,
        position,
        userName,
        password: password,
        companyId: userInfo.value.companyId,
      }
      console.log(targetObj, 'add')
      isLoadingAddCompanyUserApis.value = true
      const res = await addCompanyUser(targetObj)
      isLoadingAddCompanyUserApis.value = false
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      ElMessage.success(res.message)
      dialogVisible.value = false;
      await getQuerySubAccountListApis()
      ruleFormRef.value.resetFields();
    } else {
      ElMessage.error('您输入信息不符合规范');
      // ruleFormRef.value.resetFields();
    }
  })
}, 1500);

const changeAddChild = (val) => {
  dialogVisible.value = val
  ruleFormRef.value.resetFields();
}

const updateCompanyUserSp = async (row) => {
  dialogVisibleUpdate.value = true
  const {account, id, userName, position} = row;
  const targetObj = {
    account: account.split('@')[0],
    id,
    userName,
    position
  }
  ruleFormUpdate.value = targetObj;
};


const isLoadingEditCompanyUserApis = ref(false)
const UpdateCompanyUserApis = throttle(async () => {
  ruleFormRefUpdate.value.validate(async (valid, fields) => {
    if (valid) {
      const {account, id, userName, position} = ruleFormUpdate.value;
      const targetObj = {
        account: `${account}@${comFlag.value.endItem}`,
        id,
        userName,
        position,
        companyId: userInfo.value.companyId
      }
      isLoadingEditCompanyUserApis.value = true
      const res = await updateCompanyUser(targetObj)
      isLoadingEditCompanyUserApis.value = false
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      ElMessage.success(res.message)
      dialogVisibleUpdate.value = false;
      await getQuerySubAccountListApis()
    } else {
      ElMessage.error('您输入信息不符合规范');
    }
  })
}, 1500)
const editComChild = (val) => {
  dialogVisibleUpdate.value = val;
  ruleFormRefUpdate.value.resetFields()
}


/**
 * 删除子账号
 */
const visibleDialogOne = ref(false);
const selectDelTargetId = ref(null)
const deleteDiaOne = (row) => {
  selectDelTargetId.value = row.id
  visibleDialogOne.value = true
}
const closeOne = () => {
  visibleDialogOne.value = false;
}


/**
 * 删除自账号 开始删除
 * @param row
 * @returns {Promise<void>}
 */
const deleteLoading = ref(false)
const handleDelete = async () => {
  deleteLoading.value = true
  try {
    const res = await deleteCompanyUser({id: selectDelTargetId.value})
    closeOne()
    ElMessage.success(res.message);
    await getQuerySubAccountListApis()
    deleteLoading.value = false
  } catch (e) {
    deleteLoading.value = false
  }
};


/**
 * 空间分配
 */
const visibleDialogSpace = ref(false)
const closeSpace = () => {
  visibleDialogSpace.value = false
}
const currentItem = ref(null)
const handleAllocateSpace = (row) => {
  console.log(row)
  visibleDialogSpace.value = true;
  currentItem.value = row;
}
onMounted(async () => {
  let res = JSON.parse(window.localStorage.getItem('userInfo')).account
  companyUserFlags.value = res
  await getQuerySubAccountListApis()
})
</script>


<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  width: 1200px !important;
  min-width: 1200px !important;

}

.settings {
  display: flex;
  justify-content: flex-end;
}

.col {
  color: #e8e8e8;
}

.red {
  color: #9e5651;
}

:deep(.el-table__header), :deep(.el-table__body) {
  width: 100% !important;
}

.inners .right {
  height: max-content !important;
}


</style>
