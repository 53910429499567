<template>
  <el-dialog
      :visible="dialogVisible"
      :title="title"
      :width="width"
      :style="{ minWidth: width + 'px' }"
      :before-close="close"
      :class="[isHeadLine ? 'headline' : 'noheadline']"
      @cancel="close"
  >
    <slot name="content"></slot>
    <slot name="footer"></slot>
  </el-dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits} from "vue";

const {dialogVisible: propDialogVisible, title, isHeadLine} = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  isHeadLine: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "30%",
  },
});
const emit = defineEmits();

const close = () => {
  emit("update:dialogVisible")
}
</script>

<style lang="less" scoped>

.el-dialog__title {
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

#sendRequire {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

:deep(.el-dialog__body) {
  padding: 60px 20px;
}
</style>
