<template>
  <el-popover :visible="visible" placement="top" :width="width" trigger="click">
    <div class="confirm-delete-popover">
      <el-icon>
        <WarningFilled/>
      </el-icon>
      <p>{{ message }}</p>
    </div>
    <div style="text-align: center; margin-top: 8px">
      <el-button size="small" text @click="cancel">{{ $t('common.sss16')}}</el-button>
      <el-button size="small" type="primary" @click="confirm">{{ $t('common.confirm')}}</el-button>
    </div>
    <template #reference>
      <slot name="settings"></slot>
    </template>
  </el-popover>
</template>

<script setup>
import {ref, defineProps, defineEmits, nextTick} from "vue";
import {WarningFilled} from "@element-plus/icons-vue";

const {
  visible,
  width: propWidth,
  message: propMessage,
} = defineProps({
  visible: Boolean,
  width: String,
  message: String,
});

const emit = defineEmits();

const cancel = () => {
  emit("update:visible", false);
};

const confirm = () => {
  emit("confirm:delete");
  cancel();
};

const requestDelete = () => {
};
</script>

<style lang="less" scoped>
.confirm-delete-popover {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .el-icon {
    color: #faad14;
    margin-right: 2px;
  }
}
</style>
