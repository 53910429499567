<template>
  <div class="list_wrap">
    <div v-for="(conversation) in list" :key="conversation.conversationID" class="item" @click="handleToChat(conversation)">
      <img :src="conversation.getAvatar()" alt="" />
      <div class="info">
        <div class="name">{{ conversation.getShowName() }}</div>
        <div class="message">
          <span
                v-if="
                  conversation.type === 'GROUP' &&
                  conversation.groupAtInfoList &&
                  conversation.groupAtInfoList.length > 0
                "
                class="middle-box-at"
              >{{}}</span>
          {{ conversation?.lastMessage?.messageForShow }}
        </div>
      </div>
      <div class="time">
        {{ formatTimeAgo(conversation.lastMessage.lastTime) }}
        <el-badge :value="conversation.unreadCount" :hidden="conversation.unreadCount === 0" :badge-style="{ top: `5px !important` }"></el-badge>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { ElBadge } from 'element-plus';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

const store = useStore();
const list = computed(() => store.state.chat.conversationList);

function formatTimeAgo(timestamp) {
  const now = dayjs();
  const past = dayjs(timestamp * 1000); // 假设传入的时间戳是以秒为单位的UNIX时间戳
  const diff = now.diff(past, 'day'); // 计算时间差，单位为天

  if (diff === 0) {
    // 今天
    const minutesDiff = now.diff(past, 'minute');
    if (minutesDiff < 60) {
      return `${Math.floor(minutesDiff)}分钟前`;
    } else if (minutesDiff < 1440) {
      return `${Math.floor(minutesDiff / 60)}小时前`;
    }
    return `${Math.floor(minutesDiff / 1440)}小时前`; // 今天但超过24小时，按小时计算
  } else if (diff === 1) {
    // 昨天
    return '昨天';
  } else {
    // 超过一天前
    return `${Math.floor(diff)}天前`;
  }
}

function handleToChat(item) {
  store.dispatch("chat/updateCurrentConversation", {
    showModal: true,
    conversation: item
  })
}


onMounted(() => {
  console.log(list, '列表', store);
});
</script>

<style lang="less" scoped>
.item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #AAAAAA;
  >img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  .info {
    flex-grow: 1;
    margin-left: 10px;
    width: 50%;
    .name {
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 16px;
    }
    .message {
      font-weight: 400;
      font-size: 14px;
      color: #AAAAAA;
      margin-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .time {
    display: flex;
    flex-direction: column;
    align-items: end;
    font-weight: 400;
    font-size: 12px;
    color: #AAAAAA;
  }
}
</style>
