<template>
  <el-card :title="title" :style="{ height: height + 'px' }"
           style="margin-top: 10px !important;min-width: 358px !important">
    <div class="tops">
      <div style="font-size: 20px;color:'#333';font-weight: 700;">
        <span>{{ title }}</span>
      </div>
      <el-button
          @click="addFriend"
          slot="extra"
          type="primary"
          extra="addfriend"
          link
      >{{ $t("common.more") }}
      </el-button
      >
    </div>

    <div class="mainBox" slot="content">
      <div class="item fx-d-c fx-a-c" v-for="item in list" :key="item.icon">
        <div
            class="box fx-aj-c"
            :style="{ backgroundImage: `url(${item.icon})` }"
        ></div>
        <span class="content" style="margin: 6px 0 4px 0"
        >{{ item.name }}
          </span>
        <span
            class="number bold"
            :style="{ color: item.countRed ? 'red' : '#000' }"
        >{{ item.count }}</span
        >
      </div>
    </div>
  </el-card>
</template>

<script setup>
import {ElCard} from "element-plus";
import Card from "@/components/Card.vue";
import {ref, defineEmits} from "vue";

const props = defineProps({
  title: {
    type: String,
  },
  height: {
    type: String,
    default: '400'
  },
  list: {
    type: Array,
    default: () => [],
  },
  extra: String,
})

const test = ref(false)
const emit = defineEmits(["addFriend"])
const addFriend = () => {
  emit("addFriend");
};

</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  color: #333;
}

.mainBox {
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  text-align: center;
  margin-top: 12px;
}

.item {
  width: 118px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.content,
.number {
  line-height: 1;

}

.tops {
  display: flex;
  justify-content: space-between;
}

:deep(.el-card__body) {
  min-width: 309px !important;
}

.bold {
  font-weight: 700;
}
</style>
    