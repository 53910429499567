<template>
  <div style="position: relative">
    <transition name="f_slide">
      <div v-if="!isShowFriend" class="contact">
        <div class="search">
          <el-input
            v-model="searchKey"
            @input="searchFriendByKey"
            style="width: 240px"
            placeholder="搜索"
            :suffix-icon="Search"
          />
        </div>
        <div class="tips">共{{ friendCount }}个好友</div>
        <div class="new_item">
          <img src="../../assets/new.png" alt="" />
          <div class="t" @click="handleFriendVisible(true)">新的朋友</div>
          <div v-if="friendApplication.unreadCount !== 0" class="num">
            {{ friendApplication.unreadCount }}
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="(item, i) in letterList" :key="i">
            <div class="letter" :id="item.tag">{{ item.tag }}</div>
            <div class="items">
              <div
                class="ele"
                v-for="(friend, profileIndex) in item.list"
                :key="profileIndex"
                @click="toFriendChat(friend)"
              >
                <img :src="friend.getAvatar()" alt="" class="avatar" />
                <div class="name">{{ friend.getShowName() }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide">
      <div v-if="isShowFriend" class="new_friend_contact">
        <div class="left_text" @click="handleFriendVisible(false)">
          <el-icon>
            <ArrowLeftBold />
          </el-icon>
          新的朋友
        </div>
        <div class="list">
          <div class="items">
            <div
              class="ele"
              v-for="friend in friendApplication.list"
              :key="friend.userID"
            >
              <img :src="friend.avatar" alt="" class="avatar" />
              <div class="name">{{ friend.nick }}</div>
              <el-button
                class="pri_btn"
                type="primary"
                size="small"
                @click="handleFriendApplication(friend)"
                >通过验证</el-button
              >
            </div>
            <el-empty :image-size="100" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import { pinyin } from "pinyin-pro";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import { ElEmpty } from "element-plus";
import TencentCloudChat from "@tencentcloud/chat";

import { chat } from "../../store/modules/chat";

const store = useStore();

const friendCount = ref(0);

onMounted(() => {
  store.dispatch("chat/updateFriendList");

  // 监听处理事件
  chat.on(
    TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED,
    updateFriendList
  );
});

onUnmounted(() => {
  chat.off(
    TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED,
    updateFriendList
  );
});

const chatModal = ref(false);
const toFriendChat = async (friend) => {
  const conversationID =
    TencentCloudChat.TYPES.CONV_C2C + friend.profile.userID;
  const result = await chat.getConversationProfile(conversationID);
  console.log(result, "好友会话资料");
  store.dispatch("chat/updateCurrentConversation", {
    showModal: true,
    conversation: { ...friend, ...result.data.conversation },
  })
};

const updateFriendList = () => {
  console.log("好友申请列表改变...");
  // 更新好友列表
  store.dispatch("chat/updateFriendList");
  // 更新申请列表
  store.dispatch("chat/updateFriendApplicationList");
};

// 是否显示好友申请列表状态
const isShowFriend = ref(false);

/**
 * 是否显示好友申请列表
 * @param {boolean} visible 显示状态
 */
const handleFriendVisible = (visible) => {
  isShowFriend.value = visible;
};

/**
 * 处理好友请求
 * @param friend 申请人数据对象
 */
const handleFriendApplication = (friend) => {
  store.dispatch("chat/processFriendApplication", friend);
};

const friendList = computed(() => store.state.chat.friendList);
// 好友申请列表
const friendApplication = computed(() => store.state.chat.friendApplication);
const letterList = ref([]);
/**
 * 处理friendList，以拼音首字母区分
 */
function getFriendList(searchFriendList) {
  const tempMap = {};
  friendCount.value = (searchFriendList || friendList.value).length;
  (searchFriendList || friendList.value).forEach((friend) => {
    const firstChar = friend.profile.nick.charAt(0);
    const code = isLetterOrChinese(firstChar);
    // tempMap.count += 1;
    let tag;
    if (code === "1") {
      tag = firstChar.toUpperCase();
    } else if (code === "2") {
      tag = pinyin(firstChar, {
        toneType: "none",
        type: "array",
      })[0][0].toUpperCase();
    } else {
      tag = "#";
    }

    // 初始化分组
    if (!tempMap[tag]) {
      tempMap[tag] = {
        tag,
        list: [],
      };
    }
    tempMap[tag].list.push(friend);
  });
  // 转换为数组并排序
  const sortedList = Object.values(tempMap).sort((a, b) => {
    // 如果包含 "#"，将其移动到末尾
    const aTag = a.tag.startsWith("#") ? 1 : 0;
    const bTag = b.tag.startsWith("#") ? 1 : 0;

    if (aTag !== bTag) {
      return aTag - bTag; // "#" 应在末尾
    }

    // 常规排序规则
    return a.tag.localeCompare(b.tag, "en", { sensitivity: "base" });
  });

  // 更新响应式数据
  letterList.value = sortedList;
}

function isLetterOrChinese(char) {
  const letterRegex = /^[a-zA-Z]$/; // 匹配单个字母
  const chineseRegex = /^[\u4e00-\u9fa5]$/; // 匹配单个汉字
  if (letterRegex.test(char)) {
    // 是字母
    return "1";
  } else if (chineseRegex.test(char)) {
    // 是汉字
    return "2";
  } else {
    return "0";
  }
}

/**
 * 搜索
 */
const searchKey = ref("");
// 根据关键字搜索
const searchFriendByKey = () => {
  const searchFriendList = friendList.value.filter((friend) => {
    return (
      friend.profile.nick.includes(searchKey.value) ||
      friend.remark.includes(searchKey.value) ||
      friend.profile.userID.includes(searchKey.value)
    );
  });
  getFriendList(searchFriendList);
};

watch(
  () => friendList.value,
  (newVal) => {
    if (newVal) {
      getFriendList();
    }
  }
);
</script>

<style lang="less" scoped>
.contact {
  width: 100%;

  .search {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tips {
    font-weight: 400;
    font-size: 12px;
    color: #8a8a8f;
    line-height: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  }

  .new_item {
    display: flex;
    align-items: center;
    padding: 12px 10px 12px 16px;
    border-top: 1px solid rgba(0, 21, 41, 0.12);
    border-bottom: 1px solid rgba(0, 21, 41, 0.12);

    > img {
      width: 32px;
      height: 32px;
    }

    .t {
      font-weight: 400;
      font-size: 14px;
      color: #0d0e15;
      margin-left: 20px;
      flex-grow: 1;
    }

    .num {
      width: 16px;
      height: 16px;
      background: #db4437;
      border-radius: 50%;
      font-weight: 600;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      line-height: 16px;
    }
  }

  .list {
    .item {
      .letter {
        padding: 8px 20px;
        font-size: 14px;
        color: #768196;
        line-height: 20px;
      }

      .items {
        .ele {
          padding: 8px 20px;
          display: flex;
          align-items: center;

          .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          .name {
            font-weight: 400;
            font-size: 14px;
            color: #0d0e15;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.new_friend_contact {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #fff;

  .left_text {
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    border-bottom: 1px solid #f8f8f8;
  }

  .list {
    .items {
      .ele {
        padding: 8px 20px;
        display: flex;
        align-items: center;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .name {
          font-weight: 400;
          font-size: 14px;
          color: #0d0e15;
          margin-left: 10px;
        }

        .pri_btn {
          margin-left: auto;
        }
      }
    }
  }
}

.f_slide-content {
  transition: all 0.3s ease;
}

/* 定义进入和离开的状态 */
.f_slide-enter-from,
.f_slide-leave-to {
  transform: translateX(-30%);
  opacity: 0;
}

.f_slide-enter-to,
.f_slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}

/* 定义过渡状态 */
.f_slide-enter-active,
.f_slide-leave-active {
  transition: all 0.3s ease;
}

.slide-content {
  transition: all 0.3s ease;
}

/* 定义进入和离开的状态 */
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}

/* 定义过渡状态 */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
</style>
