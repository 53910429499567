<template>
  <transition name="fade">
    <Card :useBorder="false" v-if="flag">
      <div class="outs_box">
        <div class="tops">
          <div style="display: flex;justify-content: center;align-items: center">
            <span>{{ $t('common.data.home.title') }}</span>
            <span
                style="font-size: 14px;margin-left: 20px;font-weight: 400;padding-top: 5px;display: flex;align-items: center;justify-content: center">
              <span style="padding-top: 5px">
                <el-icon style="color: white;padding-top: 3px">
                <Management/>
              </el-icon></span>
              <span @click="toHref"
                    style="cursor: pointer;text-decoration: underline">{{ $t('common.data.home.start.flow') }}</span>
            </span>
          </div>
          <div>
            <el-icon @click="closeAd" style="font-size: 20px"
            >
              <CircleClose
              />
            </el-icon>
          </div>
        </div>
        <!--        <div class="bottoms">-->
        <!--&lt;!&ndash;          <span>{{ $t('common.data.home.desc') }}</span>&ndash;&gt;-->
        <!--          -->
        <!--        </div>-->
      </div>
    </Card>
  </transition>
</template>

<script setup>
import {ref} from "vue";
import Card from "@/components/Card.vue";
import {useRouter} from "vue-router";
import * as target from "../../../../public/settings.json";

const router = useRouter()

function toHref() {
  // const newUrl = router.resolve('/guide')
  // openNewWindow(newUrl.href)
  window.open(target.studyUrl, "_blank")
}

function openNewWindow(url) {
  window.open(url, "_blank");
}

const flag = ref(true);
const closeAd = () => {
  flag.value = false;
};
</script>

<style lang="less" scoped>
@import "./less/index";
</style>
