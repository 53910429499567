<template>
  <Cards>
    <div>
      <TopSearch :adminShow="false" :search-show="true" @searchKey="getSearchKey"/>
    </div>
  </Cards>

<!--  <div style="margin: 16px 0px 0px 16px">-->
<!--    -->
<!--  </div>-->

  <Cards>
    <div>
      <div class="top_tips">
        <h2 style="font-weight: 500;font-size: 21px !important;">{{ $t('common.customer.followup.list') }}</h2>

        <div>
          <el-button type="primary" @click="delayDayAll">{{
              $t("common.bulk.delay")
            }}
          </el-button>
          <el-button @click="cancelAll">{{
              $t("common.bulk.cancel")
            }}
          </el-button>
        </div>
      </div>
      <ElTable
          id="sp_tables"
          ref="multipleTableRef"
          :data="tableData"
          style="width: 100%; margin-top: 10px;"
          @selection-change="handleSelectionChange"
          :v-loading="isLoading"
          :cell-style="rowClassName"
          :header-cell-style="headerClassName"
      >
        >
        <el-table-column type="selection" width="60" fixed="left"/>
        <el-table-column
            v-for="item in table_columns_follow"
            :key="item.key"
            :label="item.key"
            :prop="item.value"
            :width="item.value==='customerCode'?200:item.value==='customerOrigin'?240:item.value==='customerName'?auto:(flexWidth(item.value, tableData, item.key))"
            :min-width="item.value==='customerName'?200:auto"
            :show-overflow-tooltip="item.value==='customerName'||item.value==='customerCode'||item.value==='customerOrigin'?true:false"
        >
          <template v-if="item.value==='zoneAddress'" #default="{row}">
            <div style="cursor: pointer">
              <el-popover
                  effect="dark"
                  placement="right"
                  width="max-content"
                  trigger="hover"
                  :show-after="500"
                  :content="row.zoneAddress"
                  v-if="row.zoneAddress"
              >
                <template #reference>
                  <el-image :src="flag[row.zoneCode]"
                            style="width:26px;margin-right:2px;vertical-align:middle;" lazy/>
                </template>
              </el-popover>
            </div>
          </template>
          <template v-else-if="item.key === $t('common_customer_name')" #default="{row}" :width="auto">
            <!--          <el-button id="customerName_id" link type="primary"-->
            <!--                     @click.native.stop="toDetail(row)">-->
            <!--            &lt;!&ndash;            {{ truncateByBytes(row.customerName, 45) }}&ndash;&gt;-->

            <!--            -->
            <!--          </el-button>-->
            <span style="color:#5a9cf8;cursor:pointer;" @click.native.stop="toDetail(row)">
            {{ row.customerName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.cz')" fixed="right" :width="lang==='en'?210:170">
          <template #default="{ row, column, $index }">
            <el-button type="primary" @click="follow(row, column, $index)" link
            >{{ $t('common.followup') }}
            </el-button
            >
            <el-button type="primary" @click="delay(row, column, $index)" link
            >{{ $t('common.delay') }}
            </el-button
            >
            <ConfirmDeletePopover
                :visible="row.popoverVisibleCancel"
                :width="164"
                :message="$t('sys.message.ask.followup.cancel')"
                @update:visible="(value) => (row.popoverVisibleCancel = value)"
                @confirm:delete="handleCancel(row, column, $index)"
            >
              <template #settings>
                <el-button
                    type="primary"
                    @click="cancel(row, column, $index)"
                    link
                >{{ $t('common.cancel') }}
                </el-button
                >
              </template>
            </ConfirmDeletePopover
            >
          </template>
        </el-table-column>
        <template #empty>
          <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
        </template>
      </ElTable>
    </div>
    <!-- 分页 -->
    <div class="pages">
      <el-pagination
          v-model:current-page="currentFlow"
          v-model:page-size="sizeFLow"
          background
          layout="total, prev, pager, next, jumper"
          v-if="totalsFlow"
          :total="totalsFlow"
          :pager-count="4"
          @current-change="handleCurrentChange"
      />
    </div>
  </Cards>


  <Dialog
      v-model="dialogVisible"
      :title="$t('common.yst')"
      :dialogVisible="dialogVisible"
      :isHeadLine="true"
      :width="'400'"
      @update:dialogVisible="(val) => (dialogVisible = val)"
  >
    <template #content>
      <el-date-picker
          style="width: 200px;margin: auto;display: flex;margin-top: 10px"
          v-model="delayDay"
          type="date"
          :placeholder="$t('common.slt')"
          :disabled-date="disabledDate"
      />
    </template>
    <template #footer>
      <div class="buttons_bottom">
        <el-button @click="dialogVisible = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="addDelayDay">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>

  <Dialog
      v-model="cancelAllVisible"
      :dialogVisible="cancelAllVisible"
      :isHeadLine="false"
      :width="400"
      @update:dialogVisible="(val) => (cancelAllVisible = val)"
  >
    <template #content>
      <div class="content_box">
        <div>
          <el-icon style="font-size: 20px; color: #efb041; margin-right: 10px"
          >
            <QuestionFilled
            />
          </el-icon>
        </div>
        <div class="inner_text">{{ $t('common.sl1') }}</div>
      </div>
    </template>
    <template #footer>
      <div class="buttons_bottom">
        <el-button @click="cancelAllVisible = false">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click="handleCancelAll">{{ $t('common.confirm') }}</el-button>
      </div>
    </template>
  </Dialog>
</template>
<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {dayjs, ElMessage, ElTable} from "element-plus";

import Cards from "./Cards.vue";
import TopSearch from "./TopComponents/TopSearch.vue";
import Dialog from "@/components/Dialog.vue";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";

import {getMailCustomerFollow, batchDelayCancel} from "@/api/customer.js";
import {useStore} from "vuex";
import {table_columns_follow} from "@/views/Customer/map";
import {emptyUrl} from "@/utils/statusParams";
import flag from "@/assets/flag";
import {flexWidth} from "@/utils/tableWidth";
import {i18n} from "@/lang"

const store = useStore()
const router = useRouter();
const teamId = computed(() => store.state.email.teamID);


// 语言
const lang = computed(() => store.state.system.langLocal)

// 延迟时间
const delayDay = ref("");
// const shortcuts = [
//   {
//     text: "今天",
//     value: new Date(),
//   },
// ];

const disabledDate = (time) => {
  return time.getTime() < Date.now();
};

// 搜索
const searchValue = ref({});
const getSearchKey = async (value) => {
  const newObj = JSON.parse(JSON.stringify(value));
  searchValue.value = newObj;
  console.log(searchValue.value, "ffff");
  // 列表数据
  await getListFollow();
};

// table 的ref 对象
const multipleTableRef = ref();
// 表格是否加载
const isLoading = ref(false)
// 批量选中的客户
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  const value = JSON.parse(JSON.stringify(val));
  multipleSelection.value = value;
};

// 分页
const currentFlow = ref(1);
const sizeFLow = ref(10);
const totalsFlow = ref(0);

function handleCurrentChange(page) {
  currentFlow.value = page;
  getListFollow();
}

// 客户跟进表数据
const tableData = ref([]);
const getListFollow = async () => {
  isLoading.value = true;
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  let res = await getMailCustomerFollow({
    ...searchValue.value,
    pageSize: sizeFLow.value,
    pageNo: currentFlow.value,
    teamId: teamId.value,
  });
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  isLoading.value = false;
  const {records, total, current, size} = res.result;
  tableData.value = records;
  sizeFLow.value = size;
  totalsFlow.value = total;
  currentFlow.value = current;
};

// 跟进
const isFllow = ref(false)
const follow = async (row) => {
  isFllow.value = true
  localStorage.setItem('follow', JSON.stringify(row))
  const query = {type: "follow"};
  if (row.customerId) {
    query.customerId = row.customerId;
  }

  const url = row.customerId ? `/email/write_mail?type=follow&customerId=${row.customerId}` : `/email/write_mail?type=follow`
  const newUrl = router.resolve(url)
  openNewWindow(newUrl.href)
  // 重新获取列表数据
  await getListFollow();

  //   取消目标 邮件跟进
  let timer = setTimeout(() => {
    handleCancel(row)
    clearTimeout(timer)
  }, 300)
};
const openNewWindow = (url) => {
  window.open(url, "_blank");
};

// 延迟对话框
const dialogVisible = ref(false);

// 延迟
const activeItem = ref(null);
const delay = async (row, column, $index) => {
  dialogVisible.value = true;
  activeItem.value = row;
};

// 批量延迟
const delayDayAll = () => {
  if (multipleSelection.value.length === 0) {
    ElMessage.warning(i18n.global.t('30001'));
    return;
  }
  dialogVisible.value = true;
};

// 确认添加延迟时间
const addDelayDay = async () => {
  if (delayDay.value === "") {
    ElMessage.warning(i18n.global.t('please.enter.time'));
    return;
  }

  // 确认添加
  let targetObj = {
    mailCustomerFollowListDTOList: [],
    teamId: teamId.value,
    time: dayjs(delayDay.value).format("YYYY-MM-DD HH:mm:ss"),
    type: 1,
  };
  console.log(targetObj, "targetObj");
  if (multipleSelection.value.length >= 1) {
    // 批量
    let result = multipleSelection.value.map((item) => {
      return {
        customerId: item.customerId,
        customerTypeId: item.customerTypeId,
        id: item.id,
      };
    });
    targetObj.mailCustomerFollowListDTOList = [...result];
    console.log("all", targetObj);
  } else {
    // 单个
    const {customerId, customerTypeId, id} = activeItem.value;
    targetObj.mailCustomerFollowListDTOList = [
      {customerId, customerTypeId, id},
    ];

    console.log("dan", targetObj);
  }
  let res = await batchDelayCancel({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  dialogVisible.value = false;
  delayDay.value = "";
  await getListFollow();
  await store.dispatch('countshow/getTeamAllDataApis')
};

// 取消对话框
const popoverVisibleCancel = ref(false);
// 取消
const cancel = async (row, colum, $index) => {
  row.popoverVisibleCancel = true;
};
// 确认取消
const handleCancel = async (row) => {
  const {customerId, customerTypeId, id, alertDay} = row;
  let targetObj = {
    mailCustomerFollowListDTOList: [
      {
        customerId,
        customerTypeId,
        id,
        alertDay,
      },
    ],
    teamId: teamId.value,
    type: 2,
  };
  console.log(targetObj, "targetObj");
  let res = await batchDelayCancel({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  if (!isFllow.value) {
    ElMessage.success(res.message);
  }
  await getListFollow();
  await store.dispatch('countshow/getTeamAllDataApis')
  isFllow.value = false
};

// 批量取消对话框
const cancelAllVisible = ref(false);
// 批量取消
const cancelAll = () => {
  if (multipleSelection.value.length === 0) {
    ElMessage.warning(i18n.global.t('30001'));
    return;
  }
  cancelAllVisible.value = true;
};
// 确定批量取消
const handleCancelAll = async () => {
  const result = JSON.parse(JSON.stringify(multipleSelection.value));
  console.log(result)
  const targetList = result.map((item) => {
    return {
      customerId: item.customerId,
      customerTypeId: item.customerTypeId,
      id: item.id,
      alertDay: item.alertDay,
    };
  });
  const targetObj = {
    mailCustomerFollowListDTOList: [...targetList],
    teamId: teamId.value,
    type: 2,
  };
  let res = await batchDelayCancel({...targetObj});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.message);
  cancelAllVisible.value = false;
  await getListFollow();
  await store.dispatch('countshow/getTeamAllDataApis')
};

onMounted(async () => {
  // await getListFollow();
  await store.dispatch('countshow/getTeamAllDataApis')
});
watch(() => teamId.value, async () => {
  await getListFollow();
  await store.dispatch('countshow/getTeamAllDataApis')
})

const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  console.log(row, columnIndex)
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 8) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}

const headerClassName = ({row, column, rowIndex, columnIndex}) => {
  if (columnIndex === 1 || columnIndex === 2 || columnIndex === 8) {
    return {'text-align': 'left', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  } else {
    return {'text-align': 'center', background: '#f9f9f9', color: 'black', padding: '16px 0px'}
  }
}

const toDetail = (row) => {
  const newUrl = router.resolve('/email/customer/detail/' + row.customerId + '?type=1')
  openNewWindow(newUrl.href);
}
</script>

<style lang="less" scoped>
@import "../../less/page.less";

.table_name {
  font-size: 20px;
}

.top_tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.settings {
  display: flex;
  justify-content: center;
}

:deep(.el-table__cell) {
  text-align: center;
}

.buttons_bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: -16px;
  margin-right: -16px;
}

.buttons_bottom button:first-child {
  margin-right: 10px;
}

.content_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inner_text {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px !important;
}

#sp_tables {
  :deep(.el-table__header) {
    width: 100% !important;
  }

  :deep(.el-table__body) {
    width: 100% !important;
  }
}
</style>
