<template>
  <div>
    <div class="tops">
      <span style="font-size: 14px; font-weight: 700;display: flex;justify-items: flex-start"
      >
        <div v-html="formattedMessage"></div>
        <!--       <i18n path="displace.team.station.totalanduse" tag="p">-->
        <!--         <template v-slot:use>-->
        <!--           {{ mailWorkPosition.usePosition }}-->
        <!--         </template>-->
        <!--         <template v-slot:remain>-->
        <!--          <span :style="{ color: userInfo.accountType !== 2 ? '#1890ff' : 'red' }"-->
        <!--          >{{ mailWorkPosition.freePosition }}</span>-->
        <!--         </template>-->
        <!--       </i18n>-->
        <span style="color: #1890ff;cursor: pointer;margin-left: 10px" v-if="isSuperAdmin" @click="toBuy"> {{
            $t("common.station.buy")
          }}</span>
      </span>
      <div>
        <el-button type="primary" v-if="isSuperAdmin" @click="addUsers"> {{
            isEnterpriseMainUser ? $t("common.sss4") : $t('common.member.add')
          }}
        </el-button>
        <el-button @click="addUsersInner" v-if="isSuperAdmin&&isEnterpriseMainUser">{{ $t("common.sss3") }}</el-button>
      </div>
    </div>
    <div class="tables">
      <el-table
          :data="tableData"
          height="auto"
          style="width: 100%"
          v-loading="isLoadingUser"
          :cell-style="{ textAlign: 'left' }"
          :header-cell-style="{ 'text-align': 'left',background: '#f9f9f9', color: 'black',padding:'16px 0px'}">
        >
        <el-table-column prop="id" :label="$t('common.member')" width="280">
          <template #default="{ row, column, $index }">
            <div class="users">
              <div>
                <el-avatar v-if="row.avatar!==null" :size="45" @error="errorHandler">
                  <img :src="row.avatar"/>
                </el-avatar>
                <el-avatar :size="45" v-else
                >
                  <img style="height: 20px; width: 20px" :src="require('@/assets/default/avatar.png')"/>
                </el-avatar>
              </div>
              <div style="display: flex;flex-direction: column;justify-content: center">
                <div v-if="row.userName">{{ row.userName }}</div>
                <div v-if="row.email">{{ row.email }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="joinDate" :label="$t('common.time.join')" width="180"/>
        <el-table-column prop="deptName" :label="$t('common.department.index')"
                         :width="flexWidth('deptName', tableData, $t('common.department.index'))"/>
        <!--        <el-table-column prop="teamStatus" :label="$t('common.team.membersTheState')"-->
        <!--                         :width="flexWidth('teamStatus', tableData, $t('common.team.membersTheState'))"/>-->
        <el-table-column width="160" prop="memberType" :label="$t('common.permission')">
          <template #default="{ row, column, $index }">
            <div v-if="row.memberType === 3">
              <div>{{ $t("common.admin.super") }}</div>
            </div>
            <div class="settings" v-else>
              {{ row.memberType == 2 ? $t("common.admin") : $t("common.generaluser") }}
              <div class="settings">
                <span class="col" v-if="isSuperAdmin">|</span
                >
                <el-icon v-if="isSuperAdmin" @click="startSettings(row, column, $index)"
                >
                  <Setting
                  />
                </el-icon>
                <!--                {{ userIsAdmin ? userIsAdmin : userInfo.accountType !== 3 ? row.userId === userInfo.id : false}}-->
                <span class="col"
                      v-if="isSuperAdmin ? isSuperAdmin :  !isEnterpriseMainUser ? row.userId === userInfo.id : false">|</span>
                <ConfirmDeletePopover
                    :visible="row.popoverVisible"
                    :width="164"
                    :message="$t('sys.message.ask.member.unbind')"
                    @update:visible="(value) => (row.popoverVisible = value)"
                    @confirm:delete="handleDelete(row, column, $index)"
                >
                  <template #settings>
                    <el-icon @click="row.popoverVisible = true"
                             v-if="isSuperAdmin ? isSuperAdmin : !isEnterpriseMainUser ? row.userId === userInfo.id : false"
                    >
                      <DeleteFilled
                      />
                    </el-icon>
                  </template>
                </ConfirmDeletePopover>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pages">
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            v-if="total"
            layout="total, prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <SearchTargetUserAndSend v-if="dialogVisible" v-model="dialogVisible" @close="closeDia"></SearchTargetUserAndSend>
    <Dialog
        v-model="dialogVisibleSetting"
        :title="$t('common.permission.setting')"
        :dialogVisible="dialogVisibleSetting"
        :isHeadLine="true"
        :width="480"
        @update:dialogVisible="(val) => (dialogVisibleSetting = val)"
    >
      <template #content>
        <div style="margin: 10px 0px 35px 0px;">
          {{ $t("displace.name.setting", {name: rows.userName}) }}
        </div>
        <div class="selections">
          <div>
            <el-select
                :disabled="allDepartment.length===0"
                v-model="departmentNames"
                class="m-2"
                style="width: 200px"
                :placeholder="allDepartment.length===0 ? $t('left.add.department'):$t('common.please.select')"
            >
              <el-option
                  v-for="item in allDepartment"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
          <div>
            <el-select v-model="usersNames" class="m-2" :placeholder="$t('common.please.select')" style="width: 200px">
              <el-option
                  v-for="item in optionsUsers"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div style="display: flex;justify-content: center;margin-top: 30px">
          <el-button type="primary" @click="changeUserDepartment"
          >{{ $t("common.confirm") }}
          </el-button
          >
        </div>
      </template>
    </Dialog>
    <Dialog
        v-model="dialogVisibleInner"
        :title="$t('common.sss3')"
        :width="690"
        :dialogVisible="dialogVisibleInner"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (dialogVisibleInner = val)"
    >
      <template #content>
        <Transfers :key="mainKey"></Transfers>
      </template>
      <template #footer>
        <div class="setting">
          <el-button @click="dialogVisibleInner=false">{{ $t("common.sss16") }}</el-button>
          <el-button type="primary" @click="submitTransfers" :loading="addIsloading">{{
              $t("common.confirm")
            }}
          </el-button>
        </div>
      </template>
    </Dialog>
    <!--    工位预警-->
    <PositionWarningPop v-if="dialogVisibleTips" v-model="dialogVisibleTips" @close="close"></PositionWarningPop>
  </div>
</template>

<script setup>
import {ref, onMounted, computed, watch} from "vue";
import {useStore} from "vuex";
import Dialog from "@/components/Dialog.vue";
import ConfirmDeletePopover from "@/components/ConfirmDeletePopover.vue";
import Transfers from "@/components/Transfers.vue";
import {findTeamDepartmentById,} from "@/api/groups.js";
import {ElMessage, ElTable} from "element-plus";
import {i18n} from "../../lang/index";
import {useRouter} from "vue-router";
import PositionWarningPop from "@/components/DiaPops/PositionWarningPop.vue";
import SearchTargetUserAndSend from "@/components/DiaPops/SearchTargetUserAndSend.vue";
import {flexWidth} from "@/utils/tableWidth";

const router = useRouter();


// 判断是否是超级管理员如果是的话 就展示
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isEnterpriseMainUser = computed(() => store.getters['user/isEnterpriseMainUser']);


const userInfo = computed(() => store.state.user.userInfo)
const isLoadingUser = computed(() => store.state.groups.isLoadingUser);
const mailWorkPosition = computed(() => store.state.user.mailWorkPosition)

// team id
const teamId = computed(() => store.state.email.teamID);
// 用户头像
const errorHandler = () => true;
const store = useStore();
// 对话框
const dialogVisible = ref(false);
const dialogVisibleSetting = ref(false);
const dialogVisibleTips = ref(false) //工位预警
// 用户
const usersNames = ref("");
// 部门
const departmentNames = ref("");
// 记录选中的row
const rows = ref({});
const mainKey = ref(1)
// 工位
const teamList = computed(() => store.state.email.teamList)

const tableData = computed(() => store.state.groups.tableData);
// 所有的部门
const allDepartment = computed(() => store.state.groups.departList);

const optionsUsers = [
  {
    value: 1,
    label: i18n.global.t("common.generaluser"),
  },
  {
    value: 2,
    label: i18n.global.t("common.admin")
  },
];

const close = () => {
  dialogVisibleTips.value = false
}
const closeDia = () => {
  dialogVisible.value = false
}

// 分页
const current = computed(() => store.state.groups.pageUser.current);
const size = computed(() => store.state.groups.pageUser.size);
const total = computed(() => store.state.groups.pageUser.total);
const handleCurrentChange = async (page) => {
  await store.commit("groups/setUserPage", {current: page, size: size.value, total: total.value});
  await store.dispatch("groups/getUserList");
};

// 添加外部成员
const addUsers = () => {
  dialogVisible.value = true;
};
// 添加内部成员 对话框
const dialogVisibleInner = ref(false);

// 分页
const currentUserList = ref(1);
const sizeUserList = ref(10);
const totalsUserList = ref(0);
const handleCurrentChangeUserList = async (page) => {
  currentUserList.value = page;
  // mainKey.value = mainKey.value++;
  await store.dispatch('groups/getCompanyUserList')
}

const addUsersInner = async () => {
  dialogVisibleInner.value = true;
  await store.dispatch('groups/getCompanyUserList');
};
const addIsloading = ref(false)
const submitTransfers = async () => {
  //首先判断是否还具有工位再进行添加
  if (mailWorkPosition.value.freePosition !== 0) {
    dialogVisibleInner.value = false;
    const transferData = computed(() => store.state.groups.transferData);
    const targetObj = {
      userId: transferData.value,
      teamId: teamId.value,
    };
    // 这里是需要再进行处理的
    try {
      addIsloading.value = true
      await store.dispatch('groups/addUsers', targetObj)
      await store.commit('groups/setTransferData', [])
      addIsloading.value = false
    } catch (e) {
      addIsloading.value = false
    }
    await store.dispatch('groups/getUserList')
  } else {
    dialogVisibleTips.value = true
  }
};

// 设置行
const startSettings = (row, column, $index) => {
  dialogVisibleSetting.value = true;
  rows.value = {...row};
  const targetId = allDepartment.value.length > 1 ? allDepartment.value.filter(item => item.name === row.deptName)[0].id : false
  if (targetId) {
    // 设置部门
    departmentNames.value = targetId;
  }
  // 设置类型
  usersNames.value = row.memberType
  console.log(row, 'row', allDepartment.value)
};
// 用户 关联 部门
const changeUserDepartment = async () => {
  dialogVisibleSetting.value = false;
  console.log('rows.value', rows.value)
  // 根据id 查找部门
  let deptId = null;
  if (departmentNames.value) {
    let res = await findTeamDepartmentById({
      id: departmentNames.value,
    });
    if (res.code !== 200) {
      ElMessage.error(res.message);
      return;
    }
    const {name, id} = res.result;
    deptId = id;
  }
  store.dispatch("groups/updateUsers", {
    id: rows.value.id,
    memberType: usersNames.value,
    deptId: deptId,
    teamId: teamId.value,
  });
  usersNames.value = "";
  departmentNames.value = "";
};

// 删除用户
const handleDelete = async (row, column, $index) => {
  row.dialogVisible = true;
  await store.dispatch("groups/deleteUsers", {id: row.id, teamId: teamId.value});
  await store.dispatch("user/getPositionByLoginList");
  await store.dispatch('email/getTeamList')

  if (teamList.value.length >= 1) {
    localStorage.setItem('teamId', teamList.value[0].id)
  } else {
    localStorage.removeItem("teamId")
    //   如果查询到没有团队的详情的话那么就跳转到首页
    router.push("/dashboard")
  }
};
const toBuy = () => {
  router.push({path: "/personal/buystorage", query: {index: 1}});
}

onMounted(async () => {
  await store.dispatch("groups/getUserList");
  await store.dispatch("user/getPositionByLoginList");
  // await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value})
});

watch(() => teamId.value, async () => {
  await store.dispatch("groups/getUserList");
  await store.dispatch("user/getPositionByLoginList");
  // await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value})
})


// 文字更改颜色
const formattedMessage = computed(() => {
  const message = i18n.global.t("displace.team.station.totalanduse", {
    remain: mailWorkPosition.value.freePosition,
    use: mailWorkPosition.value.usePosition
  })
  // const reg = /\d/g;
  // // 匹配第二个数字的位置
  // const matches = `${message}`.match(reg)
  // if (matches != null)
  //   return message.replace(
  //       matches[1],
  //       `<span class="${userInfo.value.accountType === 2 ? 'variable' : 'variable2'}">1</span>`
  //   );
  return message
})
</script>

<style lang="less" scoped>
@import "./less/Left.less";
@import "../../less/page.less";

.inners_warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.setting_center {
  display: flex;
  justify-content: center;
}

.variable {
  /* Add your desired styles for the variables here */
  color: red !important;
  font-weight: bold;
}

.variable2 {
  color: #84a4f3 !important;
  font-weight: bold;
}

:deep(.el-table__header) {
  width: 100% !important;
}

:deep(.el-table__body) {
  width: 100% !important;
}

</style>
