<template>
  <div class="top_title">{{ $t('common.sys.notice') }}</div>
  <div class="scroll_out_box" v-if="resultTargetDataList.length>1">
    <NotifyList :resultTargetDataList="resultTargetDataList" @changePages="changePage">
      <template #loadings>
        <div v-loading="allDataIsLoading"></div>
      </template>
    </NotifyList>
  </div>
  <div v-else>
    <div class="item_box">
      <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref} from "vue";
import {emptyUrl} from "@/utils/statusParams";
import {useStore} from "vuex";
import {conMessage} from "@/utils/mes/messageMap";
import NotifyList from "@/components/Message/NotifyList.vue";
import {ElMessage} from "element-plus";
import {i18n} from "@/lang"
// 消息数据
const dataList = computed(() => store.state.message.allDataList)
const allDataIsLoading = computed(() => store.state.message.allDataIsLoading)
const resultTargetDataList = computed(() => {
  if (dataList.value.length !== 0) {
    // console.log('allDataList', dataList.value)
    return conMessage(dataList.value);
  } else {
    return dataList.value;
  }
})
const store = useStore()

const pageNo = ref(1);
const pageSize = ref(30);

const allDataTotal = computed(() => store.state.message.allDataTotal)
const changePage = async () => {
  const startIndex = (pageNo.value - 1) * pageSize.value; // 计算当前页的起始索引
  const endIndex = Math.min(startIndex + pageSize.value, allDataTotal.value); // 计算当前页的结束索引
  console.log(endIndex, allDataTotal.value, 'fff')
  if (endIndex >= allDataTotal.value) {
    ElMessage.warning(i18n.global.t('common.cds71'))
    return
  }
  pageNo.value = pageNo.value + 1;
  await store.dispatch("message/getMessageListApis", {pageNo: pageNo.value, pageSize: pageSize.value})
}

onMounted(async () => {
  await store.dispatch("message/getMessageListApisDefault", {pageNo: pageNo.value, pageSize: pageSize.value})
})

</script>

<style lang="less" scoped>
.top_title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding: 20px;
}

.item_box {
  background: white;

  .sp_item {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  .item {
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_box {
      display: flex;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .right_box {
      color: rgb(153, 153, 153);
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }

    .right {
      margin: 14px 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .tops {
        font-size: 16px;
        font-weight: bold;
      }

      .content {
        overflow-wrap: break-word;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
      }
    }
  }
}

.scroll_out_box {
  padding: 0px 12px 0px 18px;
  display: flex;
  flex-direction: column; /* 主轴方向设置为向下 */
  height: calc(100vh - 132px); /* 注意这个高度100%要基于父容器的height */
  box-sizing: border-box;
  //height: 100%;
}

:deep(.el-scrollbar) {
  height: calc(100vh - 140px);
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: 6px;
}

.notify_boxs {
  padding-top: 10px !important;
}
</style>