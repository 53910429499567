<template>
  <div class="video_wrap">
    <div v-if="props.messageItem.status === 'unSend'" className="g-progress-wrap">
        <el-progress type="circle" :width="80" :percentage="props.progress" />
    </div>
    <div ref="skeleton" 
         class="message-video-box"
         :class="[!(props.messageItem.status === 'unSend') &&
          'message-video-cover']"
          @click="handlePlayVideo">
       <img
        class="message-img"
        :class="[isWidth ? 'is-width' : 'is-height']"
        :src="poster || transparentPosterUrl"
      />
    </div>
  </div>

  <Teleport to="body">
    <el-dialog
      v-if="dialogVisible"
      v-model="dialogVisible"
      width="500"
      @before-close="closeVideoModal"
    >
      <div class="video_play">
        <video ref="videoRef" controls>
          <source :src="payload.url" type="video/mp4">
        </video>
      </div>
    </el-dialog>
  </Teleport>
</template>

<script setup>
import { defineProps, nextTick, watchEffect, ref, computed } from 'vue'
import { ElDialog, ElProgress } from 'element-plus'

const props = defineProps(['payload', 'messageItem', 'progress']);

const videoRef = ref();
const dialogVisible = ref(false);
const handlePlayVideo = () => {
  dialogVisible.value = true;
  nextTick(() => {
    // 加载完成自动播放
    videoRef.value.addEventListener("canplay", playVideo);
  })
}

// 关闭弹窗 移除事件
const closeVideoModal = () => {
   videoRef.value.removeEventListener("canplay", playVideo)
}

const playVideo = () => {
  videoRef.value.play();
}

// 封面大小处理
const transparentPosterUrl =
  "https://web.sdk.qcloud.com/im/assets/images/transparent.png";
const poster = ref("");
const posterWidth = ref(0);
const posterHeight = ref(0);
const skeleton = ref();

const isWidth = computed(() => {
  const { snapshotWidth = 0, snapshotHeight = 0 } = props.messageItem.payload;
  return snapshotWidth >= snapshotHeight;
});

const handleSkeletonSize = (width, height, maxWidth, maxHeight) => {
  const widthToHeight = width / height;
  const maxWidthToHeight = maxWidth / maxHeight;
  if (width <= maxWidth && height <= maxHeight) {
    return { width, height };
  }
  if (
    (width <= maxWidth && height > maxHeight) ||
    (width > maxWidth &&
      height > maxHeight &&
      widthToHeight <= maxWidthToHeight)
  ) {
    return { width: width * (maxHeight / height), height: maxHeight };
  }
  return { width: maxWidth, height: height * (maxWidth / width) };
};

watchEffect(async () => {
  if (!props.payload) return;
  poster.value = await handlePosterUrl(props.payload, props.messageItem);
  nextTick(async () => {
    // const containerWidth
    //   = document.getElementById('messageScrollList')?.clientWidth || 0;
    // const max = Math.min(containerWidth - 172, 300);
    let size;
    let { snapshotWidth = 0, snapshotHeight = 0 } = props.payload;
      const { snapshotUrl } = props.payload;
      if (snapshotWidth === 0 || snapshotHeight === 0) return;
      if (snapshotUrl === transparentPosterUrl) {
        snapshotWidth = posterWidth.value;
        snapshotHeight = posterHeight.value;
      }
      size = handleSkeletonSize(snapshotWidth, snapshotHeight, 200, 200);
      skeleton?.value?.style &&
        (skeleton.value.style.width = `${size.width}px`);
      skeleton?.value?.style &&
        (skeleton.value.style.height = `${size.height}px`);
  });
});

async function handlePosterUrl(messgeContent, messageItem) {
  if (!messageItem) return "";
  if (messageItem.status !== "success") {
    return await getVideoBase64(messgeContent.url);
  } else {
    return (
      (messgeContent.snapshotUrl !== transparentPosterUrl &&
        messgeContent.snapshotUrl) ||
      (await getVideoBase64(messgeContent.url))
    );
  }
}

function getVideoBase64(url) {
  return new Promise((resolve) => {
    let dataURL = "";
    const video = document.createElement("video");
    video.setAttribute("crossOrigin", "anonymous");
    video.setAttribute("src", url);
    video.setAttribute("preload", "auto");
    video.addEventListener(
      "loadeddata",
      function () {
        const canvas = document.createElement("canvas"),
          width = video.videoWidth,
          height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height);
        dataURL = canvas.toDataURL("image/jpeg");
        posterWidth.value = width;
        posterHeight.value = height;
        resolve(dataURL);
      },
      { once: true }
    );
  });
}

</script>

<style scoped lang="less">
.g-progress-wrap{
  position: absolute;
  width: 100%;
  z-index: 9;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}



.video_wrap{
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .message-video-box {
    cursor: pointer;
    max-width: calc(100vw - 180px);
    font-size: 0;

    video {
      max-width: calc(100vw - 180px);
      max-height: calc(100vw - 180px);
      width: inherit;
      height: inherit;
      border-radius: 10px;
    }

    img {
      max-width: calc(100vw - 180px);
      max-height: calc(100vw - 180px);
      width: inherit;
      height: inherit;
      border-radius: 10px;
    }

    img[src=""],
    img:not([src]) {
      opacity: 0;
    }
  }

  .message-video-cover {
    display: inline-block;
    position: relative;

    &::before {
      position: absolute;
      z-index: 1;
      content: "";
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-left: 15px solid #fff;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      transform: translate(5px, 0);
    }

    video {
      max-width: calc(100vw - 180px);
      max-height: calc(100vw - 180px);
      width: inherit;
      height: inherit;
      border-radius: 10px;
    }
  }
}

.video_play{
  width: 100%;
  height: 100%;

  video{
    width: 100%;
    height: 100%;
    max-height: 600px;
  }
}

:deep(.el-progress__text){
  color: #fff;
  font-size: 18px !important;
}


.is-width {
  width: 100vw;
}

.is-height {
  height: 100%;
}
</style>
