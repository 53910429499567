<template>
  <div class="write_wrap">
    <div class="btn_list">
      <ElButton size="small" type="primary" @click="() => handleSendMail('send')" :loading="loadingSend"
                :disabled="uploadProgress">
        {{ $t('common.sent') }}
      </ElButton>
      <ElButton size="small" @click="() => handleSendMail('examine')" :loading="loadingExamine"
                :disabled="uploadProgress">
        {{ $t('common.review.submit') }}
      </ElButton>
      <ElButton size="small" @click="handleSendMail('save')" :loading="loadingSave" :disabled="uploadProgress">
        {{ $t('common.draft.save') }}
      </ElButton>
      <el-dropdown trigger="click">
    <span class="el-dropdown-link" style="margin-left: 2px">
     <ElButton size="small">{{ $t('common.annex.upload') }}</ElButton>
    </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <ElUpload
                  style="margin-left: 2px;width: 100%;height: 100%"
                  class="upload-demo"
                  :action="action"
                  :headers="{
                        'X-Access-Token': token,
                      }"
                  :on-progress="onProgress"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-error="handleUploadError"
                  :on-success="handleUploadSuccess"
                  multiple
              >
                <template #trigger>
                  <span style="padding:5px 16px">{{ $t('common.cds88') }}</span>
                </template>
              </ElUpload>
            </el-dropdown-item>
            <!--            <el-dropdown-item>上传超大附件</el-dropdown-item>-->
            <el-dropdown-item @click="handleClickSelectFileFromCloud"><span
                style="padding:5px 16px">{{ $t('common.cloud.file.select') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <ElButton size="small" type="primary" style="margin-left: 2px" @click="createNewTask">
        {{ $t('sending.create.grouptask') }}
      </ElButton>
    </div>
    <div class="top">
      <div class="form">
        <el-form ref="ruleFormRef" :model="form" label-width="80px" size="large">
          <el-form-item :label="`${$t('common.email.from')}:`" prop="configId">
            <el-select
                v-model="form.configId"
                class="mailselectEl"
                style="width: 100%;"
                remote
                allow-create
                :placeholder="$t('common.please.input')"
            >
              <el-option
                  v-for="item in userMail_dropDownOptionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="`${$t('common.email.to')}:`" prop="to">
            <el-select
                v-model="form.to"
                class="mailselectEl"
                style="width: 100%;"
                remote
                multiple
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                :placeholder="$t('common.please.input')"
                @focus="(event) => handleFocus(event, 'to')"
                @blur="handleBlur"
            >
            </el-select>
          </el-form-item>
          <el-form-item :label="`${$t('common.email.cc')}:`" prop="cc" style="position: relative">
            <template #label>
              <div
                  style="
                  display: flex;
                  align-items: center;
                  position: relative;
                ">
                <el-icon size="16" @click="handleSetOpen">
                  <CaretRight v-if="!open"/>
                  <CaretBottom v-else/>
                </el-icon>
                <label style="text-align: right;">{{ $t('common.email.cc') }}: </label>
              </div>
            </template>
            <el-select
                v-model="form.cc"
                class="mailselectEl"
                style="width: 100%;"
                remote
                multiple
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                :placeholder="$t('common.please.input')"
                @focus="(event) => handleFocus(event, 'cc')"
                @blur="handleBlur"
            >
            </el-select>
          </el-form-item>
          <template v-if="open">
            <el-form-item :label="`${$t('common.email.bcc')}:`" prop="bcc">
              <el-select
                  v-model="form.bcc"
                  class="mailselectEl"
                  style="width: 100%;"
                  remote
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  :reserve-keyword="false"
                  :placeholder="$t('common.please.input')"
                  @focus="(event) => handleFocus(event, 'bcc')"
                  @blur="handleBlur"
              >
              </el-select>
            </el-form-item>
          </template>
          <el-form-item :label="`${$t('common.subject')}:`" prop="subject" :rules="[
        {
          required: true,
          message:'',
          trigger: ['blur','change'],
        },
      ]">
            <el-input
                :placeholder="$t('20212')"
                maxlength="300"
                show-word-limit
                style="width: 100%"
                class="email_theme"
                v-model="form.subject"
            >
            </el-input>
          </el-form-item>
          <div style="display: flex; align-items: center; height: 40px;overflow-x: scroll;overflow-y: hidden">
            <el-button @click="handleSelectTemplate" size="small" style="margin: 0 10px;">
              {{ $t('common.email.template.select') }}
            </el-button>
            <el-checkbox
                v-model="form.urgent"
                :true-label="1"
                :false-label="0"
                style="margin-right: 8px;"
            >{{ $t('common.urgent') }}
            </el-checkbox
            >
            <el-checkbox
                v-model="form.receipt"
                :true-label="1"
                :false-label="0"
                style="margin-right: 8px;"
            >{{ $t('common.read.receipt') }}
            </el-checkbox
            >
            <el-checkbox
                v-model="form.readTrack"
                :true-label="1"
                :false-label="0"
                style="margin-right: 8px;"
            >{{ $t('common.read.tracking') }}
            </el-checkbox
            >
            <el-space style="font-weight: 700; font-size: 14px; margin-left: 10px; color: #606266;">
              {{ $t('common.send.timing') }}：
              <el-date-picker
                  v-model="form.sendTime"
                  type="datetime"
                  size="small"
                  style="width: 160px;"
                  :disabled-date="disabledDate"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :placeholder="$t('sys.tip.select.date')">
              </el-date-picker>
            </el-space>
            <el-checkbox style="margin-left: 10px;"
                         v-model="followDayFlag"
                         :true-label="1"
                         :false-label="0"
            >
              {{ $t('common_followup_remind') }}:
            </el-checkbox>
            <el-space style="font-weight: 700; font-size: 14px; margin-left: 10px; color: #606266;">
              <el-input
                  v-model="sendNum"
                  class="w-50 m-2"
                  placeholder=""
                  style="width: 140px;"
                  size="small"
              >
                <template #suffix>
                  <div class="suffix">
                    <el-icon class="el-input__icon" style="margin-left: auto;">
                      <Calendar/>
                    </el-icon>
                    <el-date-picker
                        class="oh"
                        v-model="sendDate"
                        type="date"
                        @change="handleSendDateChange"
                        size="small"
                        format="YYYY-MM-DD HH:mm:ss"
                        :disabled-date="disabledDate"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        :placeholder="$t('sys.tip.select.date')">
                    </el-date-picker>

                  </div>
                </template>
              </el-input>
              <!--  -->
              {{ $t('common.p2h3') }}
            </el-space>
            <el-space style="font-weight: 700; font-size: 14px; margin-left: 10px; color: #606266;">
              {{ $t('common.remark') }}：
              <el-input size="small" style="width: 200px;" v-model="form.remark"></el-input>
            </el-space>
          </div>
        </el-form>
      </div>
      <div class="customer">
        <el-tabs model-value="customer" class="demo-tabs">
          <el-tab-pane :label="$t('common.customer')" name="customer">
          </el-tab-pane>
        </el-tabs>
        <el-input
            v-model="customerName"
            class="input"
            size="small"
            :placeholder="$t('common.please.input')"
            @keyup.enter="handleSearchCustomer"
        >
          <template #suffix>
            <el-icon class="el-input__icon" @click="handleSearchCustomer">
              <Search/>
            </el-icon>
          </template>
        </el-input>
        <div style="margin-top: 80px" v-loading="customerLoading">
        </div>
        <div class="list" @scroll="handleScroll" ref="List">
          <div class="item" v-for="(item) in customerList"
               @click="() => handleSelectCustomer(item)" :key="item.email">
            <el-popover
                placement="right"
                :width="'max-content'"
                trigger="hover"
                :content="item.email"
                effect="dark"
                :show-after="500"
            >
              <template #reference>
                {{ item.name }}
              </template>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="editor_wrap">
      <TinyEditor ref="tinyEditorWrite" :width="'calc(100% - 265px)'"
                  :initial-content="tinyEditorWriteContentHtml"
                  :editorId="'write'"></TinyEditor>
      <div class="files" ref="filesRef">
        <div class="files_title">{{ $t('common.file.list') }}</div>
        <div class="file_box" style="position: relative">
          <div class="files_out_box" style="position: absolute;z-index: 80">
            <div class="file" v-for="(item, index) in multipartFiles" :key="item.fileName">
              <div style="display: flex;flex-direction: column;width: 100%">
                <div style="display: flex;justify-content: space-between;align-items: center">
                  <div style="display: flex;justify-content: flex-start;align-items: center;">
                    <el-icon v-if="item.percentage===100 && item.response || item.isCloud || item.url"
                             color="rgba(0,0,0,.45)"
                             size="14">
                      <Link/>
                    </el-icon>
                    <el-icon v-else class="is-loading" color="rgba(0,0,0,.45)" size="14">
                      <Loading/>
                    </el-icon>
                    <el-popover
                        placement="right"
                        :width="'max-content'"
                        effect="dark"
                        trigger="hover"
                        :content="item.name"
                        show-after="500"
                    >
                      <template #reference>
                        <a v-if="item.response||item.isCloud|| item.url" @click="downloadFiles(item.url,item.name)"
                           class="name contents text"
                           :download="true">{{
                            item.name
                          }}</a>
                        <span v-else class="name contents text" style="color: grey;pointer-events: none">{{
                            item.name
                          }}</span>
                      </template>
                    </el-popover>
                  </div>

                  <!-- 查看-->
                  <template v-if="item.percentage===100 && item.response || item.isCloud || item.url">
                    <el-icon color="rgba(0,0,0,.45)" size="14" @click="handleDetailFile(item)">
                      <Search/>
                    </el-icon>
                    <el-icon color="rgba(0,0,0,.45)" size="14" @click="handleDelMul(index)">
                      <Delete/>
                    </el-icon>
                  </template>
                </div>
                <div style="margin-top: 4px" v-if="!item.isCloud && !item.url">
                  <!--              5% 等待服务端返回-->
                  <el-progress v-if="!item.response"
                               :percentage="item.response&&uploadCompleted?100:Math.max(0,item.percentage-5)"/>
                </div>
              </div>
            </div>
          </div>
          <div class="drop_area">
            <el-upload
                class="upload-demo"
                drag
                style="padding:0px!important;"
                :show-file-list="false"
                :action="action"
                :headers="{
                                  'X-Access-Token': token,
                                }"
                :on-progress="onProgress"
                :before-upload="beforeUpload"
                :on-error="handleUploadError"
                :on-success="handleUploadSuccess"
                multiple
            >
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <!--    设置模版-->
    <el-dialog
        v-model="templateFlag"
        width="50vw"
        :z-index="97"
        :append-to-body="true"
        :close-on-click-modal="false"
        style="min-width: 400px"
    >
      <div class="modal_content">
        <div class="list">
          <el-tabs model-value="first" class="demo-tabs">
            <el-tab-pane :label="$t('common.email.template')" name="first">
              <ElTable
                  :data="templateList"
                  ref="templateRef"
                  @current-change="handleCurrentRowChange"
                  style="width: 100%"
                  :height="400"
                  v-loading="isLoading"
                  v-el-table-infinite-scroll="handleTableScroll"
              >
                <!--                v-el-table-infinite-scroll="handleTableScroll"-->
                <el-table-column prop="name" :label="$t('common.email.templateName')" width="auto"
                                 show-overflow-tooltip/>
                <el-table-column prop="subject" :label="$t('common.subject')" width="auto"
                                 show-overflow-tooltip/>
                <el-table-column prop="edit" :label="$t('common.cz')" width="260">
                  <template #default="scope">
                    <el-button link type="primary" size="small" @click.stop="handleSetDefault(scope.row)"
                    >{{ scope.row.isDefault !== 1 ? $t('common.setdefault') : $t('common.rmdefault') }}
                    </el-button
                    >
                    <el-button link type="primary" size="small" @click.stop="handleView(scope.row)">
                      {{ $t('common.preview') }}
                    </el-button>
                    <el-button link type="primary" size="small" @click.stop="handleUse(scope.row)">
                      {{ $t('common.email.use') }}
                    </el-button>
                    <el-button link type="primary" size="small" @click.stop="handleEditTemplate(scope.row)">
                      {{ $t('common.edit') }}
                    </el-button>
                    <el-button link type="danger" size="small" @click.stop="handleDel(scope.row)"
                               :loading="scope.row.isLoadingDel">
                      {{ $t('common.delete') }}
                    </el-button>
                  </template>
                </el-table-column>
              </ElTable>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!--        <div class="view_html" v-html="currentTemplateMail"></div>-->
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="handleInitTemplateEditor">
            {{ $t('common.email.newTemplate') }}
          </el-button>
          <el-button @click="templateFlag = false">{{ $t('common.exit') }}</el-button>
        </div>
      </template>
    </el-dialog>
    <!--    模版预览-->
    <el-dialog :append-to-body="true" :z-index="99" width="75vw"
               :title="editTemplate && editTemplate.id ? $t('common.email.editTemplate') : $t('common.email.newTemplate')"
               v-model="addTemplateFlag" :top="'2em'"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
    >
      <div class="template_name" style="display: flex;flex-direction: column">
        <div style="width: 100%">
          <div class="label">{{ $t('common.email.templateName') }}：</div>
          <el-input v-model="templateName" :placeholder="$t('common.email.template.name')"/>
        </div>
        <!--      模版主题 -->
        <div style="width: 100%;margin-top: 20px">
          <div class="label">{{ $t('common.subject') }}：</div>
          <el-input v-model="subject" :placeholder="$t('common.email.template.name')"/>
        </div>
      </div>
      <div style="height: 60vh;overflow: scroll;margin-top: 30px">
        <TinyEditor ref="tinyEditorTemplate" :width="'100%'" :initial-content="tinyEditorTemplateContentHtml"
                    :editorId="'template'"></TinyEditor>
      </div>
      <template #footer>
        <div class="dialog-footer1">
          <el-button @click="calcelEditor">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="handleSaveTemplate" :loading="isLoadingAdd">
            {{ $t('common.save') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog :title="$t('common.preview')"
               width="75vw"
               v-model="previewFlag"
               :close-on-click-modal="false"
               :top="'3em'"
    >
      <div style="height: 500px">
        <!--        <div v-html="previewContent"></div>-->
        <iframe style="width: 100%;height: 100%" :srcdoc="previewContent" frameborder="0"></iframe>
      </div>
      <template #footer>
        <div>
          <el-button type="primary"
                     style="background: #285ec7 !important;color: white !important;border-color:#285ec7 !important;"
                     @click="previewFlag = false">{{ $t('common.close') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <!--    从云文件中选择-->
    <WriteMailSelectTargetFile v-if="writeSelectTargetFileDialog" v-model="writeSelectTargetFileDialog"
                               @close="closeWriteSelectTargetFileDialog" :type="1"
                               @selectTargetFile="getSelectTargetFile" :isHeadLine="true"></WriteMailSelectTargetFile>
    <!--    创建群发模版-->
    <CreateTaskDia v-model="visibleDialog" v-if="visibleDialog" @close="close" :isHeadLine="true"
    ></CreateTaskDia>
  </div>
</template>

<script>
// import Editor from "@/utils/wangEditor/wangEditor.min";
import {onMounted, computed, reactive, ref, nextTick, watch, onBeforeUnmount, onUpdated, onUnmounted} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import dayjs from 'dayjs';
import {Calendar, Search} from '@element-plus/icons-vue';
import {useStore} from 'vuex';
import {getEmailCustomer} from '@/api/customer';
import {ElMessage, ElTable} from 'element-plus';
import TinyEditor from "@/components/TinyEditor/index.vue"
import {
  getMailConfigList,
  saveDraft,
  sendMail,
  getDefaultMailConfig as requestDefaultMailConfig,
  getMailDetails, tagMail,
} from "@/api/index";
import {
  requestMailSendTemplate,
  requestMailSendTemplateAdd,
  requestMailSendTemplateDelete,
  requestMailSendTemplateEdit,
  requestCommitEmailAudit,
  requestDefaultSendTemplate,
} from '@/api/mail';
import {requestCustomerEmailByCustomerId} from '@/api/customer.js';
import {
  audioFormat,
  downloadFile,
  excelFormat,
  imgFormat,
  pdfFormat,
  pptFormat,
  requestUploadFile,
  textFormat, videoFormat,
  wordFormat
} from '@/utils/file';
import {getToken} from '@/utils/auth';
import {emailReg, TIME_OUT} from "@/utils/statusParams";
import TreeContact from "@/components/DiaPops/TreeContact.vue";
import CreateTeamPop from "@/components/DiaPops/CreateTeamPop.vue";
import WangEditors from "@/components/WangEditor/index.vue";
import {debouncePlus, throttle} from "@/utils/mylodash";
import WriteMailSelectTargetFile from "@/views/Cloud/Components/WriteMailSelectTargetFile.vue";
import {i18n} from "@/lang";
import CreateTaskDia from "@/views/SendGroup/compontents/CreateTaskDia.vue";
import {api as viewerApi} from "v-viewer";

let intervalId = null;
export default {
  computed: {
    i18n() {
      return i18n
    }
  },
  methods: {},
  components: {
    TinyEditor,
    ElTable,
    CreateTaskDia,
    WriteMailSelectTargetFile,
    CreateTeamPop,
    TreeContact,
    Calendar,
    Search,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const teamId = computed(() => store.state.email.teamID);
    const filesRef = ref(null);
    const customerName = ref('');
    const id = ref('');
    const customerLoading = ref(false);
    const followDayFlag = ref(0);
    const form = reactive({
      // 密送列表
      bcc: [],
      // 抄送列表
      cc: [],
      followDay: 0,
      // 附件
      // multipartFiles: [
      //   // {
      //   //   name: '',
      //   //   url: ''
      //   // }
      // ],
      // 0 不回执, 1 回执
      receipt: 0,
      // 1 追踪 0 不追踪
      readTrack: 1,
      // 回复地址
      replyTo: "",
      // 发送时间
      sendTime: "",
      // 邮件主题
      subject: "",
      // 内容
      text: "",
      // 邮件类型
      textType: "TEXT/HTML; charset=utf-8",
      to: [],
      // 0 不紧急，1紧急
      urgent: 0,
      userId: 0,
      configId: '',
      remark: '',
    });
    const userMail_dropDownOptionList = ref([]);
    const templateFlag = ref(false);
    const addTemplateFlag = ref(false);
    const previewFlag = ref(false);
    const templateList = ref([]);
    const currentTemplateMail = ref('');
    const previewContent = ref('');
    const editTemplate = ref(null);
    const templateName = ref('');
    const subject = ref("")
    const mainEditor = ref(null);
    const templateEditor = ref(null);
    const isSaved = ref(false);
    const open = ref(false);
    const customerList = ref([]);
    const multipartFiles = ref([]);
    const activeSelectProp = ref(null);
    const configMapSysUser = reactive({});
    const configIdMapUserName = reactive({});
    const sendNum = ref(0);
    const sendDate = ref('');
    const userNameMap = reactive({});
    const action = ref(`${process.env.VUE_APP_BASE_API}/sys/oss/qiniu/upload`);


    // 群发任务创建
    const visibleDialog = ref(false)
    const close = () => {
      visibleDialog.value = false
    }

    const createNewTask = () => {
      visibleDialog.value = true;
    }


    const writeSelectTargetFileDialog = ref(false);

    // 云文件选择
    const handleClickSelectFileFromCloud = () => {
      writeSelectTargetFileDialog.value = true
    }
    const closeWriteSelectTargetFileDialog = () => {
      writeSelectTargetFileDialog.value = false
    }

    // 获取选中的文件
    const getSelectTargetFile = (value) => {
      console.log(value, '发送')
      const targetArrays = value.map(item => ({
        url: item.fileExtendPath,
        name: item.fileName,
        extendSize: item.fileSize,
        isCloud: true
      }))
      multipartFiles.value = [...targetArrays, ...multipartFiles.value]
      closeWriteSelectTargetFileDialog()
    }

    const downloadFiles = (url, filename) => {
      fetch(url.replace(/^http/, "https"))
          // record.doc_url为文件url地址
          .then((res) => res.blob())
          .then((blob) => {
            downloadFile(blob, filename);
          })
          .catch((error) => {
            ElMessage.error(i18n.global.t('download.failed'));
          });
    }


    const beforeUpload = (file) => {
      console.log('文件：', file)
      var FileExt = file.name.replace(/.+\./, "")
      const isLt5M = file.size / 1024 / 1024 < 100
      var extension = ['exe', 'iso'].indexOf(FileExt.toLowerCase()) === -1
      if (!extension) {
        ElMessage({
          type: 'warning',
          message: i18n.global.t('send.all.upload.ex')
        })
        return false
      }
      if (!isLt5M) {
        ElMessage({
          type: 'warning',
          message: i18n.global.t('send.all.upload.over.size')
        })
        return false
      }
    }


    // 批量群发
    function sendAll() {
      router.push({path: '/email/send_all', query: {id: route.query.id, customerId: route.query.customerId, type: 1}})
    }


    const customerPages = ref(1);
    const customerPageSize = ref(10)
    const customerTotalPages = ref(1)
    const customerIsCurrent = ref(false)
    const customerTotal = ref(0)

    async function getAllCustomer() {
      let payload = {
        teamId: teamId.value,
        customerName: customerName.value,
        pageNo: customerPages.value,
        pageSize: customerPageSize.value
      }
      // if (customerName) {
      //   delete payload.customerName
      // }
      try {
        customerLoading.value = true;
        const result = await getEmailCustomer(payload);
        if (!customerIsCurrent.value) {
          customerList.value = result.result.records || [];
        } else {
          customerList.value = [...customerList.value, ...result.result.records || []]
        }
        customerTotalPages.value = result.result.pages
        customerTotal.value = result.result.total;
        customerLoading.value = false;
      } catch (e) {
        customerLoading.value = false;
      } finally {
        customerLoading.value = false;
      }

    }


    /*
客户列表分页
 */
    const refScrollTop = ref(null)
    const List = ref(null)
    const handleScroll = debouncePlus(async (event) => {
      const element = event.target;
      console.log("scroll", element.scrollHeight, element.scrollTop, element.clientHeight)
      refScrollTop.value = element.scrollTop;
      // const scrollTop = Math.floor(element.scrollTop);
      // const clientHeight = element.getBoundingClientRect().height;
      const tolerance = 5;
      if (element.scrollTop >= (element.scrollHeight - element.clientHeight - tolerance)) {
        if (customerTotalPages.value === customerPages.value) {
          return;
        } else {
          customerIsCurrent.value = true
          customerPages.value++;
          await getAllCustomer()
        }
      }
    }, 500)
    const resetCurrentAboutCustomer = () => {
      customerIsCurrent.value = false
      if (customerPages.value * customerPageSize.value >= customerTotal.value) {
        if (customerPages.value) {
          customerPages.value = 1;
        }
      }
    }

    async function getCustomerEmailByCustomerId() {
      customerLoading.value = true;
      const result = await requestCustomerEmailByCustomerId({
        customerId: route.query.customerId,
        teamId: teamId.value,
        customerName: customerName.value
      });
      customerLoading.value = false;
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      // const resetFullData = result.result.map(item => {
      //   return {newSet: filterString(item.name), ...item}
      // })
      console.log(result.result, 'result.result')
      customerList.value = result.result || [];
      // console.log('查询客户下的所有邮箱列表', result);
    }

    function filterString(inputString) {
      // 使用正则表达式匹配括号中的内容以及括号外的内容
      const regex = /^(.*?)\((.*?)\)(.*?)$/;
      const matches = inputString.match(regex);

      // 如果匹配成功，返回过滤后的字符串
      if (matches && matches.length === 4) {
        const prefix = matches[1].trim(); // 括号前的部分
        const suffix = matches[3].trim(); // 括号后的部分
        return `${prefix}(${suffix})`;
      } else {
        // 如果匹配失败，返回原始字符串
        return inputString;
      }
    }

    const handleSearchCustomer = throttle(async () => {
      console.log(customerName.value, 'customerName.value')
      customerIsCurrent.value = false;
      customerPages.value = 1;
      route.query.customerId ? await getCustomerEmailByCustomerId() : await getAllCustomer();
      // scroll 重置高度
      List.value.scrollTop = 0;
    }, TIME_OUT)


    const templatePageNo = ref(1);
    const templatePageSize = ref(10)
    const isCurrent = ref(false)
    const pages = ref(1);
    const total = ref(0);
    const isLoading = ref(false)
    const templateRef = ref(null)

    async function getMailTemplateList() {
      isLoading.value = true
      try {
        const result = await requestMailSendTemplate({
          pageNo: templatePageNo.value,
          pageSize: templatePageSize.value,
          teamId: teamId.value
        });
        const {records} = result.result;
        if (!isCurrent.value) {
          templateList.value = records.map(item => ({...item, isLoadingDel: false}));
        } else {
          templateList.value = [...templateList.value, ...records.map(_ => ({..._, isLoadingDel: false}))];
        }
        pages.value = result.result?.pages;
        total.value = result.result?.total;
        isLoading.value = false
        console.log('获取邮件模板', records);
      } catch (e) {
        isLoading.value = false
      }
    }

    // 用户是否开始滚动 标识
    const scrollStartFlag = ref(false)
    const scrollBehavior = () => {
      scrollStartFlag.value = true;
    }

    const handleTableScroll = async () => {
      console.log('scroll',)
      //  用户开始滚动
      // await store.commit("cloud/changeDisabled", false);
      if (scrollStartFlag.value) {
        if (templatePageNo.value === pages.value) {
          return;
        } else {
          isCurrent.value = true;
          templatePageNo.value++
          await getMailTemplateList();
        }
      } else {

      }
    }

    const resetCurrentAbout = () => {
      isCurrent.value = false
      if (templatePageNo.value * templatePageSize.value >= total.value) {
        if (templatePageNo.value) {
          templatePageNo.value = 1;
        }
      }
    }


    function handleCurrentRowChange(row) {
      console.log('click current', row);
      currentTemplateMail.value = row?.textContent;
    }

    function formatText(list, type) {
      console.log(list, type);
      if (!list || !list.length) {
        return '';
      }
      const infoMap = list.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item.mail);
        return prev;
      }, {});
      return infoMap[type] ? infoMap[type].join(',') : '';
    }

    // 设为默认
    async function handleSetDefault(row) {
      const result = await requestMailSendTemplateEdit({
        id: row.id,
        isDefault: row.isDefault === 1 ? 0 : 1,
        teamId: teamId.value
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(result.result);
      await resetCurrentAbout()
      await getMailTemplateList();
      templateRef.value.setScrollTop(0)
      console.log('设为默认', result);
    }


    const TargetEmailTemplate = ref("")
    const TargetEmailTemplateSubject = ref("")

    async function getDefaultEmailTemplate() {
      const result = await requestDefaultSendTemplate({teamId: teamId.value});
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      if (result.result) {
        TargetEmailTemplate.value = result.result?.textContent || ""
        if (tinyEditorWrite.value.tinyMCEEditor.getEditor()) {
          await nextTick(() => {
            tinyEditorWriteContentHtml.value = result.result?.textContent || ""
            tinyEditorWrite.value.tinyMCEEditor.getEditor().setContent(result.result?.textContent ? result.result?.textContent : "")
            TargetEmailTemplateSubject.value = result.result?.subject || ""
          })
        }
      }
    }


    function handleView(row) {
      previewContent.value = row?.textContent;
      previewFlag.value = true;
    }


    async function handleUse(row) {
      console.log(row?.textContent, targetDetailObj.value, row, 'targetDetailObj')
      await nextTick(() => {
        // tinyEditorWriteContentHtml.value = row?.textContent + targetDetailObj.value ? targetDetailObj.value : ``
        tinyEditorWrite.value.tinyMCEEditor.getEditor().setContent((row.textContent ? row.textContent : '') + (targetDetailObj.value !== "" ? targetDetailObj.value : ""))
        form.subject = row?.subject
      })
      templateFlag.value = false;
    }

    const handleDel = async (row) => {
      row.isLoadingDel = true
      try {
        const result = await requestMailSendTemplateDelete({id: row.id, teamId: teamId.value});
        if (result.code !== 200) {
          ElMessage.error(result.message);
        } else {
          ElMessage.success(result.result);
          await resetCurrentAbout()
          await getMailTemplateList();
          templateRef.value.setScrollTop(0)
        }
      } catch (e) {
        ElMessage.error(e);
      } finally {
        row.isLoadingDel = false
      }
    }

    async function handleInitTemplateEditor() {
      addTemplateFlag.value = true;
      editTemplate.value = null;
      templateName.value = "";
      subject.value = ""
      await nextTick(() => {
        // 监听更新
        tinyEditorTemplateContentHtml.value = ""
        // 内容更新
        tinyEditorTemplate.value?.tinyMCEEditor?.getEditor().setContent("")
      })
    }

    async function handleEditTemplate(row) {
      console.log(row, '编辑');
      editTemplate.value = row;
      addTemplateFlag.value = true;
      templateName.value = row.name;
      subject.value = row.subject
      await nextTick(() => {
        tinyEditorTemplateContentHtml.value = row?.textContent || "";
        tinyEditorTemplate.value?.tinyMCEEditor?.getEditor().setContent(row?.textContent ? row?.textContent : "")
      })
    }

    // 获取用户邮箱列表
    function initUserMailList() {
      // currentMailId = this.mailConfigId;
      return getMailConfigList({teamId: teamId.value}).then((res) => {
        console.log(res, '获取用户邮箱列表');
        if (res.code === 200) {
          // const configId = res.data.data[0].id
          res.result = res.result || [];
          const arr = [];
          res.result.forEach((item) => {
            configMapSysUser[item.id] = item.sysUserId;
            configIdMapUserName[item.username] = item.id;
            userNameMap[item.id] = item.username;
            arr.push({label: item.username, value: item.id});
          });
          userMail_dropDownOptionList.value = arr;
        }
      });
    }

    async function getDefaultMailConfig() {
      const result = await requestDefaultMailConfig({teamId: teamId.value});
      console.log('默认邮箱', result);
      if (result.code !== 200) {
        ElMessage.error(result.msg);
        return;
      }
      const {id, username, sysUserId} = result.result;
      console.log('默认邮箱', result);
      form.configId = id;
      form.userId = sysUserId;
      form.from = username;
    }

    const isLoadingAdd = ref(false)

    const handleSaveTemplate = throttle(async () => {
      const html = tinyEditorTemplate.value.tinyMCEEditor.getEditor().getContent()
      const requestApi = editTemplate.value ? requestMailSendTemplateEdit : requestMailSendTemplateAdd;
      const params = editTemplate.value ? {
        id: editTemplate.value.id,
        name: templateName.value,
        subject: subject.value,
        textContent: html,
        teamId: teamId.value
      } : {name: templateName.value, subject: subject.value, teamId: teamId.value, textContent: html};
      let result = null;
      try {
        isLoadingAdd.value = true
        result = await requestApi(params);
        isLoadingAdd.value = false
      } catch (e) {
        isLoadingAdd.value = false
      }
      console.log(result, '添加模板');
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(result.result);
      templateName.value = '';
      subject.value = '';
      // if (!editTemplate.value) {
      //   tinyEditorTemplate.value.tinyMCEEditor.getEditor().setContent("")
      // }
      editTemplate.value = null;
      addTemplateFlag.value = false;

      await resetCurrentAbout()
      await getMailTemplateList();
      templateRef.value.setScrollTop(0)
    }, TIME_OUT)

    //
    const calcelEditor = () => {
      editTemplate.value = null;
      addTemplateFlag.value = false;
      // tinyEditorTemplate.value.tinyMCEEditor.getEditor().setContent("")
    }

    function disabledDate(date) {
      return date.getTime() < Date.now();
    }

    async function handleSelectTemplate() {
      templateFlag.value = true;
      await getMailTemplateList()
      templateRef.value && templateRef.value.$refs.bodyWrapper.addEventListener("mousewheel", scrollBehavior, {passive: true})
    }


    const rules = reactive({
      subject: [{required: true, trigger: ["blur", "change"]}],
    });


    async function requestTagMail(id) {
      const result = await tagMail({
        isRead: 1,
        mailIdList: [id],
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')
    }

    /**
     * 发送、保存邮件
     * @param type  {String}  ['send': 发送, 'save': 保存，examine: 审核]
     * @param cb    {Function || null}  回调函数
     */
    const loadingSend = ref(false)
    const loadingExamine = ref(false);
    const loadingSave = ref(false)
    const ruleFormRef = ref(null)
    const handleSendMail = throttle(async (type, cb = null) => {
      ruleFormRef.value?.validate((valid, fields) => {
        if (valid) {
          if (!form.configId) {
            ElMessage.error(i18n.global.t('send.all.target.username'));
            return;
          }
          if (form.to.length === 0) {
            ElMessage.error(i18n.global.t('common.email.recipientNotEmpty'))
            return
          }
          if (form.to.filter(item => !emailReg.test(item)).length >= 1 && !isEmailReg) {
            ElMessage.warning(i18n.global.t('send.all.delete.no.email'))
            form.to = form.to.filter(item => emailReg.test(item))
            isEmailReg.value = true
            return;
          }
          if (type && type === 'save') {
            form.groupId = '2';
          }

          console.log(type, "type=============================")
          console.log("发送", form.to)
          //获取时间戳
          var timestamp = Date.now();

          // 邮件内容
          const data = {
            ...form,
            subject: form.subject.trim(),
            to: mapRecipients(form.to, "to"),
            cc: form.cc ? mapRecipients(form.cc, "cc") : [],
            bcc: form.bcc ? mapRecipients(form.bcc, "bbc") : [],
            multipartFiles: multipartFiles.value,
            userId: configMapSysUser[form.configId],
            from: userNameMap[form.configId],
            text: tinyEditorWrite.value.tinyMCEEditor.getEditor().getContent(),
            teamId: teamId.value,
            followDay: followDayFlag.value === 1 ? trueTargetFollowDay.value : 0,
            isRepeat: route.query.mailType === "2" || route.query.mailType === "3" ? 1 : 0,  //mailType==2 草稿箱重发，mailType==3发件箱的重发
            timeKey: timestamp
          };
          console.log(data, currentTargetEmailDetail.value, '发送', type, configMapSysUser, form.to);
          const requestApi = type === "send" ? sendMail : type === 'examine' ? requestCommitEmailAudit : saveDraft;
          const loadingFlag = type === "send" ? loadingSend : type === 'examine' ? loadingExamine : loadingSave;
          const infoText = type === "send" ? i18n.global.t('common.email.list.sendSuccess') : i18n.global.t('sys.message.success.save');


          //1.收件箱入口把列表的主键id传到replyMailId，主键id不传，2.发件箱入口，id，replyMailId都不传,3.草稿箱入口，只需要把列表的id传到id，replyMailId不用传
          if (route.query.mailType === '1') {
            data.replyMailId = route.query.id
          }
          if (route.query.mailType === '2') {
            data.id = route.query.id
          }

          // 回复标识、转发 符号
          // if ((!route.query.type || route.query.type === "forward" || route.query.type === "replayAll" || route.query.mailType === "2" || route.query.mailType === "3") && (type === "send" || type === "save" || type === "examine")) {
          //   data.replyMailId = route.query.id
          // }

          loadingFlag.value = true
          requestApi(data)
              .then(async (res) => {
                console.log(res.result, "是否成功？");
                loadingFlag.value = false;
                if (res.code === 200) {
                  if (!route.query.type && route.query.isRead === '0') {
                    //回复成功改变邮件的阅读状态
                    await requestTagMail(route.query.id)
                  }

                  if (type === "examine") {
                    ElMessage.success(infoText);
                    // localStorage.setItem('activeMenu', '/email/examine')
                    router.push('/email/examine');
                  }
                  // router.replace('/email/inbox');
                  if (type !== "examine") {
                    // 邮件发送成功
                    router.push({
                      path: '/success',
                      query: {
                        id: route.query.id,
                        customerId: route.query.customerId,
                        to: JSON.stringify(data.to),
                        text: infoText,
                        flag: route.query.flag,
                        sendTime: form.sendTime,
                      }
                    });
                  }
                  if (cb) {
                    cb("success");
                  } else {
                    if (type === "send") {
                      // 发送成功 跳转
                      // this.$emit("changeRoute", {});
                    }
                  }
                } else {
                  loadingFlag.value = false;
                  ElMessage.error(res.message);
                }
              })
              .catch(e => {
                loadingFlag.value = false;
                // 出现错误后跳转到草稿箱
                setTimeout(() => {
                  router.push({path: "/email/draft"});
                }, TIME_OUT)
              })
              .finally(() => {
                loadingFlag.value = false;
              });
        } else {
          // ruleFormRef.value.resetFields();
          return false;
        }
      })
    }, TIME_OUT)

    const mapRecipients = (recipients, type) => {
      const customerListMap = customerList.value.reduce((prev, item) => {
        prev[item.email] = item;
        return prev;
      }, {});

      return recipients.map(item => {
        const mappedItem = customerListMap[item] ? {...customerListMap[item]} : {name: item, email: item};
        return {
          ...mappedItem
        };
      });
    };

    function handleUploadError(error) {
      console.log('上传失败', error);
    }

    const uploadCompleted = ref(false)

    async function handleUploadSuccess(e) {
      console.log('上传成功', e.result, multipartFiles.value);
      if (e.code === 200) {
        uploadCompleted.value = true;
        const res = mergeArrays(multipartFiles.value, [e.result])
        console.log(res, 'res')
        // 注意 multipartFiles 中有两种结构的数据类型
        res.forEach(file => {
          if (!file.extendSize)
            file.extendSize = file.size;
        });
        multipartFiles.value = res;
        console.log(multipartFiles.value, 'multipartFiles')
      }
    }

    // 合并数组并处理重复项
    function mergeArrays(array1, array2) {
      // 合并数组并处理重复项
      return array1.map((item1) => {
        const item2 = array2.find(
            (item2) => item2.name === item1.name && item2.size === item1.size
        );
        if (item2) {
          // 如果 filename 和 name 相同，则执行特定操作
          return {...item1, ...item2};
        } else {
          // 如果找不到匹配项，则保留原始数据
          return item1;
        }
      });
    }

    // 文件进度
    const uploadProgress = ref(false)

    function onProgress(progressEvent, file) {
      uploadProgress.value = true
      if (multipartFiles.value.filter(item => item.uid === file.uid).length === 0) {
        multipartFiles.value.push(file)
      }
      // 计算上传进度百分比
      file.percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      file.percentage === 100 ? uploadProgress.value = false : uploadProgress.value = true;
    }

    function handleDelMul(index) {
      multipartFiles.value.splice(index, 1);
    }

    function handleSelectCustomer(item) {
      if (!activeSelectProp.value) {
        return;
      }
      if (!form[activeSelectProp.value].includes(item.email)) {
        form[activeSelectProp.value].push(item.email);
      }
      console.log('click', item, form[activeSelectProp.value]);
    }

    function handleFocus(event, type) {
      console.log('聚焦', type);
      console.log(form.to, 'form.to,focus')
      activeSelectProp.value = type;
    }

    const isEmailReg = ref(false)

    function handleBlur() {
      const targetArray = form.to.filter(item => !emailReg.test(item));
      console.log(form.to, targetArray, 'form.to,blur', form.to.filter(item => emailReg.test(item)), targetArray, targetArray.length)
      if (targetArray.length >= 1) {
        ElMessage.warning(i18n.global.t('send.all.delete.no.email'))
        form.to = form.to.filter(item => emailReg.test(item))
        isEmailReg.value = true
      } else {
        isEmailReg.value = true;
      }
    }

    function originalEmailTemp(info) {
      let {from, to, cc, subject, content, createTime} = info;
      let ccTmp = cc.length ? `<div><b>Cc:</b>&nbsp;${cc.split(',').reduce((t, n) => t + `;&nbsp;${n}`)}</div>` : ''
      let replyTemplate = `
					<div><br /></div>
					<div style="font-size: 12px;background:#F5F5F5;padding:8px;color:#444;"><div>
					<div style="font-size: 12px;font-family: Arial Narrow;padding:2px 0 2px 0;">------------------ Original Message ------------------</div>
						<b>From:</b>&nbsp;${from}</div>
						<div><b>Date:</b>&nbsp;${createTime}</div>
						<div><b>To:</b>&nbsp;${to.split(',').reduce((t, n) => t + `;&nbsp;${n}`)}</div>
						${ccTmp}
						<div><b>Subject:</b>&nbsp;${subject}</div>
					</div>
					<div><br /></div>
					${content}
				`
      return replyTemplate;
    }

    const contentLoading = ref(false)
    const currentTargetEmailDetail = ref(null)

    const targetDetailObj = ref("")

    async function getDetail() {
      contentLoading.value = true;
      const result = await getMailDetails({id: route.query.id});
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      contentLoading.value = false
      console.log(result, 'resultdddd')
      currentTargetEmailDetail.value = result.result;
      const type = route.query.type;
      const mailRecipientVOList = result.result.mailRecipientVOList || [];
      const mailExtendList = type ? result.result.mailExtendList || [] : [];
      const mailBody = result.result.mailBody || {};

      const personInfo = mailRecipientVOList.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item);
        return prev;
      }, {});

      console.log('888888', formatText(mailRecipientVOList, 'cc').split(","))
      console.log(personInfo, personInfo.to[0].mail, route.query.type === 'follow', [personInfo.to[0].mail], route.query.type, mailBody, route.query.type === 'resend' || route.query.type === 'editMail' || route.query.type === 'follow' ? [personInfo.to[0].mail] : mailBody.from ? [mailBody.from] : [], formatText(mailRecipientVOList, 'to'), formatText(mailRecipientVOList, 'cc'), '收件人等信息');
      //========================= 默认回复 ==========================
      console.log('times', mailBody.followTime !== null, mailBody.followTime ? Math.ceil(Math.abs((new Date(mailBody.followTime).getTime() - new Date().getTime()) / 1000 / 3600 / 24)) : 0)
      form.to = route.query.mailType === "3" && type ? formatText(mailRecipientVOList, 'to').split(",") : route.query.flag === "again" ? [] : (type === 'resend' && route.query.mailType === "1" || route.query.mailType === "4") ? mailBody.from ? [mailBody.from] : [] : type === 'resend' || type === 'follow' || type === "editMail" ? [...personInfo.to.map(_ => (_.mail))] : mailBody.from ? [mailBody.from] : [];
      form.cc = route.query.mailType === "3" && type === "replayAll" ? formatText(mailRecipientVOList, 'cc').split(",").filter(item => item !== "") : personInfo.cc && (personInfo.cc && type === "replayAll" || personInfo.cc && type === "follow" || type === 'editMail' || type === 'resend') ? personInfo.cc.map(item => item.mail) : [];
      form.bcc = route.query.mailType === "3" && type === "replayAll" ? formatText(mailRecipientVOList, 'bcc').split(",").filter(item => item !== "") : personInfo.bcc && (personInfo.bcc && type === "replayAll" || personInfo.bcc && type === "follow" || type === 'editMail' || type === 'resend') ? personInfo.bcc.map(item => item.mail) : [];
      form.urgent = mailBody.urgent ? 1 : 0;
      form.receipt = mailBody.receipt ? 1 : 0;
      form.sendTime = type === 'resend' ? '' : mailBody.sendTime;
      // form.followDay = mailBody.followDay === 1 && Number(sendNum.value) !== 0 ? 1 : 0;
      followDayFlag.value = Math.ceil(Math.abs((new Date(mailBody.followTime).getTime() - new Date().getTime()) / 1000 / 3600 / 24)) > 0 ? 1 : 0;
      form.followDay = mailBody.followTime !== null ? Math.ceil(Math.abs((new Date(mailBody.followTime).getTime() - new Date().getTime()) / 1000 / 3600 / 24)) : 0
      handleSendDateChange(mailBody.followTime !== null ? mailBody.followTime : dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"))
      form.remark = mailBody.remark;
      form.content = (type === "resend" && route.query.mailType === '2') || (type === "resend" && route.query.mailType === "3") ? mailBody.content : `<br><br>${originalEmailTemp({
        ...mailBody,
        subject: type === 'forward' ? `Fw:` + mailBody.subject : `Re:` + mailBody.subject,
        cc: formatText(mailRecipientVOList, 'cc'),
        to: formatText(mailRecipientVOList, 'to'),
      })}`;
      // 富文本追加
      // 追加模版 和 原有内容
      targetDetailObj.value = form.content
      await nextTick(() => {
        tinyEditorWriteContentHtml.value = TargetEmailTemplate.value + form.content
      })
      if (tinyEditorWrite.value.tinyMCEEditor.getEditor()) {
        await nextTick(() => {
          tinyEditorWrite.value.tinyMCEEditor.getEditor().setContent(((TargetEmailTemplate.value ? TargetEmailTemplate.value : "") + form.content) || "")
          console.log(tinyEditorWriteContentHtml.value, 'tinyEditorWriteContentHtml.value')
        })
      }
      console.log(route.query.mailType, "转发的type")
      //发件箱||草稿箱--重发或者转发都带上附件
      if (type === "forward" || type === 'resend') {
        multipartFiles.value = mailExtendList.filter(_ => _.url).map(item => ({
          name: item.name,
          url: item.url,
          extendSize: item.extendSize,
        }));
      }


      form.subject = route.query.flag === "again" ? [] : type === 'editMail' || type === 'resend' ? mailBody.subject : `Re:` + mailBody.subject;
      if (type !== 'forward' && type !== 'follow' && type !== 'resend' && type !== 'editMail') {
        if (personInfo.to) {
          let index = null;
          personInfo.to.forEach(item => {
            index = userMail_dropDownOptionList.value.findIndex(el => el.label === item.mail);
          });
          console.log(index, '索引 配置id', configIdMapUserName, userMail_dropDownOptionList.value, userMail_dropDownOptionList.value[index]);
          if (userMail_dropDownOptionList.value[index]) {
            form.configId = configIdMapUserName[userMail_dropDownOptionList.value[index].label];
          }
        }
      } else {
        if (mailBody) {
          form.configId = mailBody.mailConfigId
        }
      }
      // 已发送 增加 回复、回复全部
      if (route.query.mailType === "3" && (type === "replayAll" || type !== "resend")) {
        form.configId = mailBody.mailConfigId
      }
      // 回复全部
      if (type === "replayAll" && route.query.mailType !== "3") {
        form.to = [mailBody.from, ...personInfo.to.filter(item => item.mail !== userNameMap[form.configId]).map(_ => (_.mail))]

        console.log(form.to, 'userNameMap', userNameMap)
      }
      //========================== 转发 ==============================
      if (type === 'forward') {
        // form.from = this.defaultMailbox
        form.to = [];
        form.cc = [];
        form.bcc = [];
        form.status = 1;
        form.subject = `Fw:` + mailBody.subject;
      }
      // =========================== 回复全部 ==================================
      if (type === 'replay') {
        form.to = personInfo.to ? personInfo.to.map(item => item.mail).filter(item => !configUserMail[item]) : [];
      }
      // 主题
      console.log('TargetEmailTemplateSubject.value', TargetEmailTemplateSubject.value)
      if (TargetEmailTemplateSubject.value !== "") {
        form.subject = TargetEmailTemplateSubject.value
      }
      console.log(form.to, '获取详情', result, form, userMail_dropDownOptionList.value);
    }

    const trueTargetFollowDay = ref(0)

    watch(() => sendNum.value, (newVal) => {
      console.log(newVal, 'newVal')
      followDayFlag.value = newVal > 0 ? 1 : 0;
      trueTargetFollowDay.value = newVal - 0;
      if (!Number(newVal)) {
        return;
      }
      const curDate = new Date().setHours(0, 0, 0, 0);
      const totalDate = new Date(curDate + Number(newVal) * 24 * 60 * 60 * 1000);
      sendDate.value = dayjs(totalDate).format('YYYY-MM-DD');
    });

    function handleSendDateChange(val) {
      const counts = new Date(val).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0);
      sendNum.value = Math.floor(counts / (24 * 60 * 60 * 1000));
      followDayFlag.value = sendNum.value > 0 ? 1 : 0;
      console.log(val, sendNum.value, 'val');
      console.log(counts / (24 * 60 * 60 * 1000), '数量');
    }


    function handleSetOpen() {
      open.value = !open.value;
      nextTick(() => {
        const editorWrap = document.getElementsByClassName('editor_wrap')[0];
        const height = editorWrap.clientHeight - 42;
        mainEditor.value.change.target.parentNode.style.height = height + 'px';
        mainEditor.value.change.target.parentNode.style.minHeight = height + 'px';
        mainEditor.value.change.target.style.height = height + 'px';
        mainEditor.value.change.target.style.minHeight = height + 'px';
      })
    }

    watch(() => teamId.value, () => {
      console.log('组件团队ID 监听', teamId.value);
      resetCurrentAboutCustomer()
      route.query.customerId ? getCustomerEmailByCustomerId() : getAllCustomer();
      getDefaultMailConfig();
    });


    const tinyEditorWrite = ref(null)
    const tinyEditorWriteContentHtml = ref('')

    const tinyEditorTemplate = ref(null)
    const tinyEditorTemplateContentHtml = ref("")


    onMounted(async () => {
      // 获取所有邮箱配置列表
      await initUserMailList();
      console.log('路由信息', route.query.id, userMail_dropDownOptionList);
      if (teamId.value) {
        route.query.customerId != null ? getCustomerEmailByCustomerId() : getAllCustomer();
        await getDefaultMailConfig();
        // 重发不用 所有重发不需要导入模版
        if (route.query.mailType !== '2' && (route.query.mailType !== '3' || (route.query.mailType === '3' && route.query.type !== "resend"))) {
          await getDefaultEmailTemplate();
        }
      }
      if (route.query.id) {
        await getDetail();
      }
      intervalId = setInterval(autoSaveDraft, 45000); // 每45秒调用一次保存草稿
    });

    const autoSaveDraft = async () => {
      var timestamp = Date.now();
      const subject = form.subject.trim() + '(自动草稿)'
      const data = {
        ...form,
        subject: subject,
        to: mapRecipients(form.to, "to"),
        cc: form.cc ? mapRecipients(form.cc, "cc") : [],
        bcc: form.bcc ? mapRecipients(form.bcc, "bbc") : [],
        multipartFiles: multipartFiles.value,
        groupId: '10',
        userId: configMapSysUser[form.configId],
        from: userNameMap[form.configId],
        text: tinyEditorWrite.value.tinyMCEEditor.getEditor().getContent(),
        teamId: teamId.value,
        followDay: followDayFlag.value === 1 ? trueTargetFollowDay.value : 0,
        isRepeat: route.query.mailType === "2" || route.query.mailType === "3" ? 1 : 0,
        timeKey: timestamp,
        id: id.value
      };

      //保存草稿
      saveDraft(data).then(res => {
        id.value = res.result.id;
      })
    }


    onUnmounted(() => {
      templateRef.value && templateRef.value.$refs.bodyWrapper.removeEventListener("mousewheel", scrollBehavior)
      // 消除定时器
      if (intervalId) {
        clearInterval(intervalId);
      }
    })


    // 附件预览
    const handleDetailFile = (item) => {
      const {url, name} = item
      // 获取扩展名
      const fileExtend = name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase()
      console.log(item, imgFormat.includes(fileExtend), fileExtend, 'item')

      if (imgFormat.includes(fileExtend)) {
        // 图片预览
        showTargetFile(item)
      } else if (pdfFormat.includes(fileExtend)) {
        // pdf
        //预览
        showTargetPdf(item);
      } else if (wordFormat.includes(fileExtend) || excelFormat.includes(fileExtend) || pptFormat.includes(fileExtend)) {
        // /world/excel
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(item.url))
      } else if (textFormat.includes(fileExtend)) {
        showTargetText(item)
      } else if (videoFormat.includes(fileExtend)) {
        showTargetVideo(item)
      } else if (audioFormat.includes(fileExtend)) {
        showTargetAudio(item)
      } else {
        // alert('下载')
        handleDownload(item.url, item.name)
      }
    }

    const openNewWindow = (url) => {
      window.open(url, "_blank");
    };
    const showTargetPdf = (row) => {
      console.log(row, 'row')
      let fullPath = `${location.origin}/showfile?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetText = (row) => {
      let fullPath = `${location.origin}/showtext?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetVideo = (row) => {
      // visitableVideo.value = true;
      let fullPath = `${location.origin}/showvideo?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetAudio = (row) => {
      let fullPath = `${location.origin}/showaudio?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}&title=${encodeURIComponent(row.name)}`;
      openNewWindow(fullPath)
    }


    const showTargetFile = (row) => {
      // alert(row.name)
      let images = [];
      let initialViewIndex = 0;

      // 过滤出非文件夹的文件
      multipartFiles.value.filter(_ => imgFormat.includes(_.name.substring(_.name.lastIndexOf(".") + 1).toLocaleLowerCase())).forEach((o, i) => {
        if (o.name === row.name) initialViewIndex = images.length
        images.push({src: o.url})
      })
      console.log(images, 'images')
      viewerApi({
        images,
        options: {
          initialViewIndex
        }
      })
    }


    return {
      subject,
      handleDetailFile,
      tinyEditorTemplateContentHtml,
      tinyEditorTemplate,
      tinyEditorWriteContentHtml,
      tinyEditorWrite,
      followDayFlag,
      trueTargetFollowDay,
      List,
      handleScroll,
      templateRef,
      isLoading,
      handleTableScroll,
      ruleFormRef,
      contentLoading,
      isEmailReg,
      customerLoading,
      beforeUpload,
      form,
      userMail_dropDownOptionList,
      open,
      action,
      templateFlag,
      addTemplateFlag,
      previewFlag,
      previewContent,
      editTemplate,
      templateName,
      currentTemplateMail,
      templateList,
      customerList,
      multipartFiles,
      sendNum,
      sendDate,
      filesRef,
      customerName,
      token: getToken(),
      handleSearchCustomer,
      handleSetOpen,
      handleDelMul,
      handleSendDateChange,
      handleFocus,
      handleBlur,
      handleDel,
      handleView,
      handleInitTemplateEditor,
      handleUse,
      handleSaveTemplate,
      handleEditTemplate,
      disabledDate,
      handleSelectTemplate,
      handleCurrentRowChange,
      handleSetDefault,
      handleSendMail,
      handleSelectCustomer,
      handleUploadError,
      handleUploadSuccess,
      onProgress,
      uploadCompleted,
      loadingSend,
      loadingExamine,
      loadingSave,
      writeSelectTargetFileDialog,
      closeWriteSelectTargetFileDialog,
      handleClickSelectFileFromCloud,
      getSelectTargetFile,
      downloadFiles,
      isLoadingAdd,
      rules,
      visibleDialog,
      createNewTask,
      close,
      calcelEditor,
      uploadProgress,
    }
  }
}
</script>

<style lang="less" scoped>
@import "./less/write_mail";

.upload-demo {
  height: 100% !important;
}

:deep(.el-upload-dragger) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}

:deep(.el-upload--text) {
  height: 100% !important;
}

:deep(.el-upload-dragger) {
  border: none !important;
}

:deep(.el-overlay:nth-of-type(1)) {
  z-index: 90 !important;
}

:deep(.el-overlay:nth-of-type(2)) {
  z-index: 99 !important;
}

iframe {
  border: none !important;
}

:deep(.el-dialog__title) {
  font-size: 20px !important;
}


:deep(.el-dialog__headerbtn) {
  font-size: 20px !important; /* 调整关闭按钮大小 */
}

:deep(.el-dialog__headerbtn .el-dialog__close) {
  color: #252f3d !important;
}
</style>
