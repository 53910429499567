<template>
  <div class="success_box">
    <div class="inners">
      <div class="right">
        <div>
          <el-icon style="color:#72c140;font-size: 58px">
            <SuccessFilled/>
          </el-icon>
        </div>
        <div class="inner_text">
          <span class="text" v-if="route.query.groupType">
            {{ $t('group.send.task.submit') }}
          </span>
          <span class="text" v-else>{{ $t('common.email') }}{{ texts }}!</span>
          <span v-if="texts===$t('common.email.list.sendSuccess')" style="margin-top: 8px">{{ $t('common.yjbcz') }}<el-button
              link type="primary"
              @click="toSend">{{
              route.query.sendTime ? `「${$t('common.draftsbox')}」` : route.query.groupType ? `「${$t('group.send.task.list')}」` : `「${$t('common.has.sent')}」`
            }}</el-button></span>
          <span v-if="texts===$t('sys.message.success.save')" style="margin-top: 8px">{{ $t('common.yjbcz') }} <el-button
              link
              type="primary"
              @click="toDraf">「{{ $t('common.draftsbox') }}」</el-button></span>
          <span style="margin-top: 17px">
            <span style="vertical-align: -webkit-baseline-middle;"><img src="@/assets/write.png" width="16"
                                                                        height="16"/></span>
            <el-button link type="primary" @click="requestAgain">{{ $t('common.team.toWriteLetter') }}</el-button>
          </span>
        </div>

        <div class="area_box" v-if="texts===$t('common.email.list.sendSuccess') && route.query.flag==='1'">
          <div>
            <div class="left">
              <span>{{ $t('group.send.belong.customer') }}</span>
              <span v-for="item in JSON.parse(route.query.to)" :key="item.email">{{ item.email }}</span>
            </div>
            <div class="right">
              <!--              <el-button link type="primary" @click="addCustomer">添加客户</el-button>-->
              <el-button link type="primary" @click="addTargetCustomer">
                {{ $t('common.khjd') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ReturnEmails ref="emailsReturn" v-if="dialogVisible" v-model="dialogVisible" @close="close"
                @createCustomer="createdNewCustomer" :targetEmail="JSON.parse(route.query.to)"

  ></ReturnEmails>
  <CustomerCreated ref="customerCreated" v-if="dialogVisibleCreatedMessage"
                   v-model="dialogVisibleCreatedMessage" @close="closeCreated" :innerDialog="dialogVisible"
                   @changeInnerDialog="close"></CustomerCreated>
</template>
<script setup>
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive, ref, defineExpose, nextTick} from "vue";
import ReturnEmails from "@/components/DiaPops/Returns/ReturnEmails.vue";
import CustomerCreated from "@/components/DiaPops/Returns/CustomerCreated.vue";
import {useStore} from "vuex";

const store = useStore()
const router = useRouter();
const route = useRoute()

const dialogVisible = ref(false)
const emailsReturn = ref(null)
const dialogVisibleCreatedMessage = ref(false)

const requestAgain = () => {
  if (route.query.groupType) {
    router.push({
      path: '/email/send_all',
      query: {
        id: route.query.id,
        customerId: route.query.customerId,
        type: route.query.groupType === "1" ? 1 : 2,
        flag: 'again',
      }
    })
  } else {
    router.push({
      path: '/email/write_mail',
      query: {id: route.query.id, type: "resend", customerId: route.query.customerId, flag: 'again'}
    })
  }
}

const toSend = () => {
  // localStorage.setItem('activeMenu', '/email/has_send')
  if (route.query.groupType) {
    router.push('/email/customer/sending')
  } else if (route.query.sendTime) {
    router.push("/email/draft")
  } else {
    router.push('/email/has_send')
  }
}

const toDraf = () => {
  // localStorage.setItem('activeMenu', '/email/draft')
  router.push('/email/draft')
}

const addCustomer = async () => {
  dialogVisibleCreatedMessage.value = true
  await store.commit('customer/setRuleForm', {
    zoneCode: "",
    customerCode: "",
    zoneAddress: "",
    customerName: "",
    setUpTime: "",
    economicNature: "",
    companyWebsite: "",
    companyAddress: "",
    phoneWayList: [],
    emailWayList: [],
    companyFax: "",
    companyRemark: "",
    customerLevel: "",
    customerType: "",
    customerTypeId: "",
    product: "",
    marketingRemark: " ",
    createBy: "",
    branchAdmin: "",
    mangerVOList: [{managerName: ''}]
  });
  const target = route.query.to;
  if (target && JSON.parse(route.query.to).length !== 0) {
    const result = convertData(JSON.parse(route.query.to))
    console.log(result, 'result')
    await store.commit('customer/setForm', {
      phones: [{phoneNumber: "", isDefault: true}],
      emails: result,
    })
  }
}

function convertData(inputData) {
  let result = [];

  inputData.forEach((entry, index) => {
    let isDefault = index === 0;
    result.push({email: entry.email, isDefault: isDefault});
  });

  return result;
}

const addTargetCustomer = async () => {
  dialogVisible.value = true;
  await nextTick();
  console.log(emailsReturn.value)
  await emailsReturn.value.getAllCustomers();

}
const close = () => {
  dialogVisible.value = false;
}
const closeCreated = () => {
  dialogVisibleCreatedMessage.value = false;
}

const texts = ref('');
onMounted(() => {
  texts.value = route.query.text;
})


const createdNewCustomer = async () => {
  dialogVisibleCreatedMessage.value = true
  await store.commit('customer/setRuleForm', {
    zoneCode: "",
    customerCode: "",
    zoneAddress: "",
    customerName: "",
    setUpTime: "",
    economicNature: "",
    companyWebsite: "",
    companyAddress: "",
    phoneWayList: [],
    emailWayList: [],
    companyFax: "",
    companyRemark: "",
    customerLevel: "",
    customerType: "",
    customerTypeId: "",
    product: "",
    marketingRemark: " ",
    createBy: "",
    branchAdmin: "",
    mangerVOList: [{managerName: ''}]
  });
  await store.commit('customer/setForm', {
    phones: [{phoneNumber: "", isDefault: true}],
    emails: [{email: "", isDefault: true}],
  })
}

</script>

<style lang="less" scoped>
@import "../Customer/less/detail.less";

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.success_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #f5f5f5;
  height: calc(100vh - 64px);
  padding-top: 47px;

  .text {
    height: 32px;
    font-size: 24px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
  }

  .inner_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;

    a {
      color: #1890FF;
    }
  }

  .again_write {
    width: 56px;
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #1890FF;
    line-height: 14px;
    margin-left: 2px;
    cursor: pointer;
  }

  .area_box {
    width: 575px;
    height: 89px;
    background: #EAF3FB;
    margin-top: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
    }

    .left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      & > span {
        padding: 4px 0px !important;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
    }

  }
}

.inners {
  width: 1000px !important;
  min-width: 1000px !important;
  padding-top: 58px;
  background: white;

  .right {
    height: max-content !important;
    //overflow: auto !important;
    padding-bottom: 40px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
}
</style>