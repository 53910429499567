<template>
  <div class="email">
    <template v-if="visitableSliderNav">
      <div :class="[!isClose?'SideTab':'SideTab_sp','scroll']" ref="SideTab">
        <div class="menu" @click="handleClickSide">
          <el-icon v-if="!isClose" style="color: #448ef7">
            <CaretLeft/>
          </el-icon>
          <el-icon v-else style="color: #448ef7">
            <CaretRight/>
          </el-icon>
        </div>
        <div
            class="opt fx pointer rlt"
            :class="{ s: i === tabIndex }"
            v-for="(o, i) in teamList"
            :key="i"
            @click="tabChange(i, o)"
            :style="{
            backgroundColor: !isEnterpriseChildUser? i === tabIndex ? 'rgb(217, 55, 55)' : '#fff':i === tabIndex ? 'rgb(62, 141, 87)' : '#fff',
          }"
        >
          <div class="fx-aj-c flex1 tab_item">
            <el-popover
                placement="right"
                :width="'max-content'"
                trigger="hover"
                :content="o.name"
                effect="dark"
                :show-after="500"
            >
              <template #reference>
                <div class="tab_name"> {{ o.name }}</div>
              </template>
            </el-popover>
          </div>

          <div
              class="ic"
              v-if="i !== tabIndex"
              :style="{ backgroundColor:!isEnterpriseChildUser? 'rgb(217, 55, 55)':'rgb(62, 141, 87)', width: '4px' }"
          ></div>
        </div>
        <div
            v-if="!isEnterpriseChildUser"
            class="opt fx-aj-c pointer"
            :class="{ s: tabIndex === 'add' }"
            @click="tabChange('add', { teamID: teamId })"
        >
          <img class="add" src="@/assets/img/tab/add.png"/>
        </div>
      </div>
      <SideBar/>
    </template>
    <CreateTeamPop v-if="visitable" v-model="visitable" @close="close"/>
    <div class="view">
      <!--      内部内容-->
      <router-view v-if="isRouterAlive"></router-view>
    </div>
    <!--    工位预警-->
    <PositionWarningPop v-if="dialogVisibleTips" v-model="dialogVisibleTips"
                        @close="closePositionDia"></PositionWarningPop>
    <!--    邮箱是否绑定？-->
    <Dialog
        v-model="dialogVisibleEmailBind"
        :width="400"
        :dialogVisible="dialogVisibleEmailBind"
        :isHeadLine="false"
        @update:dialogVisible="(val) => (dialogVisibleEmailBind = val)"
    >
      <template #content>
        <div class="cont">
          <span><el-icon style="color:#efb041;font-size: 20px;margin-right: 10px;"><QuestionFilled/></el-icon></span>
          <span class="text">{{ $t('common.email.reminderEmailAddress') }}</span>
        </div>
      </template>
      <template #footer>
        <div class="setting_left">
          <el-button @click="dialogVisibleEmailBind = false">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="goToAddShowConfig">{{ $t('common.confirm') }}</el-button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {computed, nextTick, onBeforeUpdate, onMounted, onUpdated, ref, watch} from "vue";
import {useStore} from "vuex";
import CreateTeamPop from "../components/DiaPops/CreateTeamPop.vue";
import SideBar from "./SideBar/SideBar.vue";
import {useRouter, useRoute} from "vue-router";
import Dialog from "@/components/Dialog.vue";
import '@/views/components/css/index.css'
import _store from "store2";
import {getMailConfigList} from "@/api";
import PositionWarningPop from "@/components/DiaPops/PositionWarningPop.vue";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import sidebar from "@/store/modules/sidebar";

export default {
  components: {PositionWarningPop, Dialog, CreateTeamPop, SideBar},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const teamList = computed(() => store.state.email.teamList);
    const tabIndex = ref(0)
    const getTabIndex = computed(() => {
      let indexs = [];
      if (route.query.teamID) {
        localStorage.setItem('teamId', route.query.teamID)
        const result = teamList.value.filter(item => item.id === route.query.teamID)
        indexs = result.map(item => teamList.value.indexOf(item));
      }
      console.log(localStorage.getItem('tabIndex'), '00000')
      return route.query.teamID ? indexs[0] : localStorage.getItem('tabIndex') === null ? 0 : localStorage.getItem('tabIndex') * 1
    })
    const visitable = ref(false);
    const isEnterpriseChildUser = computed(
        () => store.getters["user/isEnterpriseChildUser"]
    );
    const visitableSliderNav = computed(
        () => route.path !== '/email/write_mail' && route.path !== '/email/send_all' && !route.path.includes('/email/detail')
    );
    const mailWorkPosition = computed(() => store.state.user.mailWorkPosition)
    const dialogVisibleTips = ref(false)
    const teamId = computed(() => store.state.email.teamID)
    const userInfo = computed(() => store.state.user.userInfo);

    const dialogVisibleEmailBind = ref(false)
    const isRouterAlive = computed(() => store.state.sidebar.isRouterAlive)

    // 选择 team ID
    async function tabChange(index, item) {
      console.log(index, item);
      // 添加团队的时候需要判断是否还有工位？
      // 如果没有工位，则需要提示用户
      if (index === "add") {
        if (mailWorkPosition.value.freePosition !== 0) {
          visitable.value = true;
          return;
        } else {
          dialogVisibleTips.value = true;
          return;
        }
      }

      tabIndex.value = index;
      await store.commit("email/setCurTeamID", item.id);
      localStorage.setItem('tabIndex', index)
      router.push(`/email/inbox`);
      // 判断是否绑定了邮箱，如果绑定了不进行弹窗
      await getList()
      if (emailList.value.length === 0) {
        //  弹窗 是否需要绑定
        dialogVisibleEmailBind.value = true;
      }
      // 清除 本地 session 中 缓存的数据
      sessionStorageUtil.clear()
      //   获取权限
      await store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value})
      await store.dispatch("sidebar/getEmailGroupFileListApis", {teamId: teamId.value, isUpdateFileList: true})
    }

    async function close() {
      visitable.value = false;
      // 判断是否绑定了邮箱，如果绑定了不进行弹窗
      await getList()

      if (emailList.value.length === 0) {
        //  弹窗 是否需要绑定
        dialogVisibleEmailBind.value = true;
      }
    }

    const closePositionDia = () => {
      dialogVisibleTips.value = false
    }


    function toBuy() {
      dialogVisibleTips.value = false
      router.push({path: '/personal/buystorage', query: {index: 1}})
    }


    //  获取邮箱列表
    const tableData = ref([])
    const emailList = ref([])

    async function getList() {
      // console.log("用户信息ddd", _store.get("userInfo"), teamId.value);
      // 从 缓存中拿
      // const teamId = window.localStorage.getItem("teamId")
      await getMailConfigList({teamId: teamId.value})
          .then((res) => {
            console.log(res.result, "邮箱列表cc");
            const list = res.result || [];
            tableData.value = list;
            emailList.value = list.map((item) => ({
              label: item.username,
              value: item.id,
            }));
            console.log(emailList.value, 'emailList')
          })
    }

    const goToAddShowConfig = () => {
      dialogVisibleEmailBind.value = false;
      // localStorage.setItem('activeMenu', '/email/account_setting')
      router.push({path: `/email/account_setting`, query: {type: 'add'}})
    }


    const SideTab = ref(null)
    const isClose = computed(() => store.state.email.isClose)

    // 监听 isClose 变化，动态修改 SideTab 样式
    watch(() => isClose.value, (newVal) => {
      if (SideTab.value) {
        SideTab.value.style.width = newVal ? "10px" : "86px";
        SideTab.value.style.minWidth = newVal ? "10px" : "86px";
        SideTab.value.style.overflow = newVal ? "hidden" : "visible";
      }
      store.commit("email/setIsClose", newVal)
    });

    const handleClickSide = () => {
      store.commit("email/setIsClose", !isClose.value)
    }

    onMounted(async () => {
      await store.dispatch("user/getPositionByLoginList");
      tabIndex.value = getTabIndex.value;
      if (route.query.teamID) {
        await getList()
      }
    });

    return {
      isClose,
      SideTab,
      handleClickSide,
      isRouterAlive,
      teamList,
      tabIndex,
      isEnterpriseChildUser,
      visitableSliderNav,
      tabChange,
      close,
      visitable,
      dialogVisibleTips,
      mailWorkPosition,
      teamId,
      userInfo,
      dialogVisibleEmailBind,
      goToAddShowConfig,
      toBuy,
      closePositionDia
    };
  }

  ,
};
</script>
<style lang="less" scoped>
.tab_item {
  text-align: center;
  line-height: 32px;
  width: 76px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view {
  flex-grow: 1;
  height: calc(100vh - 64px);
  overflow: scroll;
  background-color: rgb(245, 245, 245);
}

.email {
  // width: 100vw;
  // height: 100vh;
  display: flex;
  overflow: hidden;
}

.flex1 {
  flex: 1;
}

.fx-aj-c {
  align-items: center;
  justify-content: center;
}

.fx {
  display: flex;
}

.pointer {
  cursor: pointer;
  transition: all 0.4s;
}

.rlt {
  position: relative;
}

.scroll {
  overflow: auto;
}

.SideTab_sp {
  position: relative;
  z-index: 20;
  width: 10px;
  min-width: 10px;
  //height: calc(100vh - 64px);
  background: #f0f0f0;
  padding: 20px 0 20px 10px;
  overflow: hidden;
  transition: all .3s ease-in-out;

  .menu {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 15px;
    cursor: pointer;
  }

  .opt {
    width: 100%;
    height: 32px;
    background: #ffffff;
    border-radius: 100px 0px 0px 100px;
    font-weight: 600;
    color: #444444;
    font-size: 14px;
    opacity: 0.4;
    margin-bottom: 20px;
    white-space: nowrap;

    &.s {
      opacity: 1;
      color: #fff;
    }
  }
}

.SideTab {
  position: relative;
  z-index: 20;
  width: 86px;
  min-width: 86px;
  //height: calc(100vh - 64px);
  background: #f0f0f0;
  padding: 20px 0 20px 10px;
  transition: all .3s ease-in-out;

  .menu {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 15px;
    cursor: pointer;
  }

  .opt {
    width: 100%;
    height: 32px;
    background: #ffffff;
    border-radius: 100px 0px 0px 100px;
    font-weight: 600;
    color: #444444;
    font-size: 14px;
    opacity: 0.4;
    margin-bottom: 20px;
    white-space: nowrap;

    &.s {
      opacity: 1;
      color: #fff;
    }
  }
}

.inners_warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.setting_center {
  display: flex;
  justify-content: center;
}

.setting_left {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.cont {
  display: flex;

  .text {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}

.tab_name {
  padding-left: 4px;
  width: 72px;
  text-overflow: ellipsis;
  overflow: hidden;
}

// 滑块样式
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

//定义滑块 内阴影+圆角
::-webkit-scrollbar-thumb {
  border-radius: 1em;
  cursor: pointer;
  background-color: #7f7f7f;
  opacity: 0.3;
}

// 轨道
::-webkit-scrollbar-track {
  border-radius: 1em;
}
</style>
