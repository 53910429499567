import {spaceSizeFormat} from "@/utils/file";

function formatEchartsData(spaceSize) {
    const {containsLocation, effectiveLocation, fileCloudLocation, freeLocation, emailFileLocation} = spaceSize;
    let targetObjArray = [{
        name: "common_cloud_file_space_available",
        num: freeLocation,
        numStr: spaceSizeFormat(freeLocation),
        percentage: Math.round((freeLocation / effectiveLocation) * 100).toFixed(2)
    }, {
        name: "common_file_cloud",
        num: fileCloudLocation,
        numStr: spaceSizeFormat(fileCloudLocation),
        percentage: Math.round((fileCloudLocation / effectiveLocation) * 100).toFixed(2)
    },]

    // {
    //     name: "common_email_file",
    //         num: emailFileLocation,
    //     numStr: spaceSizeFormat(emailFileLocation),
    //     percentage: 10.0
    // }
    return targetObjArray;
}

const echarts = require('echarts/lib/echarts');
require('echarts/lib/component/title');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');
require('echarts/lib/component/grid');
require('echarts/lib/chart/pie');


export {formatEchartsData, echarts}