const sessionStorageUtil = {
    /**
     * 存储数据到 sessionStorage
     * @param {string} key - 存储数据的键
     * @param {any} value - 存储的数据
     */
    setItem(key, value) {
        if (!key) return;
        let storageValue;
        try {
            storageValue = JSON.stringify(value);
        } catch (e) {
            console.error("Failed to stringify value", e);
            return;
        }
        sessionStorage.setItem(key, storageValue);
    },

    /**
     * 从 sessionStorage 获取数据
     * @param {string} key - 获取数据的键
     * @returns {any} - 存储的数据
     */
    getItem(key) {
        if (!key) return null;
        const value = sessionStorage.getItem(key);
        if (!value) return null;
        try {
            return JSON.parse(value);
        } catch (e) {
            console.error("Failed to parse value", e);
            return null;
        }
    },

    /**
     * 从 sessionStorage 删除数据
     * @param {string} key - 删除数据的键
     */
    removeItem(key) {
        if (!key) return;
        sessionStorage.removeItem(key);
    },

    /**
     * 清空 sessionStorage
     */
    clear() {
        sessionStorage.clear();
    }
};

export default sessionStorageUtil;
