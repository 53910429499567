<template>
  <div id="box">
    <div>
      <el-icon color="red" style="font-size: 2.5rem">
        <Warning/>
      </el-icon>
    </div>
    <div style="padding: 1.5rem">{{ $t('device.text') }}</div>
    <el-button style="margin: 0px!important;margin-top: 10px" @click="handleClick">{{ $t('device.con') }}</el-button>
  </div>
</template>

<script setup>
import {defineEmits} from "vue"

const emit = defineEmits()
const handleClick = () => {
  emit("change")
}

</script>
<style lang="less" scoped>
#box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
</style>
