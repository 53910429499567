<template>
  <div id="emialBrowser" class="rlt" :style="isPageEqResize ? `min-height: ${minHeight}` : 'height: 100vh'">
    <!-- 无显示内容 -->
    <div class="fx-aj-c h100 abs-c" v-if="status === 'noCnt'">
      <a-result status="warning" :title="$t('sys.tip.content.none')"></a-result>
    </div>
    <!-- 接口报错 -->
    <div class="fx-aj-c h100 abs-c" v-else-if="status === 'error'">
      <a-result status="error" :title="$t('sys.tip.content.failure')">
        <template #extra>
          <el-button key="console" type="primary" @click="render">{{ $t('common.reacquire') }}</el-button>
        </template>
      </a-result>
    </div>
    <!-- 加载中 -->
    <div class="fx-aj-c abs-c" v-if="loading">
      <div v-loading="loading"></div>
    </div>
    <iframe v-else id="pageframe" @load="iframeLoad" :srcdoc="filterContent" frameborder="0" class="scroll"
            style="width: 100%" :style="{ height: iframeHeight }" scrolling="no">
      <p>Your browser does not support preview features, please download Google browser or other modern browsers.</p>
    </iframe>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => ({
        messageID: '', //邮件id
        Address: '', //邮件地址
        Type: '',
        Key: '', //模糊搜索
        CustomerName: '', //客户名称
        CustomerRating: '', //客户等级
        ZoneCode: '', //客户国家编码
        // ProjectID, //项目ID
        // ProjectStatus, //项目状态(项目进度)
        // ReadTracking, //阅读追踪
        SendDate: '' //发送日期
      })
    },
    isPageEqResize: {
      //页面高度等于iframe高度模式
      type: Boolean,
      default: false
    },
    minHeight: {
      //页面高度等于iframe高度模式时，默认的组件高度
      type: String,
      default: 'calc(100vh - 327px)'
    },
    //自定义列表函数  返回值是个promise
    customFunction: {
      type: Function,
      default: null
    },
    content: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      status: '', //once: 第一次加载状态  noCnt:没内容状态 ok：内容显示成功 error:接口报错
      loading: false,
      filterContent: '',
      iframeHeight: '0px' //高度
    }
  },
  created() {
    if (!this.isPageEqResize) this.iframeHeight = '100%'
  },
  methods: {
    //获取邮件详情
    async render() {
      //iframe插入滚动条样式
      let style = `
			<style>
			::-webkit-scrollbar {
					width: 8px !important;
					height: 8px !important;
					background-color: #fafafa !important;
			}
			::-webkit-scrollbar-thumb {
						border-radius: 4px !important;
						background-color: #c1c1c1 !important;
				}
			/*	去除页眉 和 页尾 */
			@page { margin: 0; }
			</style>`;
      if (this.content) {
        /// 解决邮件a标签内部跳转问题
        let str = this.content
        var regex = /<a(.*?)target='[^']*'(.*?)>/gi
        str = str.replace(regex, "<a$1target='_blank '$2>")
        var regex1 = /<a(((?!target).)*?)>/gi
        var datasContent = str.replace(regex1, "<a target='_blank '$1>")
        ///
        this.status = 'ok'
        this.filterContent = style + datasContent
      } else {
        this.status = 'noCnt'
        this.filterContent = ''
      }
    },
    iframeLoad() {
      if (this.isPageEqResize) this.iframeHeight = document.querySelector('#pageframe').contentDocument.documentElement.scrollHeight + 'px'
    },
    //打印功能
    printPage() {
      document.querySelector('#pageframe').contentWindow.print()
    }
  }
}
</script>
<style media="print" scoped>

</style>

<style scoped>
.rlt {
  position: relative;
}

.fx-aj-c {
  align-items: center;
  justify-content: center;
}

.h100 {
  height: 100%;
}

.abs-c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll {
  overflow: auto;
}
</style>